/*
* @version : 2021.02.15
* @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
* @Copyright Notice : Copyright 2008. 금융결제원.
감독당국용 공지사항 목록 조회
*/
import {
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { userStore } from '../../../stores/supervisors/userStore';
import axiosUtil from '../../../utils/axiosUtil';
import * as CF from '../../common/template/ComponentForm';
import * as CM from '../../common/util/common';

//공지사항 조회조건 콤보박스
const searchTypeOptions = [
  { value: 'ALL', label: '제목 + 내용' },
  { value: 'TITLE', label: '제목' },
  { value: 'CONTENTS', label: '내용' },
];

//공지사항 검색 파라미터
const DEFAULT_HOOKS = {
  requestParams: {
    searchType: searchTypeOptions[0].value, //검색조건
    searchKeyword: '', //검색어
    pageNumber: 1, //페이지 요청 번호
    pageSize: 10, //페이지 사이즈
    sortDirection: 'DESC', //정렬조건
    sortProperty: 'registration_dtm', //정렬필드
  },
};

const BoardListM = ({ location }) => {
  const [noticeList, setNoticeList] = useState({}); //공지사항 목록
  const [noticeFixList, setNoticeFixList] = useState({}); //중요 공지사항 목록
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams); //공지사항 검색 파라미터
  const [buttonState, setButtonState] = useState(true); //조회버튼
  const [pagination, setPagination] = useState(CM.cfnSetPagination());
  const [requestParamsFlag, setRequestParamsFlag] = useState(true); //검색조건 초기화 여부

  let msg = []; //오류 메시지

  //공지사항 목록 조회
  useEffect(() => {
    userStore.handleNaviTrigger(); //네비 현행화
    //공지사항 목록 조회 결과 세팅
    const handleNoticeList = (response) => {
      setNoticeList(response);
      handlePaginationChange(response);
    };
    //중요 공지사항 목록 조회 결과 세팅
    const handleNoticeFixList = (response) => {
      setNoticeFixList(response);
    };
    //공지사항 목록 조회
    const getNoticeList = (param) => {
      return new Promise((resolve, reject) => {
        let url = 'web/supervisors/notices/search?';
        //전달받은 검색조건 있는 경우 적용
        if (requestParamsFlag && CM.cfnIsNotEmpty(location.state) && CM.cfnIsNotEmpty(location.state.requestParams)) {
          setRequestParams(location.state.requestParams);
          setRequestParamsFlag(false);
          param = location.state.requestParams;
        }
        url += `search_type=${param.searchType}&search_keyword=${param.searchKeyword}`;
        url += `&page_number=${param.pageNumber - 1}&page_size=${param.pageSize}`;
        url += `&sort_directions=${param.sortDirection}&sort_properties=${param.sortProperty}`;
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            msg.push('공지사항 ');
            resolve();
          },
        );
      });
    };
    //중요 공지사항 목록 조회
    const getNoticeFixList = () => {
      return new Promise((resolve, reject) => {
        let url = 'web/supervisors/notices/high-priority';
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            msg.push('중요 공지사항 ');
            resolve();
          },
        );
      });
    };
    //공지사항 조회
    const start = async () => {
      if (buttonState) {
        setButtonState(false);
        const noticeList = await getNoticeList(requestParams);
        const noticeFixList = await getNoticeFixList();
        if (CM.cfnIsNotEmpty(msg)) {
          await CM.cfnApiErrorMsg(msg, process.env.REACT_APP_M001);
        }
        handleNoticeList(noticeList);
        handleNoticeFixList(noticeFixList);
      }
    };
    start();
  }, [buttonState]);

  const handleNoticeSearch = (e) => setRequestParams((data) => ({ ...data, searchType: e.target.value })); //검색조건 변경
  const handleSearchKeyword = (e) => setRequestParams((data) => ({ ...data, searchKeyword: e.target.value })); //검색어 변경

  //조회 이벤트
  const handleClick = () => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 1,
    }));
    setButtonState(true);
  };

  //페이지 숫자클릭 이벤트함수
  const handleOffsetChange = (e, offset, page) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: page,
    }));
    setButtonState(true);
  };

  //페이지 상태 세팅
  const handlePaginationChange = (param) => {
    setPagination(CM.cfnSetPagination(param));
  };

  /* 컨텐츠 영역_공지사항_검색조회_셀렉트박스 */
  function SelectBox() {
    const iconComponent = (props) => {
      return <ExpandMoreIcon className={props.className + ' '} />;
    };
    return (
      <FormControl variant="filled">
        <CF.SelectForm
          arrayOption={searchTypeOptions}
          customProps={{ onChange: handleNoticeSearch }}
          testid="selectBoxTest"
          name="searchKeyword"
          value={requestParams.searchType}
          IconComponent={iconComponent}
        />
      </FormControl>
    );
  }

  /* 컨텐츠 영역_테이블 위 영역(목록개수, 조회시점, 조회건수) */
  function TblTop() {
    return (
      <div className="tblTop">
        <CF.TotalCountForm totalElements={pagination} />
      </div>
    );
  }

  //게시글 공지여부 세팅
  const setSeqId = (row, index) => (
    <span className={row.fix_yn ? 'newsMark' : ''}>{row.fix_yn ? '중요' : index + 1}</span>
  );

  //게시글 제목 세팅
  const setTitle = (row) => {
    const registrationDate = row.registration_dtm;
    return (
      <Link
        to={{
          pathname: '/board/' + row.seq_id,
          state: {
            requestParams: requestParams,
          },
        }}
      >
        <span>
          {row.title}
          {row.file_count > 0 ? (
            <span className="iconFile">
              {<AttachFileIcon />}
              <span className="hide">파일첨부</span>
            </span>
          ) : (
            ''
          )}
        </span>
        {CM.cfnDateCalculation(CM.cfnGetDate(), String(registrationDate).substr(0, 8)) <= 3 ? (
          <span className="iconNew">NEW</span>
        ) : (
          ''
        )}
      </Link>
    );
  };

  //검색어 입력창 엔터키 이벤트
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>공지사항</h4>
          <div className="searchArea">
            <div className="tblSearch">
              {/* 양식 자동 제출 방지 */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <table>
                  <colgroup>
                    <col width="17%" />
                    <col width="20%" />
                    <col width="37%" />
                    <col width="38%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <span className="hide">키워드 검색</span>
                      </th>
                      <td>
                        <div className="searchList">
                          <SelectBox></SelectBox>
                        </div>
                      </td>
                      <td>
                        <div className="searchList">
                          <div className="inputTxt" noValidate autoComplete="off">
                            <TextField
                              id="basicinput"
                              inputProps={{ 'data-testid': 'searchKeyword' }}
                              variant="outlined"
                              value={requestParams.searchKeyword}
                              onChange={handleSearchKeyword}
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <Button
                          vatiant="contained"
                          id="searchButton"
                          startIcon={<SearchIcon />}
                          onClick={handleClick}
                          data-testid="searchBtn"
                        >
                          조회
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
          <TblTop></TblTop>
          {(CM.cfnIsNotEmpty(noticeList) && CM.cfnIsNotEmpty(noticeList.contents)) ||
          (CM.cfnIsNotEmpty(noticeFixList) && CM.cfnIsNotEmpty(noticeFixList.list)) ? (
            <Fragment>
              <TableContainer>
                <Table className="tblTypenohead" aria-label="공지사항 목록">
                  <colgroup>
                    <col width="12%" />
                    <col width="58%" />
                    <col width="15%" />
                    <col width="15%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>번호</TableCell>
                      <TableCell>제목</TableCell>
                      <TableCell>게시일</TableCell>
                      <TableCell>조회수</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CM.cfnIsNotEmpty(noticeFixList) && CM.cfnIsNotEmpty(noticeFixList.list)
                      ? noticeFixList.list.map((row, index) => (
                          <TableRow
                            data-testid={'fixtablerow' + index}
                            key={'tablerow' + index}
                            className="bglightGrey"
                          >
                            <TableCell>{setSeqId(row, index)}</TableCell>
                            <TableCell component="th" scope="row" className="title">
                              {setTitle(row)}
                            </TableCell>
                            <TableCell>
                              {<DescriptionOutlinedIcon />}
                              {CM.cfnDateFormat(row.registration_dtm, 'yyyyMMdd')}
                            </TableCell>
                            <TableCell>
                              {<DraftsOutlinedIcon />}
                              {CM.cfnAddComma(row.inquiry_count)}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                    {CM.cfnIsNotEmpty(noticeList) && CM.cfnIsNotEmpty(noticeList.contents) ? (
                      noticeList.contents.map((row, index) => (
                        <TableRow data-testid={'tablerow' + index} key={'tablerow' + index}>
                          <TableCell>
                            {pagination.total - pagination.rowsPerPage * (requestParams.pageNumber - 1) - index}
                          </TableCell>
                          <TableCell component="th" scope="row" className="title">
                            {setTitle(row)}
                          </TableCell>
                          <TableCell>
                            {<DescriptionOutlinedIcon />}
                            {CM.cfnDateFormat(row.registration_dtm, 'yyyyMMdd')}
                          </TableCell>
                          <TableCell>
                            {<DraftsOutlinedIcon />}
                            {CM.cfnAddComma(row.inquiry_count)}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow data-testid="tablerow0" key="tablerow0">
                        <TableCell colSpan="4">조회된 내용이 없습니다.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {CM.cfnIsNotEmpty(noticeList) && CM.cfnIsNotEmpty(noticeList.contents) ? (
                <div className="paging">
                  <Fragment>
                    <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} />
                  </Fragment>
                </div>
              ) : null}
            </Fragment>
          ) : (
            <TableContainer>
              <Table className="tblTypenohead" aria-label="공지사항 목록">
                <colgroup>
                  <col width="12%" />
                  <col width="58%" />
                  <col width="15%" />
                  <col width="15%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>번호</TableCell>
                    <TableCell>제목</TableCell>
                    <TableCell>게시일</TableCell>
                    <TableCell>조회수</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow data-testid="tablerow0" key="tablerow0">
                    <TableCell colSpan="4">조회된 내용이 없습니다.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoardListM;
