import { useEffect } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import IntroP2P01 from '../../../../resource/styles/images/intro01.png';

const IntroduceP2PM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce">
          <h4>P2P 금융이란?</h4>
          <div className="section">
            <h5>개요</h5>
            <p className="areaR">
              <strong>P2P금융(Peer-to-Peer lending, 온라인투자연계금융)</strong>은 온라인플랫폼을 통하여 투자자의 자금을
              투자자가 지정한 차입자에게 대출하고 그 연계대출에 따른 원리금수취권을 투자자에게 제공하는 것을 말합니다.
            </p>
          </div>
          <div className="section">
            <h5>주요 업무흐름</h5>
            <ul className="areaR item flow">
              <li>
                <span className="titNum">01</span>
                <span>
                  차입자는 온라인투자연계금융업자(이하 ‘온투업자’)에게&nbsp;
                  <strong className="primaryBlue">연계대출을 신청</strong>하고, 온투업자는 자신의 온라인플랫폼에 해당
                  차입자의 연계대출과 관련된 연계투자 정보를 게시합니다.
                </span>
              </li>
              <li>
                <span className="titNum">02</span>
                <span>
                  투자자는 온라인플랫폼에 게시된 연계투자 정보를 바탕으로 특정 차입자의 대출에 대해&nbsp;
                  <strong className="primaryBlue">연계투자를 신청</strong>합니다.
                </span>
              </li>
              <li>
                <span className="titNum">03</span>
                <span>
                  연계대출액 모집이 완료되면, 연계대출이 실행되고&nbsp;
                  <strong className="primaryBlue">차입자에게 투자금이 전달</strong>됩니다.
                </span>
              </li>
              <li>
                <span className="titNum">04</span>
                <span>온투업자는 상환계획에 맞춰 차입자로부터 상환금을 회수하고 투자자에게 원리금을 지급합니다.</span>
              </li>
              <li className="importImg w100p mr0">
                <img src={IntroP2P01} alt="p2p대출의 구조" />
              </li>
            </ul>
          </div>
          <div className="section">
            <h5>이용 기대효과</h5>
            <ul className="areaR item benefit">
              <li>
                <span>
                  <p className="tit">차입자 측면</p>
                  중간신용등급의 차입자는 핀테크기술을 활용한 비대면방식으로&nbsp;
                  <strong className="primaryBlue">중금리 대출상품을 제공</strong>받을 수 있습니다.
                </span>
              </li>
              <li>
                <span>
                  <p className="tit">투자자 측면</p>
                  투자 과정이 간단하고 소액으로도 다양한 유형의 상품에&nbsp;
                  <strong className="primaryBlue">쉽게 투자</strong>할 수 있습니다. 또한 상대적으로&nbsp;
                  <strong className="primaryBlue">높은 수익률</strong>을 기대할 수 있습니다.
                </span>
              </li>
            </ul>
          </div>
          <div className="section">
            <h5>투자 시 유의사항</h5>
            <div className="areaR">
              <p>
                P2P투자 시 투자자의 투자원금과 수익은 <strong>보장되지 않으며</strong>, 발생하는 투자금 손실 등
                투자위험은 투자자가 부담하게 됩니다.
                <br />
                따라서 다음의 사항을 충분히 확인한 후 투자하실 것을 권장합니다.
              </p>
              <ul className="areaR item">
                <li>
                  <span className="titNum">01</span>
                  <span>
                    <p className="tit primaryBlue">공시된 온라인투자연계금융업자 정보</p>
                    예) 영업현황, 차입자의 상환능력평가 체계, 경영관련 정보 등
                  </span>
                </li>
                <li>
                  <span className="titNum">02</span>
                  <span>
                    <p className="tit primaryBlue">온라인플랫폼에 게시된 투자상품 정보</p>
                    예) 차주정보, 담보내용, 대출심사내용, 수익률 및 위험성 등
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroduceP2PM;
