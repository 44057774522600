/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * Navi
 */
import { Link } from 'react-router-dom';
import { useState, Fragment } from 'react';
import * as CM from '../../common/util/common';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/* 컨텐츠 영역_서브 네비게이션 */
function Navi() {
  let requestUri = window.location.href;
  const [style1depth, setStyle1depth] = useState({ display: 'none' });
  const [style2depth, setStyle2depth] = useState({ display: 'none' });
  const [style3depth, setStyle3depth] = useState({ display: 'none' });

  //1depth 네비명 세팅
  const get1depthNm = () => {
    if (requestUri.indexOf('/board/') > 0) {
      return '고객지원';
    } else if (requestUri.indexOf('/myp2pfinanceinfo/') > 0) {
      return '내 P2P 금융정보 조회';
    } else if (requestUri.indexOf('/statistics/') > 0) {
      return '통계조회';
    } else if (requestUri.indexOf('/serviceintro/') > 0) {
      return '서비스 소개';
    } else if (requestUri.indexOf('/useinfo') > 0) {
      return '이용안내';
    } else if (requestUri.indexOf('/termpolicy') > 0) {
      return '약관 및 정책';
    }
  };

  //2depth 네비명 세팅
  const get2depthNm = () => {
    if (requestUri.indexOf('/board/notice') > 0) {
      return '공지사항';
    } else if (requestUri.indexOf('/board/faq') > 0) {
      return 'FAQ';
    } else if (requestUri.indexOf('/board/downloads') > 0) {
      return '자료실';
    } else if (requestUri.indexOf('/myp2pfinanceinfo/investmentdetails') > 0) {
      return '투자내역 조회';
    } else if (requestUri.indexOf('/myp2pfinanceinfo/loandetails') > 0) {
      return '대출내역 조회';
    } else if (requestUri.indexOf('/statistics/intergratedinfo') > 0) {
      return 'P2P금융 통합정보';
    } else if (requestUri.indexOf('/statistics/productinfo') > 0) {
      return '연계투자 상품정보';
    } else if (requestUri.indexOf('/serviceintro/registrationCompanies') > 0) {
      return '이용기관 현황';
    } else if (requestUri.indexOf('/serviceintro/p2pfinanceintro') > 0) {
      return 'P2P 금융 소개';
    } else if (requestUri.indexOf('/serviceintro/centralagencyintro') > 0) {
      return '중앙기록관리기관 소개';
    } else if (requestUri.indexOf('/useinfo/userguide/') > 0) {
      return '이용자 안내';
    } else if (requestUri.indexOf('/useinfo/companiesguide') > 0) {
      return '이용기관 안내';
    } else if (requestUri.indexOf('/termpolicy/privacypolicy') > 0) {
      return '개인정보처리방침';
    } else {
      return '투자내역 조회';
    }
  };

  //3depth 네비명 세팅
  const get3depthNm = () => {
    if (requestUri.indexOf('/serviceintro/p2pfinanceintro/introducep2plaw') > 0) {
      return 'P2P 금융 관련 법령';
    } else if (requestUri.indexOf('/serviceintro/p2pfinanceintro/introducep2p') > 0) {
      return 'P2P금융이란? (온라인투자연계금융)';
    } else if (requestUri.indexOf('/serviceintro/centralagencyintro/introducetask') > 0) {
      return '주요 역할 및 특징';
    } else if (requestUri.indexOf('/serviceintro/centralagencyintro/introduceagency') > 0) {
      return '금융결제원 안내';
    } else if (requestUri.indexOf('/useinfo/userguide/p2pfinanceusage') > 0) {
      return 'P2P 금융 이용절차';
    } else if (requestUri.indexOf('/useinfo/companiesguide/openapiservice') > 0) {
      return '오픈 API 서비스';
    } else if (requestUri.indexOf('/useinfo/companiesguide/servicerequestinfo') > 0) {
      return '서비스 이용신청 안내';
    }
  };

  //2depth 네비 메뉴박스 세팅
  const get2depthNavi = () => {
    if (requestUri.indexOf('/board/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/board/notice">공지사항</Link>
          </li>
          <li>
            <Link to="/board/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/board/downloads">자료실</Link>
          </li>
          <li>
            <a href={'mailto:' + process.env.REACT_APP_SUPPORT_QUESTION_URL}>문의하기</a>
          </li>
          <li>
            <a href={'mailto:' + process.env.REACT_APP_SUPPORT_SUGGESTION_URL}>제안하기</a>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/myp2pfinanceinfo/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/myp2pfinanceinfo/investmentdetails">투자내역 조회</Link>
          </li>
          <li>
            <Link to="/myp2pfinanceinfo/loandetails">대출내역 조회</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/statistics/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/statistics/intergratedinfo">P2P금융 통합정보</Link>
          </li>
          <li>
            <Link to="/statistics/productinfo">연계투자 상품정보</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/serviceintro/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/serviceintro/p2pfinanceintro/introducep2p">P2P금융 소개</Link>
          </li>
          <li>
            <Link to="/serviceintro/centralagencyintro/introducetask">중앙기록관리기관 소개</Link>
          </li>
          <li>
            <Link to="/serviceintro/registrationCompanies">이용기관 현황</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/useinfo') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/useinfo/userguide/p2pfinanceusage">이용자 안내</Link>
          </li>
          <li>
            <Link to="/useinfo/companiesguide/openapiservice">이용기관 안내</Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="depthDetail">
          <li>
            <Link to="/myp2pfinanceinfo/investmentdetails">투자내역 조회</Link>
          </li>
          <li>
            <Link to="/myp2pfinanceinfo/loandetails">대출내역 조회</Link>
          </li>
        </ul>
      );
    }
  };

  //3depth 네비 메뉴박스 세팅
  const get3depthNavi = () => {
    if (requestUri.indexOf('/p2pfinanceintro') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style3depth}
          onMouseLeave={() => {
            setStyle3depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/serviceintro/p2pfinanceintro/introducep2p">P2P금융이란? (온라인투자연계금융)</Link>
          </li>
          <li>
            <Link to="/serviceintro/p2pfinanceintro/introducep2plaw">P2P 금융 관련 법령</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/centralagencyintro') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style3depth}
          onMouseLeave={() => {
            setStyle3depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/serviceintro/centralagencyintro/introducetask">주요역할 및 특징</Link>
          </li>
          <li>
            <Link to="/serviceintro/centralagencyintro/introduceagency">금융결제원 안내</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/userguide') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style3depth}
          onMouseLeave={() => {
            setStyle3depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/useinfo/userguide/p2pfinanceusage">P2P 금융 이용절차</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/companiesguide') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style3depth}
          onMouseLeave={() => {
            setStyle3depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/useinfo/companiesguide/openapiservice">오픈 API 서비스</Link>
          </li>
          <li>
            <Link to="/useinfo/companiesguide/servicerequestinfo">서비스 이용신청 안내</Link>
          </li>
        </ul>
      );
    }
  };

  return (
    <div className="navi">
      <ul className="naviWrap">
        <li>
          <Link to="/main" className="home">
            <span>HOME</span>
            <HomeIcon />
          </Link>
        </li>
        {requestUri.indexOf('/termpolicy') > 0 ? (
          <Fragment>
            <li>
              <button>
                <span>
                  <h2>{get1depthNm()}</h2>
                </span>
              </button>
            </li>
            <li>
              <button>
                <span>
                  <h3>{get2depthNm()}</h3>
                </span>
              </button>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li
              onClick={() => {
                setStyle1depth({ display: 'block' });
              }}
              onMouseLeave={() => {
                setStyle1depth({ display: 'none' });
              }}
            >
              <button>
                <span>
                  <h2>{get1depthNm()}</h2>
                </span>
                <ExpandMoreIcon />
              </button>
              <ul
                className="depthDetail"
                style={style1depth}
                onMouseLeave={() => {
                  setStyle1depth({ display: 'none' });
                }}
              >
                <li>
                  <Link to="/serviceintro/p2pfinanceintro/introducep2p">서비스 소개</Link>
                </li>
                <li>
                  <Link to="/useinfo/userguide/p2pfinanceusage">이용안내</Link>
                </li>
                <li>
                  <Link to="/myp2pfinanceinfo/investmentdetails">내 P2P 금융정보 조회</Link>
                </li>
                <li>
                  <Link to="/statistics/intergratedinfo">통계조회</Link>
                </li>
                <li>
                  <Link to="/board/notice">고객지원</Link>
                </li>
              </ul>
            </li>
            <li
              onClick={() => {
                setStyle2depth({ display: 'block' });
              }}
              onMouseLeave={() => {
                setStyle2depth({ display: 'none' });
              }}
            >
              <button>
                <span>
                  <h3>{get2depthNm()}</h3>
                </span>
                <ExpandMoreIcon />
              </button>
              {get2depthNavi()}
            </li>
          </Fragment>
        )}
        {(requestUri.indexOf('/serviceintro/p2pfinanceintro') > 0 ||
          requestUri.indexOf('/serviceintro/centralagencyintro') > 0 ||
          requestUri.indexOf('/useinfo') > 0) && (
          <li
            onClick={() => {
              setStyle3depth({ display: 'block' });
            }}
            onMouseLeave={() => {
              setStyle3depth({ display: 'none' });
            }}
          >
            <button>
              <span>
                <h3>{get3depthNm()}</h3>
              </span>
              <ExpandMoreIcon />
            </button>
            {get3depthNavi()}
          </li>
        )}
      </ul>
    </div>
  );
}
export default Navi;
