/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 대출내역 조회 메인 화면
 * - 개인정보 수집 및 이용동의
 * - 로그인
 * - 내 p2p 금융정보 조회
 */
import LoginS from '../login/LoginS';
import LoginCheckS from '../login/LoginCheckS';
import { useEffect, useState } from 'react';
import LoanDetailsS from './LoanDetailsS';
import { userStore } from '../../../../stores/customers/userStore';
import { observer } from 'mobx-react';
import * as CM from '../../../common/util/common';
import qs from 'qs';

const LoanM = observer((location) => {
  const [tabIndex, setTabIndex] = useState(0); // Tab 인덱스
  const [loginSFlag, setLoginSFlag] = useState(true); // 로그인 페이지 즉시 이동 여부

  const handleNext = () => {
    setTabIndex((tabIndex) => tabIndex + 1);
  };

  const switchSubPage = (param) => {
    switch (param) {
      case 0:
        if (userStore.isLogin || userStore.getIsLogin()) {
          setTabIndex(2);
          <LoanDetailsS />;
        } else {
          return <LoginCheckS handleNext={handleNext} />;
        }
      /*falls through */
      case 1:
        return <LoginS handleNext={handleNext} />;
      /*falls through */
      case 2:
        if (userStore.isLogin || userStore.getIsLogin()) {
          return <LoanDetailsS />;
        } else {
          setTabIndex(0);
          return <LoginCheckS handleNext={handleNext} />;
        }
      /*falls through */
      default:
        return <LoginCheckS handleNext={handleNext} />;
    }
  };

  useEffect(() => {
    //로그인 실패 시 보안을 위해 화면 새로고침 처리
    if (loginSFlag) {
      const query = qs.parse(location.location.search, {
        ignoreQueryPrefix: true,
      });
      if (CM.cfnIsNotEmpty(query.loginS) && query.loginS === 'Y') {
        handleNext();
        setLoginSFlag(false);
      }
    }
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        {tabIndex === 0 || tabIndex === 1 ? (
          <div className="stepP2P">
            <ul>
              <li className={tabIndex === 0 ? 'on' : ''}>
                <div>
                  <p className="num">step 01.</p>
                  <p>개인정보 수집 및 이용동의</p>
                </div>
              </li>
              <li className={tabIndex === 1 ? 'on' : ''}>
                <div>
                  <p className="num">step 02.</p>
                  <p>로그인</p>
                </div>
              </li>
            </ul>
          </div>
        ) : (
          ''
        )}
        {switchSubPage(tabIndex)}
      </div>
    </div>
  );
});

export default LoanM;
