/*
 * @version : 2021.02.09
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * (공통) axios API 호출
 */
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import * as CM from '../components/common/util/common';
import { userStore as customersUserStore } from '../stores/customers/userStore';
import { userStore as companiesUserStore } from '../stores/companies/userStore';
import { userStore as supervisorsUserStore } from '../stores/supervisors/userStore';
/*
 * @desc    url 인코딩
 * @param   url
 * @return  url
 */
const getEncUrl = (url) => {
  if (url.indexOf('?') > 0) {
    return String(url).substr(0, url.indexOf('?')) + encodeURI(String(url).substr(url.indexOf('?'), url.length));
  }
  return url;
};

export const cfnIsEmpty = (val) => {
  if (val === null) return true;
  if (typeof val === 'undefined') return true;
  if (typeof val === 'string' && val.trim() === '') return true;
  if (Array.isArray(val) && val.length < 1) return true;
  if (
    typeof val === 'object' &&
    val.constructor.name === 'Object' &&
    Object.keys(val).length < 1 &&
    Object.getOwnPropertyNames(val) < 1
  )
    return true;
  if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true;

  return false;
};

const cfnIsNotEmpty = (val) => {
  if (val === null) return false;
  if (typeof val === 'undefined') return false;
  if (typeof val === 'string' && val.trim() === '') return false;
  if (Array.isArray(val) && val.length < 1) return false;
  if (
    typeof val === 'object' &&
    val.constructor.name === 'Object' &&
    Object.keys(val).length < 1 &&
    Object.getOwnPropertyNames(val).length < 1
  )
    return false;
  if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return false;

  return true;
};

/*
 * @desc    로그인 토큰 가져오기
 * @param
 * @return  token
 */
const getAuthToken = () => {
  let token = '';
  let domain = window.location.hostname;
  if (domain === process.env.REACT_APP_CUSTOMERS) {
    token = customersUserStore.getUserToken();
  } else if (domain === process.env.REACT_APP_COMPANIES) {
    token = companiesUserStore.getAuthToken();
  } else if (domain === process.env.REACT_APP_SUPERVISORS) {
    token = supervisorsUserStore.getAuthToken();
  }
  return token;
};

const axiosUtil = {
  /*
   * @desc    백엔드 서버 API 요청 함수
   * @param   {
   *            url: string
   *            method: string
   *            data: object
   *            authToken: string
   *            fncallback: function
   *            fnErrorCallback: function
   *            isMultipart: boolean
   *            silent: boolean
   *          }
   * @return  {
   *            data: object
   *            status: int
   *            reponse: object
   *          }
   */
  cfnAxiosApi: (
    url,
    method,
    data,
    authToken,
    fnCallback,
    fnErrorCallback,
    isMultipart,
    silent = false,
    session = true,
  ) => {
    let baseUrl =
      process.env.REACT_APP_MOCK === 'false' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_MOCK_URL;
    let domain = window.location.hostname;
    if (session && domain === process.env.REACT_APP_CUSTOMERS && customersUserStore.getIsLogin() === 'true') {
      customersUserStore.loginTimerInit();
    }
    const config = {
      url: baseUrl + getEncUrl(url),
      method: method,
      responseType: 'application/json',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    // data가 존재하는 경우 설정
    if (data) config.data = data;

    let authorizationToken = getAuthToken();
    //인증 토큰 값이 있을 때 설정
    if (cfnIsNotEmpty(authorizationToken)) {
      config.headers.Authorization = authorizationToken;
    }

    //status 값을 담은 object
    const fnSetStatus = (status, statusText) => {
      return { status: status, statusText: statusText };
    };
    //파일포함 전송 시 Content-type 설정 변경
    if (isMultipart !== 'undefined' && isMultipart) {
      config.headers['Content-type'] = 'multipart/form-data';
    }
    trackPromise(
      axios(config)
        .then((response) => {
          const objStatus = fnSetStatus(response.status, response.statusText);
          if (CM.cfnIsNotEmpty(response.data) && CM.cfnIsNotEmpty(response.headers.api_trx_dtm)) {
            response.data.api_trx_dtm = response.headers.api_trx_dtm;
            response.data.api_trx_no = response.headers.api_trx_no;
          }
          if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === 'function') fnCallback(objStatus, response.data);
        })
        .catch((error) => {
          //Network error 발생할 때
          if (cfnIsEmpty(error.response)) {
            //추후 오류 페이지로 이동하는 로직 필요
            CM.cfnAlert('네트워크 오류 또는 네트워크에 접속할 수 없어 문제가 발생했습니다. 관리자에게 문의해주세요.');
            return false;
          }
          if (CM.cfnIsNotEmpty(error.response.data) && CM.cfnIsNotEmpty(error.response.headers.api_trx_dtm)) {
            error.response.data.api_trx_dtm = error.response.headers.api_trx_dtm;
            error.response.data.api_trx_no = error.response.headers.api_trx_no;
          } else {
            error.response.data = {
              api_trx_dtm: error.response.headers.api_trx_dtm,
              api_trx_no: error.response.headers.api_trx_no,
            };
          }
          const objStatus = fnSetStatus(error.response.status, error.response.statusText);
          if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === 'function') {
            //error callback 함수 호출
            fnErrorCallback(objStatus, error.response.data);
          } else if (!silent) {
            //error callback 함수 없는 경우 백엔드서버 오류 메시지 표시
            if (CM.cfnIsNotEmpty(error.response.data) && CM.cfnIsNotEmpty(error.response.data.rsp_message)) {
              CM.cfnAlert(error.response.data.rsp_message);
            } else {
              //백엔드서버 오류 메세지 없는 경우 기본 오류 메시지 표시
              CM.cfnAlert(error);
            }
          }
        }),
    );
  },

  /*
   * @desc    백엔드 서버 로그인 API 요청 함수
   * @param   {
   *            url: string
   *            method: string
   *            data: object
   *            authToken: string
   *            fncallback: function
   *            fnErrorCallback: function
   *            isMultipart: boolean
   *            silent: boolean
   *          }
   * @return  {
   *            data: object
   *            status: int
   *            reponse: object
   *          }
   */
  cfnAxiosLoginApi: (url, method, data, authToken, fnCallback, fnErrorCallback, isMultipart, silent = false) => {
    let baseUrl =
      process.env.REACT_APP_MOCK === 'false' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_MOCK_URL;
    const config = {
      url: baseUrl + getEncUrl(url),
      method: method,
      responseType: 'application/json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    };

    // data가 존재하는 경우 설정
    if (data) config.data = data;

    let authorizationToken = getAuthToken();
    //인증 토큰 값이 있을 때 설정
    if (cfnIsNotEmpty(authorizationToken)) {
      config.headers.Authorization = authorizationToken;
    }

    //status 값을 담은 object
    const fnSetStatus = (status, statusText) => {
      return { status: status, statusText: statusText };
    };
    //파일포함 전송 시 Content-type 설정 변경
    if (isMultipart !== 'undefined' && isMultipart) {
      config.headers['Content-type'] = 'application/x-www-form-urlencoded';
    }
    trackPromise(
      axios(config)
        .then((response) => {
          const objStatus = fnSetStatus(response.status, response.statusText);
          if (CM.cfnIsNotEmpty(response.data) && CM.cfnIsNotEmpty(response.headers.api_trx_dtm)) {
            response.data.api_trx_dtm = response.headers.api_trx_dtm;
            response.data.api_trx_no = response.headers.api_trx_no;
          }
          if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === 'function') fnCallback(objStatus, response.data);
        })
        .catch((error) => {
          //Network error 발생할 때
          if (cfnIsEmpty(error.response)) {
            //추후 오류 페이지로 이동하는 로직 필요
            CM.cfnAlert('네트워크 오류 또는 네트워크에 접속할 수 없어 문제가 발생했습니다. 관리자에게 문의해주세요.');
            return false;
          }
          if (CM.cfnIsNotEmpty(error.response.data) && CM.cfnIsNotEmpty(error.response.headers.api_trx_dtm)) {
            error.response.data.api_trx_dtm = error.response.headers.api_trx_dtm;
            error.response.data.api_trx_no = error.response.headers.api_trx_no;
          } else {
            error.response.data = {
              api_trx_dtm: error.response.headers.api_trx_dtm,
              api_trx_no: error.response.headers.api_trx_no,
            };
          }
          const objStatus = fnSetStatus(error.response.status, error.response.statusText);
          if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === 'function') {
            //error callback 함수 호출
            fnErrorCallback(objStatus, error.response.data);
          } else if (!silent) {
            //error callback 함수 없는 경우 백엔드서버 오류 메시지 표시
            if (CM.cfnIsNotEmpty(error.response.data) && CM.cfnIsNotEmpty(error.response.data.rsp_message)) {
              CM.cfnAlert(error.response.data.rsp_message);
            } else {
              //백엔드서버 오류 메세지 없는 경우 기본 오류 메시지 표시
              CM.cfnAlert(error);
            }
          }
        }),
    );
  },

  cfnAxiosImgPreviewApi: (url, method, data, authToken, fnCallback, fnErrorCallback, silent = false) => {
    let baseUrl =
      process.env.REACT_APP_MOCK === 'false' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_MOCK_URL;
    const config = {
      url: baseUrl + getEncUrl(url),
      method: method,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    // data가 존재하는 경우 설정
    if (data) config.data = data;

    let authorizationToken = getAuthToken();
    //인증 토큰 값이 있을 때 설정
    if (cfnIsNotEmpty(authorizationToken)) {
      config.headers.Authorization = authorizationToken;
    }

    //status 값을 담은 object
    const fnSetStatus = (status, statusText) => {
      return { status: status, statusText: statusText };
    };

    trackPromise(
      axios(config)
        .then((response) => {
          response.data.type = response.headers['content-type'];
          const objStatus = fnSetStatus(response.status, response.statusText);
          if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === 'function') fnCallback(objStatus, response.data);
        })
        .catch((error) => {
          if (cfnIsEmpty(error) && !silent) {
            CM.cfnAlert(error);
            return false;
          }

          let message = '';
          if (typeof error.response.data === 'string') message = error.response.data;
          else if (typeof error.response.data === 'object') message = error.response.data.message;

          const objStatus = fnSetStatus(error.response.status, message);

          // error callback이 필요할 때 호출
          if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === 'function') {
            fnErrorCallback(objStatus);
          } else if (!silent) {
            // (임시) error message 출력
            CM.cfnAlert(message);
          }
        }),
    );
  },

  /*
   * @desc    axios를 처리하는 함수 (일반 파일 다운로드 처리)  (ex: 공지사항 첨부파일다운)
   * @param   {
   *            url: string
   *            method: string
   *            data: object
   *            fncallback: function
   *            fnErrorCallback: function
   *            isMultipart : boolean
   *          }
   * @return  {
   *            data: object
   *            status: int
   *            reponse: object
   *          }
   */
  cfnAxiosGeneralFileDownload: (
    url,
    method,
    data,
    fileName,
    fnCallback,
    fnErrorCallback,
    isMultipart,
    silent = false,
  ) => {
    let baseUrl =
      process.env.REACT_APP_MOCK === 'false' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_MOCK_URL;
    const config = {
      url: baseUrl + getEncUrl(url),
      method: method,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/json,application/octet-stream,text/plain;charset=UTF-8',
      },
      withCredentials: true,
    };

    // data가 존재하는 경우 설정
    if (data) config.data = data;

    let authorizationToken = getAuthToken();
    //인증 토큰 값이 있을 때 설정
    if (cfnIsNotEmpty(authorizationToken)) {
      config.headers.Authorization = authorizationToken;
    }

    //status 값을 담은 object
    const fnSetStatus = (status, statusText) => {
      return { status: status, statusText: statusText };
    };

    trackPromise(
      axios(config)
        .then((response) => {
          const objStatus = fnSetStatus(response.status, response.statusText);
          let headers_filename = '';
          if (CM.cfnIsNotEmpty(response.data) && CM.cfnIsNotEmpty(response.headers['content-disposition'])) {
            headers_filename = response.headers['content-disposition'];
            if (headers_filename.indexOf("inline; filename*=UTF-8''") > -1) {
              let utfFilename = headers_filename.split("inline; filename*=UTF-8''")[1];

              if (utfFilename.indexOf('.') > -1) {
                let tmp = utfFilename.split('.');
                headers_filename = decodeURI(tmp[0]) + '.' + tmp[1];
              } else {
                headers_filename = decodeURI(utfFilename);
              }
            }
          }

          if (CM.cfnIsEmpty(fileName)) {
            //지정 파일명이 없으면 headers의 파일명으로 지정
            fileName = headers_filename;
          }

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], { type: 'application/octet-stream,text/plain' }),
              fileName,
            );
          } else {
            const blobURL = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/octet-stream,text/plain' }),
            );
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', fileName);
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
          }
          if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === 'function') fnCallback(objStatus, response.data);
        })
        .catch((error) => {
          if (cfnIsEmpty(error.response)) {
            //네트워크 오류 발생 시
            CM.cfnAlert('네트워크 오류 또는 네트워크에 접속할 수 없어 문제가 발생했습니다. 관리자에게 문의해주세요.');
            return false;
          }
          if (CM.cfnIsNotEmpty(error.response.data)) {
            error.response.data.api_trx_dtm = error.response.headers.api_trx_dtm;
            error.response.data.api_trx_no = error.response.headers.api_trx_no;
          } else {
            error.response.data = {
              api_trx_dtm: error.response.headers.api_trx_dtm,
              api_trx_no: error.response.headers.api_trx_no,
            };
          }
          const objStatus = fnSetStatus(error.response.status, error.response.statusText);
          if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === 'function') {
            //error callback 함수 호출
            fnErrorCallback(objStatus, error.response.data);
          } else if (!silent) {
            //error callback 함수 없는 경우 백엔드서버 오류 메시지 표시
            if (CM.cfnIsNotEmpty(error.response.data) && CM.cfnIsNotEmpty(error.response.data.rsp_message)) {
              CM.cfnAlert(error.response.data.rsp_message);
            } else {
              //백엔드서버 오류 메세지 없는 경우 기본 오류 메시지 표시
              CM.cfnAlert(error);
            }
          }
        }),
    );
  },

  /*
   * @desc    axios를 처리하는 함수 (엑셀 파일 다운로드 처리)
   * @param   {
   *            url: string
   *            method: string
   *            data: object
   *            fncallback: function
   *            fnErrorCallback: function
   *            isMultipart : boolean
   *          }
   * @return  {
   *            data: object
   *            status: int
   *            reponse: object
   *          }
   */
  cfnAxiosExcelFileDownload: (url, method, data, fileName, fnCallback, fnErrorCallback, silent = false) => {
    let baseUrl =
      process.env.REACT_APP_MOCK === 'false' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_MOCK_URL;

    const config = {
      url: baseUrl + getEncUrl(url),
      method: method,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    // data가 존재하는 경우 설정
    if (data) config.data = data;

    let authorizationToken = getAuthToken();
    //인증 토큰 값이 있을 때 설정
    if (cfnIsNotEmpty(authorizationToken)) {
      config.headers.Authorization = authorizationToken;
    }

    //status 값을 담은 object
    const fnSetStatus = (status, statusText) => {
      return { status: status, statusText: statusText };
    };

    trackPromise(
      axios(config)
        .then((response) => {
          const objStatus = fnSetStatus(response.status, response.statusText);

          const blobURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
          const tempLink = document.createElement('a');
          tempLink.href = blobURL;
          tempLink.setAttribute('download', fileName);
          document.body.appendChild(tempLink);
          tempLink.click();

          if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === 'function') fnCallback(objStatus, response.data);
        })
        .catch((error) => {
          if (cfnIsEmpty(error.response)) {
            //네트워크 오류 발생 시
            CM.cfnAlert('네트워크 오류 또는 네트워크에 접속할 수 없어 문제가 발생했습니다. 관리자에게 문의해주세요.');
            return false;
          }
          if (CM.cfnIsNotEmpty(error.response.data)) {
            error.response.data.api_trx_dtm = error.response.headers.api_trx_dtm;
            error.response.data.api_trx_no = error.response.headers.api_trx_no;
          } else {
            error.response.data = {
              api_trx_dtm: error.response.headers.api_trx_dtm,
              api_trx_no: error.response.headers.api_trx_no,
            };
          }
          const objStatus = fnSetStatus(error.response.status, error.response.statusText);
          if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === 'function') {
            //error callback 함수 호출
            fnErrorCallback(objStatus, error.response.data);
          } else if (!silent) {
            //error callback 함수 없는 경우 백엔드서버 오류 메시지 표시
            console.error(error);
            if (CM.cfnIsNotEmpty(error.response.data) && CM.cfnIsNotEmpty(error.response.data.rsp_message)) {
              CM.cfnAlert(error.response.data.rsp_message);
            } else {
              //백엔드서버 오류 메세지 없는 경우 기본 오류 메시지 표시
              CM.cfnAlert(error);
            }
          }
        }),
    );
  },
};

export default axiosUtil;
