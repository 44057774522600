/*
 * @version : 2021.02.04
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * Navi
 */
import { Link } from 'react-router-dom';
import { useState } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/* 컨텐츠 영역_서브 네비게이션 */
function Navi() {
  let requestUri = window.location.href;
  const [style1depth, setStyle1depth] = useState({ display: 'none' });
  const [style2depth, setStyle2depth] = useState({ display: 'none' });

  //1depth 네비명 세팅
  const get1depthNm = () => {
    if (requestUri.indexOf('/businessinfo/') > 0) {
      return '업무정보 조회';
    } else if (requestUri.indexOf('/businessreference/') > 0) {
      return '업무관련 참고자료';
    } else if (requestUri.indexOf('/board/') > 0) {
      return '공지사항 및 자료실';
    } else {
      return '업무정보 조회';
    }
  };

  //2depth 네비명 세팅
  const get2depthNm = () => {
    if (requestUri.indexOf('/businessinfo/recordhistory') > 0) {
      return '기록내역 조회';
    } else if (requestUri.indexOf('/businessinfo/usagehistorystatistics') > 0) {
      return '이용내역 통계';
    } else if (requestUri.indexOf('/businessreference/linkedinvestment') > 0) {
      return '연계투자 관련 참고자료';
    } else if (requestUri.indexOf('/businessreference/linkedloan') > 0) {
      return '연계대출 관련 참고자료';
    } else if (requestUri.indexOf('/board/notice') > 0) {
      return '공지사항';
    } else if (requestUri.indexOf('/board/downloads') > 0) {
      return '자료실';
    } else {
      return '기록내역 조회';
    }
  };

  //2depth 네비 메뉴박스 세팅
  const get2depthNavi = () => {
    if (requestUri.indexOf('/businessinfo/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/businessinfo/recordhistory">기록내역 조회</Link>
          </li>
          <li>
            <Link to="/businessinfo/usagehistorystatistics">이용내역 통계</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/businessreference/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/businessreference/linkedloan">연계대출 관련 참고자료</Link>
          </li>
          <li>
            <Link to="/businessreference/linkedinvestment">연계투자 관련 참고자료</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/board/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/board/notice">공지사항</Link>
          </li>
          <li>
            <Link to="/board/downloads">자료실</Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/businessinfo/recordhistory">기록내역 조회</Link>
          </li>
          <li>
            <Link to="#">이용내역 통계</Link>
          </li>
        </ul>
      );
    }
  };

  return (
    <div className="navi">
      <ul className="naviWrap">
        <li>
          <Link to="/businessinfo/recordhistory" className="home">
            <span>HOME</span>
            <HomeIcon />
          </Link>
        </li>
        <li
          onClick={() => {
            setStyle1depth({ display: 'block' });
          }}
          onMouseLeave={() => {
            setStyle1depth({ display: 'none' });
          }}
        >
          <button>
            <span>
              <h2>{get1depthNm()}</h2>
            </span>
            <ExpandMoreIcon />
          </button>
          <ul
            className="depthDetail"
            style={style1depth}
            onMouseLeave={() => {
              setStyle1depth({ display: 'none' });
            }}
          >
            <li>
              <Link to="/businessinfo/recordhistory">업무정보 조회</Link>
            </li>
            <li>
              <Link to="/businessreference/linkedloan">업무관련 참고자료</Link>
            </li>
            <li>
              <Link to="/board/notice">공지사항 및 자료실</Link>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            setStyle2depth({ display: 'block' });
          }}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <button>
            <span>
              <h3>{get2depthNm()}</h3>
            </span>
            <ExpandMoreIcon />
          </button>
          {get2depthNavi()}
        </li>
        {/* <li>
                    <a href="#"><span>3뎁스</span></a>  
                    <ul className="depthDetail"> 
                        <li><a href="#">3뎁스</a></li>
                        <li><a href="#">3뎁스</a></li>
                    </ul>
                </li> */}
      </ul>
    </div>
  );
}
export default Navi;
