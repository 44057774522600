/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 P2P 금융정보 조회 - 투자내역조회 컴포넌트(투자한도)
 */
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import * as CF from '../../../common/template/ComponentForm';
import * as CM from '../../../common/util/common';
import axiosUtil from '../../../../utils/axiosUtil';

let investmentTypeOptions = [];
/* 컨텐츠 영역_투자한도_검색조회_라디오버튼 */
export const RadioButton = (props) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">투자유형선택</FormLabel>
      <CF.RadioGroupForm
        name="customerInvestmentType"
        id="customerInvestmentType"
        testId="radioButtonTest"
        onChange={props.handleChange}
        value={props.radioState}
        option={investmentTypeOptions}
        row={true}
      />
    </FormControl>
  );
};

/* 컨텐츠 영역_투자한도_검색조회 */
const SearchBox = (props) => {
  return (
    <div className="searchArea">
      <div className="tblSearch">
        <form>
          <ul>
            <li className="len12-12">
              <div className="searchList">
                <span className="tit">투자유형</span>
                <RadioButton radioState={props.radioState} handleChange={props.handleChange}></RadioButton>
              </div>
              <Button
                data-testid="searchButtonTest"
                id="customer-searchButton"
                onClick={props.handleClick}
                startIcon={<SearchIcon />}
                className="ml40"
              >
                <span>조회</span>
              </Button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
};

const InvestmentDetailsLimitC = (props) => {
  const [radioState, setRadioState] = useState(''); //라디오버튼
  const [buttonState, setButtonState] = useState(true); //조회버튼
  const [limit, setLimit] = useState({}); //투자한도

  // 투자한도 handler
  const handleLimitChange = (param) => {
    setButtonState(false);
    setLimit(param);
  };

  //라디오버튼 handler
  const handleChange = (event, newValue) => {
    setRadioState(newValue);
  };

  //조회버튼클릭 handler
  const handleClick = () => {
    setButtonState(true);
  };

  //화면로딩 시 실행
  const info = props.info;
  useEffect(() => {
    investmentTypeOptions = [];
    if (CM.cfnIsNotEmpty(info.general_investment_register_dtm)) {
      investmentTypeOptions.push({ value: 'GENERAL_PERSONAL', label: '일반개인투자자' });
    }
    if (CM.cfnIsNotEmpty(info.income_suitability_register_dtm)) {
      investmentTypeOptions.push({ value: 'SUITABLE_INCOME', label: '소득적격투자자' });
    }
    if (CM.cfnIsNotEmpty(info.pro_investment_register_dtm)) {
      investmentTypeOptions.push({ value: 'PRO_PERSONAL', label: '개인전문투자자' });
    }
    if (CM.cfnIsNotEmpty(investmentTypeOptions)) {
      setRadioState(investmentTypeOptions[0].value);
    }
  }, [props.info]);

  //화면로딩 시 실행
  useEffect(() => {
    //투자현황 axios
    const axiosStatus = () => {
      return new Promise((resolve) => {
        let api = 'web/customers/investments/limits';
        api += `?uuid=${userStore.getUuid()}`;
        api += `&investor_type_code=${radioState}`;
        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve();
          },
        );
      });
    };
    // useEffect start function
    const startAxios = async () => {
      const resultData = await axiosStatus();
      handleLimitChange(resultData);
    };
    if (buttonState && CM.cfnIsNotEmpty(radioState)) {
      startAxios();
    }
  }, [buttonState, radioState]);

  return (
    <div className="section">
      <h4>
        투자한도
        <div className="tip">
          <CF.TooltipForm
            contents={
              '업권 통합 투자한도 관리를 위해 투자신청 시점을 기준으로 한도가 관리됩니다. 이후 상품 모집이 완료되어 투자계약이 체결되면 상환된 원금만큼 투자 가능 금액이 복원됩니다.'
            }
            position="bottom-start"
          />
        </div>
      </h4>
      <SearchBox
        handleChange={handleChange}
        handleClick={handleClick}
        radioState={radioState}
        setRadioState={setRadioState}
      />
      <TableContainer>
        <Table aria-label="simple table">
          {CM.cfnIsEmpty(limit) ? (
            <TableBody>
              <TableRow data-testid={'tablerow0'} key={'tablerow0'}>
                <TableCell>조회된 내용이 없습니다.</TableCell>
              </TableRow>
            </TableBody>
          ) : limit.investor_type_code === 'PRO_PERSONAL' ? (
            <TableBody>
              <TableRow>
                <TableCell>
                  <span>
                    개인전문투자자의 경우, 총 투자금액에 대한 투자한도는 없음. <br /> ※단, 개별상품의 연계대출
                    모집금액&nbsp;{limit.product_invest_limit_ratio}% 이내에서 연계투자 가능
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              {limit.investor_type_code === 'GENERAL_PERSONAL' ? (
                <colgroup>
                  <col width="30%" />
                  <col width="25%" />
                  <col width="25%" />
                  <col />
                </colgroup>
              ) : (
                <colgroup>
                  <col width="20%" />
                  <col width="38%" />
                  <col width="25%" />
                  <col width="16%" />
                  <col />
                </colgroup>
              )}
              <TableHead>
                {limit.investor_type_code === 'GENERAL_PERSONAL' ? (
                  <TableRow>
                    <TableCell>구분</TableCell>
                    <TableCell>투자신청 중 및 투자진행 중</TableCell>
                    <TableCell>
                      투자 가능
                      <br />
                      (잔여한도 비율 %)
                    </TableCell>
                    <TableCell>투자 한도</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>구분</TableCell>
                    <TableCell>투자신청 중 및 투자진행 중 (소진한도 비율 %)</TableCell>
                    <TableCell>투자 가능 (잔여한도 비율 %)</TableCell>
                    <TableCell>투자 한도</TableCell>
                  </TableRow>
                )}
              </TableHead>
              {limit.investor_type_code === 'GENERAL_PERSONAL' ? (
                <TableBody className="new_ty">
                  {CM.cfnIsNotEmpty(limit.total_invest_progress_amount) ? (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        전체 투자상품
                      </TableCell>
                      <TableCell>
                        <strong>{CM.cfnAddComma(limit.total_invest_progress_amount)}원</strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {CM.cfnAddComma(limit.total_invest_available_amount)}원<br />
                          <span className="secondBlue">({limit.total_invest_available_ratio}%)</span>
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          <span className="secondBlue">{CM.cfnAddComma(limit.total_invest_limit_amount)}원</span> 이내
                        </strong>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow />
                  )}
                  {limit.limit_list.map((row, index) => (
                    <TableRow key={'tableRow' + index}>
                      <TableCell component="th" scope="row">
                        {row.goods_type_name}
                      </TableCell>
                      <TableCell>
                        <strong>{CM.cfnAddComma(row.invest_progress_amount)}원</strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {CM.cfnAddComma(row.invest_available_amount)}원<br />
                          <span className="secondBlue">({row.invest_available_ratio}%)</span>
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          <span className="secondBlue">{CM.cfnAddComma(row.invest_limit_amount)}원</span> 이내
                        </strong>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {CM.cfnIsNotEmpty(limit.total_invest_progress_amount) ? (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        전체 투자상품
                      </TableCell>
                      <TableCell>
                        <strong>
                          {CM.cfnAddComma(limit.total_invest_progress_amount)}원
                          <span className="secondBlue">({limit.total_invest_progress_ratio}%)</span>
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {CM.cfnAddComma(limit.total_invest_available_amount)}원
                          <span className="secondBlue">({limit.total_invest_available_ratio}%)</span>
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          <span className="secondBlue">{CM.cfnAddComma(limit.total_invest_limit_amount)}원</span> 이내
                        </strong>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow />
                  )}
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      {CM.cfnIsEmpty(limit) || limit.investor_type_code === 'PRO_PERSONAL' ? (
        <div className="info tr"> </div>
      ) : limit.investor_type_code === 'GENERAL_PERSONAL' ? (
        <div className="info tr">
          ※ 동일차입자에 대해 500만원까지 투자 가능(단, 사회기반시설 사업 관련 투자에 한해 최대 3,000만원 투자 가능)
        </div>
      ) : (
        <div className="info tr">
          ※ 동일차입자에 대해 2,000만원까지 투자 가능(단, 사회기반시설 사업 관련 투자에 한해 최대 4,000만원 투자 가능)
        </div>
      )}
    </div>
  );
};

export default InvestmentDetailsLimitC;
