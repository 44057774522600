/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용기관포탈 routes
 */
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';
import * as CM from '../components/common/util/common';
import DownloadsDetailM from '../components/companies/board/downloads/BoardDetailM';
import DownloadsListM from '../components/companies/board/downloads/BoardListM';
import NoticeDetailM from '../components/companies/board/notice/BoardDetailM';
import NoticeListM from '../components/companies/board/notice/BoardListM';
import RecordHistoryM from '../components/companies/businessinfo/recordhistory/RecordHistoryM';
import UsageHistoryStatisticsM from '../components/companies/businessinfo/usagehistorystatistics/UsageHistoryStatisticsM';
import LinkedInvestmentM from '../components/companies/businessreference/linkedinvestment/LinkedInvestmentM';
import LinkedLoanM from '../components/companies/businessreference/linkedloan/LinkedLoanM';
import Footer from '../components/companies/common/Footer';
import HeadTop from '../components/companies/common/HeadTop';
import Navi from '../components/companies/common/Navi';
import LoginM from '../components/companies/login/LoginM';
import LoginResetM from '../components/companies/login/LoginResetM';
import MemberInfoM from '../components/companies/memberinfo/MemberInfoM';
import { userStore } from '../stores/companies/userStore';
import Page404 from '../components/common/Page404';

const menuJson = [
  {
    path: '/',
    main: 'main-root',
    component: RecordHistoryM,
    loginStatus: false,
  },
  {
    path: '/login/reset',
    main: 'main-root',
    component: LoginResetM,
    loginStatus: false,
  },
  {
    path: '/board/notice',
    main: 'main-root',
    component: NoticeListM,
    loginStatus: false,
  },
  {
    path: '/businessinfo/recordhistory',
    main: 'main-root',
    component: RecordHistoryM,
    loginStatus: false,
  },
  {
    path: '/businessinfo/usageHistoryStatistics',
    main: 'main-root',
    component: UsageHistoryStatisticsM,
    loginStatus: false,
  },
  {
    path: '/board/notice/:seq_id',
    main: 'main-root',
    component: NoticeDetailM,
    loginStatus: false,
  },
  {
    path: '/board/downloads',
    main: 'main-root',
    component: DownloadsListM,
    loginStatus: false,
  },
  {
    path: '/board/downloads/:seq_id',
    main: 'main-root',
    component: DownloadsDetailM,
    loginStatus: false,
  },
  {
    path: '/businessreference/linkedloan',
    main: 'main-root',
    component: LinkedLoanM,
    loginStatus: false,
  },
  {
    path: '/businessreference/linkedinvestment',
    main: 'main-root',
    component: LinkedInvestmentM,
    loginStatus: false,
  },
  {
    path: '/memberinfo',
    main: 'main-root',
    component: MemberInfoM,
    loginStatus: true,
  },
];

/* 컨텐츠 위 공간, 서브 각페이지마다 무조건 들어감(영역잡음) */
function EmptyBox() {
  return <div className="empty-box"></div>;
}

const Routes = observer(() => {
  const getIsRootPage = () => {
    const requestUri = window.location.pathname;
    return requestUri === '' || requestUri === '/';
  };

  //세션 만료 체크
  const handleLoginCheck = () => {
    userStore.logoutCheck();
  };

  //로그인 관련 페이지 여부 반환
  const isLoginRelationComponent = (path) => {
    return path.indexOf('/login/reset') >= 0 || path.indexOf('/login/assign') >= 0;
  };

  return (
    <Router>
      <ScrollToTop>
        <div className="App p2pCompay" onClick={handleLoginCheck} onKeyDown={handleLoginCheck}>
          {CM.cfnIsNotEmpty(userStore.getIsLogin()) && userStore.logoutCheckRoute() ? (
            <Fragment>
              <HeadTop></HeadTop>
              <EmptyBox></EmptyBox>
            </Fragment>
          ) : null}
          <div className="container">
            {CM.cfnIsNotEmpty(userStore.getIsLogin()) ? <Navi naviTrigger={userStore.naviLocation}></Navi> : null}
            <Switch>
              {menuJson.map((value, key) => {
                // let Component = (CM.cfnIsEmpty(userStore.getIsLogin())) ? (getIsRootPage() && (isLoginRelationComponent(value["path"])) ? value["component"] : LoginM) : getIsRootPage() ? RecordHistoryM : value["component"];
                let Component = CM.cfnIsEmpty(userStore.getIsLogin())
                  ? isLoginRelationComponent(value['path'])
                    ? value['component']
                    : LoginM
                  : value['component'];
                return <Route exact key={key} path={value['path']} component={Component} />;
              })}
              <Route component={Page404} />
            </Switch>
          </div>
          {CM.cfnIsNotEmpty(userStore.getIsLogin()) ? <Footer></Footer> : null}
        </div>
      </ScrollToTop>
    </Router>
  );
});

export default Routes;
