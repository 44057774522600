/*
 * @version : 2021.02.08
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용기관 업무관련 참고자료 - 연계대출 관련 참고자료
 */

import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Fragment, useEffect, useRef, useState } from 'react';
import * as CM from '../../../common/util/common';
import * as CF from '../../../common/template/ComponentForm';
import SearchIcon from '@material-ui/icons/Search';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LinkedLoanP from './LinkedLoanP';

// 조회코드
const loanReferenceTypeOptions = [
  { value: 'GENERAL_TYPE', label: '일반(온투업자 고유계정으로 계약체결한 경우 제외)' },
  { value: 'P2P_COMPANY_TYPE', label: '온투업자 고유계정으로 계약체결한 경우' },
];

// 대출상태
const inquiryStatusTypeOptions = [
  { value: 'CONTRACT_PROGRESS', label: '대출진행 중 상품' },
  { value: 'NEW_CONTRACT', label: '신규 대출계약 체결 상품' },
];

const DEFAULT_HOOKS = {
  requestParams: {
    loanReferenceType: loanReferenceTypeOptions[0].value,
    inquiryStatusType: inquiryStatusTypeOptions[0].value,
    inquiryDate: CM.cfnGetCustomDate('date', -1, 'string'),
    inquiryStartDate: CM.cfnGetCustomThisDate(CM.cfnGetCustomDate('date', -1, 'string'), 'month', -3, 'string'),
    inquiryEndDate: CM.cfnGetCustomDate('date', -1, 'string'),
  },
};

// 이용기관 정보
const CorpInfo = () => {
  return (
    <div className="section">
      <h4>이용기관 정보</h4>
      <div className="contBox">
        <div className="contBox_item w100p">
          <div className="itemBox corpInfo">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="title">이용기관명</p>
                <p className="name">{userStore.getCompanyName()}</p>
              </div>
              <div className="StatusR">
                <p className="title">이용기관 코드</p>
                <p className="name">{userStore.getCompanyCode()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchBox = (props) => {
  return (
    <div className="searchArea">
      <div className="tblSearch">
        <form>
          <fieldset>
            <legend>검색조건 입력</legend>
            <table>
              <colgroup>
                <col width="18%" />
                <col width="47%" />
                <col width="35%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">대출계약 상태</th>
                  <td colSpan="2">
                    <div className="searchList">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">조회대상 선택</FormLabel>
                        <CF.RadioGroupForm
                          name="inquiryStatusType"
                          id="inquiryStatusType"
                          value={props.requestParams.inquiryStatusType}
                          option={inquiryStatusTypeOptions}
                          onChange={props.handleInquiryStatusType}
                          row={true}
                          testId="inquiryStatusType"
                        />
                      </FormControl>
                    </div>
                  </td>
                </tr>
                <tr>
                  {props.requestParams.inquiryStatusType === 'CONTRACT_PROGRESS' ? (
                    <Fragment>
                      <th scope="row">조회시점</th>
                      <td>
                        <div className="searchList">
                          <div className="datePick">
                            <CF.DatePickerForm
                              id="inquiryDate"
                              value={props.requestParams.inquiryDate}
                              handleChange={props.handleDate('inquiryDate')}
                              customProps={{
                                maxDate: CM.cfnGetCustomDate('date', -1, 'date'),
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <th scope="row">조회범위</th>
                      <td>
                        <div className="searchList">
                          <div className="datePick">
                            <CF.DatePickerForm
                              id="startDate"
                              value={props.requestParams.inquiryStartDate}
                              handleChange={props.handleDate('inquiryStartDate')}
                              customProps={{
                                maxDate: CM.cfnGetCustomDate('date', -1, 'date'),
                              }}
                            />
                            <span className="rowline">~</span>
                            <CF.DatePickerForm
                              id="endDate"
                              value={props.requestParams.inquiryEndDate}
                              handleChange={props.handleDate('inquiryEndDate')}
                              customProps={{
                                maxDate: CM.cfnGetCustomDate('date', -1, 'date'),
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </Fragment>
                  )}

                  <td className="tl">
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={props.handleClick}
                      data-testid="searchButton"
                    >
                      조회
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="hide">주의사항</span>
                  </th>
                  <td colSpan="2">
                    {props.requestParams.inquiryStatusType === 'CONTRACT_PROGRESS' ? (
                      <p className="info">
                        선택한 시점을 기준으로 대출계약이 진행 중인 상품을 조회합니다. 현재기준 전일까지 조회가
                        가능합니다.
                      </p>
                    ) : (
                      <p className="info">
                        선택한 기간 내에서 이용기관이 대출계약 기록요청을 보내 등록 처리된 상품을 조회합니다. 현재기준
                        전일까지 조회가 가능합니다.
                        <br />
                        (최대 3개월의 범위 내에서 검색 가능합니다.)
                      </p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

const LinkedLoanM = () => {
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams);
  const [loanReference, setLoanReference] = useState({});
  const [buttonState, setButtonState] = useState(true);
  const [modalOpen, setModalOpen] = useState(false); //모달
  const borrowerTypes = ['PERSONAL', 'PERSONAL_BUSINESS', 'CORPORATE']; //차입자 유형

  const handleLoanReferenceType = (event) => {
    setRequestParams((data) => ({ ...data, loanReferenceType: event.target.value }));
  };

  const handleInquiryStatusType = (event) => {
    setRequestParams((data) => ({ ...data, inquiryStatusType: event.target.value }));
  };

  const handleDate = (name) => (date) => {
    setRequestParams((data) => ({ ...data, [name]: CM.cfnConvertDateToString(date) }));
  };

  const handleLoanReference = (response) => {
    setLoanReference(response);
  };

  const handleClick = () => {
    setButtonState(true);
  };

  const modalRef = useRef(null);

  const handleReport = () => {
    //modalRef.current.state.x = 0;
    //modalRef.current.state.y = 0;
    setModalOpen(true);
  };

  useEffect(() => {
    userStore.handleNaviTrigger();

    const getLoanReference = (params) => {
      return new Promise((resolve, reject) => {
        let url = 'web/companies/loans/references';
        url += `?loan_reference_type=${requestParams.loanReferenceType}&inquiry_status_type=${requestParams.inquiryStatusType}`;

        if (requestParams.inquiryStatusType === 'CONTRACT_PROGRESS') {
          url += `&inquiry_start_date=${requestParams.inquiryDate}&inquiry_end_date=${requestParams.inquiryDate}`;
        } else if (requestParams.inquiryStatusType === 'NEW_CONTRACT') {
          url += `&inquiry_start_date=${requestParams.inquiryStartDate}&inquiry_end_date=${requestParams.inquiryEndDate}`;
        }
        url += `&p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve();
          },
        );
      });
    };

    const startGetLoanReference = async (params) => {
      setButtonState(false);
      const resultData = await getLoanReference(params);
      handleLoanReference(resultData);
    };

    if (buttonState) {
      if (requestParams.inquiryStatusType === 'NEW_CONTRACT') {
        if (!CM.cfnCommonValidDate(requestParams.inquiryStartDate, requestParams.inquiryEndDate, 3)) {
          setButtonState(false);
          return false;
        }
      }

      startGetLoanReference(requestParams);
    }
  }, [buttonState]);

  return (
    <div className="subContent">
      <div className="subInner">
        {/* 이용기관 정보 */}
        <CorpInfo></CorpInfo>

        {/* 연계대출관련 참고자료 */}
        <div className="section">
          <h4>연계대출 관련 참고자료</h4>
          <SearchBox
            requestParams={requestParams}
            handleLoanReferenceType={handleLoanReferenceType}
            handleInquiryStatusType={handleInquiryStatusType}
            handleDate={handleDate}
            handleClick={handleClick}
          />

          <div className="tblTop">
            <div className="topL"></div>
            <div className="topR">
              <Button className="btn print" onClick={handleReport} startIcon={<AssignmentIcon />}>
                참고자료 보고서<span className="hide">출력</span>
                <PrintIcon />
              </Button>
            </div>
          </div>
          <TableContainer>
            <Table className="tblTypeline sumType" aria-label="연계대출관련 참고자료">
              <colgroup>
                <col width="15%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>차입자 유형</TableCell>
                  <TableCell rowSpan={2}>상품 유형</TableCell>
                  <TableCell colSpan={2}>대출관련 수치</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>대출계약 수 (건)</TableCell>
                  <TableCell>대출금액 (원)</TableCell>
                </TableRow>
              </TableHead>
              {CM.cfnIsEmpty(loanReference) ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <p className="resultNo">조회된 내용이 없습니다.</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {borrowerTypes.map((borrowerType) => {
                    //차입자 유형별 대출 참고자료
                    let tempBorrowerType = [];
                    loanReference.reference_info_list.forEach((referenceInfo) => {
                      if (referenceInfo.borrower_type_code === borrowerType) {
                        tempBorrowerType.push(referenceInfo);
                      }
                    });
                    if (tempBorrowerType.length === 1 && tempBorrowerType[0].goods_type_code === 'TOTAL_PRODUCT') {
                      return (
                        <TableRow>
                          <TableCell scope="row" rowSpan={tempBorrowerType.length}>
                            {tempBorrowerType[0].borrower_type_name}
                          </TableCell>
                          <TableCell scope="row">소계</TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(tempBorrowerType[0].loan_count) || tempBorrowerType[0].loan_count === 0
                              ? '-'
                              : tempBorrowerType[0].loan_count}
                          </TableCell>
                          <TableCell className="tr">
                            {CM.cfnIsEmpty(tempBorrowerType[0].loan_amount) || tempBorrowerType[0].loan_amount === '0'
                              ? '-'
                              : CM.cfnAddComma(tempBorrowerType[0].loan_amount)}
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return tempBorrowerType.map((item, index) =>
                        item.goods_type_code !== 'TOTAL_PRODUCT' ? (
                          <TableRow data-testid={borrowerType + index} key={index}>
                            {index === 0 && (
                              <TableCell scope="row" rowSpan={tempBorrowerType.length}>
                                {item.borrower_type_name}
                              </TableCell>
                            )}
                            <TableCell scope="row">{item.goods_type_name}</TableCell>
                            <TableCell>
                              {CM.cfnIsEmpty(item.loan_count) || item.loan_count === 0 ? '-' : item.loan_count}
                            </TableCell>
                            <TableCell className="tr">
                              {CM.cfnIsEmpty(item.loan_amount) || item.loan_amount === '0'
                                ? '-'
                                : CM.cfnAddComma(item.loan_amount)}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow className="total" key={index}>
                            <TableCell scope="row">소계</TableCell>
                            <TableCell>
                              {CM.cfnIsEmpty(item.loan_count) || item.loan_count === 0 ? '-' : item.loan_count}
                            </TableCell>
                            <TableCell className="tr">
                              {CM.cfnIsEmpty(item.loan_amount) || item.loan_amount === '0'
                                ? '-'
                                : CM.cfnAddComma(item.loan_amount)}
                            </TableCell>
                          </TableRow>
                        ),
                      );
                    }
                  })}
                  <TableRow className="sum">
                    <TableCell component="th" scope="row" colSpan={2}>
                      합계
                    </TableCell>
                    <TableCell>
                      {CM.cfnIsEmpty(loanReference.total_count) || loanReference.total_count === 0
                        ? '-'
                        : loanReference.total_count}
                    </TableCell>
                    <TableCell className="tr">
                      {CM.cfnIsEmpty(loanReference.total_amount) || loanReference.total_amount === '0'
                        ? '-'
                        : CM.cfnAddComma(loanReference.total_amount)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
      <LinkedLoanP
        modalRef={modalRef}
        open={modalOpen}
        setOpen={setModalOpen}
        p2pCompanyCode={userStore.getCompanyCode()}
        loanReferenceType={requestParams.loanReferenceType}
        inquiryStatusType={requestParams.inquiryStatusType}
        inquiryDate={requestParams.inquiryDate}
        inquiryStartDate={requestParams.inquiryStartDate}
        inquiryEndDate={requestParams.inquiryEndDate}
      />
    </div>
  );
};

export default LinkedLoanM;
