/*
 * @version : 2021.02.19
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 대고객용 메인 페이지 - 전체 통계
 */

import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as CF from '../../../components/common/template/ComponentForm';
import * as CM from '../../../components/common/util/common';
import axiosUtil from '../../../utils/axiosUtil';

const MainStatisticsC = () => {
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [statistics, setStatistics] = useState([]); // 통계조회
  const [inquiryDate, setInquiryDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 0)); //통계조회 기준일
  const [searchState, setSearchState] = useState(true); //조회여부

  const handleStatistics = (param) => {
    setStatistics(param);
  };

  const handleDate = (date) => {
    setInquiryDate(date);
    if (CM.cfnValidDate(date)) {
      if (date >= new Date(new Date().getFullYear(), new Date().getMonth(), 1)) {
        CM.cfnAlert('전월까지만 선택가능합니다.');
      } else {
        setSearchState(true);
      }
    }
  };

  // 등록 업체 조회
  const handleCompaniesList = (response) => {
    setTotalCompanies(response.fnc_registered_p2p_company_list.length); //등록 업체수 세팅
  };

  useEffect(() => {
    const getStatistics = () => {
      return new Promise((resolve) => {
        let inquiry = CM.cfnConvertDateToString(inquiryDate).substring(0, 6);
        let api = `web/common/statistics?inquiry_year_month=${inquiry}`;
        // 대고객사이트 표시 여부
        api += `&public_open=true`;

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
          },
        );
      });
    };

    const startGetStatistics = async () => {
      const resultData = await getStatistics();
      handleStatistics(resultData);
      setSearchState(false);
    };

    if (searchState) {
      startGetStatistics();
    }
  }, [searchState, inquiryDate]);

  useEffect(() => {
    //등록 업체 조회
    const getCompaniesList = (param) => {
      return new Promise((resolve) => {
        let api = 'web/common/p2p-companies/fnc-registration';
        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
          },
        );
      });
    };
    const startCompaniesList = async () => {
      const resultData = await getCompaniesList();
      handleCompaniesList(resultData);
    };

    startCompaniesList();
  }, []);

  return (
    <div className="mainStatistics">
      <div className="left">
        <h2>P2P 금융통합정보</h2>
        <p>
          <b>통계정보는 온투업 등록 이후 데이터에 한하여 제공됩니다.</b>
        </p>
        <div className="searchDate">
          <span className="hide">조회년월</span>
          <div className="datePick">
            <CF.DatePickerForm
              id="inquiryDate"
              testId="inquiryDate"
              value={inquiryDate}
              handleChange={handleDate}
              format={'yyyy-MM'}
              viewFormat={['year', 'month']}
              customProps={{
                maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
                openTo: 'month',
              }}
            />
          </div>
        </div>
        <p>날짜를 선택하면 통계를 확인할 수 있습니다. </p>
        <div className="companyNum">
          <div className="top">
            <h3>
              금융위원회 등록
              <br />
              온투업체
            </h3>
            <p className="num" data-testid="p2pcompanycount">
              {CM.cfnIsNotEmpty(totalCompanies) ? totalCompanies : 0} <span>개</span>
            </p>
          </div>
          <Link to="/serviceintro/registrationCompanies">
            <Button className="btnmore">
              <span>P2P센터 이용기관 현황</span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="right">
        <div className="statArea">
          <h3>
            대출현황 <span>(단위 : 천원)</span>
          </h3>
          {/* pc용 표 */}
          <table className="pc">
            <caption>누적대출금액, 누적상환금액, 대출잔액으로 구성된 대출현황 표</caption>
            <colgroup>
              <col width="33.3%" />
              <col width="33.3%" />
              <col width="" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">누적대출금액</th>
                <th scope="col">누적상환금액</th>
                <th scope="col">대출잔액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{CM.cfnIsNotEmpty(statistics) ? CM.cfnAddCommaThousand(statistics.cumul_loan_amount) : 0}</td>
                <td>{CM.cfnIsNotEmpty(statistics) ? CM.cfnAddCommaThousand(statistics.cumul_repayment_amount) : 0}</td>
                <td>{CM.cfnIsNotEmpty(statistics) ? CM.cfnAddCommaThousand(statistics.loan_balance) : 0}</td>
              </tr>
            </tbody>
          </table>
          {/* 모바일용 표 */}
          <table className="mobile">
            <caption>누적대출금액, 누적상환금액, 대출잔액으로 구성된 대출현황 표</caption>
            <colgroup>
              <col width="40%" />
              <col width="60%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">누적대출금액</th>
                <td>{CM.cfnIsNotEmpty(statistics) ? CM.cfnAddCommaThousand(statistics.cumul_loan_amount) : 0}</td>
              </tr>
              <tr>
                <th scope="row">누적상환금액</th>
                <td>{CM.cfnIsNotEmpty(statistics) ? CM.cfnAddCommaThousand(statistics.cumul_repayment_amount) : 0}</td>
              </tr>
              <tr>
                <th scope="row">대출잔액</th>
                <td>{CM.cfnIsNotEmpty(statistics) ? CM.cfnAddCommaThousand(statistics.loan_balance) : 0}</td>
              </tr>
            </tbody>
          </table>
          <p style={{ marginTop: '0.2rem' }}>
            ※ 22.8월분부터 집계기준 변경 (대출계약의 해제/해지정보 반영, 월말 기준 공시)
          </p>
        </div>
        <div className="statArea">
          <h3>
            상품유형별 대출잔액 <span>(단위 : %)</span>
          </h3>
          {CM.cfnIsNotEmpty(statistics) && CM.cfnIsNotEmpty(statistics.balance_list) ? (
            <ul>
              <li>
                {CM.cfnIsNotEmpty(
                  statistics.balance_list.find((element) => element.product_type_code === 'REAL_ESTATE_PF'),
                ) ? (
                  <p>
                    <span>
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'REAL_ESTATE_PF')
                          .product_type_name
                      }
                    </span>
                    <span className="num">
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'REAL_ESTATE_PF')
                          .loan_balance_ratio
                      }
                    </span>
                  </p>
                ) : (
                  <p>
                    <span>부동산 PF</span>
                    <span className="num">0</span>
                  </p>
                )}
                {CM.cfnIsNotEmpty(
                  statistics.balance_list.find((element) => element.product_type_code === 'REAL_ESTATE_MORTGAGE'),
                ) ? (
                  <p>
                    <span>
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'REAL_ESTATE_MORTGAGE')
                          .product_type_name
                      }
                    </span>
                    <span className="num">
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'REAL_ESTATE_MORTGAGE')
                          .loan_balance_ratio
                      }
                    </span>
                  </p>
                ) : (
                  <p>
                    <span>부동산 담보</span>
                    <span className="num">0</span>
                  </p>
                )}
                {CM.cfnIsNotEmpty(
                  statistics.balance_list.find((element) => element.product_type_code === 'BILL_MORTGAGE'),
                ) ? (
                  <p>
                    <span>
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'BILL_MORTGAGE')
                          .product_type_name
                      }
                    </span>
                    <span className="num">
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'BILL_MORTGAGE')
                          .loan_balance_ratio
                      }
                    </span>
                  </p>
                ) : (
                  <p>
                    <span>어음·매출채권 담보</span>
                    <span className="num">0</span>
                  </p>
                )}
              </li>
              <li>
                {CM.cfnIsNotEmpty(
                  statistics.balance_list.find((element) => element.product_type_code === 'ETC_MORTGAGE'),
                ) ? (
                  <p>
                    <span>
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'ETC_MORTGAGE')
                          .product_type_name
                      }
                    </span>
                    <span className="num">
                      {
                        statistics.balance_list.find((element) => element.product_type_code === 'ETC_MORTGAGE')
                          .loan_balance_ratio
                      }
                    </span>
                  </p>
                ) : (
                  <p>
                    <span>기타 담보</span>
                    <span className="num">0</span>
                  </p>
                )}
                {CM.cfnIsNotEmpty(
                  statistics.balance_list.find((element) => element.product_type_code === 'PERSONAL_CREDIT_MORTGAGE'),
                ) ? (
                  <p>
                    <span>
                      {
                        statistics.balance_list.find(
                          (element) => element.product_type_code === 'PERSONAL_CREDIT_MORTGAGE',
                        ).product_type_name
                      }
                    </span>
                    <span className="num">
                      {
                        statistics.balance_list.find(
                          (element) => element.product_type_code === 'PERSONAL_CREDIT_MORTGAGE',
                        ).loan_balance_ratio
                      }
                    </span>
                  </p>
                ) : (
                  <p>
                    <span>개인 신용</span>
                    <span className="num">0</span>
                  </p>
                )}
                {CM.cfnIsNotEmpty(
                  statistics.balance_list.find((element) => element.product_type_code === 'CORPORATE_CREDIT_MORTGAGE'),
                ) ? (
                  <p>
                    <span>
                      {
                        statistics.balance_list.find(
                          (element) => element.product_type_code === 'CORPORATE_CREDIT_MORTGAGE',
                        ).product_type_name
                      }
                    </span>
                    <span className="num">
                      {
                        statistics.balance_list.find(
                          (element) => element.product_type_code === 'CORPORATE_CREDIT_MORTGAGE',
                        ).loan_balance_ratio
                      }
                    </span>
                  </p>
                ) : (
                  <p>
                    <span>법인 신용</span>
                    <span className="num">0</span>
                  </p>
                )}
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <p>
                  <span>부동산 PF</span>
                  <span className="num">0</span>
                </p>
                <p>
                  <span>부동산 담보</span>
                  <span className="num">0</span>
                </p>
                <p>
                  <span>어음·매출채권 담보</span>
                  <span className="num">0</span>
                </p>
              </li>
              <li>
                <p>
                  <span>기타 담보</span>
                  <span className="num">0</span>
                </p>
                <p>
                  <span>개인 신용</span>
                  <span className="num">0</span>
                </p>
                <p>
                  <span>법인 신용</span>
                  <span className="num">0</span>
                </p>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainStatisticsC;
