/*
 * @version : 2021.02.15
 * @author : 금융결제원
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국용 통계조회 - 감독당국 세부통계 > 연계대출채권 현황
 */
import { Button, FormControl } from '@material-ui/core';
import { useEffect, useState, Fragment } from 'react';
import * as CM from '../../../common/util/common';
import * as CF from '../../../common/template/ComponentForm';
import axiosUtil from '../../../../utils/axiosUtil';
import SearchIcon from '@material-ui/icons/Search';
import { userStore } from '../../../../stores/supervisors/userStore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { rootStore } from '../../../../stores/rootStore';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import LoanStatusStatisticsExcelS from './LoanStatusStatisticsExcelS';
import DoubleScrollbars from 'react-double-scrollbar';

const LoanStatusStatisticsM = observer(() => {
  const p2pCompanyCode = CM.cfnTotalInstituteList(); // 검색조건 이용기관 목록 관리 hook
  const [statistics, setStatistics] = useState({}); // 화면에 필요한 통계데이터 관리 hook
  const [searchState, setSearchState] = useState(true); //조회여부

  const [requestParams, setRequestParams] = useState({
    inquiryMonth: CM.cfnConvertDateToString(new Date(new Date().getFullYear(), new Date().getMonth(), 0)),
    p2pCompanyCode: 'ALL',
  });

  const handleSearchChange = (flag) => setSearchState(flag);

  /**----------------------------------------------------------------------
     *  deps (searchState, rootStore.InstituteList) 가 변경될 때 수행
     -----------------------------------------------------------------------*/
  useEffect(() => {
    userStore.handleNaviTrigger();

    //-------------------------------
    // 통계데이터 반영 함수 정의
    //-------------------------------
    const handleStatisticsChange = (response) => {
      // 통계 Hook에 변경된 통계 데이터 저장 -> 화면변경 처리
      setStatistics(response);
      // 조회버튼 클릭 활성화
      handleSearchChange(false);
    };

    //-------------------------------
    // 통계데이터 요청 함수 정의
    //-------------------------------
    const axiosStatus = () => {
      return new Promise((resolve, reject) => {
        let url = 'web/supervisors/statistics/supervisor/loans';
        if (requestParams.p2pCompanyCode !== 'ALL') {
          url += '/' + requestParams.p2pCompanyCode;
        }
        url += `?inquiry_year_month=${requestParams.inquiryMonth.substring(0, 6)}`;
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.');
              handleSearchChange(false);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    //-------------------------------
    // 통계데이터 불러오기 함수 정의
    //   통계데이터 요청 후 데이터 반영
    //-------------------------------
    const startAxios = async () => {
      const resultData = await axiosStatus(); // 통계데이터 요청
      handleStatisticsChange(resultData); // 통계데이터 반영
    };

    // 초기 화면진입 (searchState default = true) 이고 기관목록이 비어있지 않은 경우
    if (searchState && CM.cfnIsNotEmpty(toJS(rootStore.InstituteList))) {
      // 통계데이터 불러오기
      startAxios();
    }
  }, [searchState, rootStore.InstituteList]);

  /**----------------------------------------------------------------------
     *  조회버튼 클릭 처리 (searchState 변경하여 useEffect 실행)
     -----------------------------------------------------------------------*/
  const handleClick = () => {
    handleSearchChange(true);
  };

  /**----------------------------------------------------------------------
     *  검색조건 > 업체 지정 시 요청 파라미터로 저장
     -----------------------------------------------------------------------*/
  const handleP2pCompanyCode = (e) => {
    setRequestParams((data) => ({ ...data, p2pCompanyCode: e.target.value }));
  };

  /**----------------------------------------------------------------------
     *  검색조건 > 날짜 지정 시 요청 파라미터로 저장
     -----------------------------------------------------------------------*/
  const handleDate = (name) => (date) => {
    if (name === 'inquiryMonth') {
      setRequestParams((data) => ({ ...data, [name]: CM.cfnConvertDateToString(date) }));
    }
  };

  /**-----------------------------------------------------------------
     *  검색조건과 통계테이블 사이 영역 정의
     -----------------------------------------------------------------*/
  const TblTop = () => {
    return (
      <div className="tblTop">
        <div className="topL"></div>
        <div className="topR">
          <LoanStatusStatisticsExcelS statistics={statistics} />
        </div>
      </div>
    );
  };

  /**-----------------------------------------------------------------
     *  조회결과 JSON 데이터 위치의 값 검색 (SB)
     -----------------------------------------------------------------*/

  const convertStatisticsTypeName = (statisticsType) => {
    return statisticsType === 'BASE_POT_STATISTICS'
      ? '당월말현재'
      : statisticsType === 'NEW_CONTRACT_STATISTICS'
      ? '당월신규'
      : '당월상환';
  };

  const findBorrowerTypeInfo = (borrowerType, borrowerTypeInfoList) => {
    return borrowerTypeInfoList.find((e) => {
      return e.borrower_type === borrowerType;
    });
  };

  /**-----------------------------------------------------------------
     *  검색조건 세팅 영역
     -----------------------------------------------------------------*/
  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>연계대출채권 현황</h4>
          <div className="searchArea">
            <div className="tblSearch">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <fieldset>
                  <legend>검색조건 입력</legend>
                  <table>
                    <colgroup>
                      <col width="13%" />
                      <col width="16%" />
                      <col width="57%" />
                      <col width="15%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">업체명</th>
                        <td colSpan="3">
                          <div className="searchList w62p">
                            <FormControl variant="filled">
                              <CF.SelectForm
                                arrayOption={p2pCompanyCode}
                                customProps={{ onChange: handleP2pCompanyCode }}
                                name="keywordselect"
                                value={requestParams.p2pCompanyCode}
                                testid="userStatistics-p2pCompanyCode"
                              />
                            </FormControl>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">조회년월</th>
                        <td colSpan="2">
                          <div className="searchList">
                            <div className="datePick">
                              <CF.DatePickerForm
                                id="inquiryMonth"
                                value={requestParams.inquiryMonth}
                                handleChange={handleDate('inquiryMonth')}
                                format="yyyy-MM"
                                viewFormat={['year', 'month']}
                                customProps={{
                                  maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
                                  inputProps: { 'data-testid': 'userStatistics-datePickerStart' },
                                  openTo: 'month',
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <Button
                            vatiant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleClick}
                            data-testid="userStatistics-searchButton"
                          >
                            조회
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </form>
            </div>
          </div>
          <TblTop></TblTop>
          <DoubleScrollbars>
            <Table className="tblTypeline scrollX" aria-label="감독당국 세부통계 > 연계대출채권 현황">
              <colgroup>
                <col width="8%" />
                <col width="8%" />
                <col width="8%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
                <col width="4%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan="2">업체명</TableCell>
                  <TableCell rowSpan="2" colSpan="2">
                    연계대출채권금액
                  </TableCell>
                  <TableCell colSpan="6">개인</TableCell>
                  <TableCell colSpan="6">개인사업자</TableCell>
                  <TableCell colSpan="6">법인</TableCell>
                  <TableCell rowSpan="2">합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PF</TableCell>
                  <TableCell>부동산</TableCell>
                  <TableCell>채권</TableCell>
                  <TableCell>기타</TableCell>
                  <TableCell>개인신용</TableCell>
                  <TableCell>법인신용</TableCell>
                  <TableCell>PF</TableCell>
                  <TableCell>부동산</TableCell>
                  <TableCell>채권</TableCell>
                  <TableCell>기타</TableCell>
                  <TableCell>개인신용</TableCell>
                  <TableCell>법인신용</TableCell>
                  <TableCell>PF</TableCell>
                  <TableCell>부동산</TableCell>
                  <TableCell>채권</TableCell>
                  <TableCell>기타</TableCell>
                  <TableCell>개인신용</TableCell>
                  <TableCell>법인신용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CM.cfnIsNotEmpty(statistics) && CM.cfnIsNotEmpty(statistics.statistics_loan_list) ? (
                  <Fragment>
                    {statistics.statistics_loan_list.map((p2p_list, p2p_index) =>
                      p2p_list.statistics_loan_detail_list.map((detail_info, detail_index) => (
                        <Fragment>
                          <TableRow>
                            {detail_index === 0 ? (
                              <TableCell rowSpan={p2p_list.statistics_loan_detail_list.length * 2}>
                                {CM.cfnIsNotEmpty(p2p_list.p2p_company_name) ? p2p_list.p2p_company_name : '합 계'}
                              </TableCell>
                            ) : null}
                            <TableCell rowSpan="2">
                              {convertStatisticsTypeName(detail_info.loan_status_stat_type_code)}
                            </TableCell>
                            <TableCell>차입자수</TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .real_estate_pf_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .real_estate_mortgage_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list).bill_mortgage_info
                                  .borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list).etc_mortgage_info
                                  .borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .personal_credit_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .corporate_credit_info.borrower_count,
                              )}
                            </TableCell>

                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .real_estate_pf_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .real_estate_mortgage_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .bill_mortgage_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .etc_mortgage_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .personal_credit_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .corporate_credit_info.borrower_count,
                              )}
                            </TableCell>

                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .real_estate_pf_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .real_estate_mortgage_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .bill_mortgage_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list).etc_mortgage_info
                                  .borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .personal_credit_info.borrower_count,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .corporate_credit_info.borrower_count,
                              )}
                            </TableCell>

                            <TableCell>{CM.cfnAddComma(detail_info.total_info.borrower_count)}</TableCell>
                          </TableRow>
                          <TableRow>
                            {null}
                            {null}
                            <TableCell>금액</TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .real_estate_pf_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .real_estate_mortgage_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list).bill_mortgage_info
                                  .loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list).etc_mortgage_info
                                  .loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .personal_credit_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL', detail_info.borrower_type_info_list)
                                  .corporate_credit_info.loan_amount,
                              )}
                            </TableCell>

                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .real_estate_pf_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .real_estate_mortgage_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .bill_mortgage_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .etc_mortgage_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .personal_credit_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('PERSONAL_BUSINESS', detail_info.borrower_type_info_list)
                                  .corporate_credit_info.loan_amount,
                              )}
                            </TableCell>

                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .real_estate_pf_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .real_estate_mortgage_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .bill_mortgage_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list).etc_mortgage_info
                                  .loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .personal_credit_info.loan_amount,
                              )}
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(
                                findBorrowerTypeInfo('CORPORATE', detail_info.borrower_type_info_list)
                                  .corporate_credit_info.loan_amount,
                              )}
                            </TableCell>

                            <TableCell>{CM.cfnAddComma(detail_info.total_info.loan_amount)}</TableCell>
                          </TableRow>
                        </Fragment>
                      )),
                    )}
                  </Fragment>
                ) : (
                  <TableRow key="statisticsList0">
                    <TableCell colSpan="14">조회된 내용이 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DoubleScrollbars>
        </div>
      </div>
    </div>
  );
});

export default LoanStatusStatisticsM;
