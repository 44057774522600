/*
 * @version : 2021.02.01
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 연계투자 상품정보
 */
import {
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import { rootStore, p2pCompanyAction } from '../../../../stores/rootStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CF from '../../../common/template/ComponentForm';
import * as CM from '../../../common/util/common';
import { Scrollbars } from 'react-custom-scrollbars';

/* 컨텐츠 영역_상품정보조회 */
const SearchBox = (props) => {
  const p2pCompanyCode = CM.cfnTotalInstituteList(); //이용기관목록('전체'포함)
  return (
    <div className="searchArea productinfo">
      <div className="tblSearch">
        {/* <form> */}
        <ul>
          <li className="len12-6">
            <div className="searchList">
              <span className="tit">업체명</span>
              <FormControl variant="filled">
                <CF.SelectForm
                  // * 전체빼고 rootStore의 이용기관목록만 하려면
                  // arrayOption={rootStore.InstituteList.splice()}
                  arrayOption={p2pCompanyCode}
                  customProps={{ onChange: props.handleCode }}
                  name="p2pCompanyCode"
                  id="p2pCompanyCode"
                  value={props.requestParams.p2pCompanyCode}
                  testid="linkedInvestProduct-p2pCompanyCode"
                />
              </FormControl>
            </div>
          </li>
          <li className="len12-6">
            <div className="searchList">
              <span className="tit">상품유형</span>
              <FormControl variant="filled">
                <CF.SelectForm
                  // * 전체빼고 rootStore의 상품유형목록만 하려면
                  // arrayOption={rootStore.ProductTypeList.splice()}
                  arrayOption={props.productTypeCode}
                  customProps={{ onChange: props.handleCode }}
                  name="productTypeCode"
                  value={props.requestParams.productTypeCode}
                  testid="linkedInvestProduct-productTypeCode"
                />
              </FormControl>
            </div>
          </li>
          <li className="len12-12 infoDateplus">
            <div className="searchList">
              <span className="tit">
                조회기간
                <br />
                <span className="font14">(모집시작일 기준)</span>
              </span>
              <div className="dateTerm">
                <div className="datePick">
                  <CF.DatePickerForm
                    id="startDate"
                    value={props.requestParams.inquiryStartDate}
                    handleChange={props.handleDate('inquiryStartDate')}
                    customProps={{
                      maxDate: new Date(),
                    }}
                  />
                  <span className="rowline">―</span>
                  <CF.DatePickerForm
                    id="endDate"
                    value={props.requestParams.inquiryEndDate}
                    handleChange={props.handleDate('inquiryEndDate')}
                    customProps={{
                      maxDate: new Date(),
                    }}
                  />
                </div>
                <div className="searchTerm">
                  <Button startIcon={<CheckIcon />} id="recent3Btn" onClick={props.handleDate('recent3months')}>
                    <span>최근 3개월</span>
                  </Button>
                  <Button startIcon={<CheckIcon />} id="recent6Btn" onClick={props.handleDate('recent6months')}>
                    <span>최근 6개월</span>
                  </Button>
                  <Button startIcon={<CheckIcon />} id="recent12Btn" onClick={props.handleDate('recent12months')}>
                    <span>최근 12개월</span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="searchList infoDate">
              <span className="hide">검색기간 설정 주의사항</span>
              <span className="info">※ 최대 12개월의 범위 내에서 검색 가능합니다.</span>
            </div>
          </li>
          <li className="len12-10">
            <div className="searchList len1">
              <span className="tit">상품명</span>
              <form className="inputTxt len12-10" noValidate autoComplete="off">
                <TextField
                  onChange={props.handleCode}
                  onKeyPress={props.handleKeyPress}
                  name="productName"
                  id="basicinput"
                  variant="outlined"
                  inputProps={{ 'data-testid': 'linkedInvestProduct-productName' }}
                />
              </form>
            </div>
          </li>
          <li className="len12-2">
            <Button
              onClick={props.handleClick}
              data-testid="searchButtonTest"
              vatiant="contained"
              startIcon={<SearchIcon />}
            >
              조회
            </Button>
          </li>
        </ul>
        {/* </form> */}
      </div>
    </div>
  );
};

const BasicTable = (props) => {
  return (
    // 테이블 정렬 규칙 : 기본 정렬 가운데(align값 셀마다 안넣어도 됨), 왼쪽일 경우 원하는 셀에 className="tl" , 오른쪽일 경우 원하는 셀에 className="tr"
    <TableContainer>
      <Table className="tblType mscrollY" aria-label="이용기관별 통계 목록">
        <colgroup>
          <col width="20%" />
          <col width="14%" />
          <col width="30%" />
          <col width="15%" />
          <col width="12%" />
          <col width="9%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>업체명</TableCell>
            <TableCell>모집기간</TableCell>
            <TableCell>상품유형 / 상품명</TableCell>
            <TableCell>모집금액 (원)</TableCell>
            <TableCell>예상 수익률 (%)</TableCell>
            <TableCell>상품설명서</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.product.contents.map((row, index) => (
            <TableRow key={'tableRow' + index} data-testid={'tableRow' + index}>
              <TableCell>
                <span>{row.p2p_company_name}</span>
              </TableCell>
              <TableCell>
                <span>
                  {CM.cfnDateFormat(row.offering_start_date, 'yyMMdd') +
                    '~' +
                    CM.cfnDateFormat(row.offering_end_date, 'yyMMdd')}
                </span>
              </TableCell>
              <TableCell>
                <span className={props.handleGoodsType(row.product_type_name)}>
                  {row.product_type_name}
                  <br />
                  <p>{row.product_name}</p>
                </span>
              </TableCell>
              <TableCell>
                <span className="black">{CM.cfnAddComma(row.investment_offering_amount)}</span>
              </TableCell>
              <TableCell>
                <span className="lightBblue">{row.profit_rate}</span>
              </TableCell>
              <TableCell>
                {CM.cfnIsNotEmpty(row.product_description_url) && (
                  <Button
                    className="btn manual"
                    data-testid={'downloanBtn' + index}
                    onClick={() => props.handleFileDownload(row.product_description_url)}
                  >
                    <span className="hide">상품설명서 보기</span>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/* 컨텐츠 영역_상품정보조회 테이블_ 박스 타입 */
const BoxList = (props) => {
  const tmp = props.product.contents;
  let productList = [];
  for (let index = 0; index < tmp.length; index += 4) {
    productList.push(tmp.slice(index, 4 + index));
  }

  return (
    <div className="boxList">
      {productList.map((rows, indexs) => (
        <div className="listLine" key={'listLine' + indexs}>
          {rows.map((row, index) => (
            <div className="boxArea" key={'boxArea' + index} data-testid={'boxArea' + index}>
              <div className="goodsTit">
                <p>{row.p2p_company_name}</p>
              </div>
              <div className="goodsInfo">
                <Scrollbars>
                  <div className="goodsInner">
                    <div className="goodsType">
                      <span className={props.handleGoodsType(row.product_type_name)}>
                        {row.product_type_name}
                        <br />
                      </span>
                      <p>{row.product_name}</p>
                    </div>
                    <div className="bottomArea">
                      <ul className="goodsDetail">
                        <li>
                          <span>예상수익률</span>
                          <span className="lightBblue">{row.profit_rate} %</span>
                        </li>
                        <li>
                          <span>모집금액</span>
                          <span>{CM.cfnAddComma(row.investment_offering_amount)} 원</span>
                        </li>
                        <li>
                          <span>모집기간</span>
                          <span>
                            {CM.cfnDateFormat(row.offering_start_date, 'yyMMdd') +
                              ' ~ ' +
                              CM.cfnDateFormat(row.offering_end_date, 'yyMMdd')}
                          </span>
                        </li>
                      </ul>
                      {CM.cfnIsNotEmpty(row.product_description_url) && (
                        <Button
                          className="btn manual"
                          onClick={() => props.handleFileDownload(row.product_description_url)}
                        >
                          <span>상품설명서</span>
                        </Button>
                      )}
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const LinkedInvestProductM = observer(() => {
  const [product, setProduct] = useState([]); //통계조회
  const [pagination, setPagination] = useState(CM.cfnSetPagination());
  const [requestParams, setRequestParams] = useState({
    inquiryStartDate: CM.cfnGetCustomDate('month', -3, 'string'),
    inquiryEndDate: CM.cfnGetDate(),
    p2pCompanyCode: 'ALL',
    productName: '',
    productTypeCode: 'TOTAL_PRODUCT',
    pageNumber: 1,
    pageSize: 8,
    sortDirection: 'DESC',
    sortProperty: 'investment_offering_amount',
  });
  const [searchState, setSearchState] = useState(true); //조회여부
  const [viewState, setViewState] = useState(window.matchMedia('(max-width: 36rem)').matches ? 'box' : 'list');
  const productTypeCode = rootStore.ProductTypeList; //상품유형목록('전체'포함)

  const handleGoodsType = (param) => {
    if (CM.cfnIsNotEmpty(param)) {
      param = param.replace(/ /g, '');
      let classNm = 'goodsType type';
      for (let index = 0; index < productTypeCode.length; index++) {
        let tmp = productTypeCode[index].label.replace(/ /g, '');
        if (param === tmp) {
          classNm += productTypeCode[index].number - 1;
        }
      }
      return classNm;
    }
  };

  // 목록 : 리스트, 박스 방식변경
  const handleViewState = (e) => {
    setViewState(e.currentTarget.name);
  };

  //inquiryStartDate, inquiryEndDate State flag handleChange
  const handleDate = (name) => (date) => {
    if (name === 'inquiryStartDate' || name === 'inquiryEndDate') {
      setRequestParams((data) => ({ ...data, [name]: CM.cfnConvertDateToString(date) }));
    } else {
      let startDate = CM.cfnGetDate();
      if (name === 'recent3months') {
        startDate = CM.cfnGetCustomDate('month', -3, 'string');
      } else if (name === 'recent6months') {
        startDate = CM.cfnGetCustomDate('month', -6, 'string');
      } else if (name === 'recent12months') {
        startDate = CM.cfnGetCustomDate('year', -1, 'string');
      }
      setRequestParams((data) => ({ ...data, inquiryStartDate: startDate, inquiryEndDate: CM.cfnGetDate() }));
    }
  };

  // button State flag handleChange
  const handleSearchChange = (flag) => setSearchState(flag);

  // 조회버튼 클릭시 이벤트함수
  const handleClick = () => {
    handleSearchChange(true);
    setRequestParams((data) => ({
      ...data,
      pageNumber: 1,
    }));
  };

  //엔터키 이벤트
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClick();
    }
  };

  // 페이지 숫자클릭 이벤트함수
  const handleOffsetChange = (e, offset, page) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: page,
    }));
    handleSearchChange(true);
  };

  // 모집금액, 예상수익률 정렬 handler
  const createSortHandler = (e) => {
    let sortOrder = '';
    let sortProp = '';
    let tmpProp = requestParams.sortProperty;
    if (CM.cfnIsEmpty(e.currentTarget.name)) {
      return true;
    } else {
      let tmp = e.currentTarget.name.split('/');
      if (tmp.length > 1) {
        sortProp = tmp[0];
        sortOrder = tmp[1];
      }
    }

    if (sortProp !== '' && sortOrder !== '') {
      if (sortProp === 'offering') {
        tmpProp = 'investment_offering_amount';
      } else if (sortProp === 'expected') {
        tmpProp = 'profit_rate';
      }
    }
    setRequestParams((data) => ({
      ...data,
      sortDirection: sortOrder,
      sortProperty: tmpProp,
    }));
    handleSearchChange(true);
  };

  const handlePaginationChange = (param) => {
    setPagination(CM.cfnSetPagination(param));
  };

  // p2p_company_code, product_type_code, product_name State flag handleChange
  const handleCode = (event) => {
    setRequestParams((data) => ({ ...data, [event.target.name]: event.target.value }));
  };

  //화면로딩 시 실행
  useEffect(() => {
    userStore.handleNaviTrigger();
  }, []);

  useEffect(() => {
    // 연계투자상품정보 axios호출 후 handler
    const handleProductChange = (param) => {
      setProduct(param);
      handlePaginationChange(param);
      handleSearchChange(false);
    };
    //투자현황 axios
    const axiosStatus = () => {
      return new Promise((resolve, reject) => {
        let start = CM.cfnConvertDateToString(requestParams.inquiryStartDate);
        let end = CM.cfnConvertDateToString(requestParams.inquiryEndDate);

        // 모집금액 or 예상수익률이 동일한 경우의 정렬 오류 방지를 위해 최초등록일(ASC)을 정렬조건으로 추가
        let api = 'web/common/products';
        api += `?inquiry_start_date=${start}&inquiry_end_date=${end}`;
        api += `&page_number=${requestParams.pageNumber - 1}&page_size=${requestParams.pageSize}`;
        api += `&sort_directions=${
          requestParams.sortDirection
        },ASC&sort_properties=${requestParams.sortProperty.toUpperCase()},FIRST_REGISTER_DTM`;
        api += `&p2p_company_code=${requestParams.p2pCompanyCode}`;
        api += `&public_open=true`;

        if (requestParams.productName !== '') {
          api += `&product_name=${requestParams.productName}`;
        }
        if (requestParams.productTypeCode !== '') {
          api += `&product_type=${requestParams.productTypeCode}`;
        } else {
          api += `&product_type=${requestParams.productTypeCode}`;
        }

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(process.env.REACT_APP_M001);
            resolve([]);
          },
        );
      });
    };

    // useEffect start function
    const startAxios = async () => {
      p2pCompanyAction(rootStore);
      const resultData = await axiosStatus();
      handleProductChange(resultData);
    };

    if (searchState) {
      if (CM.cfnConvertDateToString(requestParams.inquiryEndDate) > CM.cfnGetDate()) {
        CM.cfnAlert('종료일은 당일을 넘을 수 없습니다.');
        handleSearchChange(false);
        return false;
      }

      const result = CM.cfnCommonValidDate(requestParams.inquiryStartDate, requestParams.inquiryEndDate, 12);
      if (!result) {
        handleSearchChange(false);
        return false;
      }
      startAxios();
    }
  }, [searchState, requestParams]);

  // 첨부파일 다운로드
  const handleFileDownload = (fileUrl) => {
    let filePath = fileUrl.substr(fileUrl.indexOf('/') + 1, fileUrl.length);
    axiosUtil.cfnAxiosGeneralFileDownload(filePath, 'GET', null, null, null, null, true);
  };

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>연계투자 상품정보 (모집완료 상품)</h4>
          <SearchBox
            productTypeCode={productTypeCode}
            requestParams={requestParams}
            handleCode={handleCode}
            handleDate={handleDate}
            handleClick={handleClick}
            handleKeyPress={handleKeyPress}
          ></SearchBox>
          <div className="mt50 mb20">
            <div className="tblTop typecomp">
              <CF.TotalCountForm totalElements={pagination} />
              <div className="topR">
                <div className="sortType">
                  <ul>
                    <li>
                      <span className="tit">
                        모집금액<span className="hide">정렬 선택</span>
                      </span>
                      <Button
                        className={
                          requestParams.sortProperty === 'investment_offering_amount' &&
                          requestParams.sortDirection === 'DESC'
                            ? 'btnSort on'
                            : 'btnSort'
                        }
                        vatiant="outlined"
                        endIcon={<ArrowDownwardIcon />}
                        name="offering/DESC"
                        onClick={(e) => createSortHandler(e)}
                      >
                        내림차순
                      </Button>
                      <Button
                        data-testid="linkedInvestProduct-offeringAsc"
                        className={
                          requestParams.sortProperty === 'investment_offering_amount' &&
                          requestParams.sortDirection === 'ASC'
                            ? 'btnSort on'
                            : 'btnSort'
                        }
                        variant="outlined"
                        endIcon={<ArrowUpwardIcon />}
                        name="offering/ASC"
                        onClick={(e) => createSortHandler(e)}
                      >
                        오름차순
                      </Button>
                    </li>
                    <li>
                      <span className="tit">
                        예상수익률<span className="hide">정렬 선택</span>
                      </span>
                      <Button
                        className={
                          requestParams.sortProperty === 'profit_rate' && requestParams.sortDirection === 'DESC'
                            ? 'btnSort on'
                            : 'btnSort'
                        }
                        vatiant="outlined"
                        endIcon={<ArrowDownwardIcon />}
                        name="expected/DESC"
                        onClick={(e) => createSortHandler(e)}
                      >
                        내림차순
                      </Button>
                      <Button
                        data-testid="linkedInvestProduct-expectedAsc"
                        className={
                          requestParams.sortProperty === 'profit_rate' && requestParams.sortDirection === 'ASC'
                            ? 'btnSort on'
                            : 'btnSort'
                        }
                        vatiant="outlined"
                        endIcon={<ArrowUpwardIcon />}
                        name="expected/ASC"
                        onClick={(e) => createSortHandler(e)}
                      >
                        오름차순
                      </Button>
                    </li>
                    <li>
                      <span className="tit">
                        정렬방식<span className="hide">선택</span>
                      </span>
                      <Button
                        className={viewState === 'list' ? 'btnShow list on' : 'btnShow list'}
                        onClick={(e) => handleViewState(e)}
                        name="list"
                      >
                        <span className="hide">리스트형</span>
                      </Button>
                      <Button
                        data-testid="linkedInvestProduct-boxIcon"
                        className={viewState === 'box' ? 'btnShow box on' : 'btnShow box'}
                        onClick={(e) => handleViewState(e)}
                        name="box"
                      >
                        <span className="hide">박스형</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {CM.cfnIsNotEmpty(product.contents) ? (
            viewState === 'list' ? (
              <BasicTable
                product={product}
                handleGoodsType={handleGoodsType}
                handleFileDownload={handleFileDownload}
              ></BasicTable>
            ) : (
              <BoxList
                product={product}
                handleGoodsType={handleGoodsType}
                handleFileDownload={handleFileDownload}
              ></BoxList>
            )
          ) : (
            <TableContainer>
              <Table className="tblType" aria-label="이용기관별 통계 목록">
                <TableBody>
                  <TableRow data-testid={'tablerow0'} key={'tablerow0'}>
                    <TableCell>조회된 내용이 없습니다.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div className="paging">
            <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default LinkedInvestProductM;
