/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 대고객포탈 routes
 */
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';
import Footer from '../components/customers/common/Footer';
import HeadTop from '../components/customers/common/HeadTop';
import Navi from '../components/customers/common/Navi';
import MainM from '../components/customers/main/MainM';
import InvestmentM from '../components/customers/myp2pfinanceinfo/investment/InvestmentM';
import LoanM from '../components/customers/myp2pfinanceinfo/loan/LoanM';
import IntroduceAgencyM from '../components/customers/serviceintro/centralagencyintro/IntroduceAgencyM';
import IntroduceTaskM from '../components/customers/serviceintro/centralagencyintro/IntroduceTaskM';
import RegistrationCompaniesM from '../components/customers/serviceintro/companiesinfo/RegistrationCompaniesM';
import IntroduceP2PLawM from '../components/customers/serviceintro/p2pfinanceintro/IntroduceP2PLawM';
import IntroduceP2PM from '../components/customers/serviceintro/p2pfinanceintro/IntroduceP2PM';
import TotalStatisticsM from '../components/customers/statistics/intergratedinfo/TotalStatisticsM';
import LinkedInvestProductM from '../components/customers/statistics/productinfo/LinkedInvestProductM';
import FaqM from '../components/customers/support/faq/FaqM';
import BoardDetailM from '../components/customers/support/notice/BoardDetailM';
import BoardListM from '../components/customers/support/notice/BoardListM';
import DownloadsDetailM from '../components/customers/support/pds/BoardDetailM';
import DownloadsListM from '../components/customers/support/pds/BoardListM';
import OpenApiServiceM from '../components/customers/useinfo/companiesguide/OpenApiServiceM';
import ServiceRequestInfoM from '../components/customers/useinfo/companiesguide/ServiceRequestInfoM';
import P2pFinanceUsageM from '../components/customers/useinfo/userguide/P2pFinanceUsageM';
import LoginKmcP from '../components/customers/myp2pfinanceinfo/login/LoginKmcP';
import LoginKgP from '../components/customers/myp2pfinanceinfo/login/LoginKgP';
import PrivacyPolicyM from '../components/customers/myp2pfinanceinfo/termpolicy/PrivacyPolicyM';
import { userStore } from '../stores/customers/userStore';
import Page404 from '../components/common/Page404Customer';
import GoToTop from '../components/common/template/GoToTop';

const menuJson = [
  {
    path: '/',
    main: 'main-root',
    component: MainM,
    loginStatus: false,
  },
  {
    path: '/main',
    main: 'main-root',
    component: MainM,
    loginStatus: false,
  },
  {
    path: '/myp2pfinanceinfo/investmentdetails',
    main: 'main-root',
    component: InvestmentM,
    loginStatus: false,
  },
  {
    path: '/myp2pfinanceinfo/loandetails',
    main: 'main-root',
    component: LoanM,
    loginStatus: false,
  },
  {
    path: '/board/notice',
    main: 'main-root',
    component: BoardListM,
    loginStatus: false,
  },
  {
    path: '/statistics/intergratedinfo',
    main: 'main-root',
    component: TotalStatisticsM,
    loginStatus: false,
  },
  {
    path: '/board/notice/:seqId',
    main: 'main-root',
    component: BoardDetailM,
    loginStatus: false,
  },
  {
    path: '/board/downloads',
    main: 'main-root',
    component: DownloadsListM,
    loginStatus: false,
  },
  {
    path: '/board/downloads/:seqId',
    main: 'main-root',
    component: DownloadsDetailM,
    loginStatus: false,
  },
  {
    path: '/statistics/productinfo',
    main: 'main-root',
    component: LinkedInvestProductM,
    loginStatus: false,
  },
  {
    path: '/serviceintro/registrationCompanies',
    main: 'main-root',
    component: RegistrationCompaniesM,
    loginStatus: false,
  },
  {
    path: '/board/faq',
    main: 'main-root',
    component: FaqM,
    loginStatus: false,
  },
  {
    path: '/serviceintro/p2pfinanceintro/introducep2p',
    main: 'main-root',
    component: IntroduceP2PM,
    loginStatus: false,
  },
  {
    path: '/serviceintro/p2pfinanceintro/introducep2plaw',
    main: 'main-root',
    component: IntroduceP2PLawM,
    loginStatus: false,
  },
  {
    path: '/serviceintro/centralagencyintro/introduceagency',
    main: 'main-root',
    component: IntroduceAgencyM,
    loginStatus: false,
  },
  {
    path: '/serviceintro/centralagencyintro/introducetask',
    main: 'main-root',
    component: IntroduceTaskM,
    loginStatus: false,
  },
  {
    path: '/useinfo/userguide/p2pfinanceusage',
    main: 'main-root',
    component: P2pFinanceUsageM,
    loginStatus: false,
  },
  {
    path: '/useinfo/companiesguide/openapiservice',
    main: 'main-root',
    component: OpenApiServiceM,
    loginStatus: false,
  },
  {
    path: '/useinfo/companiesguide/servicerequestinfo',
    main: 'main-root',
    component: ServiceRequestInfoM,
    loginStatus: false,
  },
  {
    path: '/myp2pfinanceinfo/loginkmcpopup',
    main: 'main-root',
    component: LoginKmcP,
    loginStatus: false,
  },
  {
    path: '/myp2pfinanceinfo/loginkgpopup',
    main: 'main-root',
    component: LoginKgP,
    loginStatus: false,
  },
  {
    path: '/termpolicy/privacypolicy',
    main: 'main-root',
    component: PrivacyPolicyM,
    loginStatus: false,
  },
];

/* 컨텐츠 위 공간, 서브 각페이지마다 무조건 들어감(영역잡음) */
function EmptyBox() {
  return <div className="empty-box"></div>;
}

const Routes = observer(() => {
  const [isMain, setMain] = useState(false);

  // 메인 화면 calss 추가
  const handleMain = () => {
    let mainUrl = userStore.naviLocation;

    if (mainUrl.indexOf('?') > -1) {
      mainUrl = mainUrl.substring(0, mainUrl.indexOf('?'));
    }

    if (mainUrl.indexOf('/main') > 0 || mainUrl.endsWith('/')) {
      setMain(true);
    } else {
      setMain(false);
    }
  };

  useEffect(() => {
    handleMain();
  }, [userStore.naviLocation]);

  return (
    <Router>
      <ScrollToTop>
        <div className={isMain ? 'App main customer' : 'App customer'}>
          <HeadTop></HeadTop>
          <EmptyBox></EmptyBox>
          <div id="container" className="container">
            {!isMain && <Navi naviTrigger={userStore.naviLocation}></Navi>}
            {/* {!isMain && (
              <Side naviTrigger={userStore.naviLocation}></Side>
            )} */}
            <Switch>
              {menuJson.map((value, key) => {
                let Component = value['component'];
                //Component = value["component"] != null && userStore.isLogin === true ? value["component"] : MainM;

                return <Route exact key={key} path={value['path']} component={Component} />;
              })}
              <Route component={Page404} />
            </Switch>
          </div>
          <Footer />
          <GoToTop />
        </div>
      </ScrollToTop>
    </Router>
  );
});

export default Routes;
