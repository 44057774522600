/*
 * @version : 2023.06
 * @author : 금융결제원 플랫폼개발부 Front-end팀 홍예빈(beanbin@kftc.or.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 개인정보처리방침(2021.12.17~) 파일
 */
import { React } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const PrivacyPolicyC_211217 = () => {
  return (
    <div>
      <div className="lawBox bgNo">
        <p className="tit">
          <strong className="primaryBlue">온라인투자연계금융업 중앙기록관리기관</strong>을 운영하는 사단법인&nbsp;
          <strong className="primaryBlue">금융결제원</strong>(이하 “결제원”이라 한다)은&nbsp;
          <strong className="primaryBlue">고객의 개인(신용)정보</strong>를 무엇보다 중요하게 생각하고, 고객이 안심하고
          이용할 수 있도록 <strong className="primaryBlue">개인(신용)정보보호</strong>에 최선을 다하고 있습니다. 또한
          개인정보보호법에 의거하여 <strong className="primaryBlue">개인정보처리방침</strong>을 아래와 같이 수립하여
          시행하고 있습니다.
        </p>
      </div>
      <div className="lawDetail">
        <p className="infos primaryBlue">제1조(개인(신용)정보의 처리 목적)</p>
        <p>
          ① 결제원은 다음의 목적을 위하여 최소한의 개인(신용)정보를 처리합니다. 처리하고 있는 개인(신용)정보는 다음의
          목적 이외의 용도로는 이용되지 않으며, 이용목적이 변경되는 경우에는 개인(신용)정보 보호 관련 법령에 따라 별도의
          동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </p>
        <br />
        <p>1. 온라인투자연계금융업 중앙기록관리 API 서비스</p>
        <p>
          - 온라인투자연계금융 정보의 집중·관리, 온라인투자연계금융업자(이하 ‘이용기관’이라 함)를 통한 투자자의 연계투자
          한도의 관리, 이용기관 등에 대한 연계투자(대출) 관련 정보 제공 등
        </p>
        <br />
        <p>2. 내P2P금융정보조회 서비스</p>
        <p>- 이용자(투자자, 차입자)에게 온라인투자연계금융 투자내역 및 대출내역 조회 서비스 제공을 위한 본인확인</p>
        <br />
        <p>3. 민원관리</p>
        <p> - 민원 처리시 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리결과 통보 등</p>
        <br />
        <p>② 다만, 결제원은 다음의 경우 정보주체 동의 없이 개인정보를 처리할 수 있습니다.</p>
        <br />
        <p>
          1. 개인정보보호법 제15조 및 제17조에 따라 당초 수집목적과 합리적으로 관련된 범위에서 정보주체의 동의 없이
          개인정보를 이용 및 제공할 수 있는 것으로 판단되는 경우. 이 경우 당초 수집목적과 추가 처리목적과의 연관성,
          처리하려는 정보의 특성 등 다음의 사항을 종합적으로 고려하여 판단합니다.
        </p>
        <p>- 당초 수집목적과 관련성이 있는지 여부</p>
        <p>
          - 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이
          있는지 여부
        </p>
        <p>- 정보주체의 이익을 부당하게 침해하는지 여부</p>
        <p>- 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</p>
        <br />
        <p>
          2. 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 가명정보를 처리하는 경우. 이 경우 가명정보가 재식별되지
          않도록 원본정보와 분리하여 별도 저장․관리하는 등 개인(신용)정보 보호 관련 법규에 따라 필요한
          관리적․기술적․물리적 보호조치를 취합니다.
        </p>
        <p className="infos primaryBlue">제2조(처리하는 개인(신용)정보의 항목)</p>
        <p>① 결제원은 다음과 같이 개인(신용)정보를 처리하고 있습니다.</p>
        <TableContainer className="statute">
          <Table aria-label="중앙기록관리기관 수행 업무">
            <colgroup>
              <col width="30%" />
              <col width="70%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>구분</TableCell>
                <TableCell>수집항목(필수)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>온라인투자연계금융업 중앙기록관리 API 서비스</TableCell>
                <TableCell>
                  <p>
                    - 이용자 정보(성명, 개인식별번호<sup>주1)</sup>(법인의 경우 법인식별번호<sup>주2)</sup>), 투자자
                    유형 등)
                  </p>
                  <p>- 연계대출․연계투자 신청 정보(금액, 일시 등)</p>
                  <p>- 연계대출․연계투자 실행 정보(금액, 기간, 상품 등)</p>
                  <p>
                    - 기타 연계대출․연계투자 관련 정보 (차입자의 원리금 상환에 관한 정보, 투자자의 원리금수취권 양도 및
                    양수에 관한 정보 등)
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>내P2P금융정보 조회 서비스</TableCell>
                <TableCell>
                  <p>
                    - 개인식별번호<sup>주1)</sup>(법인의 경우 사업자등록번호)
                  </p>
                  <p>
                    - 휴대폰 본인확인 정보(성명, 생년월일, 휴대폰번호 및 통신사, 성별, 내/외국인, CI/DI)<sup>주3)</sup>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>민원관리</TableCell>
                <TableCell>
                  <p>- 민원인 정보(성명, 연락처)</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p>주1) 주민등록번호, 외국인등록번호(국내거소신고번호)</p>
        <p>&emsp;* 온라인투자연계금융업 및 이용자 보호에 관한 법률 시행령 제36조 제3항에 의거 처리</p>
        <p>주2) 법인등록번호, 사업자등록번호</p>
        <p>주3) 본인확인기관으로부터 수집</p>
        <br />
        <p>② 기타 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.</p>
        <p>- IP주소, 쿠키, MAC주소, OS버전, 웹브라우저버전, 기기정보, 서비스 이용기록, 방문기록, 불량 이용기록 등</p>
        <p className="infos primaryBlue">제3조(개인(신용)정보의 수집방법)</p>
        <p>결제원은 정보주체 동의 및 관련 법령에 근거하여 다음의 방법으로 개인(신용)정보를 수집하고 있습니다.</p>
        <br />
        <p>1. 온라인투자연계금융업 중앙기록관리 API 서비스</p>
        <p>
          - 온라인투자연계금융업 및 이용자 보호에 관한 법률 제33조 제1항 및 동법 시행령 제28조 제1항에 따라
          이용기관으로부터 간접 수집
        </p>
        <br />
        <p>2. 내P2P금융정보조회 서비스</p>
        <p> - 서비스 이용 및 개인(신용)정보 수집·이용 동의에 따라 정보주체 및 본인확인기관으로부터 수집</p>
        <br />
        <p>3. 민원관리</p>
        <p> - 고객센터 등을 통한 민원신청 시 민원인으로부터 직접 수집</p>
        <p className="infos primaryBlue">제4조(개인(신용)정보의 처리 및 보유 기간)</p>
        <p>
          결제원은 관련 법령에 따른 개인(신용)정보 보유·이용기간 또는 정보주체로부터 개인(신용)정보 수집 시에 받은
          보유·이용기간 내에서 개인(신용)정보를 처리 및 보유합니다.
        </p>
        <br />
        <p>1. 온라인투자연계금융업 중앙기록관리 API 서비스</p>
        <p> - 자료를 제공받은 날부터 10년*</p>
        <p>&emsp;* 다만, 관계 법령 위반에 따라 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료시까지</p>
        <br />
        <p>2. 내P2P금융정보조회 서비스</p>
        <p> - 처리 후 저장하지 않음</p>
        <br />
        <p>3. 민원관리</p>
        <p> - 민원처리 완료 후 6개월까지</p>
        <p className="infos primaryBlue">제5조(개인(신용)정보의 제3자 제공에 관한 사항)</p>
        <p>
          ① 결제원은 개인(신용)정보를 제1조(개인(신용)정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의
          사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 외부에 공개 또는 제3자에게 제공하지 않습니다. 다만
          아래의 경우는 예외로 합니다.
        </p>
        <br />
        <p>1. 정보주체가 사전 동의한 경우</p>
        <br />
        <p>
          2. 「온라인투자연계금융업 및 이용자 보호에 관한 법률」 및 기타법령의 규정에 의거하여 관련 자료를 제공하는 경우
        </p>
        <br />
        <p>② 결제원의 개인(신용)정보 제3자 제공 내역은 다음과 같습니다.</p>
        <TableContainer className="statute">
          <Table aria-label="결제원 개인(신용)정보 제3자 제공 내역">
            <colgroup>
              <col width="22%" />
              <col width="28%" />
              <col width="28%" />
              <col width="22%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>제공받는 자</TableCell>
                <TableCell>제공 목적</TableCell>
                <TableCell>제공 항목</TableCell>
                <TableCell>보유·이용기간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>이동통신사 (SKT, KT, LGU+, MVNO)</TableCell>
                <TableCell rowSpan={2}>휴대폰 본인확인</TableCell>
                <TableCell rowSpan={2}>
                  <p>- 성명, 생년월일, 성별, 내/외국인 구분, 휴대폰번호, 통신사</p>
                </TableCell>
                <TableCell>이동통신사에서 보유중인 정보로 별도의 보유기간 없음</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>㈜한국모바일인증</TableCell>
                <TableCell>서비스 제공일로부터 200일 보관 후 영구삭제</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>이용기관</TableCell>
                <TableCell>
                  <p>온라인투자연계금융업 중앙기록관리 API 서비스 제공</p>
                  <p>(온라인투자연계금융업 및 이용자 보호에 관한 법률 제33조 제4항에 의거 자료 제공)</p>
                </TableCell>
                <TableCell>
                  <p>- 이용자 정보(성명, 개인식별번호(법인의 경우 법인식별번호), 투자자 유형 등)</p>
                  <p>- 연계대출․연계투자 신청 정보 (금액, 일시 등)</p>
                  <p>- 연계대출․연계투자 실행 정보 (금액, 기간, 상품 등) </p>
                  <p>
                    - 기타 연계대출․연계투자 관련 정보 (차입자의 원리금 상환에 관한 정보, 투자자의 원리금수취권 양도 및
                    양수에 관한 정보 등)
                  </p>
                </TableCell>
                <TableCell>
                  <p>이용기관이 자료의 정보주체인 이용자로부터 동의를 획득한 기간까지 보유</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p className="infos primaryBlue">제6조(개인(신용)정보 처리의 위탁에 관한 사항)</p>
        <p>
          ① 결제원은 이용자가‘내P2P금융정보조회 서비스’를 이용하는 경우 휴대폰을 통한 이용자 본인확인(개인식별정보
          검증)을 위해 아래와 같이 개인(신용)정보 처리업무를 위탁하고 있습니다.
        </p>
        <TableContainer className="statute">
          <Table aria-label="개인(신용)정보 처리의 위탁에 관한 사항">
            <colgroup>
              <col width="33%" />
              <col width="33%" />
              <col width="33%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>위탁받는자(수탁자)</TableCell>
                <TableCell>위탁업무 내용</TableCell>
                <TableCell>위탁하는 항목</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>나이스평가정보(주)*</TableCell>
                <TableCell>휴대폰을 통한 이용자 본인확인 (CI조회)</TableCell>
                <TableCell>주민등록번호*</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p>* 정보통신망법 제23조의3에 따른 본인확인기관으로, 동 법 제23조의2에 따라 주민등록번호 처리 가능</p>
        <br />
        <p>
          ② 결제원은 위탁계약 체결 시 개인(신용)정보 보호 관련 법령에 따라 위탁업무 수행목적 외 개인(신용)정보 처리금지,
          기술적‧관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리‧감독, 개인(신용)정보보호 관련 정기적 교육, 손해배상 등
          책임에 관한 사항을 위탁계약서 등 문서에 명시하고, 수탁자가 개인(신용)정보를 안전하게 처리하는지를 감독하고
          있습니다.
        </p>
        <br />
        <p>
          ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보처리방침을 통하여 공개하도록 하겠습니다.
        </p>
        <p className="infos primaryBlue">제7조(개인(신용)정보의 안전성 확보 조치에 관한 사항)</p>
        <p>결제원은 개인(신용)정보의 안전성 확보를 위해 다음과 같은 안전성 확보 조치를 취하고 있습니다.</p>
        <br />
        <p>1. 관리적 조치 : 개인(신용)정보 보호 관련 규정 및 계획 수립, 개인(신용)정보취급자 최소화 및 교육 등</p>
        <p>
          2. 기술적 조치 : 개인(신용)정보 처리시스템 접근통제 및 접근권한 관리, 고유식별정보 등 중요정보 암호화,
          네트워크 송수신 구간 암호화, 침입탐지시스템 등 보안솔루션 설치 및 운영 등
        </p>
        <p>3. 물리적 조치 : 전산실 및 자료보관실 접근통제, 장비 및 자료 반출입 통제 등</p>
        <p className="infos primaryBlue">제8조(개인(신용)정보의 파기에 관한 사항)</p>
        <p>
          ① 결제원은 개인(신용)정보 보유기간의 경과, 처리목적 달성 등 개인(신용)정보가 불필요하게 되었을 때에는 지체
          없이 해당 개인(신용)정보를 파기합니다.
        </p>
        <br />
        <p>
          ② 정보주체로부터 동의 받은 개인(신용)정보 보유기간이 경과하거나 처리목적이 달성 되었음에도 불구하고 다른
          법령에 따라 개인(신용)정보를 계속 보존하여야 하는 경우에는 해당 개인(신용)정보를 다른 개인(신용)정보와
          분리하여 엄격히 별도 저장ㆍ관리합니다.
        </p>
        <TableContainer className="statute">
          <Table aria-label="중앙기록관리기관 수행 업무">
            <colgroup>
              <col width="30%" />
              <col width="70%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>보존근거</TableCell>
                <TableCell>보존하는 개인(신용)정보</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>신용정보법 제20조</TableCell>
                <TableCell>
                  <p>- 개인신용정보의 처리에 대한 기록</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <p>③ 결제원의 개인(신용)정보 파기절차 및 방법은 다음과 같습니다.</p>
        <p>1. 파기절차 : 불필요한 개인(신용)정보는 개인(신용)정보관리책임자의 책임 하에 다음과 같이 파기합니다.</p>
        <p>
          - 보유기간 경과 등 파기사유가 발생한 개인(신용)정보는 자동으로 파기하거나 소관 관리책임자의 승인을 거쳐 파기
        </p>
        <br />
        <p>2. 파기방법 : 개인(신용)정보가 복구 또는 재생되지 않도록 다음과 같이 완전삭제 조치합니다. </p>
        <p>
          - 전자적 파일은 일부 파기 시에는 해당 개인(신용)정보를 삭제한 후 복구·재생이 되지 않도록 관리 및 감독하며,
          전체 파기 시에는 초기화, 덮어쓰기, 전용소자장비 이용 등의 방법으로 파기
        </p>
        <p>
          - 기타 기록물, 인쇄물, 서면 등 비전자적 파일은 일부 파기 시에는 해당 개인(신용)정보를 마스킹, 천공 등을 통해
          삭제, 전체 파기 시에는 파쇄 또는 소각 등의 방법으로 파기
        </p>
        <p className="infos primaryBlue">제9조(정보주체와 법정대리인의 권리의무 및 그 행사방법에 관한 사항)</p>
        <p>① 정보주체는 다음과 같은 권리를 행사할 수 있습니다.</p>
        <br />
        <p>1. 개인(신용)정보 열람 요구권</p>
        <p>2. 개인(신용)정보 정정·삭제 요구권</p>
        <p>3. 개인(신용)정보 처리정지 및 동의철회 요구권</p>
        <p>4. 개인신용정보 이용 및 제공사실 통지 요구권</p>
        <p>5. 개인(신용)정보 수집출처 고지 요구권</p>
        <br />
        <p>② 다만, 다음에 해당하는 경우에는 제1항에 따른 정보주체의 권리 행사가 제한될 수 있습니다.</p>
        <br />
        <p>1. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위해 불가피한 경우</p>
        <p>
          2. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는
          경우
        </p>
        <p>
          3. 개인(신용)정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한
          경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
        </p>
        <br />
        <p>
          ③ 제1항에 따른 권리 행사는 서면, 전화, 전자우편, 모사전송(FAX), 고객센터 홈페이지 등을 통하여 요청하실 수
          있습니다.
        </p>
        <br />
        <p>
          ④ 정보주체의 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있으며, 이 경우
          위임장을 함께 제출하여야 합니다.
        </p>
        <br />
        <p>
          ⑤ 결제원은 정보주체와 법정대리인이 개인(신용)정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우, 정정 또는
          삭제를 완료할 때까지 당해 개인(신용)정보를 이용하거나 제공하지 않습니다.
        </p>
        <br />
        <p>⑥ 제1항에 따른 권리 행사 요구에 대한 처리절차는 다음과 같습니다.</p>
        <div className="areaR">
          <div className="statBox">
            <p className="tit">열람 요구</p>
            <p>
              ① 개인(신용)정보 열람 요구 → ② 요구주체 확인 및 열람 범위 확인 → ③ 개인(신용)정보 열람 제한사항 확인 → ④
              열람 결정 통지(or 열람 거부 통지) → ⑤ 열람
            </p>
            <br />
            <p className="tit">정정·삭제, 처리정지 및 동의철회 등 요구</p>
            <p>
              ① 개인(신용)정보 정정·삭제·처리정지 및 동의철회 등 요구 → ② 요구주체 확인 및 처리범위 확인 → ③
              개인(신용)정보 정정·삭제, 처리정지 및 동의철회 등 제한사항 확인 → ④ 처리결과 통지
            </p>
          </div>
        </div>
        <p className="infos primaryBlue">제10조(개인정보 보호책임자에 관한 사항)</p>
        <p>
          ① 결제원은 개인(신용)정보 처리에 관한 업무를 총괄해서 책임지고 개인(신용)정보 처리와 관련한 정보주체의
          불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <br />
        <p>- 개인정보 보호책임자 : 전무이사 손희성</p>
        <p>&nbsp;·연락처 : 아래 개인정보 관리담당자 연락처로 문의바람</p>
        <p>- 개인정보 관리담당부서 : 기업금융부</p>
        <p>- 개인정보 관리담당자 : 기업금융부 P2P금융업무반 담당자</p>
        <p>&nbsp;(전화) 02-531-1575∼1577, (팩스) 02-531-1507, (이메일) p2pcenter@kftc.or.kr</p>
        <p>&nbsp;·주 소 : (우)135-758 서울특별시 강남구 논현로 432</p>
        <br />
        <p>
          ② 서비스를 이용하면서 발생한 모든 개인(신용)정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 위의
          연락처로 문의주시면 확인 및 조치 후 처리결과를 통보해 드리겠습니다.
        </p>
        <p className="infos primaryBlue">제11조(개인정보 자동수집장치의 설치·운영 및 그 거부에 관한 사항)</p>
        <p>
          ① 결제원은 홈페이지에 대한 기본 설정정보를 보관하기 위해 쿠키(cookie) 등 개인정보 자동수집장치를 설치․운영하고
          있습니다. 쿠키는 중앙기록관리기관 홈페이지를 운영하는데 이용되는 서버가 고객의 브라우저에 보내는 아주 작은
          텍스트 파일로서 고객의 컴퓨터 하드디스크에 저장됩니다.
        </p>
        <br />
        <p>
          ② 고객은 쿠키 이용에 대한 선택권을 가지고 있으며 웹브라우저 설정을 통해 쿠키의 이용을 허용하거나 거부할 수
          있습니다. 단, 쿠키의 저장을 거부하는 옵션을 선택하는 경우 서비스 이용에 불편이 야기될 수 있습니다.
        </p>
        <div className="areaR">
          <div className="statBox">
            <p className="tit">쿠키 설정 거부 방법</p>
            <p>
              (인터넷 익스플로어의 경우) 웹 브라우저 상단의 "도구 &gt; 인터넷옵션 &gt; 개인정보 &gt; 고급" → '허용',
              '차단', '사용자가 선택' 설정 가능
            </p>
            <p>
              (크롬 브라우저의 경우) 웹 브라우저 상단 우측의 "설정 &gt; 개인정보 &gt; 콘텐츠 설정 &gt; 쿠키" → '허용',
              '브라우저 종료 시까지 유지', '차단' 설정 가능
            </p>
          </div>
        </div>
        <p className="infos primaryBlue">제12조(권익침해 구제방법)</p>
        <p>
          ① 정보주체는 개인(신용)정보 침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원
          개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
        </p>
        <br />
        <p>② 개인(신용)정보 침해로 인한 신고나 상담이 필요한 경우에는 아래 기관에 문의하시기 바랍니다. </p>
        <br />
        <p>․ 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
        <p>․ 개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)</p>
        <p>․ 대검찰청 사이버수사과 : (국번없이) 1301, privacy@spo.go.kr (www.spo.go.kr)</p>
        <p>․ 경찰청 사이버수사국 : (국번없이) 182 (ecrm.cyber.go.kr)</p>
        <p className="infos primaryBlue">제13조(개인정보처리방침 변경)</p>
        <p>이 개인정보처리방침은 2021. 12. 17.부터 적용됩니다.</p>
        <p>이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicyC_211217;
