/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 업무정보 조회 - 기록내역 조회
 * 연계대출기록 - 투자상품
 */
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import VerticalAlignBottomOutlinedIcon from '@material-ui/icons/VerticalAlignBottomOutlined';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const RecordHistoryInvestProductS = (props) => {
  const effect = props;
  const currentTab = effect.currentTab;
  // const [investProduct, setInvestProduct] = useState([]); //투자상품
  // //대출연관기록
  // const [relationProduct, setRelationProduct] = useState({ "list": [] }); //투자상품

  let msg = []; //오류 메시지

  //화면로딩 시 실행
  useEffect(() => {
    // 투자상품 handler
    const handleInvestProduct = (paramRelationProduct) => {
      let tmpTab = CM.cfnCopyObject(effect.tab);
      let tmpThisTab = tmpTab[currentTab];
      let tmp = tmpThisTab.contents;
      // if (CM.cfnIsNotEmpty(resultData) && resultData !== effect.tab[currentTab].contents.basic) {
      //     props.setSearchTime.call(null, resultData.api_trx_dtm);
      //     // setInvestProduct(resultData);
      //     tmpRp.view = true;
      //     tmpRp.basic = resultData
      // } else {
      //     tmpRp.view = false;
      // }
      if (CM.cfnIsNotEmpty(paramRelationProduct) && paramRelationProduct !== effect.tab[currentTab].contents.relation) {
        // setRelationProduct(props.sortLoanRelation(paramRelationProduct));
        tmp.relation = props.sortLoanRelation(paramRelationProduct);
      }
      effect.setTab(tmpTab);
    };
    //투자상품 조회
    const axiosInvestProduct = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/products/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            let tmpRp = CM.cfnCopyObject(effect.requestParams.loans2);
            tmpRp.view = false;
            effect.setRequestParams((data) => ({
              ...data,
              loans2: tmpRp,
            }));
            msg.push(process.env.REACT_APP_M007);
            resolve(null);
          },
        );
      });
    };

    //연관기록내역 조회
    const axiosRelation = (param) => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/relations/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;
        url += `&record_type=${param}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M008);
            resolve(null);
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    const startAxios = async () => {
      // const resultData = await axiosInvestProduct(); //투자상품
      //대출신청(LOAN_REQUEST), 투자상품(INVEST_PRODUCT), 대출계약(LOAN_CONTRACT)
      const relationProduct = await axiosRelation('INVEST_PRODUCT'); //연관기록내역-투자상품
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handleInvestProduct(relationProduct);
    };

    if (effect.tab[currentTab].contents.view && CM.cfnIsEmpty(effect.tab[currentTab].contents.relation.list))
      startAxios();
  }, [effect.currentTab, effect.setCurrentTab]);

  //첨부파일 다운로드
  const handleFileDownload = () => {
    let fileUrl = effect.tab[currentTab].contents.basic.product_description_url;
    let filePath = fileUrl.substr(fileUrl.indexOf('/') + 1, fileUrl.length);
    const reject = (objStatus, response) => {
      CM.cfnAlert(process.env.REACT_APP_M005);
    };
    axiosUtil.cfnAxiosGeneralFileDownload(filePath, 'GET', null, null, null, null, true);
  };

  return (
    <div className="inner">
      <h6 className="hide">조회결과 상세내용</h6>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">기록관리 ID</p>
          <div className="itemBox corpInfo histidImg">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="name">{effect.tab[currentTab].contents.basic.record_no}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">차입자 정보</p>
          <div className="itemBox borrower">
            <div className="infoMain">
              <ul>
                <li>
                  <span className="title">차입자 성명</span>
                  <span>{effect.tab[currentTab].contents.basic.borrower_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">차입자 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.borrower_type_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">고유식별번호</span>
                  <span>{CM.cfnIdentificationNoFormat(String(effect.tab[currentTab].contents.basic.identity_no))}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">상세조회내역</p>
          <div className="itemBox">
            <div className="itemArea">
              <p className="smallTit">투자상품 정보</p>
              <ul className="infoList">
                <li className="len1">
                  <span className="tit">투자상품명</span>
                  <span>{effect.tab[currentTab].contents.basic.product_name}</span>
                </li>
                <li>
                  <span className="tit">투자상품 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.product_type_name}</span>
                </li>
                <li>
                  <span className="tit">투자모집 금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.invest_offering_amount) ||
                    effect.tab[currentTab].contents.basic.invest_offering_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.invest_offering_amount) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">모집시작일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.offering_start_date, 'yyyyMMdd')}</span>
                </li>
                <li>
                  <span className="tit">모집종료일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.offering_end_date, 'yyyyMMdd')}</span>
                </li>
                <li>
                  <span className="tit">수수료</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.fee) ||
                    effect.tab[currentTab].contents.basic.fee === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.fee) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">수익률</span>
                  <span>{effect.tab[currentTab].contents.basic.profit_rate}%</span>
                </li>
                <li>
                  <span className="tit">현재 모집금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.current_offering_amount) ||
                    effect.tab[currentTab].contents.basic.current_offering_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.current_offering_amount) + '원'}
                  </span>
                </li>
                {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.basic.product_description_url) && (
                  <li>
                    <span className="tit">상품설명서 정보</span>
                    <span>
                      <Button
                        className="btn iconPdf"
                        endIcon={<VerticalAlignBottomOutlinedIcon />}
                        onClick={handleFileDownload}
                      >
                        pdf 다운로드
                      </Button>
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">상태</p>
              <ul className="infoList">
                <li>
                  <span className="tit">투자상품 상태</span>
                  <span data-testid="test-status">{effect.tab[currentTab].contents.basic.product_status}</span>
                  {/* <span className="red">진행 중</span> 진행중일 경우 글자색 레드 */}
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">이력 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">최초등록일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.first_registration_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">연관기록내역</p>
          <TableContainer>
            <Table
              className="tblTypeline"
              aria-label="대출신청, 투자상품, 대출계약, 대출상환으로 이루어진 연관 기록내역 목록"
            >
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="33%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>대출신청</TableCell>
                  <TableCell>투자상품</TableCell>
                  <TableCell>대출계약</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {effect.tab[currentTab].contents.relation.list.map((row, index) => {
                    if (row.record_type === 'LOAN_REQUEST') {
                      return (
                        <TableCell
                          key="loanRequest"
                          data-testid="renderTest"
                          rowSpan={
                            effect.tab[currentTab].contents.relation.productCnt < 2
                              ? 1
                              : effect.tab[currentTab].contents.relation.productCnt
                          }
                        >
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,1,대출신청,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (
                      row.record_type === 'INVEST_PRODUCT' &&
                      index <= effect.tab[currentTab].contents.relation.secondProductIndex
                    ) {
                      return (
                        <TableCell key="investProduct" className="bglightSky">
                          <p>{row.record_no}</p>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'LOAN_CONTRACT') {
                      return (
                        <TableCell
                          key="loanContract"
                          rowSpan={effect.tab[currentTab].contents.relation.list.length}
                          rowSpan={
                            effect.tab[currentTab].contents.relation.productCnt < 2
                              ? 1
                              : effect.tab[currentTab].contents.relation.productCnt
                          }
                        >
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,3,대출계약,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    }
                  })}
                  {effect.tab[currentTab].contents.relation.list.length < 3
                    ? [...Array(3 - effect.tab[currentTab].contents.relation.list.length)].map((n, index) => {
                        return <TableCell key={index} />;
                      })
                    : null}
                </TableRow>

                {effect.tab[currentTab].contents.relation.productCnt > 1
                  ? effect.tab[currentTab].contents.relation.list.map((row, index) =>
                      row.record_type === 'INVEST_PRODUCT' &&
                      index > effect.tab[currentTab].contents.relation.secondProductIndex ? (
                        <TableRow key={'investProduct' + index}>
                          <TableCell>
                            <span onClick={props.handleLink}>
                              <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                                {row.record_no}
                              </p>
                            </span>
                            <p className="font14">
                              {CM.cfnIsNotEmpty(row.record_dtm) &&
                                '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                            </p>
                          </TableCell>
                        </TableRow>
                      ) : null,
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RecordHistoryInvestProductS;
