/*
 * @version : 2021.04.21
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국 메인화면 팝업
 *
 */
import { Button, Checkbox, FormControlLabel, Modal } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';
import Draggable from 'react-draggable';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const Popup = () => {
  const [popupList, setPopupList] = useState([]);
  const [popupPosition, setPopupPosition] = useState([]);
  const myRefs = useRef([]);
  const expireCheckRefs = useRef([]);
  let initialPosition = {}; //초기 팝업 위치
  let popupArray = [];

  useEffect(() => {
    //팝업 목록 조회

    const getPopupList = () => {
      return new Promise((resolve) => {
        let api = 'web/supervisors/popups';
        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          null,
        );
      });
    };
    const startGetStatistics = async () => {
      const resultData = await getPopupList();
      handlePopupList(resultData);
    };

    startGetStatistics();
  }, []);

  const handleClose = (seqId, index) => {
    setPopupList(popupList.map((popup) => (popup.seq_id === seqId ? { ...popup, open: false } : { ...popup })));

    if (CM.cfnIsEmpty(initialPosition)) {
      let offsetRight = myRefs.current[index].offsetLeft + myRefs.current[index].offsetWidth;

      initialPosition = { x: offsetRight, y: myRefs.current[index].offsetTop };
    }

    //팝업 위치 설정
    let popupPosition = [];
    myRefs.current.map((row, index) => {
      if (CM.cfnIsNotEmpty(row)) {
        popupPosition.push({
          x: row.getBoundingClientRect().right - initialPosition.x,
          y: row.getBoundingClientRect().top - initialPosition.y,
        });
      } else {
        popupPosition.push({ x: 0, y: 0 });
      }
    });
    setPopupPosition(popupPosition);

    //오늘 하루 열지 않기
    let notOpenTodayChecked = expireCheckRefs.current[index].children[0].children[0].checked;
    setNotOpenToday(notOpenTodayChecked, seqId);
  };

  // 팝업 오늘 하루 열지 않기 설정
  const setNotOpenToday = (isChecked, seqId) => {
    if (isChecked) {
      let popup = JSON.parse(localStorage.getItem('popup'));

      if (CM.cfnIsNotEmpty(popup)) {
        const found = popup.find((element) => element.seqId === seqId);
        if (CM.cfnIsNotEmpty(found)) {
          let updatedPopup = popup.map((row) =>
            row.seqId === seqId ? { ...row, expired: CM.cfnGetCustomDateTime('date', 1, 'string') } : { ...row },
          );
          localStorage.setItem('popup', JSON.stringify(updatedPopup));
        } else {
          popup.push({ seqId: seqId, expired: CM.cfnGetCustomDateTime('date', 1, 'string') });
          localStorage.setItem('popup', JSON.stringify(popup));
        }
      } else {
        localStorage.setItem(
          'popup',
          JSON.stringify([{ seqId: seqId, expired: CM.cfnGetCustomDateTime('date', 1, 'string') }]),
        );
      }
    }
  };

  //팝업 open 설정
  const setPopupOpen = (popup, seqId) => {
    const found = popup.find((element) => element.seqId === seqId);

    if (CM.cfnIsNotEmpty(found)) {
      if (found.expired > CM.cfnGetDateTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handlePopupList = (param) => {
    let storagePopup = JSON.parse(localStorage.getItem('popup'));

    if (CM.cfnIsNotEmpty(storagePopup)) {
      //1일 지난 localstorage삭제
      let tmpPopup = CM.cfnCopyObject(storagePopup);
      for (let index = 0; index < storagePopup.length; index++) {
        const element = storagePopup[index];
        if (Number(element.expired) < Number(CM.cfnGetDateTime())) {
          tmpPopup.splice(index, 1);
        }
      }
      localStorage.setItem('popup', JSON.stringify(tmpPopup));
    }

    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];

      const resolve = (objStatus, response) => {
        const blobURL = window.URL.createObjectURL(new Blob([response], { type: response.type }));
        popupArray.push({
          ...element,
          img: blobURL,
          open: CM.cfnIsEmpty(storagePopup) ? true : setPopupOpen(storagePopup, element.seq_id),
        });

        // 첨부파일이 포함된 팝업이 존재하는 경우
        if (popupArray.length === param.list.length) {
          // open할 팝업만 popupList에 세팅
          let openPopups = [];
          popupArray.map((row) => {
            if (row.open) {
              openPopups.push(row);
            }
          });
          setPopupList(openPopups);

          //팝업 초기 위치 설정
          let popupPosition = [];
          openPopups.map((row, index) => {
            popupPosition.push({ x: 0 - index * 30, y: 0 + index * 30 });
          });
          setPopupPosition(popupPosition);
        }
      };

      let url = element.file_web_url;
      if (CM.cfnIsNotEmpty(url)) {
        if (String(url).charAt(0) === '/') url = url.substr(1, url.length);
        axiosUtil.cfnAxiosImgPreviewApi(url, 'GET', null, null, resolve, (objStatus, response) => {});
      } else {
        popupArray.push({
          ...element,
          img: '',
          open: CM.cfnIsEmpty(storagePopup) ? true : setPopupOpen(storagePopup, element.seq_id),
        });

        // 첨부파일이 포함된 팝업이 존재하지 않는 경우
        if (popupArray.length === param.list.length) {
          // open할 팝업만 popupList에 세팅
          let openPopups = [];
          popupArray.map((row) => {
            if (row.open) {
              openPopups.push(row);
            }
          });
          setPopupList(openPopups);

          //팝업 초기 위치 설정
          let popupPosition = [];
          openPopups.map((row, index) => {
            popupPosition.push({ x: 0 - index * 30, y: 0 + index * 30 });
          });
          setPopupPosition(popupPosition);
        }
      }
    }
  };

  //z-index 설정
  const onStart = (e) => {
    let elements = document.getElementsByClassName('react-draggable');
    let tmpZindex = Number(elements[0].style.zIndex);
    for (let i = 0; i < elements.length; i++) {
      if (tmpZindex < Number(elements[i].style.zIndex)) {
        tmpZindex = Number(elements[i].style.zIndex);
      }
    }
    e.currentTarget.style.zIndex = tmpZindex + 1;
  };

  return (
    CM.cfnIsNotEmpty(popupList) &&
    CM.cfnIsNotEmpty(popupPosition) &&
    popupList.map((popup, index) => (
      <Modal
        ref={(el) => (myRefs.current[index] = el)}
        id={index}
        key={index}
        data-testid="popup-previewModal"
        aria-labelledby="팝업"
        aria-describedby="팝업"
        open={popup.open}
        className="sublayerPop mainpop"
        hideBackdrop={false}
      >
        <div className="popInner poppreview" style={{ height: popup.popup_height, width: popup.popup_width }}>
          <div className="popcontWrap">
            <div className="popCont">
              <Scrollbars>
                <div className="popContin">
                  <h1>{popup.popup_title}</h1>
                  <p
                    dangerouslySetInnerHTML={{ __html: popup.popup_contents.replace(new RegExp('\n', 'g'), '<br/>') }}
                  />
                  <div className="popImg">
                    {CM.cfnIsNotEmpty(popup.img) ? <img src={popup.img} alt="팝업이미지" /> : null}
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
          <div className="popBottom">
            <FormControlLabel
              control={<Checkbox name="closecheck" id="chk" ref={(el) => (expireCheckRefs.current[index] = el)} />}
              label="오늘 하루 열지 않음"
            />
            <Button
              className="btnClose"
              id={index}
              onClick={() => {
                handleClose(popup.seq_id, index);
              }}
              data-testid="close-modal"
            >
              닫기
            </Button>
          </div>
        </div>
      </Modal>
    ))
  );
};

export default Popup;
