/*
 * @version : 2021.02.15
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용기관 업무관련 참고자료 - 연계투자 관련 참고자료
 */

import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { toJS } from 'mobx';
import { Fragment, useEffect, useRef, useState } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import { rootStore } from '../../../../stores/rootStore';
import * as CF from '../../../common/template/ComponentForm';
import * as CM from '../../../common/util/common';
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';
import axiosUtil from '../../../../utils/axiosUtil';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LinkedInvestmentP from './LinkedInvestmentP';

const inquiryStatusTypeOptions = [
  { value: 'CONTRACT_PROGRESS', label: '투자진행중 상품' },
  { value: 'NEW_CONTRACT', label: '신규 투자계약 체결 상품' },
];

const DEFAULT_HOOKS = {
  requestParams: {
    inquiryStatusType: inquiryStatusTypeOptions[0].value,
    inquiryEndDate: CM.cfnGetCustomDate('date', -1, 'string'),
    inquiryStartDate: CM.cfnGetCustomThisDate(CM.cfnGetCustomDate('date', -1, 'string'), 'month', -3, 'string'),
    inquiryDate: CM.cfnGetCustomDate('date', -1, 'string'),
  },
};

const CorpInfo = () => {
  return (
    <div className="section">
      <h4>이용기관 정보</h4>
      <div className="contBox">
        <div className="contBox_item w100p">
          <div className="itemBox corpInfo">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="title">이용기관명</p>
                <p className="name">{userStore.getCompanyName()}</p>
              </div>
              <div className="StatusR">
                <p className="title">이용기관 코드</p>
                <p className="name">{userStore.getCompanyCode()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchBox = (props) => {
  return (
    <div className="searchArea">
      <div className="tblSearch">
        <form>
          <fieldset>
            <legend>검색조건 입력</legend>
            <table>
              <colgroup>
                <col width="18%" />
                <col width="47%" />
                <col width="35%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">투자계약 상태</th>
                  <td colSpan="2">
                    <div className="searchList">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">투자계약 상태</FormLabel>
                        <CF.RadioGroupForm
                          name="inquiryStatusType"
                          id="inquiryStatusType"
                          value={props.requestParams.inquiryStatusType}
                          option={inquiryStatusTypeOptions}
                          onChange={props.handleInquiryStatusType}
                          row={true}
                          testId="inquiryStatusTypeTest"
                        />
                      </FormControl>
                    </div>
                  </td>
                </tr>
                <tr>
                  {props.requestParams.inquiryStatusType === 'CONTRACT_PROGRESS' ? (
                    <Fragment>
                      <th scope="row">조회시점</th>
                      <td>
                        <div className="searchList">
                          <div className="datePick">
                            <CF.DatePickerForm
                              id="inquiryDate"
                              value={props.requestParams.inquiryDate}
                              handleChange={props.handleDate('inquiryDate')}
                              customProps={{
                                maxDate: CM.cfnGetCustomDate('date', -1, 'date'),
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <th scope="row">조회범위</th>
                      <td>
                        <div className="searchList">
                          <div className="datePick">
                            <CF.DatePickerForm
                              id="startDate"
                              testId="startDate"
                              value={props.requestParams.inquiryStartDate}
                              handleChange={props.handleDate('inquiryStartDate')}
                              customProps={{
                                maxDate: CM.cfnGetCustomDate('date', -1, 'date'),
                              }}
                            />
                            <span className="rowline">~</span>
                            <CF.DatePickerForm
                              id="endDate"
                              testId="endDate"
                              value={props.requestParams.inquiryEndDate}
                              handleChange={props.handleDate('inquiryEndDate')}
                              customProps={{
                                maxDate: CM.cfnGetCustomDate('date', -1, 'date'),
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </Fragment>
                  )}
                  <td className="tl">
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={props.handleClick}
                      data-testid="searchButton"
                    >
                      조회
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="hide">주의사항</span>
                  </th>
                  <td colSpan="2">
                    {props.requestParams.inquiryStatusType === 'CONTRACT_PROGRESS' ? (
                      <p className="info">
                        선택한 시점을 기준으로 투자계약이 진행 중인 상품을 조회합니다. 현재기준 전일까지 조회가
                        가능합니다.
                      </p>
                    ) : (
                      <p className="info">
                        선택한 기간 내에서 이용기관이 투자계약 기록요청을 보내 등록 처리된 상품을 조회합니다. 현재기준
                        전일까지 조회가 가능합니다.
                        <br />
                        (최대 3개월의 범위 내에서 검색 가능합니다.)
                      </p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

const LinkedInvestmentM = () => {
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams);
  const [buttonState, setButtonState] = useState(true);
  const [investReference, setInvestReference] = useState({});
  const productTypeList = toJS(rootStore.ProductTypeList);
  const totalProductIndex = productTypeList.findIndex((element) => element.product_type_code === 'TOTAL_PRODUCT');
  productTypeList.splice(totalProductIndex, 1);
  productTypeList.push(toJS(rootStore.ProductTypeList[totalProductIndex]));
  const investorTypes = [
    { investorTypeCode: 'GENERAL_PERSONAL', investorTypeValue: '일반개인투자자' },
    { investorTypeCode: 'SUITABLE_INCOME', investorTypeValue: '소득적격투자자' },
    { investorTypeCode: 'PRO_PERSONAL', investorTypeValue: '개인전문투자자' },
    { investorTypeCode: 'CORPORATE', investorTypeValue: '법인투자자' },
    { investorTypeCode: 'LOAN_BUSINESS', investorTypeValue: '여신금융기관' },
    { investorTypeCode: 'P2P_CORPORATE', investorTypeValue: '온라인투자연계금융업자' },
  ]; //투자자 유형
  const borrowerTypes = ['PERSONAL', 'PERSONAL_BUSINESS', 'CORPORATE'];
  const [modalOpen, setModalOpen] = useState(false); //모달

  const handleInquiryStatusType = (event) => {
    setRequestParams((data) => ({ ...data, inquiryStatusType: event.target.value }));
  };

  const handleDate = (name) => (date) => {
    setRequestParams((data) => ({ ...data, [name]: CM.cfnConvertDateToString(date) }));
  };

  const handleClick = () => {
    setButtonState(true);
  };

  const handleInvestReference = (response) => {
    setInvestReference(response);
  };

  const modalRef = useRef(null);

  const handleReport = () => {
    //modalRef.current.state.x = 0;
    //modalRef.current.state.y = 0;
    setModalOpen(true);
  };

  useEffect(() => {
    userStore.handleNaviTrigger();

    const getInvestReference = (params) => {
      return new Promise((resolve, reject) => {
        let url = 'web/companies/investments/references';
        url += `?inquiry_status_type=${requestParams.inquiryStatusType}`;

        if (requestParams.inquiryStatusType === 'CONTRACT_PROGRESS') {
          url += `&inquiry_start_date=${requestParams.inquiryDate}&inquiry_end_date=${requestParams.inquiryDate}`;
        } else if (requestParams.inquiryStatusType === 'NEW_CONTRACT') {
          url += `&inquiry_start_date=${requestParams.inquiryStartDate}&inquiry_end_date=${requestParams.inquiryEndDate}`;
        }
        url += `&p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          null,
        );
      });
    };

    const startGetInvestReference = async (params) => {
      setButtonState(false);
      const resultData = await getInvestReference(params);
      handleInvestReference(resultData);
    };

    if (buttonState) {
      if (requestParams.inquiryStatusType === 'NEW_CONTRACT') {
        if (!CM.cfnCommonValidDate(requestParams.inquiryStartDate, requestParams.inquiryEndDate, 3)) {
          setButtonState(false);
          return false;
        }
      }
      startGetInvestReference(requestParams);
    }
  }, [buttonState]);

  return (
    <div className="subContent">
      <div className="subInner">
        <CorpInfo></CorpInfo>

        <div className="section">
          <h4>연계투자 관련 참고자료</h4>
          <SearchBox
            requestParams={requestParams}
            handleInquiryStatusType={handleInquiryStatusType}
            handleDate={handleDate}
            handleClick={handleClick}
          />
          <div className="tblTop">
            <div className="topL">
              <p>
                ※ 목록의 <span className="lightBlue">목록의 스크롤을 상하, 좌우로</span> 이동하시면 내용을 확인할 수
                있습니다.
              </p>
            </div>
            <div className="topR">
              <Button className="btn print" onClick={handleReport} startIcon={<AssignmentIcon />}>
                참고자료 보고서<span className="hide">출력</span>
                <PrintIcon />
              </Button>
            </div>
          </div>
          {CM.cfnIsEmpty(investReference) ? (
            <TableContainer>
              <Table className="tblTypeline sumType" aria-label="연계투자관련 참고자료 목록">
                <colgroup>
                  <col width="" />
                  <col width="" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={2}>차입자 유형</TableCell>
                    <TableCell rowSpan={2}>상품 유형</TableCell>
                    <TableCell colSpan={2}>투자관련 수치</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>투자계약 수 (건)</TableCell>
                    <TableCell>투자금액 (원)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <p className="resultNo">조회된 내용이 없습니다.</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer data-testid="tableContainerTest" className="smallType">
              <Scrollbars>
                <Table className="tblTypeline sumType scrollX" aria-label="연계투자관련 참고자료 목록">
                  <colgroup>
                    <col width="" />
                    <col width="" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell rowSpan={3}>차입자 유형</TableCell>
                      <TableCell rowSpan={3}>상품 유형</TableCell>
                      <TableCell colSpan={3 * investorTypes.length}>투자관련 수치</TableCell>
                    </TableRow>
                    <TableRow>
                      {investorTypes.map((item, index) => (
                        <TableCell key={'tablecell' + index} colSpan={2}>
                          {item.investorTypeValue}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {investorTypes.map((item, index) => (
                        <Fragment key={'investorTypes' + index}>
                          <TableCell>투자계약 수 (건)</TableCell>
                          <TableCell>투자금액 (원)</TableCell>
                        </Fragment>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {borrowerTypes.map((borrowerType, idx) => {
                      let tempBorrowerType = [];
                      let investorType = [];

                      investReference.reference_info_list.map((referenceInfo) => {
                        if (borrowerType === referenceInfo.borrower_type_code) {
                          tempBorrowerType.push(referenceInfo);
                        }
                      });

                      return productTypeList.map((productType, index) => {
                        investorType = [];
                        tempBorrowerType.map((item) => {
                          if (item.goods_type_code === productType.product_type_code) {
                            investorType.push(item);
                          }
                        });

                        return productType.product_type_code !== 'TOTAL_PRODUCT' ? (
                          <TableRow key={'tablerow' + idx + index}>
                            {index === 0 && (
                              <TableCell rowSpan={productTypeList.length}>
                                {investorType[0].borrower_type_name}
                              </TableCell>
                            )}
                            <TableCell scope="row">{investorType[0].goods_type_name}</TableCell>

                            {investorTypes.map((item, index) => {
                              let found = investorType.find(
                                (element) => element.investor_type_code === item.investorTypeCode,
                              );
                              if (CM.cfnIsNotEmpty(found)) {
                                return (
                                  <Fragment key={'fragment' + index}>
                                    <TableCell>
                                      {CM.cfnIsEmpty(found.invest_count) || found.invest_count === 0
                                        ? '-'
                                        : found.invest_count}
                                    </TableCell>
                                    <TableCell className="tr">
                                      {CM.cfnIsEmpty(found.invest_amount) || found.invest_amount === '0'
                                        ? '-'
                                        : CM.cfnAddComma(found.invest_amount)}
                                    </TableCell>
                                  </Fragment>
                                );
                              }
                            })}
                          </TableRow>
                        ) : (
                          <TableRow key={'tablerow' + idx + index} className="total">
                            <TableCell scope="row">소계</TableCell>

                            {investorType.map((row, index) => (
                              <Fragment key={index}>
                                <TableCell>
                                  {CM.cfnIsEmpty(row.invest_count) || row.invest_count === 0 ? '-' : row.invest_count}
                                </TableCell>
                                <TableCell className="tr">
                                  {CM.cfnIsEmpty(row.invest_amount) || row.invest_amount === '0'
                                    ? '-'
                                    : CM.cfnAddComma(row.invest_amount)}
                                </TableCell>
                              </Fragment>
                            ))}
                          </TableRow>
                        );
                      });
                    })}
                    <TableRow className="sum">
                      <TableCell component="th" scope="row" colSpan={2}>
                        합계
                      </TableCell>
                      {investReference.investor_type_total_list.map((total, index) => (
                        <Fragment key={index}>
                          <TableCell>
                            {CM.cfnIsEmpty(total.investor_type_count) || total.investor_type_count === 0
                              ? '-'
                              : total.investor_type_count}
                          </TableCell>
                          <TableCell className="tr">
                            {CM.cfnIsEmpty(total.investor_type_amount) || total.investor_type_amount === '0'
                              ? '-'
                              : CM.cfnAddComma(total.investor_type_amount)}
                          </TableCell>
                        </Fragment>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Scrollbars>
            </TableContainer>
          )}
        </div>
      </div>
      <LinkedInvestmentP
        modalRef={modalRef}
        open={modalOpen}
        setOpen={setModalOpen}
        p2pCompanyCode={userStore.getCompanyCode()}
        inquiryStatusType={requestParams.inquiryStatusType}
        inquiryDate={requestParams.inquiryDate}
        inquiryStartDate={requestParams.inquiryStartDate}
        inquiryEndDate={requestParams.inquiryEndDate}
      />
    </div>
  );
};

export default LinkedInvestmentM;
