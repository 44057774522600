/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용기관용 자료실 목록 조회
 */
import {
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CF from '../../../common/template/ComponentForm';
import * as CM from '../../../common/util/common';

//자료실 검색조건 콤보박스
const searchTypeOptions = [
  { value: 'ALL', label: '제목 + 내용' },
  { value: 'TITLE', label: '제목' },
  { value: 'CONTENTS', label: '내용' },
];

//자료실 검색 파라미터
const DEFAULT_HOOKS = {
  requestParams: {
    searchType: searchTypeOptions[0].value, //검색조건
    searchKeyword: '', //검색어
    pageNumber: 1, //페이지 요청 번호
    pageSize: 10, //페이지 사이즈
    sortDirection: 'DESC', //정렬조건
    sortProperty: 'registration_dtm', //정렬필드
  },
};

const BoardListM = ({ location }) => {
  //state
  const [downloadsList, setDownloadsList] = useState({}); //자료실 목록
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams); //자료실 검색 파라미터
  const [buttonState, setButtonState] = useState(true); //조회버튼
  const [pagination, setPagination] = useState(CM.cfnSetPagination()); //페이지 상태
  const [requestParamsFlag, setRequestParamsFlag] = useState(true); //검색조건 초기화 여부

  //자료실 목록 조회
  useEffect(() => {
    userStore.handleNaviTrigger(); //네비 현행화
    //자료실 목록 조회 결과 세팅
    const getDownloadsList = (param) => {
      const resolve = (objStatus, response) => {
        handleDownloadsList(response);
      };
      let url = 'web/companies/downloads/search?';
      //전달받은 검색조건 있는 경우 적용
      if (requestParamsFlag && CM.cfnIsNotEmpty(location.state) && CM.cfnIsNotEmpty(location.state.requestParams)) {
        setRequestParams(location.state.requestParams);
        setRequestParamsFlag(false);
        param = location.state.requestParams;
      }
      url += `search_type=${param.searchType}&search_keyword=${param.searchKeyword}`;
      url += `&page_number=${param.pageNumber - 1}&page_size=${param.pageSize}`;
      url += `&sort_directions=${param.sortDirection}&sort_properties=${param.sortProperty}`;
      axiosUtil.cfnAxiosApi(url, 'GET', null, null, resolve, null);
    };
    const handleDownloadsList = (response) => {
      setDownloadsList(response);
      handlePaginationChange(response);
      setButtonState(false);
    };
    if (buttonState) {
      getDownloadsList(requestParams);
    }
  }, [buttonState, requestParams]);

  const handleSearchType = (e) => setRequestParams((data) => ({ ...data, searchType: e.target.value })); //검색조건 변경
  const handleSearchKeyword = (e) => setRequestParams((data) => ({ ...data, searchKeyword: e.target.value })); //검색어 변경

  //조회 이벤트
  const handleClick = () => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 1,
    }));
    setButtonState(true);
  };

  //페이지 숫자클릭 이벤트함수
  const handleOffsetChange = (e, offset, page) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: page,
    }));
    setButtonState(true);
  };

  //페이지 상태 세팅
  const handlePaginationChange = (param) => {
    setPagination(CM.cfnSetPagination(param));
  };

  /* 컨텐츠 영역_자료실_검색조회_셀렉트박스 */
  function SelectBox() {
    const iconComponent = (props) => {
      return <ExpandMoreIcon className={props.className + ' '} />;
    };
    return (
      <FormControl variant="filled">
        <CF.SelectForm
          arrayOption={searchTypeOptions}
          customProps={{ onChange: handleSearchType }}
          testid="selectBoxTest"
          name="searchType"
          value={requestParams.searchType}
          IconComponent={iconComponent}
        />
      </FormControl>
    );
  }

  /* 컨텐츠 영역_테이블 위 영역(목록개수, 조회건수) */
  function TblTop() {
    return (
      <div className="tblTop">
        <CF.TotalCountForm totalElements={pagination} />
      </div>
    );
  }

  //게시글 제목 세팅
  const setTitle = (row) => {
    const registrationDate = row.registration_dtm;
    return (
      <Link
        to={{
          pathname: '/board/downloads/' + row.seq_id,
          state: {
            requestParams: requestParams,
          },
        }}
      >
        <span>{row.title}</span>
        {CM.cfnDateCalculation(CM.cfnGetDate(), String(registrationDate).substr(0, 8)) <= 3 ? (
          <span className="iconNew">NEW</span>
        ) : (
          ''
        )}
      </Link>
    );
  };

  //검색어 입력창 엔터키 이벤트
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>자료실</h4>
          <div className="searchArea">
            <div className="tblSearch">
              {/* 양식 자동 제출 방지 */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <table>
                  <colgroup>
                    <col width="17%" />
                    <col width="20%" />
                    <col width="37%" />
                    <col width="38%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <span className="hide">키워드 검색</span>
                      </th>
                      <td>
                        <div className="searchList">
                          <SelectBox></SelectBox>
                        </div>
                      </td>
                      <td>
                        <div className="searchList">
                          <div className="inputTxt" noValidate autoComplete="off">
                            <TextField
                              id="basicinput"
                              inputProps={{ 'data-testid': 'searchKeyword' }}
                              variant="outlined"
                              value={requestParams.searchKeyword}
                              onChange={handleSearchKeyword}
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <Button
                          vatiant="contained"
                          id="searchButton"
                          startIcon={<SearchIcon />}
                          onClick={handleClick}
                          data-testid="searchBtn"
                        >
                          조회
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
          <TblTop></TblTop>
          {CM.cfnIsNotEmpty(downloadsList) && CM.cfnIsNotEmpty(downloadsList.contents) ? (
            <Fragment>
              <TableContainer>
                <Table className="tblTypenohead" aria-label="번호, 제목, 등록일, 조회수로 이루어진 자료실 목록">
                  <colgroup>
                    <col width="12%" />
                    <col width="58%" />
                    <col width="15%" />
                    <col width="15%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>번호</TableCell>
                      <TableCell>제목</TableCell>
                      <TableCell>등록일</TableCell>
                      <TableCell>조회수</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {downloadsList.contents.map((row, index) => (
                      <TableRow data-testid={'tablerow' + index} key={'tablerow' + index}>
                        <TableCell>
                          {pagination.total - pagination.rowsPerPage * (requestParams.pageNumber - 1) - index}
                        </TableCell>
                        <TableCell component="th" scope="row" className="title">
                          {setTitle(row)}
                        </TableCell>
                        <TableCell>
                          {<DescriptionOutlinedIcon />}
                          {CM.cfnDateFormat(row.registration_dtm, 'yyyyMMdd')}
                        </TableCell>
                        <TableCell>
                          {<DraftsOutlinedIcon />}
                          {CM.cfnAddComma(row.inquiry_count)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="paging">
                <Fragment>
                  <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="" />
                </Fragment>
              </div>
            </Fragment>
          ) : (
            <TableContainer>
              <Table className="tblTypenohead" aria-label="번호, 제목, 등록일, 조회수로 이루어진 자료실 목록">
                <colgroup>
                  <col width="12%" />
                  <col width="58%" />
                  <col width="15%" />
                  <col width="15%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>번호</TableCell>
                    <TableCell>제목</TableCell>
                    <TableCell>등록일</TableCell>
                    <TableCell>조회수</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow data-testid="tablerow0" key="tablerow0">
                    <TableCell colSpan="4">조회된 내용이 없습니다.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoardListM;
