/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 P2P 금융정보 조회 - 투자내역조회 서브화면(3. 투자내역조회)
 */
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';
import InvestmentDetailsHistoryC from './InvestmentDetailsHistoryC';
import InvestmentDetailsInfoC from './InvestmentDetailsInfoC';
import InvestmentDetailsLimitC from './InvestmentDetailsLimitC';
import InvestmentDetailsStatusC from './InvestmentDetailsStatusC';

const InvestmentDetailsS = observer(() => {
  const [investorInfo, setInvestorInfo] = useState({});
  const [status, setStatus] = useState({ status_list: [] }); //투자현황
  const [searchParams, setSearchParams] = useState({
    investInquiryType: 'REQUEST_PROGRESS',
    p2pCompanyCode: 'ALL',
    productTypeCode: 'TOTAL_PRODUCT',
    inquiryStartDate: CM.cfnGetCustomDate('month', -6, 'string'),
    inquiryEndDate: CM.cfnGetDate(),
    pageNumber: 1,
    pageSize: 10,
    sortDirection: 'DESC',
  });

  const handleScrolling = (inverstInquiryType, productTypeCode) => {
    CM.scrollToTarget('detail_01', {
      topWeight: -100,
      duration: 500,
    });
    setSearchParams((data) => ({ ...data, investInquiryType: inverstInquiryType, productTypeCode: productTypeCode }));
  };

  // 투자정보, 투자현황 handler
  const handleStatusChange = (info, status) => {
    setInvestorInfo(info);
    setStatus(status);
  };

  let msg = []; //오류 메시지

  //화면로딩 시 실행
  useEffect(() => {
    //투자자 기본정보 조회
    const getInvestorInfo = () => {
      return new Promise((resolve) => {
        let url = 'web/customers/investments/basic-info';
        url += `?uuid=${userStore.getUuid()}`;
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M013);
            resolve({});
          },
        );
      });
    };

    //투자현황 axios
    const axiosStatus = () => {
      return new Promise((resolve, reject) => {
        let api = 'web/customers/investments/status';
        api += `?uuid=${userStore.getUuid()}`;
        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + api);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M014);
            resolve({ status_list: [] });
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    // useEffect start function
    const startAxios = async () => {
      const infoData = await getInvestorInfo();
      const statusData = await axiosStatus();
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handleStatusChange(infoData, statusData);
    };

    startAxios();
  }, []);

  const viewLimit = () => {
    if (
      userStore.getUserType() === 'P2P_WEB_PERSONAL_CUSTOMER' &&
      (CM.cfnIsNotEmpty(investorInfo.general_investment_register_dtm) ||
        CM.cfnIsNotEmpty(investorInfo.income_suitability_register_dtm) ||
        CM.cfnIsNotEmpty(investorInfo.pro_investment_register_dtm))
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      {/* 기본정보 */}
      <InvestmentDetailsInfoC investorInfo={investorInfo} status={status} handleScrolling={handleScrolling} />
      {/* 투자현황 */}
      <InvestmentDetailsStatusC status={status} handleScrolling={handleScrolling} />
      {/* 투자한도 */}
      {viewLimit() && <InvestmentDetailsLimitC info={investorInfo} />}
      {/* 세부투자내역 */}
      <InvestmentDetailsHistoryC param={searchParams} />
    </div>
  );
});

export default InvestmentDetailsS;
