/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * Footer
 */

import { React, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Menu, MenuItem, Select, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { ArrowDropUp } from '@material-ui/icons';
import * as CM from '../../common/util/common';

/* 푸터 */
function Footer() {
  const [familysite, setFamilySite] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (event) => {
    window.open(event.target.value, '_blank');
  };

  return (
    <div className="footer">
      <div className="subContent">
        <div className="inner">
          <div className="line">
            <ul className="ftMenu">
              <li>
                <a onClick={() => window.open(process.env.REACT_APP_EGOV_ABOUT_INFO_URL, '_blank')}>회사소개</a>
              </li>
              <li>
                <a onClick={() => window.open(process.env.REACT_APP_EGOV_MAP_INFO_URL, '_blank')}>오시는 길</a>
              </li>
              <li>
                <a onClick={() => window.open(process.env.REACT_APP_EGOV_DATA_SERVICE_INFO, '_blank')}>
                  고객서비스 헌장
                </a>
              </li>
              <li>
                <Link to="/termpolicy/privacypolicy">개인정보처리방침</Link>
              </li>
            </ul>
            <div className="familysite">
              <Select
                labelId="family-site-menu"
                native
                className="familysiteOpen"
                value={familysite}
                style={{
                  width: '12rem',
                }}
                onChange={onChange}
                IconComponent={ArrowDropUp}
              >
                <option value="">Family Site</option>
                <option value="http://www.bankpay.or.kr">계좌이체PG</option>
                <option value="https://www.payinfo.or.kr/payinfo.html">계좌정보통합관리 </option>
                <option value="https://www.yessign.or.kr">금융인증센터</option>
                <option value="http://www.buycard.or.kr">물품대금결제</option>
                <option value="https://www.open-platform.or.kr">오픈뱅킹</option>
                <option value="https://audit.kftc.or.kr">기업 회계감사자료 온라인 발급</option>
                <option value="http://exk.kftc.or.kr">EXK(국가간ATM·송금)</option>
                <option value="https://www.knote.kr">어음정보센터(Knote)</option>
                <option value="https://www.unote.kr/unoteIndex.html">전자어음(U-note)</option>
                <option value="https://biz.giro.or.kr">비즈지로</option>
                <option value="http://e-giro.giro.or.kr">이지로</option>
                <option value="https://www.giro.or.kr">인터넷지로</option>
                <option value="https://www.e-revenuestamp.or.kr">전자수입인지</option>
                <option value="https://www.cardrotax.kr">카드로택스</option>
                <option value="http://www.narabill.kr">나라빌</option>
                <option value="https://www.billingone.or.kr">빌링원플러스</option>
                <option value="https://www.cmsedi.or.kr/edi">지로EDI</option>
                <option value="http://www.kftcvan.or.kr">카드VAN</option>
                <option value="http://www.trusbill.kr">트러스빌(전자세금계산서)</option>
                <option value="https://www.cmsedi.or.kr/cms">CMS</option>
                <option value="https://www.cashcard.or.kr">현금카드</option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="subContent">
        <div className="inner">
          <div className="line">
            <div className="address">
              본 관 : (우)06220 서울특별시 강남구 논현로 432 (역삼동 717번지) <br />
              분당센터 : (우)13556 경기도 성남시 분당구 정자일로 213번길 9 (정자동 10-3)
              <div className="copy">
                Copyright ⓒ 2021 KFTC(Korea Financial Telecommunications & Clearings Institute) All rights reserved.
              </div>
            </div>
            {/* <ul className="footer_customer">
                            <li>고객센터 1577-5500(평일 09:00 ~ 17:45)</li>
                            <li>
                                <Link to="http://callcenter.kftc.or.kr/qna/qna_form.jsp">이메일문의</Link>
                                <Link to="http://callcenter.kftc.or.kr">고객제안</Link>
                                <Link to="#">단축번호안내</Link>
                            </li>
                        </ul> */}
          </div>
        </div>
      </div>

      {/* 모바일용 화면 */}
      <div className="subContent mobile">
        <div className="line">
          <div className="familysite">
            <Select
              labelId="family-site-menu"
              native
              className="familysiteOpen"
              value={familysite}
              style={{
                width: '12rem',
              }}
              onChange={onChange}
              IconComponent={ArrowDropUp}
            >
              <option value="">Family Site</option>
              <option value="http://www.bankpay.or.kr">계좌이체PG</option>
              <option value="https://www.payinfo.or.kr/payinfo.html">계좌정보통합관리 </option>
              <option value="https://www.yessign.or.kr">금융인증센터</option>
              <option value="http://www.buycard.or.kr">물품대금결제</option>
              <option value="https://www.open-platform.or.kr">오픈뱅킹</option>
              <option value="https://audit.kftc.or.kr">기업 회계감사자료 온라인 발급</option>
              <option value="http://exk.kftc.or.kr">EXK(국가간ATM·송금)</option>
              <option value="https://www.knote.kr">어음정보센터(Knote)</option>
              <option value="https://www.unote.kr/unoteIndex.html">전자어음(U-note)</option>
              <option value="https://biz.giro.or.kr">비즈지로</option>
              <option value="http://e-giro.giro.or.kr">이지로</option>
              <option value="https://www.giro.or.kr">인터넷지로</option>
              <option value="https://www.e-revenuestamp.or.kr">전자수입인지</option>
              <option value="https://www.cardrotax.kr">카드로택스</option>
              <option value="http://www.narabill.kr">나라빌</option>
              <option value="https://www.billingone.or.kr">빌링원플러스</option>
              <option value="https://www.cmsedi.or.kr/edi">지로EDI</option>
              <option value="http://www.kftcvan.or.kr">카드VAN</option>
              <option value="http://www.trusbill.kr">트러스빌(전자세금계산서)</option>
              <option value="https://www.cmsedi.or.kr/cms">CMS</option>
              <option value="https://www.cashcard.or.kr">현금카드</option>
            </Select>
          </div>
          <ul className="ftMenu">
            <li>
              <a onClick={() => window.open(process.env.REACT_APP_EGOV_ABOUT_INFO_URL, '_blank')}>회사소개</a>
            </li>
            <li>
              <a onClick={() => window.open(process.env.REACT_APP_EGOV_MAP_INFO_URL, '_blank')}>오시는 길</a>
            </li>
            <li>
              <a onClick={() => window.open(process.env.REACT_APP_EGOV_DATA_SERVICE_INFO, '_blank')}>고객서비스 헌장</a>
            </li>
          </ul>
        </div>
        <div className="line">
          <ul className="ftMenu">
            <li className="w100p">
              <Link to="/termpolicy/privacypolicy" className="sky">
                개인정보처리방침
              </Link>
            </li>
          </ul>
        </div>
        <div className="line">
          <div className="address">
            본 관 : (우)06220 서울특별시 강남구 논현로 432 (역삼동 717번지) <br />
            분당센터 : (우)13556 경기도 성남시 분당구 정자일로 213번길 9 (정자동 10-3)
            <p className="copy">
              Copyright ⓒ 2021 KFTC(Korea Financial Telecommunications & Clearings Institute) All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
