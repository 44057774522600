/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 업무정보 조회 - 기록내역 조회
 * 연계투자기록 - 투자계약
 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const RecordHistoryInvestContractS = (props) => {
  const effect = props;
  const currentTab = effect.currentTab;
  // const [investContract, setInvestContract] = useState({ "list": [] }); //투자계약
  // const [payment, setPayment] = useState({ "contract_payments_schedule_list": [{}] }); //투자계약 지급예정정보
  // const [pniPayment, setPniPayment] = useState({ "list": [] }); //투자계약 지급정보
  // //대출연관기록
  // const [relationContract, setRelationContract] = useState({ "list": [] }); //투자계약

  let msg = []; //오류 메시지

  //화면로딩 시 실행
  useEffect(() => {
    //투자계약 handler
    const handleInvestContract = (paramRelationContract, paramPayment, paramPniPayment) => {
      let tmpTab = CM.cfnCopyObject(effect.tab);
      let tmpThisTab = tmpTab[currentTab];
      let tmp = tmpThisTab.contents;
      // if (CM.cfnIsNotEmpty(resultData) && resultData !== effect.tab[currentTab].contents.basic) {
      //     props.setSearchTime.call(null, resultData.api_trx_dtm);
      //     // setInvestContract(resultData);
      //     tmpRp.view = true;
      //     tmpRp.basic = resultData
      // } else {
      //     tmpRp.view = false;
      // }
      if (
        CM.cfnIsNotEmpty(paramRelationContract) &&
        paramRelationContract !== effect.tab[currentTab].contents.relation
      ) {
        // setRelationContract(props.sortInvestRelation(paramRelationContract));
        tmp.relation = props.sortInvestRelation(paramRelationContract);
      }
      if (CM.cfnIsNotEmpty(paramPayment) && paramPayment !== effect.tab[currentTab].contents.payment) {
        // setPayment(paramPayment);
        tmp.payment = paramPayment;
      }
      if (CM.cfnIsNotEmpty(paramPniPayment) && paramPniPayment !== effect.tab[currentTab].contents.pniPayment) {
        paramPniPayment.list = paramPniPayment.list.reverse();
        // setPniPayment(paramPniPayment);
        tmp.pniPayment = paramPniPayment;
      }
      effect.setTab(tmpTab);
    };

    //투자계약 조회
    const axiosInvestContract = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/investments/contracts/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M007);
            resolve(null);
          },
        );
      });
    };

    //연관기록내역 조회
    const axiosRelation = (param) => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/investments/relations/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;
        url += `&record_type=${param}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M008);
            resolve(null);
          },
        );
      });
    };

    // //투자계약 지급예정정보 handler
    // const handleInvestContractPayment = (paramPayment, paramPniPayment) => {
    //     if (CM.cfnIsNotEmpty(paramPayment) && paramPayment !== payment) {
    //         setPayment(paramPayment);
    //     }
    //     if (CM.cfnIsNotEmpty(paramPniPayment) && paramPniPayment !== pniPayment) {
    //         paramPniPayment.list = paramPniPayment.list.reverse();
    //         setPniPayment(paramPniPayment);
    //     }
    // };

    //투자계약 지급예정정보 조회
    const axiosPayment = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/investments/contracts/pni-payments/schedules/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M009);
            resolve(null);
          },
        );
      });
    };

    //지급 정보 조회
    const axiosPniPayment = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/investments/contracts/pni-payments/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M010);
            resolve(null);
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    const startAxios = async () => {
      // const resultData = await axiosInvestContract(); //투자계약
      //투자상품(INVEST_PRODUCT), 투자신청(INVEST_REQUEST), 투자계약(INVEST_CONTRACT)
      const relationContract = await axiosRelation('INVEST_CONTRACT'); //연관기록내역-투자계약
      const payment = await axiosPayment(); //투자계약 지급예정정보
      const pniPayment = await axiosPniPayment(); //투자계약 지급정보
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handleInvestContract(relationContract, payment, pniPayment);
      // handleInvestContractPayment(payment, pniPayment);
    };

    if (effect.tab[currentTab].contents.view && CM.cfnIsEmpty(effect.tab[currentTab].contents.relation.list))
      startAxios();
  }, [effect.currentTab, effect.setCurrentTab]);

  return (
    <div className="inner">
      <h6 className="hide">조회결과 상세내용</h6>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">기록관리 ID</p>
          <div className="itemBox corpInfo histidImg">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="name">{effect.tab[currentTab].contents.basic.record_no}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">투자자 정보</p>
          <div className="itemBox borrower">
            <div className="infoMain">
              <ul>
                <li>
                  <span className="title">투자자 성명</span>
                  <span>{effect.tab[currentTab].contents.basic.investor_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">투자자 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.investor_type_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">고유식별번호</span>
                  <span>{CM.cfnIdentificationNoFormat(String(effect.tab[currentTab].contents.basic.identity_no))}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">상세조회내역</p>
          <div className="itemBox">
            <div className="itemArea">
              <p className="smallTit">투자계약 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">투자계약 체결일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.invest_contract_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
                <li>
                  <span className="tit">투자금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.invest_amount) ||
                    effect.tab[currentTab].contents.basic.invest_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.invest_amount) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">투자일수</span>
                  <span>{effect.tab[currentTab].contents.basic.invest_days}일</span>
                </li>
                <li>
                  <span className="tit">투자시작일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.invest_start_date, 'yyyyMMdd')}</span>
                </li>
                <li>
                  <span className="tit">투자종료일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.invest_end_date, 'yyyyMMdd')}</span>
                </li>
              </ul>
              <ul className="infoList">
                <li>
                  <span className="tit">수수료</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.fee) ||
                    effect.tab[currentTab].contents.basic.fee === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.fee) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">수익률</span>
                  <span>{effect.tab[currentTab].contents.basic.return_ratio}%</span>
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">지급예정정보</p>
              <div className="tblTop">
                <div className="topL">
                  <span className="listNum">
                    전체
                    <strong>
                      {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.payment)
                        ? effect.tab[currentTab].contents.payment.contract_payments_schedule_list.length
                        : 0}
                    </strong>
                    건
                  </span>
                </div>
              </div>
              <TableContainer className="maxH520 mb30 scrollY">
                <Table stickyHeader aria-label="지급회차, 지급예정일, 원금, 이자, 수수료로 이루어진 지급예정정보 목록">
                  <colgroup>
                    <col width="12%" />
                    <col width="22%" />
                    <col width="22%" />
                    <col width="22%" />
                    <col width="22%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>지급회차</TableCell>
                      <TableCell>지급예정일</TableCell>
                      <TableCell>원금</TableCell>
                      <TableCell>이자</TableCell>
                      <TableCell>수수료</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.payment) ? (
                      <TableRow key={'tableRow0'}>
                        <TableCell colSpan="5">조회된 내용이 없습니다.</TableCell>
                      </TableRow>
                    ) : (
                      effect.tab[currentTab].contents.payment.contract_payments_schedule_list.map((row, index) => (
                        <TableRow key={'tableRow' + index}>
                          <TableCell>{row.repayment_seqno}</TableCell>
                          <TableCell>{CM.cfnDateFormat(row.repayment_schd_date, 'yyyyMMdd')}</TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(row.principal) || row.principal === 'null'
                              ? ''
                              : CM.cfnAddComma(row.principal) + '원'}
                          </TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(row.interest) || row.interest === 'null'
                              ? ''
                              : CM.cfnAddComma(row.interest) + '원'}
                          </TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(row.fee) || row.fee === 'null' ? '' : CM.cfnAddComma(row.fee) + '원'}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="itemArea">
              <p className="smallTit">지급 정보</p>
              <div className="tblTop">
                <div className="topL">
                  <span className="listNum">
                    전체
                    <strong>
                      {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.pniPayment)
                        ? effect.tab[currentTab].contents.pniPayment.list.length
                        : 0}
                    </strong>
                    건
                  </span>
                </div>
              </div>
              <TableContainer className="maxH520 mb30 scrollY">
                <Table stickyHeader aria-label="순번, 기록일시, 상태정보 갱신내역으로 이루어진 이력정보 목록">
                  <colgroup>
                    <col width="12%" />
                    <col width="38%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="13%" />
                    <col width="13%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>지급회차</TableCell>
                      <TableCell>원리금지급 기록관리 ID</TableCell>
                      <TableCell>최초등록일</TableCell>
                      <TableCell>실제지급일</TableCell>
                      <TableCell>지급원금</TableCell>
                      <TableCell>실제지급금액</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.pniPayment) ? (
                      <TableRow key={'tableRow0'}>
                        <TableCell colSpan="6">조회된 내용이 없습니다.</TableCell>
                      </TableRow>
                    ) : (
                      effect.tab[currentTab].contents.pniPayment.list.map((row, index) => (
                        <TableRow key={'tableRow' + index}>
                          <TableCell>{row.pni_payment_seqno}</TableCell>
                          <TableCell>
                            <span onClick={props.handleLink}>
                              <p className="txtLine" name={'investments,연계투자,3,원리금지급,' + row.record_no}>
                                {row.record_no}
                              </p>
                            </span>
                          </TableCell>
                          <TableCell>{CM.cfnDateFormat(row.first_registration_dtm, 'yyyyMMdd')}</TableCell>
                          <TableCell>{CM.cfnDateFormat(row.real_pni_payment_date, 'yyyyMMdd')}</TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(row.real_principal) || row.real_principal === 'null'
                              ? ''
                              : CM.cfnAddComma(row.real_principal) + '원'}
                          </TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(row.real_pni_payment_amount) || row.real_pni_payment_amount === 'null'
                              ? ''
                              : CM.cfnAddComma(row.real_pni_payment_amount) + '원'}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="itemArea">
              <p className="smallTit">상태</p>
              <ul className="infoList">
                <li>
                  <span className="tit">투자계약 상태</span>
                  <span data-testid="test-status">{effect.tab[currentTab].contents.basic.invest_contract_status}</span>
                  {/* <span className="red">진행 중</span> 진행중일 경우 글자색 레드 */}
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">이력 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">최초등록일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.first_registration_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">연관기록내역</p>
          <TableContainer>
            <Table
              className="tblTypeline"
              aria-label="대출신청, 투자상품, 대출계약, 대출상환으로 이루어진 연관 기록내역 목록"
            >
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="33%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>[참고]투자상품</TableCell>
                  <TableCell>투자신청</TableCell>
                  <TableCell>투자계약</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {effect.tab[currentTab].contents.relation.list.map((row, index) => {
                    if (row.record_type === 'INVEST_PRODUCT') {
                      return (
                        <TableCell key="investProduct" data-testid="renderTest">
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'INVEST_REQUEST') {
                      return (
                        <TableCell key="investRequest">
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'investments,연계투자,1,투자신청,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'INVEST_CONTRACT') {
                      return (
                        <TableCell
                          key="investContract"
                          rowSpan={effect.tab[currentTab].contents.relation.list.length}
                          className="bglightSky"
                        >
                          <p>{row.record_no}</p>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    }
                  })}
                  {effect.tab[currentTab].contents.relation.list.length < 3
                    ? [...Array(3 - effect.tab[currentTab].contents.relation.list.length)].map((n, index) => {
                        return <TableCell key={index} />;
                      })
                    : null}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RecordHistoryInvestContractS;
