/*
 * @version : 2021.03.02
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용기관용 비밀번호 초기화 신청 페이지
 */
import { useEffect, useState, useRef } from 'react';
import { userStore } from '../../../stores/companies/userStore';
import axiosUtil from '../../../utils/axiosUtil';
import bg from '../../../resource/styles/images/bg_login_comp.png';
import logo from '../../../resource/styles/images/loginlogo.png';
import * as CM from '../../common/util/common';
import { Link } from 'react-router-dom';

const LoginResetM = ({ history }) => {
  const [accountInfo, setAccountInfo] = useState({ userId: '', bmanNo: '' }); //계정 정보
  const userIdRef = useRef(null); //아이디 ref
  const bmanNoRef = useRef(null); //사업자등록번호 ref

  const handleChange = (e) => {
    setAccountInfo({
      ...accountInfo,
      [e.target.name]: e.target.value,
    });
  };

  //숫자 입력 체크
  const handleChangeNumCheck = (e) => {
    CM.cfnHandleChangeNumCheck(e, accountInfo, setAccountInfo, e.target.name, '사업자등록번호');
  };

  //입력값 체크 검사
  const handleInit = () => {
    if (
      CM.cfnInputRefValidation('아이디', userIdRef) &&
      CM.cfnInputCompanyNoRefValidation('사업자등록번호', bmanNoRef)
    ) {
      CM.cfnConfirm('초기화 신청을 하시겠습니까?', initAccount);
    } else {
      return false;
    }
  };

  //비밀번호 초기화 API 호출
  const initAccount = () => {
    let url = 'web/common/p2p-company-users/reset-password?';
    url += `bman_no=${accountInfo.bmanNo}&user_id=${accountInfo.userId}`;
    //초기화 성공 시 로그인 화면으로 이동
    const resolve = (objStatus, response) => {
      CM.cfnAlert('초기화 신청 작업을 성공했습니다. 확인 버튼을 누르면 로그인 화면으로 이동합니다.', () => {
        history.push('/');
      });
    };
    axiosUtil.cfnAxiosApi(url, 'PUT', null, null, resolve, null);
  };

  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  //입력창 엔터키 이벤트
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInit();
    }
  };

  return (
    <div className="basicLogin">
      <div className="loginTit">
        <img src={logo} className="logo" alt="금융결제원" />
        <span>P2P 중앙기록관리기관 포털사이트 [이용기관용]</span>
      </div>
      <div className="loginBox">
        <div className="loginL">
          <img src={bg} alt="" />
        </div>
        <div className="loginR">
          <div>
            <p className="tit">비밀번호 초기화</p>
            <ul>
              <li>
                <input
                  name="userId"
                  data-testid="userId"
                  maxLength="50"
                  onChange={handleChange}
                  value={accountInfo.userId}
                  ref={userIdRef}
                  placeholder="아이디"
                  aria-label="아이디 입력"
                />
              </li>
              <li>
                <input
                  name="bmanNo"
                  data-testid="bmanNo"
                  maxLength="10"
                  onKeyUp={handleKeyPress}
                  onChange={handleChangeNumCheck}
                  value={accountInfo.bmanNo}
                  ref={bmanNoRef}
                  placeholder="사업자등록번호 (반드시 ­-없이 숫자만 입력하세요.)"
                  aria-label="사업자등록번호 입력"
                />
              </li>
            </ul>
            <div className="btnArea reset">
              <button onClick={handleInit} data-testid="initBtn">
                초기화 신청
              </button>
              <Link to="/">
                <button>취소</button>
              </Link>
            </div>
            <p className="info">※ 초기화 신청한 후 중앙기록관리기관 업무담당자에게 유선 연락 바랍니다.</p>
          </div>
        </div>
      </div>
      <p className="copy">
        Copyright ⓒ 2021 KFTC(Korea Financial Telecommunications & Clearings Institute) All rights reserved.
      </p>
    </div>
  );
};

export default LoginResetM;
