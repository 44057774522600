/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 대고객포탈 user store
 */
import { observable } from 'mobx';
import * as CM from '../../components/common/util/common';
import axiosUtil from '../../utils/axiosUtil';

const userStore = observable({
  // 로그인여부
  isLogin: false,

  // 개인/법인여부
  userType: '',

  // 고유식별자번호
  userToken: '',

  // 로그인 타이머 (접속 유지시간)
  loginTimer: 0,

  // 네비게이션 렌더링을 위한 주소
  naviLocation: '',

  // 세션 연장 요청 시간
  sessionReqTime: 0,

  // 로그인 정보 등록
  registerLogin(userType, userName, token, userKey, companyCode, companyName) {
    sessionStorage.setItem('userType', userType);
    sessionStorage.setItem('userName', userName);
    sessionStorage.setItem('userToken', token);
    sessionStorage.setItem('uuid', userKey);
    sessionStorage.setItem('companyCode', companyCode);
    sessionStorage.setItem('companyName', companyName);
    sessionStorage.setItem('isLogin', true);
    sessionStorage.setItem('loginTime', new Date().getTime()); //자동 로그아웃 처리를 위한 로그인 시간 저장
    this.isLogin = true;
    this.handleLogoutTimer(false);
  },

  // 인증토큰 불러오기
  getUserToken() {
    return sessionStorage.getItem('userToken');
  },

  // 유저타입 불러오기
  getUserType() {
    return sessionStorage.getItem('userType');
  },

  // UUID 불러오기
  getUuid() {
    return sessionStorage.getItem('uuid');
  },

  //네비게이션 렌더링
  handleNaviTrigger() {
    let requestUri = window.location.href;
    this.naviLocation = requestUri;
    if (this.getIsLogin() === 'true') {
      this.loginTimerInit();
    }
  },

  getIsLogin() {
    return sessionStorage.getItem('isLogin');
  },

  //로그아웃 타이머
  handleLogoutTimer(flag) {
    if (CM.cfnIsNotEmpty(sessionStorage.getItem('isLogin'))) {
      const countDownInterval = setInterval(() => {
        if (flag) {
          clearInterval(countDownInterval);
        } else {
          let nowTime = new Date().getTime();
          let loginTime = sessionStorage.getItem('loginTime');
          let timer = Math.floor((nowTime - loginTime) / 1000);
          sessionStorage.setItem('loginTimer', timer);
          if (timer === 60 * 9) {
            //로그인 시간 유지 시간 1분 전 연장 메세지
            CM.cfnConfirm(
              '로그인 시간이 1분 남았습니다. 로그인을 연장하시겠습니까?',
              () => {
                this.loginTimerInit();
              },
              '연장',
            );
          }
          if (timer >= 60 * 10) {
            //로그인 만료 시 로그아웃 처리
            this.logout();
            clearInterval(countDownInterval);
          }
        }
      }, 1000);
    }
  },

  //로그아웃 변수 갱신
  handleTimerRefresh() {
    const timerRefresh = setInterval(() => {
      this.isLogin = sessionStorage.getItem('isLogin');
      this.loginTimer = sessionStorage.getItem('loginTimer');
    }, 1000);
  },

  //로그인 시간 연장(초기화)
  loginTimerInit() {
    let nowTime = new Date().getTime();
    if (nowTime - this.sessionReqTime > 1000) {
      this.sessionReqTime = nowTime;
      let url = 'auth/session';
      const resolve = (objStatus, response) => {
        sessionStorage.setItem('loginTime', new Date().getTime());
      };
      const reject = (objStatus, response) => {
        CM.cfnAlert('세션이 이미 만료되었습니다. 다시 로그인해주세요.', () => {
          this.logout();
        });
      };
      axiosUtil.cfnAxiosApi(url, 'POST', null, this.getUserToken(), resolve, reject, false, false, false);
    }
  },

  //로그아웃 처리
  logout() {
    let url = 'auth/logout';
    const resolve = (objStatus, response) => {
      this.isLogin = false;
      this.userType = '';
      this.userToken = '';
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('userToken');
      sessionStorage.removeItem('isLogin');
      sessionStorage.removeItem('userType');
      sessionStorage.removeItem('uuid');
      sessionStorage.removeItem('companyCode');
      sessionStorage.removeItem('companyName');
      sessionStorage.removeItem('loginTime');
      sessionStorage.removeItem('loginTimer');
      this.handleLogoutTimer(true);
    };
    if (this.isLogin) axiosUtil.cfnAxiosApi(url, 'POST', null, this.getUserToken(), resolve, resolve);
  },
});

export { userStore };
