/*
 * @version : 2022.01.12
 * @author : 금융결제원
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국용 통계조회 - 감독당국 세부통계 > 투자자별 연계투자 현황 - 엑셀 다운로드
 */
import React from 'react';
import ReactExport from 'react-export-excel';
import Button from '@material-ui/core/Button';
import * as CM from '../../../common/util/common';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const InvestStatusStatisticsExcelS = ({ statistics }) => {
  const getDataSetForm = () => {
    if (CM.cfnIsEmpty(statistics)) {
      return;
    }
    const flatData1 = statistics.statistics_invest_list.flatMap(
      ({ p2p_company_name, statistics_invest_detail_list }) => {
        return statistics_invest_detail_list.map(
          ({
            goods_type_name,
            general_person_info,
            suitable_income_info,
            pro_person_info,
            corporation_info,
            loan_business_info,
            p2p_corporation_info,
            investor_sum_info,
          }) => {
            return {
              p2p_company_name,
              goods_type_name,
              general_person_info,
              suitable_income_info,
              pro_person_info,
              corporation_info,
              loan_business_info,
              p2p_corporation_info,
              investor_sum_info,
            };
          },
        );
      },
    );
    let dataList = [];
    flatData1.map((row, index) => {
      const data = {
        p2p_company_name: row.p2p_company_name,
        goods_type_name: row.goods_type_name === '전체 상품' ? '소계' : row.goods_type_name,
        general_person_ic: row.general_person_info.investor_count,
        general_person_ib: Number(row.general_person_info.investment_balance),
        suitable_income_ic: row.suitable_income_info.investor_count,
        suitable_income_ib: Number(row.suitable_income_info.investment_balance),
        pro_person_ic: row.pro_person_info.investor_count,
        pro_person_ib: Number(row.pro_person_info.investment_balance),
        corporation_ic: row.corporation_info.investor_count,
        corporation_ib: Number(row.corporation_info.investment_balance),
        loan_business_ic: row.loan_business_info.investor_count,
        loan_business_ib: Number(row.loan_business_info.investment_balance),
        p2p_corporation_ic: row.p2p_corporation_info.investor_count,
        p2p_corporation_ib: Number(row.p2p_corporation_info.investment_balance),
        investor_sum_ic: row.investor_sum_info.investor_count,
        investor_sum_ib: Number(row.investor_sum_info.investment_balance),
      };
      dataList = dataList.concat(data);
    });
    if (CM.cfnIsNotEmpty(statistics.statistics_invest_total_sum)) {
      const totalData = {
        p2p_company_name: 'TOTAL',
        goods_type_name: statistics.statistics_invest_total_sum.goods_type_name,
        general_person_ic: statistics.statistics_invest_total_sum.general_person_info.investor_count,
        general_person_ib: Number(statistics.statistics_invest_total_sum.general_person_info.investment_balance),
        suitable_income_ic: statistics.statistics_invest_total_sum.suitable_income_info.investor_count,
        suitable_income_ib: Number(statistics.statistics_invest_total_sum.suitable_income_info.investment_balance),
        pro_person_ic: statistics.statistics_invest_total_sum.pro_person_info.investor_count,
        pro_person_ib: Number(statistics.statistics_invest_total_sum.pro_person_info.investment_balance),
        corporation_ic: statistics.statistics_invest_total_sum.corporation_info.investor_count,
        corporation_ib: Number(statistics.statistics_invest_total_sum.corporation_info.investment_balance),
        loan_business_ic: statistics.statistics_invest_total_sum.loan_business_info.investor_count,
        loan_business_ib: Number(statistics.statistics_invest_total_sum.loan_business_info.investment_balance),
        p2p_corporation_ic: statistics.statistics_invest_total_sum.p2p_corporation_info.investor_count,
        p2p_corporation_ib: Number(statistics.statistics_invest_total_sum.p2p_corporation_info.investment_balance),
        investor_sum_ic: statistics.statistics_invest_total_sum.investor_sum_info.investor_count,
        investor_sum_ib: Number(statistics.statistics_invest_total_sum.investor_sum_info.investment_balance),
      };
      dataList = dataList.concat(totalData);
    }

    return dataList;
  };
  return (
    <ExcelFile element={<Button className="btn iconExcel down"> 엑셀 다운로드 </Button>}>
      <ExcelSheet data={getDataSetForm()} name="invest status statistics">
        <ExcelColumn value="p2p_company_name" label="업체명" />
        <ExcelColumn value="goods_type_name" label="상품구분" />
        <ExcelColumn value="general_person_ic" label="일반투자자 수" />
        <ExcelColumn value="general_person_ib" label="일반투자자 잔액" />
        <ExcelColumn value="suitable_income_ic" label="소득적격투자자 수" />
        <ExcelColumn value="suitable_income_ib" label="소득적격투자자 잔액" />
        <ExcelColumn value="pro_person_ic" label="개인전문투자자 수" />
        <ExcelColumn value="pro_person_ib" label="개인전문투자자 잔액" />
        <ExcelColumn value="corporation_ic" label="일반법인 수" />
        <ExcelColumn value="corporation_ib" label="일반법인 잔액" />
        <ExcelColumn value="loan_business_ic" label="금융기관 수" />
        <ExcelColumn value="loan_business_ib" label="금융기관 잔액" />
        <ExcelColumn value="p2p_corporation_ic" label="온투업자 수" />
        <ExcelColumn value="p2p_corporation_ib" label="온투업자 잔액" />
        <ExcelColumn value="investor_sum_ic" label="합계 투자자 수" />
        <ExcelColumn value="investor_sum_ib" label="합계 투자잔액" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default InvestStatusStatisticsExcelS;
