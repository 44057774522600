/*
 * @version : 2021.03.03
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용안내 - 이용기관(온투업자)안내 - 서비스 이용신청 안내
 */

import { useEffect } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import IntroP2P16 from '../../../../resource/styles/images/intro16.png';

const ServiceRequestInfoM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce">
          <h4>서비스 이용신청 안내</h4>
          <div className="section">
            <h5>온라인투자연계금융업 중앙기록관리업무 이용절차</h5>
            <div class="importImg w100p">
              <img src={IntroP2P16} alt="온라인투자연계금융업 중앙기록관리업무 이용절차" />
            </div>
            <ul className="areaR contractStep">
              <li>
                <span>➊</span>
                <strong>[회원가입 및 이용신청]</strong> 이용신청기관은 이용신청 및 관련 서류 제출
              </li>
              <li>
                <span>➋</span>
                <strong>[서비스 개발 및 테스트]</strong> 이용신청내역 검증에 따른 예비 이용승인이 있는 경우
                이용신청기관은 중앙기록관리기관 연계 서비스 개발 및 테스트 수행
              </li>
              <li>
                <span>➌</span>
                <strong>[보안점검]</strong> 이용신청기관은 서비스 개발 및 테스트와 병행하여 보안점검기관을 통한 서비스
                취약점 점검 절차 수행
              </li>
              <li>
                <span>➍</span>
                <strong>[이용승인 및 계약체결]</strong> 이용신청기관은 이용약관 및 업무규정 등을 확인하고
                중앙기록관리기관과 이용계약 체결
              </li>
            </ul>
          </div>
          <div className="section">
            <h5>문의하기</h5>
            <ul className="areaR item questBox">
              <li>
                <p>
                  <CallIcon />
                  대표번호
                </p>
                <p>1577 - 5500</p>
              </li>
              <li>
                <p>
                  <EmailIcon />
                  이메일
                </p>
                <p>p2pcenter@kftc.or.kr</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestInfoM;
