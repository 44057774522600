/*
 * @version : 2023.06
 * @author : 금융결제원 플랫폼개발부 Front-end팀 홍예빈(beanbin@kftc.or.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 개인정보처리방침 화면
 */
import { React, useState } from 'react';
import { Button, Modal, Select } from '@material-ui/core';
import { useEffect } from 'react';
import * as CM from '../../../common/util/common';
import { userStore } from '../../../../stores/customers/userStore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseRoundeIcon from '@material-ui/icons/CloseRounded';
import { Scrollbars } from 'react-custom-scrollbars';
import PrivacyPolicyC_210501 from './policyfile/PrivacyPolicyC_210501';
import PrivacyPolicyC_211217 from './policyfile/PrivacyPolicyC_211217';
import PrivacyPolicyC_230814 from './policyfile/PrivacyPolicyC_230814';
import PrivacyPolicyC_240808 from './policyfile/PrivacyPolicyC_240808';

// 이전 개인정보처리방침 리스트 (key값 : 시작일)
const prePolicyList = [
  { value: '210501', label: '2021. 5. 1 ~ 2021. 12. 16', component: <PrivacyPolicyC_210501 /> },
  { value: '211227', label: '2021. 12. 17 ~ 2023. 8. 13', component: <PrivacyPolicyC_211217 /> },
  { value: '230814', label: '2023. 8. 14 ~ 2024. 8. 7', component: <PrivacyPolicyC_230814 /> },
];

const PrivacyPolicyM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  const [prePolicy, setPrePolicy] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
    setPrePolicy('');
  };

  const onChange = (event) => {
    setPrePolicy(event.target.value);
    setModalOpen(true);
  };

  const prePrivacyPopUp = () => {
    const selectedPolicy = prePolicyList.find((element) => element.value === prePolicy);

    return (
      <>
        {CM.cfnIsNotEmpty(selectedPolicy) ? (
          <div className="popInner" style={{ width: 600, height: 600 }}>
            <div className="popHead">
              <h1>{`개인정보처리방침 (${selectedPolicy.label})`}</h1>
              <Button
                onClick={handleClose}
                variant="outlined"
                data-testid="close-modal"
                style={{ borderColor: 'rgba(255, 255, 255)' }}
              >
                <CloseRoundeIcon />
                <span className="hide">팝업창 닫기</span>
              </Button>
            </div>
            <div className="popcontWrap">
              <div className="popCont" style={{ height: 'calc(100% - 4rem)' }}>
                <Scrollbars>
                  <div className="popContin">
                    <div className="introduce privacy" style={{ marginTop: '0' }}>
                      <div className="section w100p">{selectedPolicy.component}</div>
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce privacy">
          <h4>온라인투자연계금융업 중앙기록관리업무 개인정보처리방침</h4>
          <div className="section w100p">
            <PrivacyPolicyC_240808 />
            <br />
            <div className="lawAdd">
              <div className="prepolicy">
                <Select
                  labelId="pre-policy-menu"
                  native
                  className="prepolicyOpen"
                  value={prePolicy}
                  style={{
                    width: '12rem',
                  }}
                  onChange={onChange}
                  IconComponent={ArrowDropDownIcon}
                >
                  <option value="">이전 개인정보처리방침 보기</option>
                  {prePolicyList.map((item) => (
                    <option value={item.value} key={item.value}>
                      {`${item.label} 적용`}
                    </option>
                  ))}
                </Select>
              </div>
              <br />
              <a className="fileDownload" href="/hwp/attachment1_Privacy_Request.hwp">
                <span className="iconFile">
                  <AttachFileIcon />
                </span>
                &lt;별첨1&gt; 개인(신용)정보 열람, 정정·삭제 등 요구서
              </a>
              <a className="fileDownload" href="/hwp/attachment2_Warrant.hwp">
                <span className="iconFile">
                  <AttachFileIcon />
                </span>
                &lt;별첨2&gt; 위임장
              </a>
              <Modal
                aria-labelledby="이전 개인정보처리방침 보기 팝업"
                aria-describedby="이전 개인정보처리방침 보기 팝업"
                open={modalOpen}
                className="sublayerPop mainpop"
                hideBackdrop={true}
              >
                {prePrivacyPopUp()}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyM;
