/*
 * @version : 2021.02.15
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * HeadTop
 */
import CloseRoundeIcon from '@material-ui/icons/CloseRounded';
import { Button, Popper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useState, Fragment, useRef, useEffect } from 'react';
import * as CM from '../../common/util/common';
import logo from '../../../resource/styles/images/p2plogo.png';
import PersonIcon from '@material-ui/icons/Person';
import { userStore } from '../../../stores/supervisors/userStore';
import Collapse from '@material-ui/core/Collapse';

/* 헤더 */
function HeadTop() {
  //회원정보팝업관련
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick1 = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const scrollRef = useRef(null);
  document.addEventListener('scroll', function () {
    scrollRef.current.style.left = 0 - window.pageXOffset + 'px';
  });

  return (
    <div ref={scrollRef} className="header">
      <div className="headerTop">
        <header className="inr">
          <div className="headerLogo">
            <Link to="/">
              <img src={logo} className="logo" alt="온라인투자연계금융업 중앙기록관리기관 감독당국 사이트" />
            </Link>
          </div>
          <HeadGnb></HeadGnb>
          <div className="headerEtc">
            <div className="userInfo">
              {CM.cfnIsEmpty(userStore.getIsLogin()) ? null : (
                <Fragment>
                  <Button className="small" startIcon={<PersonIcon />} onClick={handleClick1}>
                    <span className="name">{userStore.getUserName()}</span>
                  </Button>
                  <Popper id={id} open={open} anchorEl={anchorEl} className="userinfoPop supervisory">
                    <Button className="popClose" onClick={handleClick1}>
                      <CloseRoundeIcon />
                      <span className="hide">팝업창 닫기</span>
                    </Button>
                    <p className="user">
                      <span>{userStore.getUserName()}</span>님 회원정보
                    </p>
                    <ul>
                      <li>
                        <span>계정 ID</span>
                        <span>{userStore.getUserId()}</span>
                      </li>
                      <li>
                        <span>접속 IP</span>
                        <span>{userStore.getClientIp()}</span>
                      </li>
                    </ul>
                    <div className="btnBottom">
                      <Link to="/memberinfo">
                        <Button onClick={handleClick1}>회원정보변경</Button>
                      </Link>
                      <Button onClick={() => userStore.logout()} className="logout">
                        로그아웃
                      </Button>
                    </div>
                  </Popper>
                </Fragment>
              )}
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

const HeadGnb = (props) => {
  const [isOn1, setIsOn1] = useState('gnbWrap');
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (param) => {
    setExpanded(param);
  };
  return (
    <div className="gnb">
      <ul
        className="gnbWrap on"
        onMouseOver={() => handleExpandClick(true)}
        onMouseLeave={() => handleExpandClick(false)}
      >
        <li>
          <Link to="/statistics/intergratedinfo">감독당국용 통계조회</Link>
          <div className="allMenu">
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <ul className="allmenuList">
                <li>
                  <div>
                    <Link to="/statistics/intergratedinfo">감독당국용 통계조회</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/statistics/intergratedinfo" onClick={() => handleExpandClick(false)}>
                          P2P금융 통합정보
                        </Link>
                      </li>
                      <li>
                        <Link to="/statistics/supervisorDetail" onClick={() => handleExpandClick(false)}>
                          감독당국 세부통계
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="/registrationCompanies">이용기관 현황</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/registrationCompanies" onClick={() => handleExpandClick(false)}>
                          이용기관 현황
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="/board">공지사항</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/board" onClick={() => handleExpandClick(false)}>
                          공지사항
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </Collapse>
          </div>
        </li>
        <li>
          <Link to="/registrationCompanies" onClick={() => handleExpandClick(false)}>
            이용기관 현황
          </Link>
        </li>
        <li>
          <Link to="/board" onClick={() => handleExpandClick(false)}>
            공지사항
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default HeadTop;
