/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 공통 컴포넌트
 */
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  createMuiTheme,
  FormControlLabel,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import format from 'date-fns/format';
import koLocale from 'date-fns/locale/ko';
import Pagination from 'material-ui-flat-pagination';
import React from 'react';
import * as CM from '../util/common';

/*
 * @desc    Select 컴포넌트
 */
export const SelectForm = (props) => {
  const {
    value,
    handleChange,
    name,
    index,
    arrayOption,
    optionValue,
    optionLabel,
    disabled,
    readOnly,
    testid,
    className,
    iconComponent,
  } = props;

  return (
    <Select
      native
      value={value}
      onChange={handleChange}
      inputProps={{
        name: name,
        index: index,
        readOnly: readOnly,
        'data-testid': testid,
      }}
      className={className}
      IconComponent={iconComponent}
      disabled={disabled}
      {...props.customProps}
    >
      {arrayOption.map((element, idx) => {
        return (
          <option value={element[CM.cfnNvl(optionValue, 'value')]} key={idx}>
            {element[CM.cfnNvl(optionLabel, 'label')]}
          </option>
        );
      })}
    </Select>
  );
};

/*
 * @desc    Checkbox를 생성하는 컴포넌트
 * @param   {
 *            checked: state의 value 값
 *            value: state의 key 값
 *            handleChange: onChange function
 *            label: 체크박스를 설명하는 라벨
 *          }
 * @return  { Checkbox }
 */
export const CheckboxForm = (props) => {
  return (
    <FormControlLabel
      key={props.keyId}
      control={
        <Checkbox
          key={props.keyId}
          name={props.name}
          checked={props.checked}
          value={props.value}
          onChange={props.handleChange}
          {...props.customProps}
        />
      }
      label={props.label}
    />
  );
};

export const StyleTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '12px',
  },
}))(Tooltip);
/*
 * @desc    Tooltip을 생성하는 컴포넌트
 * @param   { string }
 * @return  { Tooltip }
 */
export const TooltipForm = (props) => {
  const [open, setOpen] = React.useState(false);

  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontFamily: 's-core_dream3_light, san-serif',
          fontSize: '0.844rem !important',
          textAlign: 'center',
          border: '1px solid #777',
          maxWidth: 240,
          minWidth: 200,
          padding: '8px !important',
          wordBreak: 'keep-all',
          whiteSpace: 'pre-wrap',
          marginTop: '12px !important',
          marginBottom: '12px !important',
        },
      },
    },
  });

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <MuiThemeProvider theme={theme}>
        <Tooltip
          placement={props.position ?? 'bottom'}
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              positionFixed: true,
              modifiers: {
                preventOverflow: {
                  enabled: false,
                  boundariesElement: 'window',
                },
              },
            },
          }}
          arrow
          title={props.contents}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          disableFocusListener
          disableTouchListener
        >
          <Button vatiant="contained" startIcon={<HelpIcon />} style={props.style} onClick={() => setOpen(true)}>
            <span className="hide">자세히보기</span>
          </Button>
        </Tooltip>
      </MuiThemeProvider>
    </ClickAwayListener>
  );
};

/*
 * @desc    RadioGroup 컴포넌트
 * @param   {
 *            name: 이름
 *            value: 값
 *            onChange: onChange 함수
 *            option: option 배열
 *            optionValue: optionValue 이름
 *            optionLabel: optionLabel 이름
 *            testId: data-testid
 *            row: radio 정렬 방향
 *          }
 * @return  { div }
 */
export const RadioGroupForm = ({
  name,
  value,
  onChange,
  option,
  optionValue = 'value',
  optionLabel = 'label',
  testId,
  row = false,
  customProps,
}) => {
  return (
    <RadioGroup name={name} value={value} onChange={onChange} data-testid={testId} row={row} {...customProps}>
      {option.map((element, index) => {
        return (
          <FormControlLabel
            id={name + index}
            key={index}
            control={<Radio color="primary" />}
            value={element[optionValue]}
            label={element[optionLabel]}
          />
        );
      })}
    </RadioGroup>
  );
};

/*
 * @desc    Table 전체 row count 컴포넌트
 * @param   {
 *            totalElements: 전체 수
 *          }
 * @return  { div }
 */
export const TotalCountForm = (props) => {
  return (
    <div className="topL">
      <span className="listNum">
        전체 <strong>{CM.cfnAddComma(props.totalElements.total)}</strong>건
      </span>
    </div>
  );
};

/*
 * @desc    Table row per page 컴포넌트
 * @param   {
 *            value: 값
 *            onChange: onChange 함수
 *          }
 * @return  { div }
 */
export const RowPerPageForm = (props) => {
  return (
    <Select
      native
      name="rowPerPage"
      value={props.value}
      onChange={props.onChange}
      data-testid={props['data-testid']}
      {...props.customProps}
    >
      {CM.cfnGetOptionPageSize().map((option, index) => {
        return (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        );
      })}
    </Select>
  );
};

/*
 * @desc    Pagination 컴포넌트
 * @param   {
 *            pagination: 페이지네이션 object
 *            onClick: function
 *            testId: data-testid
 *          }
 * @return  { div }
 */
export const PaginationForm = (props) => {
  return (
    <Pagination
      className="centered"
      limit={props.pagination.rowsPerPage || props.limit}
      offset={props.pagination.offset || props.offset}
      total={props.pagination.total || props.total}
      onClick={props.onClick}
      innerButtonCount={window.matchMedia('(max-width: 36rem)').matches ? 1 : 2}
      outerButtonCount={1}
      size={window.matchMedia('(max-width: 36rem)').matches ? 'small' : 'large'}
      data-testid={props.testId}
      centerRipple={false}
      disableFocusRipple={true}
      disableRipple={true}
    />
  );
};

/*
 * @desc  달력 컴포넌트 설정 class
 */
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'M월 d일 EEEE', { locale: this.locale });
  }

  getCalendarHeaderText(date) {
    return format(date, 'yyyy년 M월', { locale: this.locale });
  }

  getYearText(date) {
    return format(date, 'yyyy년', { locale: this.locale });
  }
}

/*
 * @desc    DatePicker를 생성하는 컴포넌트
 * @param   {
 *            value: date
 *            handleChange: function
 *            format: string
 *            className : string
 *            disabled : boolean
 *          }
 * @return  { KeyboardDatePicker }
 */
export const DatePickerForm = (props) => {
  const { value, handleChange, format, className, disabled, customProps, testId, viewFormat } = props;

  // value string일 때 date type으로 변경
  const applyValue = typeof value === 'string' ? CM.cfnConvertStringToDate(value) : value;

  // 최대날짜 / 최소날짜 설정시 Error Message 설정
  let maxDateLabel = '최대날짜';
  let minDateLabel = '최소날짜';

  if (Object.isExtensible(customProps)) {
    if (CM.cfnIsNotEmpty(customProps.maxDate)) {
      maxDateLabel = CM.cfnDateFormat(CM.cfnConvertDateToString(customProps.maxDate));
    }
    if (CM.cfnIsNotEmpty(customProps.minDate)) {
      minDateLabel = CM.cfnDateFormat(CM.cfnConvertDateToString(customProps.minDate));
    }
  }

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={koLocale}>
      <KeyboardDatePicker
        margin="normal"
        variant="dialog"
        format={CM.cfnNvl(format, 'yyyy-MM-dd')}
        views={CM.cfnNvl(viewFormat, ['year', 'month', 'date'])}
        value={applyValue}
        onChange={handleChange}
        className={className}
        disabled={disabled}
        autoOk={false}
        invalidDateMessage={''}
        helperText={null}
        placeholder={'YYYY-MM-DD'}
        data-testid={testId}
        {...customProps}
      />
    </MuiPickersUtilsProvider>
  );
};
