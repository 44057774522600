/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 p2p 금융정보 조회 화면
 * - 차입자 정보
 * - 대출 현황
 * - 세부 대출 내역
 */
import { useEffect, useState } from 'react';
import * as CM from '../../../common/util/common';
import axiosUtil from '../../../../utils/axiosUtil';
import LoanDetailsInfoC from './LoanDetailsInfoC';
import LoanDetailsStatusC from './LoanDetailsStatusC';
import LoanDetailsHistoryC from './LoanDetailsHistoryC';
import { observer } from 'mobx-react';
import { userStore } from '../../../../stores/customers/userStore';

const LoanDetailsS = observer(() => {
  const [status, setStatus] = useState({ status_list: [] }); //대출 현황
  const [searchParams, setSearchParams] = useState({
    loanInquiryType: 'CONTRACT_PROGRESS',
    p2pCompanyCode: 'ALL',
    productTypeCode: 'TOTAL_PRODUCT',
    inquiryStartDate: CM.cfnGetCustomDate('month', -6, 'string'),
    inquiryEndDate: CM.cfnGetDate(),
    pageNumber: 1,
    pageSize: 10,
    sortDirection: 'DESC',
    sortProperty: 'loan_contract_conclusion_dtm',
  });

  const handleScrolling = (loanInquiryType, productTypeCode) => {
    CM.scrollToTarget('detail_01', {
      topWeight: -100,
      duration: 500,
    });
    setSearchParams((data) => ({ ...data, loanInquiryType: loanInquiryType, productTypeCode: productTypeCode }));
  };

  const handleStatusChange = (param) => {
    setStatus(param);
  };

  //화면 로딩 시 실행
  useEffect(() => {
    //대출 현황 axios
    const axiosStatus = () => {
      return new Promise((resolve) => {
        let api = `web/customers/loans/status`;
        api +=
          process.env.REACT_APP_MOCK === 'false'
            ? `?uuid=${userStore.getUuid()}`
            : '?uuid=FFFFFF00-0000-0000-0000-000000000008';
        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          null,
        );
      });
    };
    const startAxios = async () => {
      const resultData = await axiosStatus();
      handleStatusChange(resultData);
    };
    startAxios();
  }, []);

  return (
    <div>
      {/* 차입자 정보 */}
      <LoanDetailsInfoC status={status} handleScrolling={handleScrolling} />
      {/* 대출 현황 */}
      <LoanDetailsStatusC status={status} handleScrolling={handleScrolling} />
      {/* 세부 대출 내역 */}
      <LoanDetailsHistoryC param={searchParams} />
    </div>
  );
});

export default LoanDetailsS;
