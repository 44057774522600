import { Link } from 'react-router-dom';
import React, { useState, useCallback, useEffect } from 'react';
import { scrollToTarget } from '../util/common';

/**
 * GoToTop
 * @component
 * @desc 위로 끝까지 스크롤하는 함수형 UI 컴포넌트
 * @return {React.FunctionComponent} React 함수형 컴포넌트
 */
function GoToTop() {
  const [showBtn, setShowBtn] = useState(false);
  //   const onClickTop = useCallback(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);

  useEffect(() => {
    const handleShowBtn = () => {
      if (window.scrollY > 100) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    };

    window.addEventListener('scroll', handleShowBtn);
    return () => window.removeEventListener('scroll', handleShowBtn);
  }, []);

  return showBtn ? (
    <Link
      className="top_btn"
      onClick={() => {
        scrollToTarget('container', {
          topWeight: -100,
          duration: 500,
        });
      }}
    />
  ) : null;
}

export default GoToTop;
