/*
 * @version : 2021.02.15
 * @author : 금융결제원
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국용 통계조회 - 감독당국 세부통계 > 투자자별 연계투자현황
 */
import { Button, FormControl, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useEffect, useState, Fragment } from 'react';
import * as CM from '../../../common/util/common';
import * as CF from '../../../common/template/ComponentForm';
import axiosUtil from '../../../../utils/axiosUtil';
import SearchIcon from '@material-ui/icons/Search';
import { userStore } from '../../../../stores/supervisors/userStore';
import { rootStore } from '../../../../stores/rootStore';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import InvestStatusStatisticsExcelS from './InvestStatusStatisticsExcelS';
import DoubleScrollbars from 'react-double-scrollbar';

const InvestStatusStatisticsM = observer(() => {
  const p2pCompanyCode = CM.cfnTotalInstituteList(); // 검색조건 이용기관 목록 관리 hook
  const [statistics, setStatistics] = useState({}); // 화면에 필요한 통계데이터 관리 hook

  const [searchState, setSearchState] = useState(true); //조회여부

  const [requestParams, setRequestParams] = useState({
    inquiryDate: CM.cfnGetCustomDate('date', -1, 'string'),
    p2pCompanyCode: 'ALL',
  });

  const handleSearchChange = (flag) => setSearchState(flag);

  /**----------------------------------------------------------------------
     *  deps (searchState, rootStore.InstituteList) 가 변경될 때 수행
     -----------------------------------------------------------------------*/
  useEffect(() => {
    userStore.handleNaviTrigger();

    //-------------------------------
    // 통계데이터 반영 함수 정의
    //-------------------------------
    const handleStatisticsChange = (response) => {
      // 통계 Hook에 변경된 통계 데이터 저장 -> 화면변경 처리
      setStatistics(response);
      // 조회버튼 클릭 활성화
      handleSearchChange(false);
    };

    //-------------------------------
    // 통계데이터 요청 함수 정의
    //-------------------------------
    const axiosStatus = () => {
      return new Promise((resolve, reject) => {
        let url = 'web/supervisors/statistics/supervisor/investments';
        if (requestParams.p2pCompanyCode !== 'ALL') {
          url += '/' + requestParams.p2pCompanyCode;
        }
        url += `?inquiry_date=${requestParams.inquiryDate}`;
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.');
              handleSearchChange(false);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    //-------------------------------
    // 통계데이터 불러오기 함수 정의
    //   통계데이터 요청 후 데이터 반영
    //-------------------------------
    const startAxios = async () => {
      const resultData = await axiosStatus(); // 통계데이터 요청
      handleStatisticsChange(resultData); // 통계데이터 반영
    };

    // 초기 화면진입 (searchState default = true) 이고 기관목록이 비어있지 않은 경우
    if (searchState && CM.cfnIsNotEmpty(toJS(rootStore.InstituteList))) {
      // 통계데이터 불러오기
      startAxios();
    }
  }, [searchState, rootStore.InstituteList]);

  /**----------------------------------------------------------------------
     *  조회버튼 클릭 처리 (searchState 변경하여 useEffect 실행)
     -----------------------------------------------------------------------*/
  const handleClick = () => {
    handleSearchChange(true);
  };

  /**----------------------------------------------------------------------
     *  검색조건 > 업체 지정 시 요청 파라미터로 저장
     -----------------------------------------------------------------------*/
  const handleP2pCompanyCode = (e) => {
    setRequestParams((data) => ({ ...data, p2pCompanyCode: e.target.value }));
  };

  /**----------------------------------------------------------------------
     *  검색조건 > 날짜 지정 시 요청 파라미터로 저장
     -----------------------------------------------------------------------*/
  const handleDate = (name) => (date) => {
    if (name === 'inquiryDate') {
      setRequestParams((data) => ({ ...data, [name]: CM.cfnConvertDateToString(date) }));
    }
  };

  /**-----------------------------------------------------------------
     *  검색조건과 통계테이블 사이 영역 정의
     -----------------------------------------------------------------*/
  const TblTop = () => {
    return (
      <div className="tblTop">
        <div className="topL"></div>
        <div className="topR">
          <InvestStatusStatisticsExcelS statistics={statistics} />
        </div>
      </div>
    );
  };

  /**-----------------------------------------------------------------
     *  업무팀 요청에 따른 현재페이지의 상품종류명 변경
     -----------------------------------------------------------------*/
  const convertGoodsTypeName = (goods_type_code) => {
    switch (goods_type_code) {
      case 'REAL_ESTATE_PF':
        return '부동산PF';
      case 'REAL_ESTATE_MORTGAGE':
        return '부동산';
      case 'BILL_MORTGAGE':
        return '어음/매출채권';
      case 'ETC_MORTGAGE':
        return '기타';
      case 'PERSONAL_CREDIT_MORTGAGE':
        return '개인 신용';
      case 'CORPORATE_CREDIT_MORTGAGE':
        return '법인 신용';
      default:
        return '';
    }
  };

  /**-----------------------------------------------------------------
     *  검색조건 세팅 영역
     -----------------------------------------------------------------*/
  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>투자자별 연계투자 현황</h4>
          <div className="searchArea">
            <div className="tblSearch">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <fieldset>
                  <legend>검색조건 입력</legend>
                  <table>
                    <colgroup>
                      <col width="15%" />
                      <col width="70%" />
                      <col width="15%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">업체명</th>
                        <td colSpan="2">
                          <div className="searchList w62p">
                            <FormControl variant="filled">
                              <CF.SelectForm
                                arrayOption={p2pCompanyCode}
                                customProps={{ onChange: handleP2pCompanyCode }}
                                name="keywordselect"
                                value={requestParams.p2pCompanyCode}
                                testid="userStatistics-p2pCompanyCode"
                              />
                            </FormControl>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">조회일자</th>
                        <td>
                          <div className="searchList">
                            <div className="datePick">
                              <CF.DatePickerForm
                                id="inquiryDate"
                                value={requestParams.inquiryDate}
                                handleChange={handleDate('inquiryDate')}
                                viewFormat={['year', 'month', 'date']}
                                customProps={{
                                  maxDate: CM.cfnGetCustomDate('date', -1, 'date'),
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <Button
                            vatiant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleClick}
                            data-testid="userStatistics-searchButton"
                          >
                            조회
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </form>
            </div>
          </div>
          <TblTop></TblTop>
          <DoubleScrollbars>
            <Table className="tblTypeline scrollX" aria-label="감독당국 세부통계 > 투자자별 연계투자 현황">
              <colgroup>
                <col width="8%" />
                <col width="8%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
                <col width="6%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={3}>업체명</TableCell>
                  <TableCell rowSpan={3}>상품구분</TableCell>
                  <TableCell colSpan={14}>투자자유형</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>일반투자자</TableCell>
                  <TableCell colSpan={2}>소득적격 개인투자자</TableCell>
                  <TableCell colSpan={2}>개인전문투자자</TableCell>
                  <TableCell colSpan={2}>일반법인</TableCell>
                  <TableCell colSpan={2}>금융기관</TableCell>
                  <TableCell colSpan={2}>온투업자</TableCell>
                  <TableCell colSpan={2}>합계</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>투자자수</TableCell>
                  <TableCell>투자잔액</TableCell>
                  <TableCell>투자자수</TableCell>
                  <TableCell>투자잔액</TableCell>
                  <TableCell>투자자수</TableCell>
                  <TableCell>투자잔액</TableCell>
                  <TableCell>투자자수</TableCell>
                  <TableCell>투자잔액</TableCell>
                  <TableCell>투자자수</TableCell>
                  <TableCell>투자잔액</TableCell>
                  <TableCell>투자자수</TableCell>
                  <TableCell>투자잔액</TableCell>
                  <TableCell>투자자수</TableCell>
                  <TableCell>투자잔액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CM.cfnIsNotEmpty(statistics) && CM.cfnIsNotEmpty(statistics.statistics_invest_list) ? (
                  <Fragment>
                    {statistics.statistics_invest_list.map((p2p_list, p2p_index) => (
                      <Fragment>
                        {p2p_list.statistics_invest_detail_list.map((stat_info, stat_index) =>
                          stat_info.goods_type_code !== 'TOTAL_PRODUCT' ? (
                            <TableRow>
                              {stat_index === 0 ? (
                                <TableCell rowSpan={p2p_list.statistics_invest_detail_list.length - 1}>
                                  {p2p_list.p2p_company_name}
                                </TableCell>
                              ) : null}
                              <TableCell>{convertGoodsTypeName(stat_info.goods_type_code)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.general_person_info.investor_count)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.general_person_info.investment_balance)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.suitable_income_info.investor_count)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.suitable_income_info.investment_balance)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.pro_person_info.investor_count)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.pro_person_info.investment_balance)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.corporation_info.investor_count)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.corporation_info.investment_balance)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.loan_business_info.investor_count)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.loan_business_info.investment_balance)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.p2p_corporation_info.investor_count)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.p2p_corporation_info.investment_balance)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.investor_sum_info.investor_count)}</TableCell>
                              <TableCell>{CM.cfnAddComma(stat_info.investor_sum_info.investment_balance)}</TableCell>
                            </TableRow>
                          ) : null,
                        )}
                        <TableRow>
                          <TableCell colSpan="2">소 계</TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).general_person_info.investor_count,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).general_person_info.investment_balance,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).suitable_income_info.investor_count,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).suitable_income_info.investment_balance,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).pro_person_info.investor_count,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).pro_person_info.investment_balance,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).corporation_info.investor_count,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).corporation_info.investment_balance,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).loan_business_info.investor_count,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).loan_business_info.investment_balance,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).p2p_corporation_info.investor_count,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).p2p_corporation_info.investment_balance,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).investor_sum_info.investor_count,
                            )}
                          </TableCell>
                          <TableCell>
                            {CM.cfnAddComma(
                              p2p_list.statistics_invest_detail_list.find((e) => {
                                return e.goods_type_code === 'TOTAL_PRODUCT';
                              }).investor_sum_info.investment_balance,
                            )}
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                    {CM.cfnIsNotEmpty(statistics.statistics_invest_total_sum) ? (
                      <TableRow className="sum">
                        <TableCell colSpan="2">합 계</TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.general_person_info.investor_count)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(
                            statistics.statistics_invest_total_sum.general_person_info.investment_balance,
                          )}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.suitable_income_info.investor_count)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(
                            statistics.statistics_invest_total_sum.suitable_income_info.investment_balance,
                          )}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.pro_person_info.investor_count)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.pro_person_info.investment_balance)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.corporation_info.investor_count)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.corporation_info.investment_balance)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.loan_business_info.investor_count)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.loan_business_info.investment_balance)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.p2p_corporation_info.investor_count)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(
                            statistics.statistics_invest_total_sum.p2p_corporation_info.investment_balance,
                          )}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.investor_sum_info.investor_count)}
                        </TableCell>
                        <TableCell>
                          {CM.cfnAddComma(statistics.statistics_invest_total_sum.investor_sum_info.investment_balance)}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </Fragment>
                ) : (
                  <TableRow key="statisticsList0">
                    <TableCell colSpan="16">조회된 내용이 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DoubleScrollbars>
        </div>
      </div>
    </div>
  );
});

export default InvestStatusStatisticsM;
