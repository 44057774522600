/*
 * @version : 2021.02.04
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 업무정보 조회 - 기록내역 조회 메인화면
 */
import { Button, TextField } from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SearchIcon from '@material-ui/icons/Search';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import * as CF from '../../../common/template/ComponentForm';
import * as CM from '../../../common/util/common';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

//연계대출기록
import RecordHistoryLoanRequestS from './RecordHistoryLoanRequestS'; //대출신청
import RecordHistoryInvestProductS from './RecordHistoryInvestProductS'; //투자상품
import RecordHistoryLoanContractS from './RecordHistoryLoanContractS'; //대출계약
import RecordHistoryLoanRepaymentS from './RecordHistoryLoanRepaymentS'; //대출상환

//연계투자기록
import RecordHistoryInvestRequestS from './RecordHistoryInvestRequestS'; //투자신청
import RecordHistoryInvestContractS from './RecordHistoryInvestContractS'; //투자계약
import RecordHistoryPniPaymentS from './RecordHistoryPniPaymentS'; //원리금지급

//원리금수취권 양도양수 기록
import RecordHistoryPniTransferS from './RecordHistoryPniTransferS'; //양도양수계약

import { Link } from 'react-router-dom';
import axiosUtil from '../../../../utils/axiosUtil';
import Popup from './Popup';

const RecordHistoryM = () => {
  // 조회대상
  const [searchObject, setSearchObject] = useState('loans');
  const searchObjectList = [
    { value: 'loans', label: '연계대출기록' },
    { value: 'investments', label: '연계투자기록' },
    { value: 'transfers', label: '원리금수취권 양도양수 기록' },
  ];
  const [searchObjectName, setSearchObjectName] = useState(
    searchObjectList[0].label.substring(0, searchObjectList[0].label.indexOf('기록')).replace(/\s*$/, ''),
  );

  // 기록유형
  const [recordType, setRecordType] = useState('1');
  const [recordTypeList, setRecordTypeList] = useState([]);
  const [recordTypeName, setRecordTypeName] = useState('');

  // 기록관리 ID
  const [recordId, setRecordId] = useState('');

  // 탭 관리 state
  const [tab, setTab] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const focusRef = useRef(null);

  const handleSearchObject = (event) => {
    let eventVal = event.target.value;
    let tmpLabel = '';
    if (eventVal === 'loans') {
      tmpLabel = '연계대출';
    } else if (eventVal === 'investments') {
      tmpLabel = '연계투자';
    } else if (eventVal === 'transfers') {
      tmpLabel = '원리금수취권 양도양수';
    }
    setSearchObject(eventVal);
    setSearchObjectName(tmpLabel);
    setRecordType('1');
  };

  const handleRecordType = (event) => {
    let eventVal = event.target.value;
    let tmpLabel = '';
    if (searchObject === 'loans') {
      if (eventVal === '1') {
        tmpLabel = '대출신청';
      } else if (eventVal === '2') {
        tmpLabel = '투자상품';
      } else if (eventVal === '3') {
        tmpLabel = '대출계약';
      } else if (eventVal === '4') {
        tmpLabel = '대출상환';
      }
    } else if (searchObject === 'investments') {
      if (eventVal === '1') {
        tmpLabel = '투자신청';
      } else if (eventVal === '2') {
        tmpLabel = '투자계약';
      } else if (eventVal === '3') {
        tmpLabel = '원리금지급';
      }
    } else if (searchObject === 'transfers') {
      tmpLabel = '양도양수계약';
    }

    setRecordType(eventVal);
    setRecordTypeName(tmpLabel);
  };

  const handleRecordId = (event) => {
    setRecordId(event.target.value);
  };

  const sortLoanRelation = (param) => {
    //대출신청,투자상품,대출계약 순으로 정렬
    let relation = CM.cfnCopyObject(param);
    relation.list = [];
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'LOAN_REQUEST') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'INVEST_PRODUCT') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'LOAN_CONTRACT') relation.list.push(element);
    }

    //투자상품 갯수 카운트, 두번째 투자상품index 기억
    let productCnt = 0;
    let secondProductIndex = 0;
    for (let index = 0; index < relation.list.length; index++) {
      const element = relation.list[index];
      if (element.record_type === 'INVEST_PRODUCT') {
        productCnt++;
        if (productCnt === 1) {
          secondProductIndex = index;
        }
      }
    }
    relation.productCnt = productCnt;
    relation.secondProductIndex = secondProductIndex;

    let productYn = false;
    let contractYn = false;
    for (let index = 0; index < relation.list.length; index++) {
      const element = relation.list[index];
      if (element.record_type === 'INVEST_PRODUCT') productYn = true;
      if (element.record_type === 'LOAN_CONTRACT') contractYn = true;
    }
    if (!productYn) {
      relation.list.push({ record_type: 'INVEST_PRODUCT' });
    }
    if (!contractYn) {
      relation.list.push({ record_type: 'LOAN_CONTRACT' });
    }
    return relation;
  };

  const sortInvestRelation = (param) => {
    //투자상품, 투자신청, 투자계약(또는 양도양수계약) 순으로 정렬
    let relation = CM.cfnCopyObject(param);
    relation.list = [];
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'INVEST_PRODUCT') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'INVEST_REQUEST') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'INVEST_CONTRACT') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'TRANSFER_CONTRACT') relation.list.push(element);
    }

    return relation;
  };

  const sortPniRelation = (param) => {
    //투자상품, 투자계약(또는 이전양도양수계약), 양도양수계약 순으로 정렬
    let relation = CM.cfnCopyObject(param);
    relation.list = [];
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'INVEST_PRODUCT') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'INVEST_CONTRACT') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'PREVIOUS_TRANSFER_CONTRACT') relation.list.push(element);
    }
    for (let index = 0; index < param.list.length; index++) {
      const element = param.list[index];
      if (element.record_type === 'TRANSFER_CONTRACT') relation.list.push(element);
    }

    return relation;
  };

  //기록관리ID 엔터키 이벤트
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearchValidation();
    }
  };

  //조회버튼 handler
  const handleSearchValidation = () => {
    // validation 체크
    if (CM.cfnIsEmpty(recordId)) {
      CM.cfnAlert('기록관리ID를 입력하세요.');
      return true;
    } else {
      handleSearchAxiosBasic(searchObject, recordType, recordId.trim(), searchObjectName, recordTypeName);
    }
  };

  const handleSearchAxiosBasic = (
    paramSearchObject,
    paramRecordType,
    paramRecordId,
    paramSearchObjectName,
    paramRecordTypeName,
  ) => {
    let paramKey = paramSearchObject + paramRecordType;

    const axios = (url) => {
      return new Promise((resolve, reject) => {
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(process.env.REACT_APP_M007);
          },
        );
      });
    };

    const startAxios = async () => {
      let url = '';
      if (paramKey === 'loans1') {
        url = `web/companies/loans/requests/${paramRecordId}`;
      } else if (paramKey === 'loans2') {
        url = `web/companies/loans/products/${paramRecordId}`;
      } else if (paramKey === 'loans3') {
        url = `web/companies/loans/contracts/${paramRecordId}`;
      } else if (paramKey === 'loans4') {
        url = `web/companies/loans/repayments/${paramRecordId}`;
      } else if (paramKey === 'investments1') {
        url = `web/companies/investments/requests/${paramRecordId}`;
      } else if (paramKey === 'investments2') {
        url = `web/companies/investments/contracts/${paramRecordId}`;
      } else if (paramKey === 'investments3') {
        url = `web/companies/investments/pni-payments/${paramRecordId}`;
      } else if (paramKey === 'transfers1') {
        url = `web/companies/transfers/${paramRecordId}`;
      }
      url += `?p2p_company_code=${userStore.getCompanyCode()}`;
      const resultData = await axios(url);
      handleSearch(
        resultData,
        paramSearchObject,
        paramRecordType,
        paramRecordId,
        paramSearchObjectName,
        paramRecordTypeName,
      );
    };

    // 탭 존재 체크
    let tempTab = CM.cfnCopyObject(tab);

    // 탭 없으면 추가
    let tabExistYn = false;
    let tabExistIdx = 0;
    for (let index = 0; index < tempTab.length; index++) {
      const element = tempTab[index];
      if (paramSearchObject === element.searchObject && paramRecordType === element.recordType) {
        if (paramRecordId === element.contents.recordId) {
          tabExistYn = true;
          tabExistIdx = index;
        }
      }
    }

    if (tabExistYn) {
      setCurrentTab(tabExistIdx);
    } else {
      if (tempTab.length >= 20) {
        CM.cfnAlert('탭 생성은 20개까지 가능합니다.');
        return false;
      }
    }

    startAxios();
  };

  const handleSearch = (
    basic,
    paramSearchObject,
    paramRecordType,
    paramRecordId,
    paramSearchObjectName,
    paramRecordTypeName,
  ) => {
    // 탭 존재 체크
    let tempTab = CM.cfnCopyObject(tab);

    // 탭 없으면 추가
    let tabExistYn = false;
    let tabExistIdx = 0;
    for (let index = 0; index < tempTab.length; index++) {
      const element = tempTab[index];
      if (paramSearchObject === element.searchObject && paramRecordType === element.recordType) {
        if (paramRecordId === element.contents.recordId) {
          tabExistYn = true;
          tabExistIdx = index;
        }
      }
    }

    if (tabExistYn) {
      setCurrentTab(tabExistIdx);
    } else {
      tempTab.push({
        searchObject: paramSearchObject,
        searchObjectName: paramSearchObjectName,
        recordType: paramRecordType,
        recordTypeName: paramRecordTypeName,
        contents: {
          recordId: paramRecordId, //기록관리ID
          view: true, //이전 조회성공실패여부(탭 재클릭시 false면 내역표현X)
          basic: basic, //기본조회
          relation: { list: [] }, //연관기록조회
          searchTime: basic.api_trx_dtm,
        },
      });

      setTab(tempTab);
      setCurrentTab(tempTab.length - 1);
    }
  };

  //화면 내 기록관리ID 링크 클릭 시 handler
  const handleLink = (event) => {
    let param = event.target.getAttribute('name');
    param = param.split(',');

    focusRef.current.focus();
    let paramSearchObject = param[0];
    let paramRecordType = param[2];
    let recordId = param[4];
    let paramSearchObjectName = param[1];
    let paramRecordTypeName = param[3];
    handleSearchAxiosBasic(
      paramSearchObject,
      paramRecordType,
      recordId.trim(),
      paramSearchObjectName,
      paramRecordTypeName,
    );
  };

  //탭 닫기버튼 handler
  const closeCurrentTab = async (event, index) => {
    if (event !== null) {
      event.stopPropagation();
      event.preventDefault();
    }
    let tempTab = CM.cfnCopyObject(tab);
    tempTab.splice(index, 1);

    if (index <= currentTab) {
      if (index === 0 && currentTab === 0) {
        setCurrentTab(0);
      } else {
        setCurrentTab(currentTab - 1);
      }
    }
    await setTab(tempTab);
  };

  //탭 이동 handler
  const handleTabMove = async (event, index) => {
    const scrollPos = window.pageYOffset;
    await setCurrentTab(index);
    window.scrollTo(0, scrollPos);
  };

  //화면로딩 시 실행
  useEffect(() => {
    // 조회대상 별 기록유형
    const switchRecordTypeList = () => {
      let tempList = [];
      switch (searchObject) {
        case 'loans':
          tempList.push({ value: '1', label: '대출신청' });
          tempList.push({ value: '2', label: '투자상품' });
          tempList.push({ value: '3', label: '대출계약' });
          tempList.push({ value: '4', label: '대출상환' });
          return tempList;
        case 'investments':
          tempList.push({ value: '1', label: '투자신청' });
          tempList.push({ value: '2', label: '투자계약' });
          tempList.push({ value: '3', label: '원리금지급' });
          return tempList;
        case 'transfers':
          tempList.push({ value: '1', label: '양도양수계약' });
          return tempList;
        default:
          tempList.push({ value: '1', label: '대출신청' });
          tempList.push({ value: '2', label: '투자상품' });
          tempList.push({ value: '3', label: '대출계약' });
          tempList.push({ value: '4', label: '대출상환' });
          return tempList;
      }
    };

    userStore.handleNaviTrigger();
    const list = switchRecordTypeList();
    setRecordTypeList(list);
    setRecordTypeName(list[0].label);
  }, [searchObject]);

  //서브페이지 로딩
  const switchSubPage = () => {
    let param = tab[currentTab].searchObject + tab[currentTab].recordType;

    // window.scrollTo(0, 0);
    switch (param) {
      //연계대출기록
      case 'loans1': //대출신청
        return (
          <RecordHistoryLoanRequestS
            handleLink={handleLink}
            sortLoanRelation={sortLoanRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );
      case 'loans2': //투자상품
        return (
          <RecordHistoryInvestProductS
            handleLink={handleLink}
            sortLoanRelation={sortLoanRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );
      case 'loans3': //대출계약
        return (
          <RecordHistoryLoanContractS
            handleLink={handleLink}
            sortLoanRelation={sortLoanRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );
      case 'loans4': //대출상환
        return (
          <RecordHistoryLoanRepaymentS
            handleLink={handleLink}
            sortLoanRelation={sortLoanRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );

      //연계투자기록
      case 'investments1': //투자신청
        return (
          <RecordHistoryInvestRequestS
            handleLink={handleLink}
            sortInvestRelation={sortInvestRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );
      case 'investments2': //투자계약
        return (
          <RecordHistoryInvestContractS
            handleLink={handleLink}
            sortInvestRelation={sortInvestRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );
      case 'investments3': //원리금지급
        return (
          <RecordHistoryPniPaymentS
            handleLink={handleLink}
            sortInvestRelation={sortInvestRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );

      //원리금수취권 양도양수 기록
      case 'transfers1': //양도양수계약
        return (
          <RecordHistoryPniTransferS
            handleLink={handleLink}
            sortPniRelation={sortPniRelation}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        );

      default:
        return '';
    }
  };

  return (
    <Fragment>
      <div className="subContent">
        <div className="subInner">
          <div className="section">
            <h4>이용기관 정보</h4>
            <div className="contBox">
              <div className="contBox_item w100p">
                <div className="itemBox corpInfo">
                  <div className="infoStatus">
                    <div className="StatusL">
                      <p className="title">이용기관명</p>
                      <p className="name">{userStore.getCompanyName()}</p>
                    </div>
                    <div className="StatusR">
                      <p className="title">이용기관 코드</p>
                      <p className="name">{userStore.getCompanyCode()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h4>기록내역 조회</h4>
            <div className="searchArea">
              <div className="tblSearch">
                <fieldset>
                  <legend>검색조건 입력</legend>
                  <table>
                    <colgroup>
                      <col width="15%" />
                      <col width="85%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">조회대상</th>
                        <td>
                          <div className="searchList">
                            <CF.RadioGroupForm
                              name="searchObject"
                              id="searchObject"
                              value={searchObject}
                              option={searchObjectList}
                              onChange={handleSearchObject}
                              row={true}
                              testId="recordHistory-searchObject"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="hr"></div>
                  <table>
                    <colgroup>
                      <col width="15%" />
                      <col width="70%" />
                      <col width="15%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">기록유형</th>
                        <td colSpan={2}>
                          <div className="searchList">
                            <CF.RadioGroupForm
                              name="recordType"
                              id="recordType"
                              value={recordType}
                              option={recordTypeList}
                              onChange={handleRecordType}
                              row={true}
                              testId="recordHistory-recordType"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">기록관리 ID</th>
                        <td>
                          <div className="searchList">
                            <form className="inputTxt" noValidate autoComplete="off">
                              <TextField
                                onChange={handleRecordId}
                                onKeyPress={handleKeyPress}
                                name="recordId"
                                id="basicinput"
                                variant="outlined"
                                inputProps={{ 'data-testid': 'recordHistory-recordId' }}
                              />
                            </form>
                          </div>
                        </td>
                        <td className="tc">
                          <Button
                            vatiant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleSearchValidation}
                            data-testid="searchButton"
                          >
                            조회
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </div>
            </div>
            <div className="resultArea">
              {(function () {
                if (CM.cfnIsNotEmpty(tab)) {
                  return (
                    <>
                      {tab[currentTab].contents.view && (
                        <div className="searchTime">
                          <QueryBuilder></QueryBuilder>
                          <span>조회시점 {CM.cfnDateFormat(tab[currentTab].contents.searchTime, 'yyyyMMddhhmm')}</span>
                        </div>
                      )}
                      <div className="resultBox">
                        <div className="resultTop">
                          <div className="inner">
                            <h5>조회결과</h5>
                            <p className="hide">
                              오른쪽의 조회결과 리스트에서 내용 확인을 원하시는 버튼을 클릭하시면 조회결과 상세내용을
                              확인할 수 있습니다.
                            </p>
                            <ul>
                              {tab.map((row, index) => (
                                <li
                                  id={row.searchObject + row.recordType + row.contents.recordId}
                                  key={row.searchObject + row.recordType + row.contents.recordId}
                                >
                                  <Link
                                    to="#"
                                    className={currentTab === index ? 'on' : ''}
                                    role="button"
                                    onClick={(e) => handleTabMove(e, index)}
                                  >
                                    {/* 선택되면 클래스 on 추가 */}
                                    <span>
                                      [{row.searchObjectName}]{row.recordTypeName}
                                    </span>
                                    <Button endIcon={<ClearOutlinedIcon />} onClick={(e) => closeCurrentTab(e, index)}>
                                      <span className="hide">삭제</span>
                                    </Button>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                            <p tabIndex={0} ref={focusRef}></p>
                          </div>
                        </div>
                        <div className="resultBottom">
                          {tab[currentTab].contents.view ? (
                            switchSubPage()
                          ) : (
                            <div className="inner">
                              <h6 className="hide">조회결과 상세내용</h6>
                              <div className="contBox">
                                <div className="contBox_item">
                                  <p className="middleTit">기록관리 ID</p>
                                  <div className="itemBox corpInfo histidImg">
                                    <div className="infoStatus">
                                      <div className="StatusL">
                                        {CM.cfnIsNotEmpty(tab[currentTab]) && (
                                          <p className="name">{tab[currentTab].contents.recordId}</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="itemBox resultnoBox">
                                <p className="resultNo">
                                  <ErrorOutlineIcon />
                                  조회된 내용이 없습니다.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return <p tabIndex={0} ref={focusRef}></p>;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      <Popup></Popup>
    </Fragment>
  );
};

export default RecordHistoryM;
