/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 P2P 금융정보 조회 - 투자내역조회 컴포넌트(투자세부내역)
 */
import {
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  useMediaQuery,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useEffect, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import * as CF from '../../../common/template/ComponentForm';
import * as CM from '../../../common/util/common';
import axiosUtil from '../../../../utils/axiosUtil';
import { rootStore } from '../../../../stores/rootStore';
import { toJS } from 'mobx';

//세부 투자내역 조회대상 라디오버튼
const investInquiryTypeOptions = [
  { value: 'REQUEST_PROGRESS', label: '투자신청 중 상품' },
  { value: 'CONTRACT_PROGRESS', label: '투자진행 중 상품' },
  { value: 'CONTRACT_COMPLETE', label: '투자종료 상품' },
];

const InvestmentDetailsHistoryC = (props) => {
  const mobileStyle = {
    marginTop: useMediaQuery('(max-width : 52.5rem)') ? '3rem' : '',
  };

  const [investInquiryType, setInvestInquiryType] = useState(investInquiryTypeOptions[0].value); //조회 대상
  const p2pCompanyCode = CM.cfnTotalInstituteList(); //업체목록
  const productTypeCode = toJS(rootStore.ProductTypeList); // 상품유형목록('전체'포함)

  const [requestParams, setRequestParams] = useState({
    investInquiryType: investInquiryTypeOptions[0].value,
    p2pCompanyCode: 'ALL',
    productTypeCode: 'TOTAL_PRODUCT',
    inquiryStartDate: CM.cfnGetCustomDate('month', -6, 'string'),
    inquiryEndDate: CM.cfnGetDate(),
    pageNumber: 1,
    pageSize: 10,
    sortDirection: 'DESC',
    sortProperty: 'investment_request_dtm',
  });

  const [pagingInvestmentDetails, setPagingInvetstmentDetails] = useState({});
  const [pagination, setPagination] = useState(CM.cfnSetPagination());
  const [buttonState, setButtonState] = useState(true); //조회버튼

  // investInquiryType State flag handleChange
  const handleInvestInquiryType = (event) => {
    setInvestInquiryType(event.target.value);
  };

  // p2pCompanyCode State flag handleChange
  const handleP2pCompnayCode = (event) => {
    setRequestParams((data) => ({ ...data, p2pCompanyCode: event.target.value }));
  };

  // productTypeCode State flag handleChange
  const handleProductTypeCode = (event) => {
    setRequestParams((data) => ({ ...data, productTypeCode: event.target.value }));
  };

  // button State flag handleChange
  const handleSearchButtonChange = (flag) => setButtonState(flag);

  //inquiryStartDate, inquiryEndDate State flag handleChange
  const handleDate = (name) => (date) => {
    if (name === 'inquiryStartDate' || name === 'inquiryEndDate') {
      setRequestParams((data) => ({ ...data, [name]: CM.cfnConvertDateToString(date) }));
    } else {
      const endDate = CM.cfnGetDate();
      let startDate = CM.cfnGetDate();
      if (name === 'recent1months') {
        startDate = CM.cfnGetCustomDate('month', -1, 'string');
      } else if (name === 'recent3months') {
        startDate = CM.cfnGetCustomDate('month', -3, 'string');
      } else if (name === 'recent6months') {
        startDate = CM.cfnGetCustomDate('month', -6, 'string');
      } else if (name === 'recent12months') {
        startDate = CM.cfnGetCustomDate('year', -1, 'string');
      } else if (name === 'recent24months') {
        startDate = CM.cfnGetCustomDate('year', -2, 'string');
      }
      setRequestParams((data) => ({ ...data, inquiryStartDate: startDate, inquiryEndDate: endDate }));
    }
  };

  // 조회버튼 클릭시 이벤트함수
  const handleClick = () => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 1,
      sortDirection: 'DESC',
      sortProperty:
        investInquiryType === investInquiryTypeOptions[0].value ? 'investment_request_dtm' : 'contract_conclusion_dtm',
    }));
    handleSearchButtonChange(true);
  };

  // 페이지 숫자클릭 이벤트함수
  const handleOffsetChange = (e, offset, page) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: page,
    }));
    handleSearchButtonChange(true);
  };

  // 화면에 보여줄 row개수 변경 이벤트함수
  const handleRowPerPage = ({ target: { value } }) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 1,
      pageSize: value,
    }));
    handleSearchButtonChange(true);
  };

  const handlePaginationChange = (param) => {
    setPagination(CM.cfnSetPagination(param));
  };

  const createSortHandler = (e, deactive) => {
    let sortOrder = '';
    let sortProp = '';

    if (CM.cfnIsEmpty(e.currentTarget.name)) {
      sortProp = requestParams.sortProperty;
    } else {
      sortProp = e.currentTarget.name;
    }

    //비활성화아이콘 처음정렬 desc
    if (CM.cfnIsNotEmpty(deactive)) {
      sortOrder = 'DESC';
    } else {
      if (requestParams.sortDirection === 'DESC') {
        sortOrder = 'ASC';
      } else {
        sortOrder = 'DESC';
      }
    }
    setRequestParams((data) => ({
      ...data,
      sortDirection: sortOrder,
      sortProperty: sortProp,
      pageNumber: 1,
    }));
    handleSearchButtonChange(true);
  };

  const handleOpen = (index) => {
    let tempObj = CM.cfnCopyObject(pagingInvestmentDetails.contents);
    let row = tempObj[index];
    if (CM.cfnIsEmpty(row.open)) {
      row.open = true;
    } else {
      row.open = !row.open;
    }
    setPagingInvetstmentDetails({ ...pagingInvestmentDetails, contents: tempObj });

    if (row.open) {
      handleDetail(row.principal_interest_management_no);
    }
  };

  // searchParam 변경 이벤트함수
  useEffect(() => {
    setRequestParams({
      ...props.param,
      sortProperty:
        props.param.investInquiryType === investInquiryTypeOptions[0].value
          ? 'investment_request_dtm'
          : 'contract_conclusion_dtm',
    });
    setInvestInquiryType(props.param.investInquiryType);
    handleSearchButtonChange(true);
  }, [props.param]);

  useEffect(() => {
    const handleInvestmentDetails = (pageData) => {
      setPagingInvetstmentDetails(pageData);
      handlePaginationChange(pageData);
      handleSearchButtonChange(false);
    };

    //현재 페이지 투자세부내역 조회
    const getPaginInvestmentDetail = (param) => {
      return new Promise((resolve) => {
        let url = 'web/customers/investments/details';
        if (investInquiryType === 'REQUEST_PROGRESS') {
          url += `/invest-requests`;
        } else {
          url += `/invest-contract`;
        }
        url += `?uuid=${userStore.getUuid()}`;
        url += `&invest_inquiry_type=${investInquiryType}&p2p_company_code=${param.p2pCompanyCode}&product_type_code=${param.productTypeCode}`;
        url += `&page_number=${param.pageNumber - 1}&page_size=${param.pageSize}`;
        url += `&sort_directions=${param.sortDirection}&sort_properties=${param.sortProperty}`;
        url += `&inquiry_start_date=${param.inquiryStartDate}&inquiry_end_date=${param.inquiryEndDate}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve();
          },
        );
      });
    };

    // useEffect start function
    const startInvestDetailList = async (params) => {
      const pageData = await getPaginInvestmentDetail(params);
      handleInvestmentDetails(pageData);
      setRequestParams((data) => ({ ...data, investInquiryType: investInquiryType }));
    };

    if (buttonState) {
      const result = CM.cfnCommonValidDate(requestParams.inquiryStartDate, requestParams.inquiryEndDate, 24);
      if (!result) {
        handleSearchButtonChange(false);
        return false;
      }

      startInvestDetailList(requestParams);
    }
  }, [buttonState]);

  const handleExcelDownload = async () => {
    const axiosDownload = (data) => {
      let fileName =
        requestParams.investInquiryType === 'REQUEST_PROGRESS'
          ? '투자신청 중 상품.xlsx'
          : requestParams.investInquiryType === 'CONTRACT_PROGRESS'
          ? '투자진행 중 상품.xlsx'
          : '투자종료 상품.xlsx';

      return new Promise((resolve) => {
        let api = 'web/customers/investments/details/download';

        axiosUtil.cfnAxiosExcelFileDownload(
          api,
          'POST',
          data,
          fileName,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    if (pagingInvestmentDetails.contents.length === 0) return;

    let recordNos = [];
    pagingInvestmentDetails.contents.forEach((data) => {
      recordNos.push(
        requestParams.investInquiryType === 'REQUEST_PROGRESS'
          ? data.investment_request_no
          : data.principal_interest_management_no,
      );
    });

    let data = {
      uuid: userStore.getUuid(),
      inquiry_type: requestParams.investInquiryType,
      record_no_list: recordNos,
    };

    await axiosDownload(data);
  };

  const handleDetail = async (id) => {
    // 예정지급내역 조회 (투자진행중)
    const axiosScheduledDetail = () => {
      return new Promise((resolve) => {
        let api = 'web/customers/investments/details/invest-contract/scheduled-payments';
        api += `?uuid=${userStore.getUuid()}`;
        api += `&principal_interest_management_no=${id}`;

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    // 예정지급내역 조회 (투자진행중/투자종료)
    const axiosActualDetail = () => {
      return new Promise((resolve) => {
        let api = 'web/customers/investments/details/invest-contract/actual-payments';
        api += `?uuid=${userStore.getUuid()}`;
        api += `&principal_interest_management_no=${id}`;

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    const scheduledData = requestParams.investInquiryType === 'CONTRACT_PROGRESS' ? await axiosScheduledDetail() : null;
    const actualData = await axiosActualDetail();

    let list = pagingInvestmentDetails.contents.map((item) =>
      item.principal_interest_management_no === id
        ? { ...item, scheduled_list: scheduledData?.contents, actual_list: actualData?.contents, open: true }
        : { ...item },
    );
    setPagingInvetstmentDetails({ ...pagingInvestmentDetails, contents: list });
  };

  return (
    <div className="section" id="detail_01">
      <h4>세부 투자내역</h4>
      <div className="searchArea">
        <div className="tblSearch">
          <form>
            <ul>
              <li className="len12-8">
                <div className="searchList">
                  <span className="tit">조회대상</span>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">조회대상선택</FormLabel>
                    <CF.RadioGroupForm
                      name="investInquiryType"
                      id="investInquiryType"
                      value={investInquiryType}
                      option={investInquiryTypeOptions}
                      onChange={handleInvestInquiryType}
                      row={true}
                      testId="investInquiryTypeTest"
                    />
                  </FormControl>
                </div>
              </li>
              <li className="imsi_div"></li>
              <li className="len12-4 ty_new">
                <div className="searchList">
                  <span className="tit">업체명</span>
                  <FormControl variant="filled">
                    <CF.SelectForm
                      value={requestParams.p2pCompanyCode}
                      arrayOption={p2pCompanyCode}
                      customProps={{ onChange: handleP2pCompnayCode }}
                      name="p2pCompanyCode"
                    />
                  </FormControl>
                </div>
              </li>
              <li className="len12-new">
                <div className="searchList">
                  <span className="tit">상품유형</span>
                  <FormControl variant="filled">
                    <CF.SelectForm
                      value={requestParams.productTypeCode}
                      arrayOption={productTypeCode}
                      customProps={{ onChange: handleProductTypeCode }}
                      name="productTypeCode"
                    />
                  </FormControl>
                </div>
              </li>
              <li className="len12-11 infoDateplus">
                <div className="searchList">
                  <span className="tit">
                    조회기간
                    <br />
                    {investInquiryType === 'REQUEST_PROGRESS' ? (
                      <span className="font14">(투자신청일 기준)</span>
                    ) : (
                      <span className="font14">(투자계약일 기준)</span>
                    )}
                  </span>
                  <div className="dateTerm">
                    <div className="datePick">
                      <CF.DatePickerForm
                        id="startDate"
                        value={requestParams.inquiryStartDate}
                        handleChange={handleDate('inquiryStartDate')}
                        customProps={{
                          maxDate: new Date(),
                        }}
                      />
                      <span className="rowline">~</span>
                      <CF.DatePickerForm
                        id="endDate"
                        value={requestParams.inquiryEndDate}
                        handleChange={handleDate('inquiryEndDate')}
                        customProps={{
                          maxDate: new Date(),
                        }}
                      />
                    </div>
                    {investInquiryType === 'REQUEST_PROGRESS' ? (
                      <div className="searchTerm ty_small">
                        <Button startIcon={<CheckIcon />} id="recent1Btn" onClick={handleDate('recent1months')}>
                          <span>최근 1개월</span>
                        </Button>
                        <Button startIcon={<CheckIcon />} id="recent3Btn" onClick={handleDate('recent3months')}>
                          <span>최근 3개월</span>
                        </Button>
                        <Button startIcon={<CheckIcon />} id="recent6Btn" onClick={handleDate('recent6months')}>
                          <span>최근 6개월</span>
                        </Button>
                      </div>
                    ) : (
                      <div className="searchTerm ty_small">
                        <Button startIcon={<CheckIcon />} id="recent6Btn" onClick={handleDate('recent6months')}>
                          <span>최근 6개월</span>
                        </Button>
                        <Button startIcon={<CheckIcon />} id="recent12Btn" onClick={handleDate('recent12months')}>
                          <span>최근 12개월</span>
                        </Button>
                        <Button startIcon={<CheckIcon />} id="recent24Btn" onClick={handleDate('recent24months')}>
                          <span>최근 24개월</span>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="searchList infoDate">
                  <span className="info blue">
                    ※ 동 정보는 온투업자(폐업업체의 경우 청산업무 수탁기관)가 중앙기록관리기관에 기록한 이용자의
                    투자내역 정보로, 관련 문의사항이 있을 경우 해당 온투업자의 고객센터로 문의하여 주시기 바랍니다.
                  </span>
                </div>
                {investInquiryType === 'CONTRACT_COMPLETE' && (
                  <div className="searchList infoDate">
                    <span className="hide">검색기간 설정 주의사항</span>
                    <span className="info blue">
                      ※ 투자종료일 기준으로 최근 5년 이내의 투자계약을 조회할 수 있습니다.
                    </span>
                  </div>
                )}
              </li>
              <li className="len12-1 top_align">
                <Button onClick={handleClick} data-testid="searchButtonTest" startIcon={<SearchIcon />}>
                  <span>조회</span>
                </Button>
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div className="tblTop new_ty">
        <div className="topL">
          <span className="listNum">
            {requestParams.investInquiryType === 'REQUEST_PROGRESS' && '투자신청 중 상품, '}
            {requestParams.investInquiryType === 'CONTRACT_PROGRESS' && '투자진행 중 상품, '}
            {requestParams.investInquiryType === 'CONTRACT_COMPLETE' && '투자종료 상품, '}
            전체 <strong>{CM.cfnAddComma(pagination.total)}</strong>건
          </span>
        </div>
        <div className="topR">
          <div className="dwn_wrap">
            <Button className="btn iconExcel" data-testid="excelButtonTest" onClick={handleExcelDownload}>
              엑셀 다운로드
            </Button>
          </div>
          {CM.cfnIsEmpty(pagingInvestmentDetails) ? (
            <span className="searchTime l_down"></span>
          ) : (
            <span className="searchTime l_down">
              <QueryBuilder></QueryBuilder>
              <span>조회시점 {CM.cfnDateFormat(pagingInvestmentDetails.api_trx_dtm, 'yyyyMMddhhmm')}</span>
            </span>
          )}
          <CF.RowPerPageForm
            value={requestParams.pageSize}
            onChange={handleRowPerPage}
            customProps={{ inputProps: { 'data-testid': 'customerInformation-rowPerPage-select' } }}
          />
        </div>
        <div className="sub_txt">
          ※ 표는 좌우로 스크롤&nbsp;
          <TouchAppIcon />
          가능합니다.
        </div>
      </div>
      {CM.cfnIsEmpty(pagingInvestmentDetails) ? (
        <TableContainer>
          <Table className="tblTypeline maxTd" aria-label="spanning table">
            <TableBody>
              <TableRow data-testid={'tablerow0'} key={'tablerow0'}>
                <TableCell>조회된 내용이 없습니다.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table className="tblTypeline maxTd" aria-label="spanning table">
            {requestParams.investInquiryType === 'REQUEST_PROGRESS' ? (
              <colgroup>
                <col width="8%" />
                <col width="11%" />
                <col width="10%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col />
                <col width="14%" />
              </colgroup>
            ) : requestParams.investInquiryType === 'CONTRACT_PROGRESS' ? (
              <colgroup>
                <col width="5%" />
                <col width="8%" />
                <col width="8%" />
                <col width="10%" />
                <col width="10%" />
                <col width="11%" />
                <col width="9%" />
                <col width="11%" />
                <col width="9%" />
                <col width="12.5%" />
                <col />
              </colgroup>
            ) : (
              <colgroup>
                <col width="5%" />
                <col width="8%" />
                <col width="8%" />
                <col width="10%" />
                <col width="10%" />
                <col width="11%" />
                <col width="9.5%" />
                <col width="11%" />
                <col width="9.5%" />
                <col width="9.5%" />
                <col width="4.25%" />
                <col width="4.25%" />
              </colgroup>
            )}
            {requestParams.investInquiryType === 'REQUEST_PROGRESS' ? (
              <TableHead>
                <TableRow>
                  <TableCell className="bglightGreen" rowSpan={2}>
                    No
                  </TableCell>
                  <TableCell className="bglightGreen th_center" rowSpan={2}>
                    <div>
                      <p>
                        투자
                        <br />
                        신청일
                      </p>
                      {requestParams.sortProperty !== 'investment_request_dtm' ? (
                        <IconButton name="investment_request_dtm" onClick={(e) => createSortHandler(e, 'Y')}>
                          <ArrowDropDownIcon color="disabled" />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="bglightGreen" rowSpan={2}>
                    투자
                    <br />
                    기간
                    <p className="font11">(단위 : 일)</p>
                  </TableCell>
                  <TableCell className="bglightGreen" colSpan="4">
                    상품정보
                  </TableCell>
                  <TableCell className="bglightGreen">
                    금액정보 <p className="font11">(단위 : 원)</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="bglightGreen">
                    업체명
                    {requestParams.sortProperty !== 'p2p_corporation.name' ? (
                      <IconButton name="p2p_corporation.name" onClick={(e) => createSortHandler(e, 'Y')}>
                        <ArrowDropDownIcon color="disabled" />
                      </IconButton>
                    ) : requestParams.sortDirection === 'DESC' ? (
                      <ArrowDropDownIcon onClick={createSortHandler} />
                    ) : (
                      <ArrowDropUpIcon onClick={createSortHandler} />
                    )}
                  </TableCell>
                  <TableCell className="bglightGreen">상품명</TableCell>
                  <TableCell className="bglightGreen">상품유형</TableCell>
                  <TableCell className="bglightGreen">
                    예상수익률
                    <br /> <p className="font11">(단위 : %)</p>
                  </TableCell>
                  <TableCell className="bglightGreen">
                    투자금액<p className="font11">(원금 기준)</p>
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow>
                  <TableCell className="bglightGreen" rowSpan={2}>
                    No
                  </TableCell>
                  <TableCell className="bglightGreen th_center" rowSpan={2}>
                    <div>
                      <p>
                        투자
                        <br />
                        계약일
                      </p>
                      {requestParams.sortProperty !== 'contract_conclusion_dtm' ? (
                        <IconButton name="contract_conclusion_dtm" onClick={(e) => createSortHandler(e, 'Y')}>
                          <ArrowDropDownIcon color="disabled" />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="bglightGreen" rowSpan={2}>
                    투자
                    <br />
                    기간
                    <br />
                    <div className="tip">
                      <CF.TooltipForm
                        contents={
                          '투자계약 시의 ‘투자시작일~투자종료일’\n※ 연체, 부실 등에 따라 실제 기간과 상이할 수 있음.'
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell className="bglightGreen" colSpan="3">
                    상품정보
                  </TableCell>
                  <TableCell className="bglightGreen" colSpan="4">
                    금액정보 (단위 : 원)
                  </TableCell>
                  {requestParams.investInquiryType === 'CONTRACT_COMPLETE' && (
                    <TableCell className="bglightGreen" rowSpan={2}>
                      상환결과
                      <div className="tip">
                        <CF.TooltipForm
                          contents={
                            '온투업자가 기록한 투자상품의 최종상태값\n(정상상환/부실/양도/기타*)\n* 기타 : 개인회생/파산/구제금융신청 등\n채권의 정상상환이 불가한 경우 등'
                          }
                          position="bottom-end"
                        />
                      </div>
                    </TableCell>
                  )}
                  <TableCell className="bglightGreen" rowSpan={2}>
                    상세
                    <br />
                    보기
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="bglightGreen">
                    업체명
                    {requestParams.sortProperty !== 'p2p_corporation.name' ? (
                      <IconButton name="p2p_corporation.name" onClick={(e) => createSortHandler(e, 'Y')}>
                        <ArrowDropDownIcon color="disabled" />
                      </IconButton>
                    ) : requestParams.sortDirection === 'DESC' ? (
                      <ArrowDropDownIcon onClick={createSortHandler} />
                    ) : (
                      <ArrowDropUpIcon onClick={createSortHandler} />
                    )}
                  </TableCell>
                  <TableCell className="bglightGreen">상품명</TableCell>
                  <TableCell className="bglightGreen">상품유형</TableCell>

                  <TableCell className="bglightGreen">
                    투자금액<p className="font11">(원금 기준)</p>
                  </TableCell>
                  <TableCell className="bglightGreen">
                    지급원금
                    {requestParams.investInquiryType === 'CONTRACT_PROGRESS' && (
                      <div className="tip">
                        <CF.TooltipForm
                          contents={
                            '지급된 투자원금\n※ 부실처리 시 부실처리된 금액으로,\n실제 지급원금과 상이할 수 있음.'
                          }
                        />
                      </div>
                    )}
                    <p className="font11">(원금 기준)</p>
                  </TableCell>
                  <TableCell className="bglightGreen">
                    투자잔액<p className="font11">(원금 기준)</p>
                  </TableCell>
                  <TableCell className="bglightGreen">
                    실제지급
                    <br />
                    금액
                    <div className="tip">
                      <CF.TooltipForm
                        contents={
                          '실제 지급된 투자원금 +\n실제 지급된 이자 (연체이자 포함)\n※ 원금, 이자, 수수료 등 관련 제비용을\n고려한 실제 지급금액'
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {pagingInvestmentDetails.contents.length > 0 ? (
                pagingInvestmentDetails.contents.map((row, index) =>
                  requestParams.investInquiryType === 'REQUEST_PROGRESS' ? (
                    <TableRow data-testid={'tablerow' + index} key={'tablerow' + index}>
                      <TableCell>{pagination.rowsPerPage * (requestParams.pageNumber - 1) + index + 1}</TableCell>
                      <TableCell>{CM.cfnDateFormat(row.invest_request_dtm, 'yyMMdd')}</TableCell>
                      <TableCell>{row.loan_days}</TableCell>
                      <TableCell>{row.p2p_company_name}</TableCell>
                      <TableCell>{row.goods_name}</TableCell>
                      <TableCell>{row.goods_type_name}</TableCell>
                      <TableCell>{row.profit_rate}</TableCell>
                      <TableCell className="tr">{CM.cfnAddComma(row.invest_amount)}</TableCell>
                    </TableRow>
                  ) : (
                    <>
                      <TableRow data-testid={'tablerow' + index} key={'tablerow' + index}>
                        <TableCell>{pagination.rowsPerPage * (requestParams.pageNumber - 1) + index + 1}</TableCell>
                        <TableCell>{CM.cfnDateFormat(row.invest_contract_sign_dtm, 'yyMMdd')}</TableCell>
                        <TableCell className="w_break">{`${CM.cfnDateFormat(
                          row.invest_start_date,
                          'yyMMdd',
                        )} ~${CM.cfnDateFormat(row.invest_end_date, 'yyMMdd')}`}</TableCell>
                        <TableCell>{row.p2p_company_name}</TableCell>
                        <TableCell>{row.goods_name}</TableCell>
                        <TableCell>{row.goods_type_name}</TableCell>
                        <TableCell className="tr">{CM.cfnAddComma(row.invest_amount)}</TableCell>
                        {requestParams.investInquiryType !== 'CONTRACT_COMPLETE' ||
                        row.additional_stat_name === '상환완료' ? (
                          <>
                            <TableCell className="tr">{CM.cfnAddComma(row.pni_payment_amount)}</TableCell>
                            <TableCell className="tr">{CM.cfnAddComma(row.invest_balance)}</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell className="td_empty" colSpan={2}>
                              -
                            </TableCell>
                          </>
                        )}
                        <TableCell className="tr">{CM.cfnAddComma(row.actual_pay_amount)}</TableCell>
                        {requestParams.investInquiryType === 'CONTRACT_COMPLETE' && (
                          <TableCell>{row.additional_stat_name}</TableCell>
                        )}
                        <TableCell>
                          <IconButton onClick={() => handleOpen(index)}>
                            {row.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {requestParams.investInquiryType !== 'REQUEST_PROGRESS' && (
                        <TableRow className="detail_area">
                          <td
                            className="inner_tbl"
                            colSpan={requestParams.investInquiryType === 'CONTRACT_PROGRESS' ? 11 : 12}
                          >
                            <Collapse in={CM.cfnIsEmpty(row.open) ? false : row.open} timeout="auto" unmountOnExit>
                              <div className="tbl_list_wrap">
                                {requestParams.investInquiryType === 'CONTRACT_PROGRESS' &&
                                  row.scheduled_list?.length > 0 && (
                                    <div className="tbl_list scrollbar">
                                      <table>
                                        <colgroup>
                                          <col width="8%" />
                                          <col width="17%" />
                                          <col width="17%" />
                                          <col width="17%" />
                                          <col />
                                        </colgroup>
                                        <thead>
                                          <tr>
                                            <th className="alig_l" colSpan="5">
                                              {'> 예정 지급 내역 (단위 : 원)'}
                                              <div className="tip">
                                                <CF.TooltipForm
                                                  style={{ marginTop: '0.15rem' }}
                                                  contents={
                                                    '투자계약 시의 예정 지급내역\n※ 차입자의 중도상환, 연체 등이 발생하는 경우, 실제 지급내역과 상이할 수 있음.'
                                                  }
                                                />
                                              </div>
                                            </th>
                                          </tr>
                                          <tr>
                                            <th>회차</th>
                                            <th>예정 지급일</th>
                                            <th>예정 지급원금</th>
                                            <th>예정 지급이자</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {row.scheduled_list.map((item) => (
                                            <tr>
                                              <td>{item.securities_n_count}회</td>
                                              <td>{CM.cfnDateFormat(item.schd_date, 'yyMMdd')}</td>
                                              <td className="tr">{CM.cfnAddComma(item.schd_p_amount)}</td>
                                              <td className="tr">{CM.cfnAddComma(item.schd_interest)}</td>
                                              <td></td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                {row.actual_list?.length > 0 && (
                                  <div className="tbl_list scrollbar">
                                    <table
                                      style={{ borderTop: row.scheduled_list?.length > 0 ? '1px solid #6f8c9f' : '' }}
                                    >
                                      <colgroup>
                                        <col width="8%" />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                      </colgroup>
                                      <thead>
                                        <tr>
                                          <th className="alig_l" colSpan="6">
                                            {'> 실제 지급 내역 (단위 : 원)'}
                                            {requestParams.investInquiryType === 'CONTRACT_COMPLETE' && (
                                              <div className="tip">
                                                <CF.TooltipForm
                                                  style={{ marginTop: '0.15rem' }}
                                                  contents={
                                                    '※ 상환결과가 ‘부실’, ‘양도’, ‘기타’인 경우,\n회차별 지급일 및 실제 지급금액 정보만 제공됨.'
                                                  }
                                                />
                                              </div>
                                            )}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th>회차</th>
                                          <th>실제 지급일</th>
                                          <th>
                                            지급원금
                                            {requestParams.investInquiryType === 'CONTRACT_PROGRESS' && (
                                              <div className="tip">
                                                <CF.TooltipForm
                                                  style={{ marginTop: '0.15rem' }}
                                                  contents={
                                                    '지급된 투자원금\n※ 부실처리 시 부실처리된 금액으로,\n실제 지급원금과 상이할 수 있음.'
                                                  }
                                                />
                                              </div>
                                            )}
                                          </th>
                                          <th>지급이자</th>
                                          <th>연체이자</th>
                                          <th>
                                            실제 지급금액
                                            <div className="tip">
                                              <CF.TooltipForm
                                                style={{ marginTop: '0.15rem' }}
                                                contents={
                                                  '실제 지급된 투자원금 +\n실제 지급된 이자 (연체이자 포함)\n※ 원금, 이자, 수수료 등 관련 제비용을\n고려한 실제 지급금액'
                                                }
                                                position="bottom-end"
                                              />
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {row.actual_list.map((item) => (
                                          <tr>
                                            <td>{item.actual_payment_seq}회</td>
                                            <td>{CM.cfnDateFormat(item.actual_payment_date, 'yyMMdd')}</td>
                                            {requestParams.investInquiryType !== 'CONTRACT_COMPLETE' ||
                                            row.additional_stat_name === '상환완료' ? (
                                              <>
                                                <td className="tr">{CM.cfnAddComma(item.principal_payment)}</td>
                                                <td className="tr">{CM.cfnAddComma(item.interest)}</td>
                                                <td className="tr">{CM.cfnAddComma(item.overdue_interest)}</td>
                                              </>
                                            ) : (
                                              <td className="td_empty" colspan="3">
                                                -
                                              </td>
                                            )}
                                            <td className="tr">{CM.cfnAddComma(item.actual_payment_amount)}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                                {row.transfer_amount !== '0' && (
                                  <div className="tbl_list scrollbar">
                                    <table
                                      style={{
                                        borderTop:
                                          row.scheduled_list?.length > 0 || row.actual_list?.length > 0
                                            ? '1px solid #6f8c9f'
                                            : '',
                                      }}
                                    >
                                      <colgroup>
                                        <col width="8%" />
                                        <col width="17%" />
                                        <col width="17%" />
                                        <col width="17%" />
                                        <col />
                                      </colgroup>
                                      <thead>
                                        <tr>
                                          <th className="alig_l" colSpan="5">
                                            {`> 투자자 양도금액(원금 기준) : ${CM.cfnAddComma(row.transfer_amount)}원`}
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </Collapse>
                          </td>
                        </TableRow>
                      )}
                    </>
                  ),
                )
              ) : (
                <TableRow data-testid={'tablerow0'} key={'tablerow0'}>
                  <TableCell
                    colSpan={
                      requestParams.investInquiryType === 'REQUEST_PROGRESS'
                        ? '8'
                        : requestParams.investInquiryType === 'CONTRACT_PROGRESS'
                        ? '11'
                        : '12'
                    }
                  >
                    조회된 내용이 없습니다.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="paging" style={mobileStyle}>
        <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="" />
      </div>
    </div>
  );
};

export default InvestmentDetailsHistoryC;
