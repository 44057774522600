/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 세부 대출내역 조회
 */

import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';
import * as CF from '../../../common/template/ComponentForm';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckIcon from '@material-ui/icons/Check';
import { toJS } from 'mobx';

import { userStore } from '../../../../stores/customers/userStore';
import { rootStore } from '../../../../stores/rootStore';

//세부 대출내역 조회대상 라디오버튼
const loanInquiryTypeOptions = [
  { value: 'CONTRACT_PROGRESS', label: '대출진행 중 상품' },
  { value: 'CONTRACT_COMPLETE', label: '대출종료 상품' },
];

const LoanDetailsHistoryC = (props) => {
  const mobileStyle = {
    marginTop: useMediaQuery('(max-width : 52.5rem)') ? '3rem' : '',
  };

  const [loanInquiryType, setLoanInquiryType] = useState(loanInquiryTypeOptions[0].value);
  const p2pCompanyCode = CM.cfnTotalInstituteList(); // 업체목록
  const productTypeCode = toJS(rootStore.ProductTypeList); // 상품유형목록('전체'포함)

  const [requestParams, setRequestParams] = useState({
    loanInquiryType: loanInquiryTypeOptions[0].value,
    p2pCompanyCode: 'ALL',
    productTypeCode: 'TOTAL_PRODUCT',
    inquiryStartDate: CM.cfnGetCustomDate('month', -6, 'string'),
    inquiryEndDate: CM.cfnGetDate(),
    pageNumber: 1,
    pageSize: 10,
    sortDirection: 'DESC',
    sortProperty: 'loan_contract_conclusion_dtm',
  });

  const [pagingLoanDetails, setPagingLoanDetails] = useState({});
  const [buttonState, setButtonState] = useState(true);
  const [pagination, setPagination] = useState(CM.cfnSetPagination());

  const handleLoanInquiryType = (event) => {
    setLoanInquiryType(event.target.value);
  };

  // p2pCompanyCode State flag handleChange
  const handleP2pCompnayCode = (event) => {
    setRequestParams((data) => ({ ...data, p2pCompanyCode: event.target.value }));
  };

  // productTypeCode State flag handleChange
  const handleProductTypeCode = (event) => {
    setRequestParams((data) => ({ ...data, productTypeCode: event.target.value }));
  };

  // 조회버튼 클릭시 이벤트함수
  const handleClick = () => {
    setRequestParams((data) => ({ ...data, pageNumber: 1 }));
    setButtonState(true);
  };

  //inquiryStartDate, inquiryEndDate State flag handleChange
  const handleDate = (name) => (date) => {
    if (name === 'inquiryStartDate' || name === 'inquiryEndDate') {
      setRequestParams((data) => ({ ...data, [name]: CM.cfnConvertDateToString(date) }));
    } else {
      let startDate = CM.cfnGetDate();
      if (name === 'recent6months') {
        startDate = CM.cfnGetCustomDate('month', -6, 'string');
      } else if (name === 'recent12months') {
        startDate = CM.cfnGetCustomDate('year', -1, 'string');
      } else if (name === 'recent24months') {
        startDate = CM.cfnGetCustomDate('year', -2, 'string');
      }
      setRequestParams((data) => ({ ...data, inquiryStartDate: startDate, inquiryEndDate: CM.cfnGetDate() }));
    }
  };

  // 페이지 숫자클릭 이벤트함수
  const handleOffsetChange = (e, offset, page) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: page,
    }));
    setButtonState(true);
  };

  // 화면에 보여줄 row개수 변경 이벤트함수
  const handleRowPerPage = ({ target: { value } }) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 1,
      pageSize: value,
    }));
    setButtonState(true);
  };

  const handlePaginationChange = (param) => {
    setPagination(CM.cfnSetPagination(param));
  };

  const createSortHandler = (e, deactive) => {
    let sortOrder = '';
    let sortProp = '';

    if (CM.cfnIsEmpty(e.currentTarget.name)) {
      sortProp = requestParams.sortProperty;
    } else {
      sortProp = e.currentTarget.name;
    }

    //비활성화아이콘 처음정렬 desc
    if (CM.cfnIsNotEmpty(deactive)) {
      sortOrder = 'DESC';
    } else {
      if (requestParams.sortDirection === 'DESC') {
        sortOrder = 'ASC';
      } else {
        sortOrder = 'DESC';
      }
    }
    setRequestParams((data) => ({
      ...data,
      sortDirection: sortOrder,
      sortProperty: sortProp,
      pageNumber: 1,
    }));
    setButtonState(true);
  };

  const handleOpen = (index) => {
    let tempObj = CM.cfnCopyObject(pagingLoanDetails.contents);
    let row = tempObj[index];
    if (CM.cfnIsEmpty(row.open)) {
      row.open = true;
    } else {
      row.open = !row.open;
    }
    setPagingLoanDetails({ ...pagingLoanDetails, contents: tempObj });

    if (row.open) {
      handleDetail(row.loan_contract_no);
    }
  };

  // searchParam 변경 이벤트함수
  useEffect(() => {
    setRequestParams(props.param);
    setLoanInquiryType(props.param.loanInquiryType);
    setButtonState(true);
  }, [props.param]);

  useEffect(() => {
    const handleLoanDetails = (pageData) => {
      setPagingLoanDetails(pageData);
      handlePaginationChange(pageData);
      setButtonState(false);
    };

    //현재 페이지 대출세부내역 조회
    const getPagingLoanDetail = (param) => {
      return new Promise((resolve) => {
        let url = 'web/customers/loans/details';
        url += `?uuid=${userStore.getUuid()}`;
        url += `&loan_inquiry_type=${loanInquiryType}&p2p_company_code=${param.p2pCompanyCode}&product_type_code=${param.productTypeCode}`;
        url += `&page_number=${param.pageNumber - 1}&page_size=${param.pageSize}`;
        url += `&sort_directions=${param.sortDirection}&sort_properties=${param.sortProperty}`;
        url += `&inquiry_start_date=${param.inquiryStartDate}&inquiry_end_date=${param.inquiryEndDate}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          null,
        );
      });
    };

    const startGetCustomerLoanDetails = async (params) => {
      const pageData = await getPagingLoanDetail(params);
      handleLoanDetails(pageData);
      setRequestParams((data) => ({ ...data, loanInquiryType: loanInquiryType }));
    };
    if (buttonState) {
      if (!CM.cfnCommonValidDate(requestParams.inquiryStartDate, requestParams.inquiryEndDate, 24)) {
        setButtonState(false);
        return false;
      }

      startGetCustomerLoanDetails(requestParams);
    }
  }, [buttonState]);

  const handleExcelDownload = async () => {
    const axiosDownload = (data) => {
      let fileName =
        requestParams.loanInquiryType === 'CONTRACT_PROGRESS' ? '대출진행 중 상품.xlsx' : '대출종료 상품.xlsx';

      return new Promise((resolve) => {
        let api = 'web/customers/loans/details/download';

        axiosUtil.cfnAxiosExcelFileDownload(
          api,
          'POST',
          data,
          fileName,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    if (pagingLoanDetails.contents.length === 0) return;

    let recordNos = [];
    pagingLoanDetails.contents.forEach((data) => {
      recordNos.push(data.loan_contract_no);
    });

    let data = {
      uuid: userStore.getUuid(),
      inquiry_type: requestParams.loanInquiryType,
      record_no_list: recordNos,
    };

    await axiosDownload(data);
  };

  const handleDetail = async (id) => {
    // 대출진행중 상품 상세보기
    const axiosProgressDetail = () => {
      return new Promise((resolve) => {
        let api = 'web/customers/loans/details/all-repayments';
        api += `?uuid=${userStore.getUuid()}`;
        api += `&loan_contract_no=${id}`;

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    // 대출종료 상품 상세보기
    const axiosCompleteDetail = () => {
      return new Promise((resolve) => {
        let api = 'web/customers/loans/details/actual-repayments';
        api += `?uuid=${userStore.getUuid()}`;
        api += `&loan_contract_no=${id}`;

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    const data =
      requestParams.loanInquiryType === 'CONTRACT_PROGRESS' ? await axiosProgressDetail() : await axiosCompleteDetail();

    let list = pagingLoanDetails.contents.map((item) =>
      item.loan_contract_no === id ? { ...item, detail_list: data.contents, open: true } : { ...item },
    );
    setPagingLoanDetails({ ...pagingLoanDetails, contents: list });
  };

  return (
    <div className="section" id="detail_01">
      <h4>세부 대출내역</h4>
      <div className="searchArea">
        <div className="tblSearch">
          <form>
            <ul>
              <li className="len12-6">
                <div className="searchList">
                  <span className="tit">조회대상</span>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">조회대상선택</FormLabel>
                    <CF.RadioGroupForm
                      name="loanInquiryType"
                      id="loanInquiryType"
                      value={loanInquiryType}
                      option={loanInquiryTypeOptions}
                      onChange={handleLoanInquiryType}
                      row={true}
                      testId="loanInquiryTypeTest"
                    />
                  </FormControl>
                </div>
              </li>
              <li className="imsi_div"></li>
              <li className="len12-5 ty_new">
                <div className="searchList">
                  <span className="tit">업체명</span>
                  <FormControl variant="filled">
                    <CF.SelectForm
                      arrayOption={p2pCompanyCode}
                      customProps={{ onChange: handleP2pCompnayCode }}
                      name="p2pCompanyCode"
                      testid="p2pCompanyCodeTest"
                      value={requestParams.p2pCompanyCode}
                    />
                  </FormControl>
                </div>
              </li>
              <li className="len12-new">
                <div className="searchList">
                  <span className="tit">상품유형</span>
                  <FormControl variant="filled">
                    <CF.SelectForm
                      value={requestParams.productTypeCode}
                      arrayOption={productTypeCode}
                      customProps={{ onChange: handleProductTypeCode }}
                      name="productTypeCode"
                    />
                  </FormControl>
                </div>
              </li>
              <li className="len12-11 infoDateplus">
                <div className="searchList">
                  <span className="tit">
                    조회기간
                    <br />
                    <span className="font14">(대출계약일 기준)</span>
                  </span>
                  <div className="dateTerm">
                    <div className="datePick">
                      <CF.DatePickerForm
                        id="startDate"
                        value={requestParams.inquiryStartDate}
                        handleChange={handleDate('inquiryStartDate')}
                        customProps={{
                          maxDate: new Date(),
                        }}
                        testId="startDate"
                      />
                      <span className="rowline">~</span>
                      <CF.DatePickerForm
                        id="endDate"
                        value={requestParams.inquiryEndDate}
                        handleChange={handleDate('inquiryEndDate')}
                        customProps={{
                          maxDate: new Date(),
                        }}
                      />
                    </div>
                    <div className="searchTerm ty_small">
                      <Button
                        startIcon={<CheckIcon />}
                        id="recent6Btn"
                        onClick={handleDate('recent6months')}
                        data-testid="recent6Btn"
                      >
                        <span>최근 6개월</span>
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        id="recent12Btn"
                        onClick={handleDate('recent12months')}
                        data-testid="recent12Btn"
                      >
                        <span>최근 12개월</span>
                      </Button>
                      <Button
                        startIcon={<CheckIcon />}
                        id="recent24Btn"
                        onClick={handleDate('recent24months')}
                        data-testid="recent24Btn"
                      >
                        <span>최근 24개월</span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="searchList infoDate">
                  <span className="info blue">
                    ※ 동 정보는 온투업자(폐업업체의 경우 청산업무 수탁기관)가 중앙기록관리기관에 기록한 이용자의
                    대출내역 정보로, 관련 문의사항이 있을 경우 해당 온투업자의 고객센터로 문의하여 주시기 바랍니다.
                  </span>
                </div>
                {loanInquiryType === 'CONTRACT_COMPLETE' && (
                  <div className="searchList infoDate">
                    <span className="hide">검색기간 설정 주의사항</span>
                    <span className="info blue">
                      ※ 대출종료일 기준으로 최근 5년 이내의 대출계약을 조회할 수 있습니다.
                    </span>
                  </div>
                )}
              </li>

              <li className="len12-1 top_align">
                <Button onClick={handleClick} data-testid="searchButtonTest" startIcon={<SearchIcon />}>
                  <span>조회</span>
                </Button>
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div className="tblTop new_ty">
        <div className="topL">
          <span className="listNum">
            {requestParams.loanInquiryType === 'CONTRACT_PROGRESS' && '대출진행 중 상품, '}
            {requestParams.loanInquiryType === 'CONTRACT_COMPLETE' && '대출종료 상품, '}
            전체 <strong>{CM.cfnAddComma(pagination.total)}</strong>건
          </span>
        </div>
        <div className="topR">
          <div className="dwn_wrap">
            <Button className="btn iconExcel" data-testid="excelButtonTest" onClick={handleExcelDownload}>
              엑셀 다운로드
            </Button>
          </div>
          {CM.cfnIsEmpty(pagingLoanDetails) ? (
            <span className="searchTime l_down"></span>
          ) : (
            <span className="searchTime l_down">
              <QueryBuilder></QueryBuilder>
              <span>
                조회시점
                {CM.cfnIsNotEmpty(pagingLoanDetails) && CM.cfnDateFormat(pagingLoanDetails.api_trx_dtm, 'yyyyMMddhhmm')}
              </span>
            </span>
          )}
          <CF.RowPerPageForm
            value={requestParams.pageSize}
            onChange={handleRowPerPage}
            customProps={{ inputProps: { 'data-testid': 'customerInformation-rowPerPage-select' } }}
          />
        </div>
        <div className="sub_txt">
          ※ 표는 좌우로 스크롤&nbsp;
          <TouchAppIcon />
          가능합니다.
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className="tblTypeline" aria-label="spanning table">
          {requestParams.loanInquiryType === 'CONTRACT_PROGRESS' ? (
            <colgroup>
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="9%" />
              <col width="9%" />
              <col width="11%" />
              <col width="9.5%" />
              <col width="11%" />
              <col width="9.5%" />
              <col width="12%" />
              <col />
            </colgroup>
          ) : (
            <colgroup>
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="10%" />
              <col width="10%" />
              <col width="11%" />
              <col width="9.5%" />
              <col width="11%" />
              <col width="9.5%" />
              <col width="9.5%" />
              <col width="4.25%" />
              <col width="4.25%" />
            </colgroup>
          )}
          <TableHead>
            <TableRow>
              <TableCell className="bglightGreen" rowSpan={2}>
                No
              </TableCell>
              <TableCell className="bglightGreen th_center" rowSpan={2}>
                <div>
                  <p>
                    대출
                    <br />
                    계약일
                  </p>
                  {requestParams.sortProperty !== 'loan_contract_conclusion_dtm' ? (
                    <IconButton name="loan_contract_conclusion_dtm" onClick={(e) => createSortHandler(e, 'Y')}>
                      <ArrowDropDownIcon color="disabled" />
                    </IconButton>
                  ) : requestParams.sortDirection === 'DESC' ? (
                    <ArrowDropDownIcon onClick={createSortHandler} />
                  ) : (
                    <ArrowDropUpIcon onClick={createSortHandler} />
                  )}
                </div>
              </TableCell>
              <TableCell className="bglightGreen" rowSpan={2}>
                대출
                <br />
                기간
                <br />
                <div className="tip">
                  <CF.TooltipForm
                    contents={
                      '대출계약 시의 ‘대출시작일~대출종료일’\n※ 연체, 부실 등에 따라 실제 기간과 상이할 수 있음.'
                    }
                  />
                </div>
              </TableCell>
              <TableCell className="bglightGreen" colSpan={3}>
                상품정보
              </TableCell>
              <TableCell className="bglightGreen" colSpan={4}>
                금액정보 (단위 : 원)
              </TableCell>
              {requestParams.loanInquiryType === 'CONTRACT_COMPLETE' && (
                <TableCell className="bglightGreen" rowSpan={2}>
                  상환
                  <br />
                  결과
                  <br />
                  <div className="tip">
                    <CF.TooltipForm
                      contents={
                        '온투업자가 기록한 대출상품의 최종상태값\n(정상상환/부실/기타*)\n* 기타 : 개인회생/파산/구제금융신청 등\n채권의 정상상환이 불가한 경우 등'
                      }
                      position="bottom-end"
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className="bglightGreen" rowSpan={2}>
                상세
                <br />
                보기
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="bglightGreen">
                업체명
                {requestParams.sortProperty !== 'p2p_corporation.name' ? (
                  <IconButton name="p2p_corporation.name" onClick={(e) => createSortHandler(e, 'Y')}>
                    <ArrowDropDownIcon color="disabled" />
                  </IconButton>
                ) : requestParams.sortDirection === 'DESC' ? (
                  <ArrowDropDownIcon onClick={createSortHandler} />
                ) : (
                  <ArrowDropUpIcon onClick={createSortHandler} />
                )}
              </TableCell>
              <TableCell className="bglightGreen">상품명</TableCell>
              <TableCell className="bglightGreen">상품유형</TableCell>
              <TableCell className="bglightGreen">
                대출금액<p className="font11">(원금 기준)</p>
              </TableCell>
              <TableCell className="bglightGreen">
                상환원금
                {requestParams.loanInquiryType === 'CONTRACT_PROGRESS' && (
                  <div className="tip">
                    <CF.TooltipForm
                      contents={'상환된 대출원금\n※ 부실처리 시 부실처리된 금액으로,\n실제 상환원금과 상이할 수 있음.'}
                    />
                  </div>
                )}
                <p className="font11">(원금 기준)</p>
              </TableCell>
              <TableCell className="bglightGreen">
                대출잔액<p className="font11">(원금 기준)</p>
              </TableCell>
              <TableCell className="bglightGreen">
                실제상환
                <br />
                금액
                <div className="tip">
                  <CF.TooltipForm
                    contents={
                      '실제 상환된 대출원금\n+ 실제 상환된 이자 (연체이자 포함)\n※ 원금, 이자, 수수료 등 관련 제비용을\n고려한 실제 상환금액'
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CM.cfnIsNotEmpty(pagingLoanDetails) && pagingLoanDetails.contents.length > 0 ? (
              pagingLoanDetails.contents.map((row, index) => (
                <>
                  <TableRow key={'tablerow' + index} data-testid={'tablerow' + index}>
                    <TableCell>{pagination.rowsPerPage * (requestParams.pageNumber - 1) + index + 1}</TableCell>
                    <TableCell>{CM.cfnDateFormat(row.loan_contract_sign_dtm, 'yyMMdd')}</TableCell>
                    <TableCell className="w_break">
                      {`${CM.cfnDateFormat(row.loan_start_date, 'yyMMdd')} ~${CM.cfnDateFormat(
                        row.loan_end_date,
                        'yyMMdd',
                      )}`}
                    </TableCell>
                    <TableCell>{row.p2p_company_name}</TableCell>
                    <TableCell>{row.goods_name}</TableCell>
                    <TableCell>{row.goods_type_name}</TableCell>
                    <TableCell className="tr">{CM.cfnAddComma(row.loan_amount)}</TableCell>
                    {requestParams.loanInquiryType !== 'CONTRACT_COMPLETE' ||
                    row.additional_stat_name === '상환완료' ? (
                      <>
                        <TableCell className="tr">{CM.cfnAddComma(row.repayment_amount)}</TableCell>
                        <TableCell className="tr">{CM.cfnAddComma(row.loan_balance)}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className="td_empty" colSpan={2}>
                          -
                        </TableCell>
                      </>
                    )}
                    <TableCell className="tr">{CM.cfnAddComma(row.actual_repayment_amount)}</TableCell>
                    {requestParams.loanInquiryType === 'CONTRACT_COMPLETE' && (
                      <TableCell>{row.additional_stat_name}</TableCell>
                    )}
                    <TableCell>
                      <IconButton onClick={() => handleOpen(index)}>
                        {row.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow className="detail_area">
                    <td className="inner_tbl" colSpan={requestParams.loanInquiryType === 'CONTRACT_PROGRESS' ? 11 : 12}>
                      <Collapse in={CM.cfnIsEmpty(row.open) ? false : row.open} timeout="auto" unmountOnExit>
                        <div className="tbl_list_wrap">
                          <div className="tbl_list scrollbar">
                            <table>
                              <colgroup>
                                <col width="8%" />
                                <col width="17%" />
                                <col width="17%" />
                                <col width="17%" />
                                <col width="17%" />
                                <col />
                              </colgroup>
                              {row.detail_list?.length > 0 &&
                                (requestParams.loanInquiryType === 'CONTRACT_PROGRESS' ? (
                                  <>
                                    <thead>
                                      <tr>
                                        <th className="alig_l" colSpan="6">
                                          {'> 예정 상환 내역 / 실제 상환 내역 (단위 : 원)'}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th rowSpan={2}>회차</th>
                                        <th>예정 상환일</th>
                                        <th>예정 상환원금</th>
                                        <th>예정 상환이자</th>
                                        <th colSpan={2}></th>
                                      </tr>
                                      <tr>
                                        <th>실제 상환일</th>
                                        <th>
                                          상환원금
                                          <div className="tip">
                                            <CF.TooltipForm
                                              style={{ marginTop: '0.15rem' }}
                                              contents={
                                                '상환된 대출원금\n※ 부실처리 시 부실처리된 금액으로,\n실제 상환원금과 상이할 수 있음.'
                                              }
                                            />
                                          </div>
                                        </th>
                                        <th>상환이자</th>
                                        <th>연체이자</th>
                                        <th>
                                          실제 상환금액
                                          <div className="tip">
                                            <CF.TooltipForm
                                              style={{ marginTop: '0.15rem' }}
                                              contents={
                                                '실제 상환된 대출원금\n+ 실제 상환된 이자 (연체이자 포함)\n※ 원금, 이자, 수수료 등 관련 제비용을\n고려한 실제 상환금액'
                                              }
                                              position="bottom-end"
                                            />
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tr_2row">
                                      {row.detail_list.map((item) => (
                                        <>
                                          <tr>
                                            <td class="cel_gray" rowspan="2">
                                              {item.repayment_seq}회
                                            </td>
                                            <td>{CM.cfnDateFormat(item.schd_repayment_date, 'yyMMdd')}</td>
                                            <td className="tr">{CM.cfnAddComma(item.schd_repayment_principal)}</td>
                                            <td className="tr">{CM.cfnAddComma(item.schd_repayment_interest)}</td>
                                            <td colSpan={2}></td>
                                          </tr>
                                          {item.real_repayment_date ? (
                                            <tr>
                                              <td>{CM.cfnDateFormat(item.real_repayment_date, 'yyMMdd')}</td>
                                              <td className="tr">{CM.cfnAddComma(item.loan_repayment_principal)}</td>
                                              <td className="tr">{CM.cfnAddComma(item.loan_repayment_interest)}</td>
                                              <td className="tr">{CM.cfnAddComma(item.overdue_interest)}</td>
                                              <td className="tr">{CM.cfnAddComma(item.real_repayment_amount)}</td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td colspan="5"> - </td>
                                            </tr>
                                          )}
                                        </>
                                      ))}
                                    </tbody>
                                  </>
                                ) : (
                                  <>
                                    <thead>
                                      <tr>
                                        <th className="alig_l" colSpan="6">
                                          {'> 실제 상환 내역 (단위 : 원)'}
                                          <div className="tip">
                                            <CF.TooltipForm
                                              style={{ marginTop: '0.15rem' }}
                                              contents={
                                                '※ 상환결과가 ‘부실’, ‘기타’인 경우,\n회차별 상환일 및 실제 상환금액 정보만 제공됨.'
                                              }
                                            />
                                          </div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th>회차</th>
                                        <th>실제 상환일</th>
                                        <th>상환원금</th>
                                        <th>상환이자</th>
                                        <th>연체이자</th>
                                        <th>
                                          실제 상환금액
                                          <div className="tip">
                                            <CF.TooltipForm
                                              style={{ marginTop: '0.15rem' }}
                                              contents={
                                                '실제 상환된 대출원금\n+ 실제 상환된 이자 (연체이자 포함)\n※ 원금, 이자, 수수료 등 관련 제비용을\n고려한 실제 상환금액'
                                              }
                                              position="bottom-end"
                                            />
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {row.detail_list.map((item) => (
                                        <tr>
                                          <td>{item.repayment_seq}회</td>
                                          <td>{CM.cfnDateFormat(item.real_repayment_date, 'yyMMdd')}</td>
                                          {row.additional_stat_name === '상환완료' ? (
                                            <>
                                              <td className="tr">{CM.cfnAddComma(item.loan_repayment_principal)}</td>
                                              <td className="tr">{CM.cfnAddComma(item.loan_repayment_interest)}</td>
                                              <td className="tr">{CM.cfnAddComma(item.overdue_interest)}</td>
                                            </>
                                          ) : (
                                            <td className="td_empty" colSpan={3}>
                                              -
                                            </td>
                                          )}
                                          <td className="tr">{CM.cfnAddComma(item.real_repayment_amount)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </>
                                ))}
                            </table>
                          </div>
                        </div>
                      </Collapse>
                    </td>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow data-testid={'tablerow'} key={'tablerow0'}>
                <TableCell colSpan={requestParams.loanInquiryType === 'CONTRACT_PROGRESS' ? 11 : 12}>
                  조회된 내용이 없습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="paging" style={mobileStyle}>
        <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} testId="" />
      </div>
    </div>
  );
};

export default LoanDetailsHistoryC;
