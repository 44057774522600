/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 대고객용 faq 목록 조회
 */

import {
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from '@material-ui/icons/Search';
import { Fragment, useEffect, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CF from '../../../common/template/ComponentForm';
import * as CM from '../../../common/util/common';

//검색조건
const searchType = [
  { value: 'ALL', label: '제목 + 내용' },
  { value: 'TITLE', label: '제목' },
  { value: 'CONTENTS', label: '내용' },
];

//문의유형
const questionType = [
  { value: 'ALL', label: '전체' },
  { value: 'SYSTEM', label: '제도' },
  { value: 'SERVICE', label: '서비스' },
  { value: 'ETC', label: '기타' },
];

//FAQ 검색 파라미터
const DEFAULT_HOOKS = {
  requestParams: {
    questionType: questionType[0].value, //문의유형
    searchType: searchType[0].value, //검색조건
    searchKeyword: '', //검색어
    sortDirections: 'DESC', //정렬조건
    sortProperties: 'registration_dtm', //정렬필드
    pageNumber: 1, //페이지 요청 번호
    pageSize: 10, //페이지 사이즈
  },
};

const FaqM = (props) => {
  let seqId = CM.cfnIsNotEmpty(props.location.state) ? props.location.state.seqId : ''; //FAQ 일련번호(공지사항에서 FAQ를 지정하고 접근한 경우 자동 펼치기)
  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams); //FAQ 검색 파라미터
  const [buttonState, setButtonState] = useState(true); //조회버튼
  const [pagination, setPagination] = useState(CM.cfnSetPagination()); //페이징 처리
  const [faqContents, setFaqContents] = useState([]); //FAQ 목록

  //검색 조건 변경 handler
  const handleChange = (e) => setRequestParams((data) => ({ ...data, [e.target.name]: e.target.value }));

  //검색버튼 클릭 handler
  const handleClick = () => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: 1,
    }));
    setButtonState(true);
  };

  //검색어 입력창 엔터키 이벤트
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  //페이지 숫자클릭 이벤트함수
  const handleOffsetChange = (e, offset, page) => {
    setRequestParams((data) => ({
      ...data,
      pageNumber: page,
    }));
    setButtonState(true);
  };

  //문의유형별 class 설정
  const questionTypeClass = (questionType) => {
    let className = '';
    if (questionType === '제도') {
      className = 'green';
    } else if (questionType === '서비스') {
      className = 'purple';
    } else if (questionType === '기타') {
      className = 'grey';
    }
    return className;
  };

  //페이지 상태 세팅
  const handlePaginationChange = (param) => {
    setPagination(CM.cfnSetPagination(param));
  };

  //FAQ 답변 펼치기 이벤트
  const setOpen = (seqId, open) => {
    let contents = faqContents.map((content) =>
      content.seq_id === seqId ? { ...content, open: !open } : { ...content, open: false },
    );
    setFaqContents(contents);
  };

  //FAQ 목록 조회
  useEffect(() => {
    userStore.handleNaviTrigger(); //네비 현행화
    //FAQ 목록 조회 결과 세팅
    const getFaqList = (param) => {
      const resolve = (objStatus, response) => {
        handleFaqList(response);
      };
      let url = 'web/common/faq/search?';
      url += `question_type=${param.questionType}`;
      url += `&search_keyword=${param.searchKeyword}&search_type=${param.searchType}`;
      url += `&page_number=${param.pageNumber - 1}&page_size=${param.pageSize}`;
      url += `&sort_directions=${param.sortDirections}&sort_properties=${param.sortProperties}`;
      axiosUtil.cfnAxiosApi(url, 'GET', null, null, resolve, (objStatus, response) => {
        CM.cfnAlert(response.rsp_message);
        resolve();
      });
    };
    const handleFaqList = (response) => {
      let contents = [];
      if (CM.cfnIsNotEmpty(response.contents)) {
        if (CM.cfnIsEmpty(seqId)) {
          contents = response.contents.map((content) => ({ ...content, open: false }));
        } else {
          contents = response.contents.map((content) =>
            content.seq_id === seqId ? { ...content, open: true } : { ...content, open: false },
          );
        }
      }
      setFaqContents(contents);
      handlePaginationChange(response);
      setButtonState(false);
    };
    if (buttonState) {
      getFaqList(requestParams);
    }
  }, [buttonState, requestParams]);

  //문의유형 SelectBox 세팅
  function QuestionTypeSelectBox() {
    const iconComponent = (props) => {
      return <ExpandMoreIcon className={props.className + ' '} />;
    };
    return (
      <FormControl variant="filled">
        <CF.SelectForm
          arrayOption={questionType}
          customProps={{ onChange: handleChange }}
          testid="questionTypeTest"
          name="questionType"
          value={requestParams.questionType}
          IconComponent={iconComponent}
        />
      </FormControl>
    );
  }

  //검색조건 SlectBox
  function SearchTypeSelectBox() {
    const iconComponent = (props) => {
      return <ExpandMoreIcon className={props.className + ' '} />;
    };
    return (
      <FormControl variant="filled">
        <CF.SelectForm
          arrayOption={searchType}
          customProps={{ onChange: handleChange }}
          testid="searchTypeTest"
          name="searchType"
          value={requestParams.searchType}
          IconComponent={iconComponent}
        />
      </FormControl>
    );
  }

  //컨텐츠 영역_테이블 위 영역(목록개수, 조회시점, 조회건수)
  function TblTop() {
    return (
      <div className="tblTop">
        <CF.TotalCountForm totalElements={pagination} />
      </div>
    );
  }

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>FAQ</h4>
          {/* 검색영역 */}
          <div className="searchArea">
            <div className="tblSearch">
              {/* 양식 자동 제출 방지 */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <ul>
                  <li className="len0">
                    <div className="searchList mr40">
                      <span className="tit">문의유형</span>
                      <QuestionTypeSelectBox></QuestionTypeSelectBox>
                    </div>
                    <span className="hide">키워드 검색</span>
                    <div className="searchList">
                      <SearchTypeSelectBox></SearchTypeSelectBox>
                    </div>
                    <div className="searchList">
                      <div className="inputTxt w300" noValidate autoComplete="off">
                        <TextField
                          id="basicinput"
                          variant="outlined"
                          name="searchKeyword"
                          onChange={handleChange}
                          value={requestParams.searchKeyword}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={handleClick}
                      data-testid="searchBtn"
                    >
                      조회
                    </Button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
          <TblTop></TblTop>
          {CM.cfnIsEmpty(faqContents) ? (
            <TableContainer>
              <Table role="grid" className="tblTypenohead listFaq" aria-label="FAQ 목록">
                <colgroup>
                  <col width="100%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>질문</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <p className="resultNo">조회된 내용이 없습니다.</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer>
              <Table role="grid" className="tblTypenohead listFaq" aria-label="FAQ 목록">
                <colgroup>
                  <col width="100%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>질문</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {faqContents.map((row, index) => (
                    <Fragment key={'fragment' + index}>
                      <TableRow data-testid={'answerTableRow' + index}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="title clickmore"
                          onClick={() => setOpen(row.seq_id, row.open)}
                        >
                          <span className="question">
                            Q.<span className="hide">문의유형</span>
                            <span className={'newsMark ' + questionTypeClass(row.question_type_name)}>
                              {row.question_type_name}
                            </span>
                          </span>
                          {row.question}
                          <span className="hide">답변보기</span>
                          {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="moreDetailarea faq">
                          <Collapse in={row.open} timeout="auto" unmountOnExit>
                            <div
                              className="moreDetail"
                              dangerouslySetInnerHTML={{ __html: row.answer.replace(new RegExp('\n', 'g'), '<br/>') }}
                            ></div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div className="paging">
            <Fragment>
              <CF.PaginationForm pagination={pagination} onClick={handleOffsetChange} />
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqM;
