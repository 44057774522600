/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 업무정보 조회 - 기록내역 조회
 * 원리금수취권 양도양수 기록 - 양도양수계약
 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Fragment, useEffect } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const RecordHistoryPniTransferS = (props) => {
  const effect = props;
  const currentTab = effect.currentTab;
  // const [pniTransfer, setPniTransfer] = useState([]); //양도양수계약
  // //원리금수취권양도양수연관기록
  // const [relationPniContract, setRelationPniContract] = useState({ "list": [] }); //양도양수계약

  // const [pniPayment, setPniPayment] = useState({ "list": [] }); //원리금지급정보
  // const [pniPaymentSchedule, setPniPaymentSchedule] = useState({ "contract_payments_schedule_list": [{}] }); //원리금지급예정정보

  let msg = []; //오류 메시지

  //화면로딩 시 실행
  useEffect(() => {
    //양도양수계약 handler
    const handlePniTransfer = (paramRelationPniContract, paramPniPaymentSchedule, paramPniPayment) => {
      let tmpTab = CM.cfnCopyObject(effect.tab);
      let tmpThisTab = tmpTab[currentTab];
      let tmp = tmpThisTab.contents;
      // if (CM.cfnIsNotEmpty(resultData) && resultData !== effect.tab[currentTab].contents.basic) {
      //     props.setSearchTime.call(null, resultData.api_trx_dtm);
      //     // setPniTransfer(resultData);
      //     tmpRp.view = true;
      //     tmpRp.basic = resultData
      // } else {
      //     tmpRp.view = false;
      // }
      if (
        CM.cfnIsNotEmpty(paramRelationPniContract) &&
        paramRelationPniContract !== effect.tab[currentTab].contents.relation
      ) {
        // setRelationPniContract(props.sortPniRelation(paramRelationPniContract));
        tmp.relation = props.sortPniRelation(paramRelationPniContract);
      }
      if (
        CM.cfnIsNotEmpty(paramPniPaymentSchedule) &&
        paramPniPaymentSchedule !== effect.tab[currentTab].contents.pniPaymentSchedule
      ) {
        paramPniPaymentSchedule.contract_payments_schedule_list =
          paramPniPaymentSchedule.contract_payments_schedule_list.reverse();
        // setPniPaymentSchedule(paramPniPaymentSchedule);
        tmp.pniPaymentSchedule = paramPniPaymentSchedule;
      }
      if (CM.cfnIsNotEmpty(paramPniPayment) && paramPniPayment !== effect.tab[currentTab].contents.pniPayment) {
        paramPniPayment.list = paramPniPayment.list.reverse();
        // setPniPayment(paramPniPayment);
        tmp.pniPayment = paramPniPayment;
      }
      effect.setTab(tmpTab);
    };
    //양도양수계약 조회
    const axiosPniTransfer = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/transfers/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M007);
            resolve(null);
          },
        );
      });
    };

    //연관기록내역 조회
    const axiosRelation = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/transfers/relations/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M008);
            resolve(null);
          },
        );
      });
    };

    // //양수자 원리금지급정보 / 예정정보 handler
    // const handlePniPayment = (paramPniPaymentSchedule, paramPniPayment) => {
    //     if (CM.cfnIsNotEmpty(paramPniPaymentSchedule) && paramPniPaymentSchedule !== pniPaymentSchedule) {
    //         paramPniPaymentSchedule.contract_payments_schedule_list = paramPniPaymentSchedule.contract_payments_schedule_list.reverse();
    //         setPniPaymentSchedule(paramPniPaymentSchedule);
    //     }
    //     if (CM.cfnIsNotEmpty(paramPniPayment) && paramPniPayment !== pniPayment) {
    //         paramPniPayment.list = paramPniPayment.list.reverse();
    //         setPniPayment(paramPniPayment);
    //     }
    // };
    //양수자 원리금지급예정정보 조회
    const axiosPniPaymentSchedules = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/transfers/pni-payments/schedules/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M009);
            resolve(null);
          },
        );
      });
    };

    //양수자 원리금지급정보 조회
    const axiosPniPayment = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/transfers/pni-payments/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M010);
            resolve(null);
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    const startAxios = async () => {
      // const resultData = await axiosPniTransfer(); //양도양수계약
      //투자상품(INVEST_PRODUCT), 투자계약(), 양도양수계약()
      const relationPniContract = await axiosRelation(); //연관기록내역-양도양수계약
      const pniPaymentSchedule = await axiosPniPaymentSchedules(); //양수자 원리금지급예정정보
      const pniPayment = await axiosPniPayment(); //양수자 원리금지급정보
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handlePniTransfer(relationPniContract, pniPaymentSchedule, pniPayment);
      // handlePniPayment(pniPaymentSchedule, pniPayment);
    };

    if (effect.tab[currentTab].contents.view && CM.cfnIsEmpty(effect.tab[currentTab].contents.relation.list))
      startAxios();
  }, [effect.currentTab, effect.setCurrentTab]);

  return (
    <div className="inner">
      <h6 className="hide">조회결과 상세내용</h6>
      <div className="contBox assignment">
        <div className="contBox_item">
          <p className="middleTit">기록관리 ID</p>
          <div className="itemBox corpInfo histidImg">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="name">{effect.tab[currentTab].contents.basic.record_no}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox assignment">
        <div className="contBox_item flex_g3">
          <p className="middleTit">양도인, 양수인 정보</p>
          <div className="itemBox borrower">
            <div className="infoMain">
              <ul>
                <li>
                  <span className="title w125">양도인 성명</span>
                  <span>{effect.tab[currentTab].contents.basic.seller_name}</span>
                </li>
                <li className="w50p pt8">
                  <span className="title">양도인 투자자 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.seller_type_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">고유식별번호</span>
                  <span>
                    {CM.cfnIdentificationNoFormat(String(effect.tab[currentTab].contents.basic.seller_identity_no))}
                  </span>
                </li>
                <li>
                  <span className="title w125">양수인 성명</span>
                  <span>{effect.tab[currentTab].contents.basic.buyer_name}</span>
                </li>
                <li className="w50p pt8">
                  <span className="title">양수인 투자자 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.buyer_type_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">고유식별번호</span>
                  <span>
                    {CM.cfnIdentificationNoFormat(String(effect.tab[currentTab].contents.basic.buyer_identity_no))}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">상세조회내역</p>
          <div className="itemBox">
            <div className="itemArea">
              <p className="smallTit">양도 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">양도상품유형</span>
                  <span>{effect.tab[currentTab].contents.basic.product_type_name}</span>
                </li>
                <li>
                  <span className="tit">양도계약 체결일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.transfer_contract_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
                <li>
                  <span className="tit">판매자 총 양도금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.seller_total_transfer_amount) ||
                    effect.tab[currentTab].contents.basic.seller_total_transfer_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.seller_total_transfer_amount) + '원'}
                  </span>
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">양수 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">구매자 투자금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.buyer_investment_amount) ||
                    effect.tab[currentTab].contents.basic.buyer_investment_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.buyer_investment_amount) + '원'}
                  </span>
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">양수자 지급 예정 정보</p>
              <div className="tblTop">
                <div className="topL">
                  <span className="listNum">
                    전체
                    <strong>
                      {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.pniPaymentSchedule)
                        ? effect.tab[currentTab].contents.pniPaymentSchedule.contract_payments_schedule_list.length
                        : 0}
                    </strong>
                    건
                  </span>
                </div>
              </div>

              <TableContainer className="maxH520 mb30 scrollY">
                <Table stickyHeader aria-label="순번, 기록일시, 상태정보 갱신내역으로 이루어진 이력정보 목록">
                  <colgroup>
                    <col width="12%" />
                    <col width="22%" />
                    <col width="22%" />
                    <col width="22%" />
                    <col width="22%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>지급회차</TableCell>
                      <TableCell>지급예정일</TableCell>
                      <TableCell>원금</TableCell>
                      <TableCell>이자</TableCell>
                      <TableCell>수수료</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.pniPaymentSchedule) ? (
                      <TableRow key={'tableRow0'}>
                        <TableCell colSpan="5">조회된 내용이 없습니다.</TableCell>
                      </TableRow>
                    ) : (
                      effect.tab[currentTab].contents.pniPaymentSchedule.contract_payments_schedule_list.map(
                        (row, index) => (
                          <TableRow key={'tableRow' + index}>
                            <TableCell>{row.repayment_seqno}</TableCell>
                            <TableCell>{CM.cfnDateFormat(row.repayment_schd_date, 'yyyyMMdd')}</TableCell>
                            <TableCell>
                              {CM.cfnIsEmpty(row.principal) || row.principal === 'null'
                                ? ''
                                : CM.cfnAddComma(row.principal) + '원'}
                            </TableCell>
                            <TableCell>
                              {CM.cfnIsEmpty(row.interest) || row.interest === 'null'
                                ? ''
                                : CM.cfnAddComma(row.interest) + '원'}
                            </TableCell>
                            <TableCell>
                              {CM.cfnIsEmpty(row.fee) || row.fee === 'null' ? '' : CM.cfnAddComma(row.fee) + '원'}
                            </TableCell>
                          </TableRow>
                        ),
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="itemArea">
              <p className="smallTit">양수자 지급 정보</p>
              <div className="tblTop">
                <div className="topL">
                  <span className="listNum">
                    전체
                    <strong>
                      {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.pniPayment)
                        ? effect.tab[currentTab].contents.pniPayment.list.length
                        : 0}
                    </strong>
                    건
                  </span>
                </div>
              </div>
              <TableContainer className="maxH520 mb30 scrollY">
                <Table stickyHeader aria-label="순번, 기록일시, 상태정보 갱신내역으로 이루어진 이력정보 목록">
                  <colgroup>
                    <col width="12%" />
                    <col width="40%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>지급회차</TableCell>
                      <TableCell>원리금지급 기록관리 ID</TableCell>
                      <TableCell>최초등록일</TableCell>
                      <TableCell>실제지급일</TableCell>
                      <TableCell>지급원금</TableCell>
                      <TableCell>실제지급금액</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.pniPayment) ? (
                      <TableRow key={'tableRow0'}>
                        <TableCell colSpan="6">조회된 내용이 없습니다.</TableCell>
                      </TableRow>
                    ) : (
                      effect.tab[currentTab].contents.pniPayment.list.map((row, index) => (
                        <TableRow key={'tableRow' + index}>
                          <TableCell>{row.pni_payment_seqno}</TableCell>
                          <TableCell>
                            <span onClick={props.handleLink}>
                              <p className="txtLine" name={'investments,연계투자,3,원리금지급,' + row.record_no}>
                                {row.record_no}
                              </p>
                            </span>
                          </TableCell>
                          <TableCell>{CM.cfnDateFormat(row.first_registration_dtm, 'yyyyMMdd')}</TableCell>
                          <TableCell>{CM.cfnDateFormat(row.real_pni_payment_date, 'yyyyMMdd')}</TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(row.real_principal) || row.real_principal === 'null'
                              ? ''
                              : CM.cfnAddComma(row.real_principal) + '원'}
                          </TableCell>
                          <TableCell>
                            {CM.cfnIsEmpty(row.real_pni_payment_amount) || row.real_pni_payment_amount === 'null'
                              ? ''
                              : CM.cfnAddComma(row.real_pni_payment_amount) + '원'}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="itemArea">
              <p className="smallTit">이력 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">최초등록일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.first_registration_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">연관기록내역</p>
          <TableContainer>
            <Table
              className="tblTypeline"
              aria-label="대출신청, 투자상품, 대출계약, 대출상환으로 이루어진 연관 기록내역 목록"
            >
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="33%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>[참고]투자상품</TableCell>
                  {effect.tab[currentTab].contents.relation.list.map((row, index) => (
                    <Fragment key={index}>
                      {row.record_type === 'INVEST_CONTRACT' ? <TableCell>[참고](양도된)투자계약</TableCell> : null}
                      {row.record_type === 'PREVIOUS_TRANSFER_CONTRACT' ? (
                        <TableCell>[참고](양도된)양도양수계약 </TableCell>
                      ) : null}
                    </Fragment>
                  ))}
                  <TableCell>양도양수계약</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {effect.tab[currentTab].contents.relation.list.map((row, index) => {
                    if (row.record_type === 'INVEST_PRODUCT') {
                      return (
                        <TableCell key="investProduct" data-testid="renderTest">
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'INVEST_CONTRACT') {
                      return (
                        <TableCell key="investContract">
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'investments,연계투자,2,투자계약,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'PREVIOUS_TRANSFER_CONTRACT') {
                      return (
                        <TableCell key="investContract" rowSpan={effect.tab[currentTab].contents.relation.list.length}>
                          <span onClick={props.handleLink}>
                            <p
                              className="txtLine"
                              name={'transfers,원리금수취권 양도양수,1,양도양수계약,' + row.record_no}
                            >
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'TRANSFER_CONTRACT') {
                      return (
                        <TableCell
                          key="transferContract"
                          rowSpan={effect.tab[currentTab].contents.relation.list.length}
                          className="bglightSky"
                        >
                          <p>{row.record_no}</p>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    }
                  })}
                  {effect.tab[currentTab].contents.relation.list.length < 3
                    ? [...Array(3 - effect.tab[currentTab].contents.relation.list.length)].map((n, index) => {
                        return <TableCell key={index} />;
                      })
                    : null}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RecordHistoryPniTransferS;
