import { React, useState } from 'react';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const Page404Customer = () => (
  <div className="customer">
    <div className="errorPage">
      <div className="errorInfo">
        <h1 className="hide">ERROR 404</h1>
        <p className="tit">
          페이지를 <span className="lightBlue">찾을 수 없습니다.</span>
        </p>
        <p>
          페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.
          <br />
          입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
        </p>
      </div>
      <div className="errorBtn">
        <Link to="/" className="bgGrey">
          이전 화면
        </Link>
        <Link to="/">메인으로</Link>
      </div>
    </div>
  </div>
);

export default Page404Customer;
