/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 공통 유틸 컴포넌트
 */
import axios from 'axios';
import { de } from 'date-fns/locale';
import { toJS } from 'mobx';
import { rootStore } from '../../../stores/rootStore';
import { openAlert } from '../template/AlertForm';
import { openConfirm } from '../template/ConfirmForm';

/*
 * @desc    object deep copy를 처리하는 함수
 * @param   { object }
 * @return  { object }
 */
export const cfnCopyObject = (obj) => {
  let clone = {};

  if (Array.isArray(obj)) {
    clone = obj.map((val) => {
      return cfnCopyObject(val);
    });
  } else if (typeof obj === 'object' && obj !== null) {
    if (cfnValidDate(obj)) {
      clone = new Date(obj.getTime());
    } else {
      for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
          clone[i] = cfnCopyObject(obj[i]);
        }
      }
    }
  } else {
    clone = obj;
  }
  return clone;
};

/*
 * @desc    값이 없는지 확인하는 함수
 * @param   { object }
 * @return  { boolean }
 */
export const cfnIsEmpty = (val) => {
  if (val === null) return true;
  if (typeof val === 'undefined') return true;
  if (typeof val === 'string' && val.trim() === '') return true;
  if (Array.isArray(val) && val.length < 1) return true;
  if (
    typeof val === 'object' &&
    val.constructor.name === 'Object' &&
    Object.keys(val).length < 1 &&
    Object.getOwnPropertyNames(val) < 1
  )
    return true;
  if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true;

  return false;
};

/*
 * @desc    값이 있는지 확인하는 함수
 * @param   { object }
 * @return  { boolean }
 */
export const cfnIsNotEmpty = (val) => {
  if (val === null) return false;
  if (typeof val === 'undefined') return false;
  if (typeof val === 'string' && val.trim() === '') return false;
  if (Array.isArray(val) && val.length < 1) return false;
  if (
    typeof val === 'object' &&
    val.constructor.name === 'Object' &&
    Object.keys(val).length < 1 &&
    Object.getOwnPropertyNames(val).length < 1
  )
    return false;
  if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return false;

  return true;
};

/*
 * @desc    공통 alert 창을 호출하는 함수
 * @param   message: 호출될 내용
 */
export const cfnAlert = (obj, callback) => {
  let messageString = typeof obj === 'string' ? obj : String(obj);
  openAlert(messageString, callback);
};

/*
 * @desc    공통 confirm 창을 호출하는 함수
 * @param   message: 호출될 내용
 */
export const cfnConfirm = (obj, callback, bntText) => {
  let messageString = typeof obj === 'string' ? obj : String(obj);
  openConfirm(messageString, callback, bntText);
};

/*
 * @desc    유효한 날짜인지 검사하는 함수
 * @param   { date: 날짜 }
 * @return  { boolean }
 */
export const cfnValidDate = (date) => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

/*
 * @desc    Date 객체를 String 객체로 변환하는 함수
 * @param   { Date }
 * @return  { String }
 */
export const cfnConvertDateToString = (date) => {
  let obj = date;
  //파라미터 객체가 Date 타입일 때만 변환
  if (cfnValidDate(date)) {
    const year = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();

    obj = `${year}${mm[1] ? mm : 0 + mm[0]}${dd[1] ? dd : 0 + dd[0]}`;
  }

  return obj;
};

/*
 * @desc    Date 객체를 String 객체로 변환하는 함수
 * @param   { Date }
 * @return  { String }
 */
export const cfnConvertDateTimeToString = (date) => {
  let obj = date;
  //파라미터 객체가 Date 타입일 때만 변환
  if (cfnValidDate(date)) {
    const year = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    const hh = date.getHours().toString();
    const mi = date.getMinutes().toString();
    const ss = date.getSeconds().toString();

    obj = `${year}${mm[1] ? mm : 0 + mm[0]}${dd[1] ? dd : 0 + dd[0]}${hh[1] ? hh : 0 + hh[0]}${mi[1] ? mi : 0 + mi[0]}${
      ss[1] ? ss : 0 + ss[0]
    }`;
  }

  return obj;
};

/*
 * @desc    String 객체(yyyyMMdd 포맷)를 Date 객체로 변환하는 함수
 * @param   { String }
 * @return  { Date }
 */
export const cfnConvertStringToDate = (stringDate) => {
  let obj = stringDate;

  //파라미터 객체가 Date 타입이 아니면 변환하지 않음
  if (cfnIsNotEmpty(stringDate) && !stringDate.getFullYear) {
    if (stringDate.length === 6) {
      //yyMMdd 포맷일 때
      const now = new Date().getFullYear().toString().substr(2, 2);
      const yy = stringDate.substr(0, 2) > now ? '19' : '20';
      obj = new Date(`${yy}${stringDate.substr(0, 2)}/${stringDate.substr(2, 2)}/${stringDate.substr(4, 2)}`);
    } else if (stringDate.length === 8) {
      //yyyyMMdd 포맷일 때
      obj = new Date(`${stringDate.substr(0, 4)}/${stringDate.substr(4, 2)}/${stringDate.substr(6, 2)}`);
    }
  }
  return obj;
};

/*
 * @desc    날짜 포맷을 yyyy-MM-dd hh:mm:ss 형태로 변경하는 함수
 * @param   { string }
 * @return  { string }
 */
export const cfnDateFormat = (date, format) => {
  if (cfnIsEmpty(date)) {
    return date;
  }

  if (typeof date === 'number') {
    date = String(date);
  }

  // return 날짜
  let year = '';
  let month = '';
  let day = '';
  let hour = '';
  let minute = '';
  let second = '';

  // 기본 포맷 설정
  if (cfnIsEmpty(format)) {
    switch (date.length) {
      case 4:
        format = 'MMdd';
        break;
      case 6:
        format = 'yyMMdd';
        break;
      case 8:
        format = 'yyyyMMdd';
        break;
      case 12:
        format = 'yyyyMMddhhmm';
        break;
      case 14:
        format = 'yyyyMMddhhmmss';
        break;
      default:
        break;
    }
  }

  // 포맷에 해당하는 결과값 return
  switch (format) {
    case 'yyMM':
      return `${date.substr(2, 2)}.${date.substr(4, 2)}`;
    case 'MMdd':
      return `${date.substr(0, 2)}.${date.substr(2, 2)}`;
    case 'yyyyMM':
      year = date.substr(0, 4);

      // 예외 처리
      if (year === '9999') {
        return '';
      }

      month = date.substr(4, 2);
      return `${year}.${month}`;
    case 'yyMMdd':
      year = date.substr(2, 2);
      month = date.substr(4, 2);
      day = date.substr(6, 2);
      return `${year}.${month}.${day}`;
    case 'yyyyMMdd':
      year = date.substr(0, 4);
      month = date.substr(4, 2);
      day = date.substr(6, 2);
      return `${year}.${month}.${day}`;
    case 'yyyyMMddhhmm':
      year = date.substr(0, 4);
      month = date.substr(4, 2);
      day = date.substr(6, 2);
      hour = date.substr(8, 2);
      minute = date.substr(10, 2);
      return `${year}.${month}.${day} ${hour}:${minute}`;
    case 'yyyyMMddhhmmss':
      year = date.substr(0, 4);
      month = date.substr(4, 2);
      day = date.substr(6, 2);
      hour = date.substr(8, 2);
      minute = date.substr(10, 2);
      second = date.substr(12, 2);
      return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
    default:
      break;
  }
};

/*
 * @desc    전화번호에 대쉬 기호(-)를 삽입하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnAddtDashToPhoneNumber = (string) => {
  let number;

  if (cfnIsNotEmpty(string)) {
    if (string.indexOf('02') === 0) {
      if (string.length === 9) {
        number = string.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      } else {
        number = string.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      switch (string.length) {
        case 11:
          number = string.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
          break;
        case 8:
          number = string.replace(/(\d{4})(\d{4})/, '$1-$2');
          break;
        default:
          number = string.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          break;
      }
    }
  } else {
    number = null;
  }

  return number;
};

/*
 * @desc    생년월일 / 사업자번호를 format에 맞게 수정해서 리턴하는 함수
 * @param   { String }
 * @return  { String }
 */
export const cfnIdentificationNoFormat = (data, type) => {
  if (cfnIsEmpty(data)) return data;

  // 사업자 번호일 때
  if (data.length === 10) return data.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');

  // 주민등록 번호 앞자리만 있을 때
  if (data.length === 6) return cfnDateFormat(data);

  if (data.length === 7) return cfnDateFormat(data.substr(0, 6));

  //주민번호 뒷자리 보이게(999999-*******)
  if (data.length === 13 && type === 'show') return data.replace(/(.{6})/, '$1-');

  //주민번호 뒷자리 다 안보이게(999999-*******)
  if (data.length === 13 && type === 'allMasking')
    return data.replace(/(.{6})(.{1})/, '$1-$2').replace(/.{6}$/, '******');

  //주민번호 뒷자리 처음빼고 안보이게(999999-1******)
  if (data.length === 13) return data.replace(/(.{6})(.{1})/, '$1-$2').replace(/.{6}$/, '******');

  // 휴대폰 번호일 때
  if (data.length === 11) return cfnAddtDashToPhoneNumber(data);

  return data;
};

/*
 * @desc    숫자 포맷을 천 단위 콤마 형태로 변경하는 함수
 * @param   { string || number }
 * @return  { string }
 */
export const cfnAddComma = (param) => {
  //undefined 또는 null일 경우 0으로 처리
  if (param === undefined || param === null) param = 0;
  return Number(param).toLocaleString('en').split('.')[0];
};

/*
 * @desc    천원 단위로 변경 후 콤마 형태로 변경하는 함수
 * @param   { string || number }
 * @return  { string }
 */
export const cfnAddCommaThousand = (param) => {
  //undefined 또는 null일 경우 0으로 처리
  if (param === undefined || param === null) param = 0;
  if (typeof param !== 'string') {
    param = param.toString();
  }

  param = param.split('.')[0];
  param = param.substring(0, param.length - 3);
  return Number(param).toLocaleString('en');
};

/*
 * @desc    오늘 날짜를 구하는 함수
 * @param   N/A
 * @return  { string }
 */
export const cfnGetDate = () => {
  const today = new Date();

  let date = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  if (date < 10) {
    date = `0${date}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${year}${month}${date}`;
};

/*
 * @desc    오늘 날짜,시간을 구하는 함수
 * @param   N/A
 * @return  { string }
 */
export const cfnGetDateTime = () => {
  const today = new Date();

  let date = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  let hour = today.getHours();
  let minutes = today.getMinutes();
  if (date < 10) {
    date = `0${date}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  if (hour < 10) {
    hour = `0${hour}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${year}${month}${date}${hour}${minutes}`;
};

/*
 * @desc    생년월일 포맷을 yy-MM-dd(기본) 또는 yyyy-MM-dd 형태로 변경하는 함수
 * @param   { stirng }
 * @return  { string }
 */
export const cfnBirthdayFormat = (param) => {
  let year = '';
  let month = '';
  let day = '';

  // yy-MM-dd 일 때
  if (param.length === 6) {
    year = param.substr(0, 2);
    month = param.substr(2, 2);
    day = param.substr(4, 2);
  } else if (param.length === 8) {
    year = param.substr(0, 4);
    month = param.substr(4, 2);
    day = param.substr(6, 2);
  } else {
    return param;
  }

  return `${year}-${month}-${day}`;
};

/*
 * @desc    오늘 날짜를 기준으로 특정 날짜를 구하는 함수
 * @param   {
 *            dateType: "year, month, date"
 *            number: 오늘 날짜로부터 + 또는 - 할 날짜
 *            dataType: "string, date" default "string"
 *          }
 * @return  { string }
 */
export const cfnGetCustomDate = (dateType, number, dataType) => {
  let customDate = new Date();
  let lastCustomDate;
  let beforeDate = customDate.getDate();

  switch (dateType) {
    case 'year':
      customDate.setDate(1);
      customDate.setFullYear(customDate.getFullYear() + number);
      break;
    case 'month':
      customDate.setDate(1);
      customDate.setMonth(customDate.getMonth() + number);
      break;
    case 'date':
      customDate.setDate(customDate.getDate() + number);
      break;
    default:
      break;
  }

  if (dateType === 'year' || dateType === 'month') {
    // +- 한 날짜가 존재하지 않는 경우, +-한 달의 다음달의 1일을 리턴
    lastCustomDate = new Date(customDate.getFullYear(), customDate.getMonth() + 1, 0).getDate();
    if (lastCustomDate < beforeDate) customDate.setMonth(customDate.getMonth() + 1);
    else {
      customDate.setDate(beforeDate);
      customDate = new Date(customDate.getTime() + 86400000);
    }
  }

  let date = customDate.getDate();
  let month = customDate.getMonth() + 1;
  let year = customDate.getFullYear();

  if (date < 10) {
    date = `0${date}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  switch (dataType) {
    case 'date':
      return new Date(`${year}/${month}/${date}`);
    case 'string':
    default:
      return `${year}${month}${date}`;
  }
};

/*
 * @desc    오늘 날짜와 시간을 기준으로 특정 날짜, 시간을 구하는 함수
 * @param   {
 *            dateType: "year, month, date"
 *            number: 오늘 날짜로부터 + 또는 - 할 날짜
 *            dataType: "string, date" default "string"
 *          }
 * @return  { string }
 */
export const cfnGetCustomDateTime = (dateType, number, dataType) => {
  let customDate = new Date();
  let lastCustomDate;
  let beforeDate = customDate.getDate();

  switch (dateType) {
    case 'year':
      customDate.setDate(1);
      customDate.setFullYear(customDate.getFullYear() + number);
      break;
    case 'month':
      customDate.setDate(1);
      customDate.setMonth(customDate.getMonth() + number);
      break;
    case 'date':
      customDate.setDate(customDate.getDate() + number);
      break;
    case 'hour':
      customDate.setHours(customDate.getHours() + number);
      break;
    case 'minutes':
      customDate.setMinutes(customDate.getMinutes() + number);
      break;
    default:
      break;
  }

  if (dateType === 'year' || dateType === 'month') {
    // +- 한 날짜가 존재하지 않는 경우, +-한 달의 다음달의 1일을 리턴
    lastCustomDate = new Date(customDate.getFullYear(), customDate.getMonth() + 1, 0).getDate();
    if (lastCustomDate < beforeDate) customDate.setMonth(customDate.getMonth() + 1);
    else {
      customDate.setDate(beforeDate);
      customDate = new Date(customDate.getTime() + 86400000);
    }
  }

  let date = customDate.getDate();
  let month = customDate.getMonth() + 1;
  let year = customDate.getFullYear();
  let hour = customDate.getHours();
  let minutes = customDate.getMinutes();

  if (date < 10) {
    date = `0${date}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  if (hour < 10) {
    hour = `0${hour}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  switch (dataType) {
    case 'date':
      return new Date(`${year}/${month}/${date}/${hour}/${minutes}`);
    case 'string':
    default:
      return `${year}${month}${date}${hour}${minutes}`;
  }
};

/*
 * @desc    해당 날짜를 기준으로 + 또는 - 된 날짜를 가져오는 함수
 * @param   {
 *            thisDate: 해당 날짜
 *            number: + 또는 - 할 날짜
 *            dateType: year, month, date
 *            dataType: "string, date" default "string"
 *          }
 * @return  { string }
 */
export const cfnGetCustomThisDate = (thisDate, dateType, number, dataType) => {
  let customDate;

  if (cfnIsEmpty(thisDate)) {
    return null;
  } else if (cfnValidDate(thisDate)) {
    customDate = new Date(thisDate.getTime());
  } else if (typeof thisDate === 'string') {
    const convertDate = cfnConvertStringToDate(thisDate);
    if (cfnValidDate(convertDate)) {
      customDate = convertDate;
    } else {
      return null;
    }
  } else {
    return null;
  }

  let lastCustomDate;
  let beforeDate = customDate.getDate();

  switch (dateType) {
    case 'year':
      customDate.setDate(1);
      customDate.setFullYear(customDate.getFullYear() + number);
      break;
    case 'month':
      customDate.setDate(1);
      customDate.setMonth(customDate.getMonth() + number);
      break;
    case 'date':
      customDate.setDate(customDate.getDate() + number);
      break;
    default:
      break;
  }

  if (dateType === 'year' || dateType === 'month') {
    // +- 한 날짜가 존재하지 않는 경우, +-한 달의 다음달의 1일을 리턴
    lastCustomDate = new Date(customDate.getFullYear(), customDate.getMonth() + 1, 0).getDate();
    if (lastCustomDate < beforeDate) customDate.setMonth(customDate.getMonth() + 1);
    else {
      customDate.setDate(beforeDate);
      customDate = new Date(customDate.getTime() + 86400000);
    }
  }

  let date = customDate.getDate();
  let month = customDate.getMonth() + 1;
  let year = customDate.getFullYear();

  if (date < 10) {
    date = `0${date}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  switch (dataType) {
    case 'date':
      return new Date(`${year}/${month}/${date}`);
    case 'string':
    default:
      return `${year}${month}${date}`;
  }
};

/*
 * @desc    두 날짜 차이를 return하는 함수
 * @param   {
 *            startDate: 시작일
 *            endDate: 종료일
 *            type: d = 일, m = 월, y = 년
 *          }
 * @return  { string }
 */
export const cfnDateCalculation = (startDate, endDate, type) => {
  const date1 = typeof startDate === 'string' ? cfnConvertStringToDate(startDate) : startDate;
  const date2 = typeof endDate === 'string' ? cfnConvertStringToDate(endDate) : endDate;

  const difference = date1 - date2;
  const day = 24 * 60 * 60 * 1000;
  const month = day * 30;
  const year = month * 12;

  switch (type) {
    case 'y':
      return parseInt(difference / year);
    case 'm':
      return parseInt(difference / month);
    case 'd':
    default:
      return parseInt(difference / day);
  }
};

/*
 * @desc    날짜 유효성 검사를 처리하는 함수
 * @param   date: 날짜, message: 날짜 label
 * @return  boolean
 */
export const cfnDateValidation = (date, label = '날짜') => {
  if (cfnIsEmpty(date)) {
    cfnAlert(`${label}을(를) 입력해주세요.`);
    return false;
  }

  if (typeof date === 'string') {
    date = cfnConvertStringToDate(date);
  }

  if (!cfnValidDate(date)) {
    cfnAlert(`올바른 ${label}을(를) 입력해주세요.`);
    return false;
  }

  return true;
};

/*
 * @desc    lpad 기능을 구현하는 함수
 * @param   str : 문자열, num : 원하는 문자열 길이, chr : 공백을 채울 문자
 */
export const cfnLpad = (str, num, chr) => {
  let max = num - str.length;
  for (let i = 0, j = max; i < j; i++) {
    str = chr + str;
  }
  return str;
};

/*
 * @desc    rpad 기능을 구현하는 함수
 * @param   str : 문자열, num : 원하는 문자열 길이, chr : 공백을 채울 문자
 */
export const cfnRpad = (str, num, chr) => {
  let max = num - str.length;
  for (let i = 0, j = max; i < j; i++) {
    str = str + chr;
  }
  return str;
};

/* @desc    값이 null일 때 다른 값으로 치환 처리를 하며 null이 아닐때는 자기 자신을 return하는 함수
 * @param   { string || number || boolean }
 * @return  { string || number || boolean }
 */
export const cfnNvl = (currentValue, changeValue) => {
  if (cfnIsEmpty(currentValue) && typeof changeValue === 'function') return changeValue();
  else if (cfnIsEmpty(currentValue)) return changeValue;
  else return currentValue;
};

export let gblHeader = '';

export const cfnSetHeader = (authorization) => {
  gblHeader = authorization;
};

export const cfnGetHeader = () => {
  if (cfnIsEmpty(gblHeader)) {
    if (sessionStorage.hasOwnProperty('loginDto')) {
      let loginDtoObject =
        typeof storage.get('loginDto') === 'object' ? storage.get('loginDto') : JSON.parse(storage.get('loginDto'));
      gblHeader = loginDtoObject.hasOwnProperty('jwt') ? loginDtoObject['jwt'] : gblHeader;
    }
  }

  return gblHeader;
};

/*
 * @desc    localStorage 관리를 하는 함수
 */
export const storage = {
  set: (key, object) => {
    if (!sessionStorage) return false;
    sessionStorage[key] = typeof object === 'string' ? object : JSON.stringify(object);
  },
  get: (key) => {
    if (!sessionStorage) return null;
    if (!sessionStorage[key]) return null;

    try {
      const parsed = JSON.parse(sessionStorage[key]);
      return parsed;
    } catch (e) {
      return sessionStorage[key];
    }
  },
  remove: (key) => {
    if (!sessionStorage) return null;

    if (sessionStorage[key]) {
      sessionStorage.removeItem(key);
    }
  },
};

/*
 * @desc    axios를 처리하는 함수 (일반 파일 다운로드 처리)  (ex: 공지사항 첨부파일다운)
 * @param   {
 *            url: string
 *            method: string
 *            data: object
 *            fncallback: function
 *            fnErrorCallback: function
 *            isMultipart : boolean
 *          }
 * @return  {
 *            data: object
 *            status: int
 *            reponse: object
 *          }
 */
export const cfnAxiosGeneralFileDownload = (url, method, data, fileName, fnCallback, fnErrorCallback, isMultipart) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: url,
    method: method,
    data: data,
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/json,application/octet-stream,text/plain;charset=UTF-8',
    },
  };

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status: status, statusText: statusText };
  };

  Promise(
    axios(config)
      .then((response) => {
        const objStatus = fnSetStatus(response.status, response.statusText);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], { type: 'application/octet-stream,text/plain' }),
            fileName,
          );
        } else {
          const blobURL = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/octet-stream,text/plain' }),
          );
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('download', fileName);
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === 'function') fnCallback(objStatus, response.data);
      })
      .catch((error) => {
        console.log(error);
        // Network error 발생할 때
        if (cfnIsEmpty(error.response)) {
          cfnAlert(error.response);
          return false;
        }

        let message = '';
        if (typeof error.response.data === 'string') {
          message = error.response.data;
        } else if (typeof error.response.data === 'object') {
          let messageJson = JSON.parse(
            decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))),
          );

          message = Object.keys(messageJson).length === 0 ? error.response.statusText : messageJson['message'];
        }

        const objStatus = fnSetStatus(error.response.status, message);

        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === 'function') {
          fnErrorCallback(objStatus);
        } else {
          // (임시) error message 출력

          cfnAlert(message);
        }
      }),
  );
};

/*
 * @desc    axios를 처리하는 함수 (ex: 엑셀다운)
 * @param   {
 *            url: string
 *            method: string
 *            data: object
 *            fncallback: function
 *            fnErrorCallback: function
 *            isMultipart : boolean
 *          }
 * @return  {
 *            data: object
 *            status: int
 *            reponse: object
 *          }
 */
export const cfnAxiosFileDownload = (url, method, data, fnCallback, fnErrorCallback, isMultipart, filename) => {
  let gblHeaderValue = cfnGetHeader();
  const config = {
    url: url,
    method: method,
    data: data,
    responseType: 'arraybuffer',
    exposedHeaders: ['Content-Disposition'],
    headers: {
      Accept: 'application/json,application/octet-stream,image/*,text/plain;charset=UTF-8',
    },
    // withCredentials: true,
  };

  // gblHeader 값이 있을 때 설정
  if (cfnIsNotEmpty(gblHeaderValue)) {
    config.headers.Authorization = gblHeaderValue;
  }

  // status 값을 담은 object
  const fnSetStatus = (status, statusText) => {
    return { status: status, statusText: statusText };
  };

  Promise(
    axios(config)
      .then((response) => {
        let contentDisposition = response.headers['content-disposition'];
        let index =
          contentDisposition.indexOf("''") !== -1
            ? contentDisposition.lastIndexOf("'")
            : contentDisposition.indexOf('=');

        let fileName = decodeURIComponent(contentDisposition.substring(index + 1));

        if (filename !== '' && filename !== undefined) {
          let fileNm = fileName.substr(0, fileName.indexOf('.'));
          fileName = fileName.replace(fileNm, filename);
        }

        const objStatus = fnSetStatus(response.status, response.statusText);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], { type: 'application/octet-stream,text/plain' }),
            fileName,
          );
        } else {
          const blobURL = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/octet-stream,text/plain' }),
          );
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('download', fileName);
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }

        if (cfnIsNotEmpty(fnCallback) && typeof fnCallback === 'function') fnCallback(objStatus, response.data);
      })
      .catch((error) => {
        // Network error 발생할 때
        if (cfnIsEmpty(error.response)) {
          cfnAlert(error.response);
          return false;
        }

        let message = '';
        if (typeof error.response.data === 'string') {
          message = error.response.data;
        } else if (typeof error.response.data === 'object') {
          let messageJson = JSON.parse(
            decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))),
          );

          message = Object.keys(messageJson).length === 0 ? error.response.statusText : messageJson['message'];
        }

        const objStatus = fnSetStatus(error.response.status, message);

        // error callback이 필요할 때 호출
        if (cfnIsNotEmpty(fnErrorCallback) && typeof fnErrorCallback === 'function') {
          fnErrorCallback(objStatus);
        } else {
          // (임시) error message 출력

          cfnAlert(message);
        }
      }),
  );
};

/*
 * @desc    페이지 "00개씩 보기" pageSize Option 목록
 * @param   { N/A }
 * @return  { array }
 */
export const cfnGetOptionPageSize = () => {
  const arrCount = [5, 10, 20];
  const returnData = [];

  for (const count of arrCount) {
    const objData = { value: count, label: count + '개씩' };
    returnData.push(objData);
  }

  return returnData;
};

/*
 * @desc    페이지네이션(pagination) hooks 값 설정을 처리하는 함수
 * @param   value: object
 * @return  object
 */
export const cfnSetPagination = (value) => {
  const returnObject = {
    rowsPerPage: 10,
    offset: 0,
    total: 0,
    totalPages: 1,
  };

  let offsetNum = 0;
  if (cfnIsNotEmpty(value)) {
    if (cfnIsNotEmpty(value.current_page_number)) {
      value.current_page_number = Number(value.current_page_number) + 1;
      if (value.total_pages === 1) {
        if (value.current_page_size === value.total_elements) {
          offsetNum = value.total_elements - 1;
        } else {
          offsetNum = value.total_elements;
        }
      } else {
        offsetNum = value.current_page_number * value.current_page_size - 1;
      }
    }
    returnObject.rowsPerPage = value.current_page_size;
    returnObject.offset = offsetNum;
    returnObject.total = value.total_elements;
    returnObject.totalPages = value.total_pages;
  }
  return returnObject;
};

/*
 * @desc    rootStore의 이용기관 배열에 '전체'를 추가하여 반환
 * @param
 * @return  object
 */
export const cfnTotalInstituteList = () => {
  let p2pCompanyCode = [{ value: 'ALL', label: '전체' }];

  rootStore.InstituteList.slice().map((row, index) => p2pCompanyCode.push(row));

  return p2pCompanyCode;
};

/*
 * @desc    화면 링크
 * @param
 * @return  object
 */
export const cfnPageLink = (url, option) => {
  //window.open(url, option);
  alert('금융결제원 홈페이지 문의/제안하기 URL 링크 예정');
};

/*
 * @desc    rootStore의 상품유형 value에 해당하는 label값 반환
 * @param
 * @return  object
 */
export const cfnReturnProductTypeName = (value) => {
  if (cfnIsNotEmpty(rootStore.ProductTypeList)) {
    const list = toJS(rootStore.ProductTypeList);
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.value === value) {
        return element.label;
      }
    }
  }
};

/*
 * @desc    rootStore의 이용기관 value에 해당하는 label값 반환
 * @param
 * @return  object
 */
export const cfnReturnInstituteName = (value) => {
  if (cfnIsNotEmpty(rootStore.InstituteList)) {
    const list = toJS(rootStore.InstituteList);
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.value === value) {
        return element.label;
      }
    }
  }
};

/*
 * @desc    해당 월의 마지막 날을 구하는 함수
 * @param   ex) 202102
 * @return  object
 */
export const cfnGetLastDay = (yyyymm) => {
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  yyyymm += '';
  if (yyyymm.length !== 6) {
    return 0;
  }

  const yyyy = yyyymm.substring(0, 4); // 년
  const mm = yyyymm.substring(4, 6); // 월

  /*
   * 윤년계산(1년==365.2422일)
   *
   *   4년 마다 윤년을 두고
   * 100년 마다 윤년을 두지 않고
   * 400년 에는 윤년을 둔다.
   */
  if (mm === '02') {
    if (yyyy % 4 === 0 && (yyyy % 100 !== 0 || yyyy % 400 === 0)) {
      return 29 + '';
    }
  }

  return days[mm - 1] + '';
};

/*
 * @desc    파일용량 체크
 * @param   filesize, type
 * @return  object
 */
export const cfnFileSizeCalculation = (size, type) => {
  let result = '';
  let types = ['bytes', 'KB', 'MB', 'GB'];
  if (Number(size) === 0) {
    result = '0 ' + types[0];
  } else {
    let cal = Math.floor(Math.log(size) / Math.log(1024));
    result = (size / Math.pow(1024, cal)).toFixed(2) + ' ' + types[cal];
  }
  return result;
};

/*
 * @desc    필드 입력값 체크 함수
 * @param   value: 필드 입력값
 * @param   name: 항목명
 * @param   ref: 포커싱 필요한 필드 ref (없으면 생략 가능)
 */
export const cfnInputValidation = (val, name, ref) => {
  if (cfnIsEmpty(val)) {
    cfnAlert(name + ' 항목을 입력해주세요.');
    if (cfnIsNotEmpty(ref)) {
      ref.current.focus();
    }
    return false;
  } else {
    return true;
  }
};

/*
 * @desc    필드 입력값 체크 함수 (Ref 사용)
 * @param   name: 항목명
 * @param   ref: 검증 필드 ref
 * @param   validGbn: 검증 방식 구분
 */
export const VALID_NATIONAL_ID_NUMBER_FIRST = 'VALID_NATIONAL_ID_NUMBER_FIRST'; //주민등록번호 앞자리
export const VALID_NATIONAL_ID_NUMBER_LAST = 'VALID_NATIONAL_ID_NUMBER_LAST'; //주민등록번호 뒷자리
export const VALID_NATIONAL_IS_NUMBER = 'VALID_NATIONAL_IS_NUMBER'; //숫자 필드
export const cfnInputRefValidation = (name, ref, validGbn) => {
  let rs = true;
  let value = ref.current.value;
  if (cfnIsEmpty(value)) {
    cfnAlert(name + ' 항목을 입력해주세요.');
    ref.current.focus();
    rs = false;
  } else if (cfnIsNotEmpty(validGbn)) {
    const numberPattern = new RegExp(/^[0-9\b]+$/);
    switch (validGbn) {
      case VALID_NATIONAL_ID_NUMBER_FIRST: //주민등록번호 앞자리 검증
        if (!numberPattern.test(value)) {
          cfnAlert(name + ' 항목은 숫자만 입력할 수 있습니다.');
          ref.current.focus();
          rs = false;
        } else if (value.length !== 6) {
          cfnAlert(name + ' 항목을 확인해주세요.');
          ref.current.focus();
          rs = false;
        }
        break;
      case VALID_NATIONAL_ID_NUMBER_LAST: //주민등록번호 뒷자리 검증
        if (!numberPattern.test(value)) {
          cfnAlert(name + ' 항목은 숫자만 입력할 수 있습니다.');
          ref.current.focus();
          rs = false;
        } else if (value.length !== 7) {
          cfnAlert(name + ' 항목을 확인해주세요.');
          ref.current.focus();
          rs = false;
        }
        break;
      case VALID_NATIONAL_IS_NUMBER: //숫자 필드인지 검증
        if (!numberPattern.test(value)) {
          cfnAlert(name + ' 항목은 숫자만 입력할 수 있습니다.');
          ref.current.focus();
          rs = false;
        }
        break;
    }
  }
  return rs;
};

/*
 * @desc    사업자등록번호 체크 함수 (Ref 사용)
 * @param   name: 항목명
 * @param   ref: 검증 필드 ref
 */
export const cfnInputCompanyNoRefValidation = (name, ref) => {
  if (cfnIsEmpty(ref.current.value)) {
    cfnAlert(name + ' 항목을 입력해주세요.');
    ref.current.focus();
    return false;
  } else if (ref.current.value.length !== 10) {
    cfnAlert(name + ' 항목을 다시 확인해주세요.');
    ref.current.focus();
    return false;
  } else {
    return true;
  }
};

/*
 * @desc    숫자만 입력가능 함수
 * @param   name: 항목명
 * @param   ref: 검증 필드 ref
 */
export const cfnNumberCheck = (param) => {
  const numberPattern = new RegExp(/^[0-9\b]+$/);
  const characterPattern = new RegExp(/[^0-9]/g);

  if (!numberPattern.test(param)) {
    //숫자만 있는지 체크
    param = param.replace(characterPattern, ''); //문자제거
  }
  return param;
};

/*
 * @desc    숫자만 입력가능 함수 (Ref 사용)
 * @param   name: 항목명
 * @param   ref: 검증 필드 ref
 */
export const cfnNumberRefCheck = (name, ref) => {
  if (cfnIsEmpty(ref.current.value)) {
    cfnAlert(name + ' 항목은 숫자만 입력할 수 있습니다.');
    ref.current.focus();
    return false;
  } else {
    return true;
  }
};

/*
 * @desc    숫자 입력 핸들러 함수
 * @param   name: 항목명
 * @param   ref: 검증 필드 ref
 */
export const cfnHandleChangeNumCheck = (e, data, setData, targetName, name) => {
  const numberPattern = new RegExp(/^[0-9\b]+$/);
  if (cfnIsEmpty(e.target.value) || numberPattern.test(e.target.value)) {
    //숫자만 있는지 체크
    setData({
      ...data,
      [targetName]: e.target.value,
    });
  } else {
    cfnAlert(name + ' 항목은 숫자만 입력할 수 있습니다.');
  }
};

/*
 * @desc    API 오류 출력 함수
 * @param   msg: 오류 항목명 (array)
 * @param   keyword: 오류 문구 (string, .env code)
 */
export const cfnApiErrorMsg = (msg, keyword) => {
  let tempMsg = '';
  for (let index = 0; index < msg.length; index++) {
    const element = msg[index];
    tempMsg += element + keyword + '\n';
  }
  cfnAlert(tempMsg);
};

/*
 * @desc    날짜 validation체크
 * @param   startDate(require), endDate(require), conditionMonth N개월(optional)
 */
export const cfnCommonValidDate = (startDate, endDate, conditionMonth) => {
  // 1. null체크
  if (startDate === null) {
    cfnAlert('조회시작일을 선택해주세요.');
    return false;
  } else if (endDate === null) {
    cfnAlert('조회종료일을 선택해주세요.');
    return false;
  }

  // 2. 유효한 날짜인지 체크 (숫자확인, 달력 내 숫자확인)
  if (!cfnValidDate(cfnConvertStringToDate(startDate)) || !cfnValidDate(cfnConvertStringToDate(endDate))) {
    cfnAlert('유효한 날짜가 아닙니다.');
    return false;
  }

  // 3. 시작일이 종료일이전인지
  if (typeof startDate === 'string') {
    startDate = cfnConvertStringToDate(startDate);
  }
  if (typeof endDate === 'string') {
    endDate = cfnConvertStringToDate(endDate);
  }
  if (startDate > endDate) {
    cfnAlert('조회시작일을 종료일 이전으로 선택해주세요.');
    return false;
  }

  // 4. 시작일이 (종료일을 기준으로)최대 N개월이후인지
  if (cfnIsNotEmpty(conditionMonth)) {
    if (isNaN(conditionMonth)) {
      //숫자인지체크
      conditionMonth = Number(conditionMonth);
    }
    if (cfnGetCustomThisDate(endDate, 'month', -conditionMonth, 'date') > startDate) {
      cfnAlert('최대 ' + conditionMonth + '개월 이내의 범위로만 설정 가능합니다.');
      return false;
    }
  }

  return true;
};

/*
 * @desc 특정 Element로 스크롤하여 이동하는 함수
 * @param targetID : 스크롤하여 이동할 element id
 * @param options : 스크롤 이동 옵션
 */
export const scrollToTarget = (targetId, options) => {
  const top = document.getElementById(targetId).getBoundingClientRect().top + window.scrollY + (options.topWeight ?? 0);

  const duration = options.duration ?? 400;
  let percent = 0;
  const currentTop = window.scrollY;
  requestAnimationFrame(function move() {
    // swing 값이 0에서 1로 완만->급격->완만 형태로 증가함
    const swing = 0.5 - Math.cos(percent * Math.PI) / 2;

    window.scrollTo(0, currentTop + swing * (top - currentTop));
    // 15ms 간격으로 총 duration ms동안 이동 -> 1회에 15/800씩 이동
    percent += 15 / duration;
    if (percent < 1) {
      requestAnimationFrame(move);
    }
  });
};
