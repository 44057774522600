/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 공통 경고 컴포넌트
 */
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import CloseRoundeIcon from '@material-ui/icons/CloseRounded';

let openAlertFn = () => {};

const errorMessageType = {
  GENERAL: '서버 시스템에서의 응답이 없습니다. 고객센터로 문의주시기 바랍니다. (GENERAL)',
  Not_Acceptable: '서버 이용에 문제가 있습니다. 고객센터로 문의주시기 바랍니다.(Not Acceptable)',
};

const AlertForm = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [callback, setCallback] = useState({ function: '' });

  useEffect(() => {
    openAlertFn = openAlert;
  }, []);

  const openAlert = (message, callback) => {
    setOpen(true);
    setMessage(message);
    setCallback({ function: callback });
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 27) {
      e.preventDefault();
      handleAlertOk();
    }
  };

  const handleAlertOk = () => {
    setOpen(false);
    if (typeof callback.function === 'function') callback.function();
  };

  return (
    <Dialog
      open={open}
      onKeyDown={handleKeyUp}
      onClose={handleAlertOk}
      transitionDuration={{ enter: 100, exit: 100 }}
      scroll="paper"
      className="alertPop"
    >
      <DialogContent>
        <h1>확인</h1>
        <DialogContentText id="alert-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="table-bottom-area">
          <button className="btnOk" onClick={handleAlertOk} data-testid="common-alert-click">
            확인
          </button>
          {/* <button>취소</button> */}
        </div>
      </DialogActions>
      <button onClick={handleAlertOk} className="closePop">
        <CloseRoundeIcon />
        <span className="hide">팝업창 닫기</span>
      </button>
    </Dialog>
  );
};

export function openAlert(message, callback) {
  let messageString = errorMessageType.hasOwnProperty(message.split(' ').join('_'))
    ? errorMessageType[message.split(' ').join('_')]
    : message;
  openAlertFn(messageString, callback);
}

export default AlertForm;
