/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * HeadTop
 */
import { Button, SwipeableDrawer } from '@material-ui/core';
import CloseRoundeIcon from '@material-ui/icons/CloseRounded';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { userStore } from '../../../stores/customers/userStore';
import logo from '../../../resource/styles/images/p2plogo.png';
import Collapse from '@material-ui/core/Collapse';

/* 헤더 */
const HeadTop = observer(() => {
  return (
    <div className="header">
      <div className="headerTop">
        <header className="inr">
          {/* // 모바일 메뉴  */}
          <div className="mobileMenu">
            <MobileM></MobileM>
          </div>

          <div className="headerLogo">
            <Link to="/main">
              <img src={logo} className="logo" alt="온라인투자연계금융업 중앙기록관리기관 포털사이트" />
            </Link>
          </div>
          <HeadGnb></HeadGnb>
        </header>
      </div>
    </div>
  );
});

const HeadGnb = observer(() => {
  const [expanded, setExpanded] = useState(false);
  const [print, setPrint] = useState(true);

  useEffect(() => {
    userStore.handleLogoutTimer(false);
    if (print) {
      userStore.handleTimerRefresh();
      setPrint(false);
    }
  }, []);

  const handleExpandClick = (param) => {
    setExpanded(param);
  };

  const getTimerPrint = () => {
    const maxTime = 60 * 10; //로그아웃 최대 유지 시간(10분)
    let time = userStore.loginTimer;
    let clacTime = maxTime - time;
    let min = parseInt(clacTime / 60);
    let sec = clacTime % 60;
    return min + '분 ' + sec + '초';
  };
  return (
    <div className="gnb">
      <div className="headerEtc">
        {userStore.isLogin === 'true' || userStore.getIsLogin() === 'true' ? (
          <div className="logoutTime">
            <p>자동로그아웃</p>
            <p>: 남은시간 {getTimerPrint()}</p>
            <Button className="small" onClick={() => userStore.loginTimerInit()} data-testid="initTimerBtn">
              연장
            </Button>
            <Button
              className="small"
              onClick={() => {
                userStore.logout();
              }}
              data-testid="logoutBtn"
            >
              로그아웃
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
      <ul
        className="gnbWrap on"
        onMouseOver={() => handleExpandClick(true)}
        onMouseLeave={() => handleExpandClick(false)}
      >
        <li>
          <Link to="/serviceintro/p2pfinanceintro/introducep2p" onClick={() => handleExpandClick(false)}>
            서비스 소개
          </Link>
          <div className="allMenu">
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <ul className="allmenuList">
                <li>
                  <div>
                    <Link to="#">서비스 소개</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/serviceintro/p2pfinanceintro/introducep2p">온라인투자연계금융소개(P2P금융)</Link>
                        <ul>
                          <li>
                            <Link
                              to="/serviceintro/p2pfinanceintro/introducep2p"
                              onClick={() => handleExpandClick(false)}
                            >
                              P2P 금융이란?
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/serviceintro/p2pfinanceintro/introducep2plaw"
                              onClick={() => handleExpandClick(false)}
                            >
                              P2P 금융 관련 법령
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/serviceintro/centralagencyintro/introducetask">중앙기록관리기관 소개</Link>
                        <ul>
                          <li>
                            <Link
                              to="/serviceintro/centralagencyintro/introducetask"
                              onClick={() => handleExpandClick(false)}
                            >
                              주요역할 및 특징
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/serviceintro/centralagencyintro/introduceagency"
                              onClick={() => handleExpandClick(false)}
                            >
                              금융결제원 안내
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/serviceintro/registrationCompanies" onClick={() => handleExpandClick(false)}>
                          이용기관 현황
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="#">이용안내</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/useinfo/userguide/p2pfinanceusage">이용자 안내</Link>
                        <ul>
                          <li>
                            <Link to="/useinfo/userguide/p2pfinanceusage" onClick={() => handleExpandClick(false)}>
                              P2P 금융 이용절차
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/useinfo/companiesguide/openapiservice">이용기관 안내</Link>
                        <ul>
                          <li>
                            <Link to="/useinfo/companiesguide/openapiservice" onClick={() => handleExpandClick(false)}>
                              오픈 API 서비스
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/useinfo/companiesguide/servicerequestinfo"
                              onClick={() => handleExpandClick(false)}
                            >
                              서비스 이용신청 안내
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="#">내 P2P 금융정보 조회</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/myp2pfinanceinfo/investmentdetails" onClick={() => handleExpandClick(false)}>
                          투자내역 조회
                        </Link>
                      </li>
                      <li>
                        <Link to="/myp2pfinanceinfo/loandetails" onClick={() => handleExpandClick(false)}>
                          대출내역 조회
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="#">통계조회</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/statistics/intergratedinfo" onClick={() => handleExpandClick(false)}>
                          P2P금융 통합정보
                        </Link>
                      </li>
                      <li>
                        <Link to="/statistics/productinfo" onClick={() => handleExpandClick(false)}>
                          연계투자 상품정보
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="#">고객지원</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/board/notice" onClick={() => handleExpandClick(false)}>
                          공지사항
                        </Link>
                      </li>
                      <li>
                        <Link to="/board/faq" onClick={() => handleExpandClick(false)}>
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link to="/board/downloads" onClick={() => handleExpandClick(false)}>
                          자료실
                        </Link>
                      </li>
                      <li>
                        <a href={'mailto:' + process.env.REACT_APP_SUPPORT_QUESTION_URL}>문의하기</a>
                      </li>
                      <li>
                        <a href={'mailto:' + process.env.REACT_APP_SUPPORT_SUGGESTION_URL}>제안하기</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </Collapse>
          </div>
        </li>
        <li>
          <Link to="/useinfo/userguide/p2pfinanceusage" onClick={() => handleExpandClick(false)}>
            이용안내
          </Link>
        </li>
        <li>
          <Link to="/myp2pfinanceinfo/investmentdetails" onClick={() => handleExpandClick(false)}>
            내 P2P 금융정보 조회
          </Link>
        </li>
        <li>
          <Link to="/statistics/intergratedinfo" onClick={() => handleExpandClick(false)}>
            통계조회
          </Link>
        </li>
        <li>
          <Link to="/board/notice" onClick={() => handleExpandClick(false)}>
            고객지원
          </Link>
        </li>
      </ul>
    </div>
  );
});

// 모바일 메뉴
const MobileM = observer(() => {
  const [print, setPrint] = useState(true);
  const [menu01, setMenu01] = useState(false);
  const [menu011, setMenu011] = useState(false);
  const [menu012, setMenu012] = useState(false);
  const [menu02, setMenu02] = useState(false);
  const [menu021, setMenu021] = useState(false);
  const [menu022, setMenu022] = useState(false);
  const [menu03, setMenu03] = useState(false);
  const [menu04, setMenu04] = useState(false);
  const [menu05, setMenu05] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });

    if (!open) {
      setMenu01(open);
      setMenu011(open);
      setMenu012(open);
      setMenu02(open);
      setMenu021(open);
      setMenu022(open);
      setMenu03(open);
      setMenu04(open);
      setMenu05(open);
    } else {
      let requestUri = window.location.href;

      if (requestUri.indexOf('/board/notice') > 0) {
        handleCollapse('menu05');
      } else if (requestUri.indexOf('/board/faq') > 0) {
        handleCollapse('menu05');
      } else if (requestUri.indexOf('/board/downloads') > 0) {
        handleCollapse('menu05');
      } else if (requestUri.indexOf('/myp2pfinanceinfo/investmentdetails') > 0) {
        handleCollapse('menu03');
      } else if (requestUri.indexOf('/myp2pfinanceinfo/loandetails') > 0) {
        handleCollapse('menu03');
      } else if (requestUri.indexOf('/statistics/intergratedinfo') > 0) {
        handleCollapse('menu04');
      } else if (requestUri.indexOf('/statistics/productinfo') > 0) {
        handleCollapse('menu04');
      } else if (requestUri.indexOf('/serviceintro/registrationCompanies') > 0) {
        handleCollapse('menu01');
      } else if (requestUri.indexOf('/serviceintro/p2pfinanceintro') > 0) {
        handleCollapse('menu01');
        handleCollapse('menu011');
      } else if (requestUri.indexOf('/serviceintro/centralagencyintro') > 0) {
        handleCollapse('menu01');
        handleCollapse('menu012');
      } else if (requestUri.indexOf('/useinfo/userguide/') > 0) {
        handleCollapse('menu02');
        handleCollapse('menu021');
      } else if (requestUri.indexOf('/useinfo/companiesguide') > 0) {
        handleCollapse('menu02');
        handleCollapse('menu022');
      }
    }
  };

  const handleCollapse = (menu) => {
    switch (menu) {
      case 'menu01': {
        if (menu01) {
          setMenu011(!menu01);
          setMenu012(!menu01);
        }
        setMenu01(!menu01);
        break;
      }
      case 'menu011': {
        setMenu011(!menu011);
        break;
      }
      case 'menu012': {
        setMenu012(!menu012);
        break;
      }
      case 'menu02': {
        if (menu02) {
          setMenu021(!menu02);
          setMenu022(!menu02);
        }
        setMenu02(!menu02);
        break;
      }
      case 'menu021': {
        setMenu021(!menu021);
        break;
      }
      case 'menu022': {
        setMenu022(!menu022);
        break;
      }
      case 'menu03': {
        setMenu03(!menu03);
        break;
      }
      case 'menu04': {
        setMenu04(!menu04);
        break;
      }
      case 'menu05': {
        setMenu05(!menu05);
        break;
      }
    }
  };

  useEffect(() => {
    userStore.handleLogoutTimer(false);
    if (print) {
      userStore.handleTimerRefresh();
      setPrint(false);
    }
  }, []);

  const getTimerPrint = () => {
    const maxTime = 60 * 10; //로그아웃 최대 유지 시간(10분)
    let time = userStore.loginTimer;
    let clacTime = maxTime - time;
    let min = parseInt(clacTime / 60);
    let sec = clacTime % 60;
    return min + '분 ' + sec + '초';
  };

  const list = (anchor) => (
    <div role="presentation">
      <div className="inrMobile">
        <div className="headerEtc">
          <div>
            <h1>
              온라인투자연계금융업
              <br />
              중앙기록관리기관
            </h1>
            {userStore.isLogin === 'true' || userStore.getIsLogin() === 'true' ? (
              <div className="logoutTime">
                <p>자동로그아웃 : 남은시간 {getTimerPrint()}</p>
                <Button className="small" onClick={() => userStore.loginTimerInit()} data-testid="m_initTimerBtn">
                  연장
                </Button>
                <Button
                  className="small"
                  onClick={() => {
                    userStore.logout();
                  }}
                  data-testid="m_logoutBtn"
                >
                  로그아웃
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
          <Link to="#" className="btnAllclose">
            <CloseRoundeIcon onClick={toggleDrawer(anchor, false)} />
            <span className="hide">전체 메뉴닫기</span>
          </Link>
        </div>
        <h2 className="hide">전체 메뉴</h2>
        <ul className="allmenuList">
          <li className={menu01 ? 'on' : ''}>
            <Button
              onClick={() => {
                handleCollapse('menu01');
              }}
            >
              서비스 소개
              {menu01 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Collapse in={menu01}>
              <ul className="depthDetail">
                <li className={menu011 ? 'on' : ''}>
                  <Button
                    onClick={() => {
                      handleCollapse('menu011');
                    }}
                  >
                    온라인투자연계금융 소개 (P2P금융)
                    {menu011 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                  <Collapse in={menu011}>
                    <ul className="lastdepthList">
                      <li onClick={toggleDrawer(anchor, false)}>
                        <Link to="/serviceintro/p2pfinanceintro/introducep2p">P2P 금융이란?</Link>
                      </li>
                      <li onClick={toggleDrawer(anchor, false)}>
                        <Link to="/serviceintro/p2pfinanceintro/introducep2plaw">P2P 금융 관련 법령</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li className={menu012 ? 'on' : ''}>
                  <Button
                    onClick={() => {
                      handleCollapse('menu012');
                    }}
                  >
                    중앙기록관리기관 소개
                    {menu012 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                  <Collapse in={menu012}>
                    <ul className="lastdepthList">
                      <li onClick={toggleDrawer(anchor, false)}>
                        <Link to="/serviceintro/centralagencyintro/introducetask">주요역할 및 특징</Link>
                      </li>
                      <li onClick={toggleDrawer(anchor, false)}>
                        <Link to="/serviceintro/centralagencyintro/introduceagency">금융결제원 안내</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/serviceintro/registrationCompanies">이용기관 현황</Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={menu02 ? 'on' : ''}>
            <Button
              onClick={() => {
                handleCollapse('menu02');
              }}
            >
              이용안내
              {menu02 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Collapse in={menu02}>
              <ul className="depthDetail">
                <li className={menu021 ? 'on' : ''}>
                  <Button
                    onClick={() => {
                      handleCollapse('menu021');
                    }}
                  >
                    이용자 안내
                    {menu021 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                  <Collapse in={menu021}>
                    <ul className="lastdepthList">
                      <li onClick={toggleDrawer(anchor, false)}>
                        <Link to="/useinfo/userguide/p2pfinanceusage">P2P 금융 이용절차</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li className={menu022 ? 'on' : ''}>
                  <Button
                    onClick={() => {
                      handleCollapse('menu022');
                    }}
                  >
                    이용기관 안내
                    {menu022 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                  <Collapse in={menu022}>
                    <ul className="lastdepthList">
                      <li onClick={toggleDrawer(anchor, false)}>
                        <Link to="/useinfo/companiesguide/openapiservice">오픈 API 서비스</Link>
                      </li>
                      <li onClick={toggleDrawer(anchor, false)}>
                        <Link to="/useinfo/companiesguide/servicerequestinfo">서비스 이용신청 안내</Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={menu03 ? 'on' : ''}>
            <Button
              onClick={() => {
                handleCollapse('menu03');
              }}
            >
              내 P2P 금융정보 조회
              {menu03 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Collapse in={menu03}>
              <ul className="depthDetail">
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/myp2pfinanceinfo/investmentdetails">투자내역 조회</Link>
                </li>
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/myp2pfinanceinfo/loandetails">대출내역 조회</Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={menu04 ? 'on' : ''}>
            <Button
              onClick={() => {
                handleCollapse('menu04');
              }}
            >
              통계조회
              {menu04 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Collapse in={menu04}>
              <ul className="depthDetail">
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/statistics/intergratedinfo">P2P금융 통합정보</Link>
                </li>
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/statistics/productinfo">연계투자 상품정보</Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={menu05 ? 'on' : ''}>
            <Button
              onClick={() => {
                handleCollapse('menu05');
              }}
            >
              고객지원
              {menu05 == true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Collapse in={menu05}>
              <ul className="depthDetail">
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/board/notice">공지사항</Link>
                </li>
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/board/faq">FAQ</Link>
                </li>
                <li onClick={toggleDrawer(anchor, false)}>
                  <Link to="/board/downloads">자료실</Link>
                </li>
                <li onClick={toggleDrawer(anchor, false)}>
                  <a href={'mailto:' + process.env.REACT_APP_SUPPORT_QUESTION_URL}>문의하기</a>
                </li>
                <li onClick={toggleDrawer(anchor, false)}>
                  <a href={'mailto:' + process.env.REACT_APP_SUPPORT_SUGGESTION_URL}>제안하기</a>
                </li>
              </ul>
            </Collapse>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor, index) => (
        <Fragment key={'MobileM' + index}>
          <Button onClick={toggleDrawer(anchor, true)} endIcon={<MenuIcon />} className="menuM">
            <span className="hide">모바일 메뉴 오픈</span>
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </Fragment>
      ))}
    </div>
  );
});

export default HeadTop;
