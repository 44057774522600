/*
 * @version : 2021.03.03
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용안내 - 이용자 안내 - P2P 금융 이용절차
 */

import { useEffect } from 'react';
import { userStore } from '../../../../stores/customers/userStore';

const P2pFinanceUsageM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce">
          <h4>P2P 금융 이용절차</h4>
          <div className="section">
            <h5>연계대출 안내</h5>
            <ul className="areaR item loan">
              <li>
                <span className="titNum step1">01</span>
                <span>
                  차입자는 온투업자의 온라인플랫폼을 이용해 <strong>대출을 신청</strong>합니다. (기본 정보 등록 후,
                  대출심사에 필요한 관련서류 제출)
                </span>
              </li>
              <li>
                <span className="titNum step2">02</span>
                <span>대출심사 결과를 확인하고, 제시받은 대출조건을 확인한 후 이에 동의합니다.</span>
              </li>
              <li>
                <span className="titNum step3">03</span>
                <span>온투업자는 해당 대출과 연계된 투자상품을 온라인플랫폼에 게시하고 투자자를 모집합니다.</span>
              </li>
              <li>
                <span className="titNum step4">04</span>
                <span>투자금 모집 완료 시 대출이 실행되며, 투자자의 투자금이 차입자의 계좌로 전달됩니다.</span>
              </li>
              <li>
                <span className="titNum step5">05</span>
                <span>
                  차입자는 상환계획에 맞춰 <strong>원리금을 상환계좌에 입금</strong>합니다. 상환된 원리금은 해당
                  연계투자에 대한 원리금수취권을 보유하고 있는 투자자의 계좌로 전달됩니다.
                </span>
              </li>
            </ul>
          </div>
          <div className="section">
            <h5>연계투자 안내</h5>
            <ul className="areaR item invest">
              <li>
                <span className="titNum step1">01</span>
                <span>
                  투자자는 온투업자의 온라인플랫폼을 이용해 기본정보를 등록합니다. (소득적격투자자, 법인투자자 등에 해당
                  시 관련서류 제출)
                </span>
              </li>
              <li>
                <span className="titNum step2">02</span>
                <span>
                  업체정보, 온라인플랫폼에 게시된 상품정보를 확인하고 상품을 선택해 <strong>투자를 신청</strong>합니다.
                  부여받은 계좌번호로 투자자금을 이체합니다.
                </span>
              </li>
              <li>
                <span className="titNum step3">03</span>
                <span>
                  투자금 모집이 완료될 시 투자자의 투자금이 차입자의 계좌로 전달됩니다. 이때 투자자는 투자에 따른
                  원리금수취권을 획득합니다.
                </span>
              </li>
              <li>
                <span className="titNum step4">04</span>
                <span>
                  만기 전에 투자금을 회수하고자 하는 경우, 법령에서 규정한 조건에 부합할 때&nbsp;
                  <strong>보유한 원리금수취권을 예외적으로 양도</strong>할 수 있으며 양도한 금액은 현 투자잔액에서
                  차감됩니다.
                </span>
              </li>
              <li>
                <span className="titNum step5">05</span>
                <span>
                  차입자가 상환계획에 맞춰 정상적으로 상환할 때, 보유한 원리금수취권에 따른 상환금이 투자자에게
                  입금됩니다. 상환된 투자원금은 현 투자잔액에서 차감됩니다.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default P2pFinanceUsageM;
