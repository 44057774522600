/*
 * @version : 2021.02.15
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국포탈 - P2P금융 통합정보
 */
import { Fragment, useEffect, useState } from 'react';
import * as CM from '../../../common/util/common';
import { Bar, BarChart, LabelList, Legend, XAxis, YAxis } from 'recharts';
import * as CF from '../../../common/template/ComponentForm';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { userStore } from '../../../../stores/supervisors/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Popup from './Popup';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { rootStore } from '../../../../stores/rootStore';

const colors = ['#e95b54', '#fbce4a', '#3caf85', '#309fdb', '#854e9b', '#ac8c2e'];
let legendData = [];

const useRowStyles = makeStyles({
  ROOT: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const TotalStatisticsM = observer(() => {
  const productTypes = toJS(rootStore.ProductTypeList);
  const totalProductIndex = productTypes.findIndex((element) => element.product_type_code === 'TOTAL_PRODUCT');
  productTypes.splice(totalProductIndex, 1);
  const [statistics, setStatistics] = useState([]); //통계조회
  const [statisticsList, setStatisticsList] = useState([]); //이용기관별 통계(하단 테이블)
  const searchDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0); //조회기준일
  const [barData, setBarData] = useState({
    name: 'A',
    '부동산 PF': 0,
    '부동산 담보': 0,
    '어음매출채권 담보': 0,
    '기타 담보': 0,
    '개인 신용': 0,
    '법인 신용': 0,
  });
  const [barItem, setBarItem] = useState([]);
  const [requestParams, setRequestParams] = useState({
    inquiryDate: searchDate,
    sortDirection: 'DESC',
    sortProperty: 'loan_balance',
  });
  const [searchState, setSearchState] = useState(true); //조회여부

  const classes = useRowStyles();

  let msg = [];

  const renderInsight = (bar, i) => {
    return (
      <LabelList style={{ fill: 'white' }} key={i}>
        {barData[0][bar.productTypeName]}
      </LabelList>
    );
  };

  const renderBars = () => {
    return barItem.map((bar, i) => (
      <Bar
        key={'bar' + i}
        stackId="s"
        dataKey={bar.productTypeName}
        fill={colors[bar.idx]}
        barSize={50}
        isAnimationActive={false}
      >
        {renderInsight(bar, i)}
      </Bar>
    ));
  };

  const renderLegendTextColor = (value, entry) => {
    return <span style={{ color: 'black' }}>{value}</span>;
  };

  const handleDate = (name) => (e) => {
    setRequestParams((data) => ({ ...data, [name]: e }));
    if (CM.cfnValidDate(e)) {
      if (e >= new Date(new Date().getFullYear(), new Date().getMonth(), 1)) {
        CM.cfnAlert('전월까지만 선택가능합니다.');
      } else {
        handleSearchChange(true);
      }
    }
  };

  // 막대그래프 값 셋팅 handler
  const handlerBarData = (param) => {
    if (CM.cfnIsNotEmpty(param.balance_list)) {
      let dataArray = [];
      let itemArray = [];
      let tmp = {};
      tmp.name = '상품유형별 대출잔액';

      let balanceList = [];
      productTypes.map((productType) => {
        let element = param.balance_list.find((balance) => balance.product_type_code === productType.product_type_code);
        if (CM.cfnIsNotEmpty(element)) {
          balanceList.push(element);
        }
      });

      for (let index = 0; index < balanceList.length; index++) {
        const element = balanceList[index];
        let idx = productTypes.findIndex((productType) => productType.product_type_code === element.product_type_code);
        itemArray.push({ productTypeName: element.product_type_name, idx: idx });
        var key = element.product_type_name;
        tmp[key] = Number(element.loan_balance_ratio).toFixed(0);
        legendData.push({
          value: key,
          type: 'square',
          color: colors[idx],
        });
      }
      dataArray.push(tmp);
      setBarData(dataArray);
      setBarItem(itemArray);
    }
  };

  // button State flag handleChange
  const handleSearchChange = (flag) => setSearchState(flag);

  const handleOpen = (index) => {
    let tempObj = CM.cfnCopyObject(statisticsList);
    let row = tempObj.list[index];
    if (CM.cfnIsEmpty(row.open)) {
      row.open = true;
    } else {
      row.open = !row.open;
    }
    setStatisticsList(tempObj);

    if (row.open) {
      handleDetail(row);
    }
  };

  const createSortHandler = (e, deactive) => {
    let sortOrder = '';
    let sortProp = '';

    if (CM.cfnIsEmpty(e.currentTarget.name)) {
      sortProp = requestParams.sortProperty;
    } else {
      sortProp = e.currentTarget.name;
    }

    //비활성화아이콘 처음정렬 desc
    if (CM.cfnIsNotEmpty(deactive)) {
      sortOrder = 'DESC';
    } else {
      if (requestParams.sortDirection === 'DESC') {
        sortOrder = 'ASC';
      } else {
        sortOrder = 'DESC';
      }
    }
    setRequestParams((data) => ({
      ...data,
      sortDirection: sortOrder,
      sortProperty: sortProp,
    }));
    handleSearchChange(true);
  };

  //화면로딩 시 실행
  useEffect(() => {
    userStore.handleNaviTrigger();
  }, []);

  useEffect(() => {
    // 통계조회 handler
    const handleStatisticsChange = (param, paramList) => {
      legendData = [];
      if (
        CM.cfnIsNotEmpty(param.balance_list) &&
        param.cumul_loan_amount !== null &&
        param.cumul_repayment_amount !== null &&
        param.loan_balance !== null &&
        param.p2p_company_count !== 0 &&
        param !== statistics
      ) {
        setStatistics(param);
        handlerBarData(param);
      } else {
        setStatistics([]);
      }
      if (CM.cfnIsNotEmpty(paramList.list) && paramList !== statisticsList) {
        setStatisticsList(paramList);
      } else {
        setStatisticsList([]);
      }
      handleSearchChange(false);
    };

    //투자현황 axios
    const axiosStatistics = (api) => {
      return new Promise((resolve) => {
        let inquiry = CM.cfnConvertDateToString(requestParams.inquiryDate).substring(0, 6);
        api += `?inquiry_year_month=${inquiry}`;
        if (api.indexOf('p2p-companies') > -1) {
          api += `&sort_directions=${
            requestParams.sortDirection
          }&sort_properties=${requestParams.sortProperty.toUpperCase()}`;
        }

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          () => {
            if (api.indexOf('p2p-companies') > -1) {
              msg.push('이용기관별 통계 ' + process.env.REACT_APP_M001);
            } else {
              msg.push('P2P금융 통합정보 ' + process.env.REACT_APP_M001);
            }
            resolve({ balance_list: [] });
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    // useEffect start function
    const startAxios = async () => {
      let api = 'web/common/statistics';
      const resultData = await axiosStatistics(api);
      const listData = await axiosStatistics(api + '/p2p-companies');
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handleStatisticsChange(resultData, listData);
    };

    if (searchState) {
      startAxios();
    }
  }, [searchState, requestParams, productTypes]);

  const handleDetail = async (row) => {
    const axiosDetail = (p2pCompanyCode) => {
      return new Promise((resolve) => {
        let api = 'web/common/statistics/p2p-companies/details';
        let inquiry = CM.cfnConvertDateToString(requestParams.inquiryDate).substring(0, 6);
        api += `?inquiry_year_month=${inquiry}`;
        api += `&p2p_company_code=${p2pCompanyCode}`;

        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve({ balance_list: [] });
          },
        );
      });
    };

    const resultData = await axiosDetail(row.p2p_company_code);
    let list = statisticsList.list.map((item) =>
      item.p2p_company_code === row.p2p_company_code
        ? { ...item, balance_list: resultData.balance_list, open: true }
        : { ...item },
    );
    setStatisticsList({ ...statisticsList, list: list });
  };

  return (
    <Fragment>
      <div className="subContent">
        <div className="subInner">
          <div className="searchdateBox">
            <span className="tit">조회년월 지정</span>
            <div className="datePick">
              <CF.DatePickerForm
                id="inquiryDate"
                value={requestParams.inquiryDate}
                handleChange={handleDate('inquiryDate')}
                format={'yyyy-MM'}
                viewFormat={['year', 'month']}
                customProps={{
                  maxDate: searchDate,
                  inputProps: { 'data-testid': 'intergrated-requestResult-inquiryDate' },
                  openTo: 'month',
                }}
              />
            </div>
          </div>
          <div className="section">
            <h4>전체 통계</h4>
            <div className="contBox statistics">
              <div className="contBox_item flex_g1">
                <h5>이용기관</h5>
                {CM.cfnIsNotEmpty(statistics) ? (
                  <div className="itemBox">
                    <div className="infoMain">
                      <p className="number">
                        {statistics.p2p_company_count}
                        <span className="unit">(개)</span>
                        <div className="tip">
                          <CF.TooltipForm
                            contents={
                              '현재 금융위원회에 등록된 온투업체 중\n중앙기록관리기관과 이용계약이 완료된 업체 수'
                            }
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="itemBox borrower corp resultnoBox">
                    <p className="resultNo">
                      <ErrorOutlineIcon />
                      조회된 내용이 없습니다.
                    </p>
                  </div>
                )}
              </div>
              <div className="contBox_item flex_g1_5">
                <h5>대출 현황</h5>
                {CM.cfnIsNotEmpty(statistics) ? (
                  <div className="itemBox">
                    <div className="infoloan">
                      <ul>
                        <li className="unit">(단위 : 천원)</li>
                        <li key="likey1">
                          <span className="title" key="spanTitle1">
                            누적 대출금액
                          </span>
                          <span key="amount1">{CM.cfnAddCommaThousand(statistics.cumul_loan_amount)}</span>
                        </li>
                        <li key="likey2">
                          <span className="title" key="spanTitle2">
                            누적 상환금액
                          </span>
                          <span key="amount2">{CM.cfnAddCommaThousand(statistics.cumul_repayment_amount)}</span>
                        </li>
                        <li key="likey3">
                          <span className="title" key="spanTitle3">
                            대출잔액
                          </span>
                          <span key="amount3">{CM.cfnAddCommaThousand(statistics.loan_balance)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="itemBox borrower corp resultnoBox">
                    <p className="resultNo">
                      <ErrorOutlineIcon />
                      조회된 내용이 없습니다.
                    </p>
                  </div>
                )}
              </div>
              <div className="contBox_item">
                <h5>상품유형별 대출잔액</h5>
                {CM.cfnIsEmpty(statistics) ? (
                  <div className="itemBox resultnoBox">
                    <p className="resultNo">
                      <ErrorOutlineIcon />
                      조회된 내용이 없습니다.
                    </p>
                  </div>
                ) : (
                  <div className="itemBox">
                    <div className="graphArea">
                      <ul>
                        <li key="graphLi1">
                          <p>(단위 : %)</p>
                        </li>
                        <li key="graphLi2">
                          <div className="graph">
                            <BarChart
                              layout="vertical"
                              margin={{ top: 0, right: 5, left: 5, bottom: 5 }}
                              stackOffset="expand"
                              width={523}
                              height={126}
                              data={barData}
                            >
                              <XAxis type="number" hide />
                              <YAxis dataKey="name" type="category" hide />
                              {renderBars()}
                              <Legend
                                width={450}
                                height={50}
                                align="center"
                                layout="horizontal"
                                payload={legendData}
                                formatter={renderLegendTextColor}
                              />
                            </BarChart>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <p>※ P2P금융 통합정보는 온투업 등록업체의 온투업 등록 이후 데이터에 한하여 제공하고 있습니다.</p>
          <p>※ 22.8월분부터 집계기준 변경 (대출계약의 해제/해지정보 반영, 월말 기준 공시)</p>
          <div className="section">
            <h4>업체별 통계</h4>
            <div className="tblTop">
              <div className="topL">
                <span className="listNum">
                  전체 <strong>{CM.cfnIsNotEmpty(statisticsList.list) ? statisticsList.list.length : 0}</strong>건
                </span>
              </div>
              <div className="topR">
                <span>(단위 : 천원)</span>
              </div>
            </div>

            <TableContainer>
              <Table className="tblType" aria-label="이용기관별 통계 목록">
                <colgroup>
                  <col width="17%" />
                  <col width="22%" />
                  <col width="22%" />
                  <col width="22%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      업체명
                      {requestParams.sortProperty !== 'p2p_company_name' ? (
                        <IconButton name="p2p_company_name" onClick={(e) => createSortHandler(e, 'Y')}>
                          <ArrowDropDownIcon color="disabled" />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                    <TableCell>
                      누적 대출금액
                      {requestParams.sortProperty !== 'cumul_loan_amount' ? (
                        <IconButton name="cumul_loan_amount" onClick={(e) => createSortHandler(e, 'Y')}>
                          <ArrowDropDownIcon color="disabled" />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                    <TableCell>
                      누적 상환금액
                      {requestParams.sortProperty !== 'cumul_repayment_amount' ? (
                        <IconButton name="cumul_repayment_amount" onClick={(e) => createSortHandler(e, 'Y')}>
                          <ArrowDropDownIcon color="disabled" />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                    <TableCell>
                      대출잔액
                      {requestParams.sortProperty !== 'loan_balance' ? (
                        <IconButton name="loan_balance" onClick={(e) => createSortHandler(e, 'Y')}>
                          <ArrowDropDownIcon color="disabled" />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {CM.cfnIsNotEmpty(statisticsList.list) ? (
                    statisticsList.list.map((row, index) => (
                      <Fragment key={'fragment' + index}>
                        <TableRow className={classes.root} key={'tableRow' + index}>
                          <TableCell component="th" scope="row" className="title">
                            {row.p2p_company_name}
                          </TableCell>
                          <TableCell className="tr">{CM.cfnAddCommaThousand(row.cumul_loan_amount)}</TableCell>
                          <TableCell className="tr">{CM.cfnAddCommaThousand(row.cumul_repayment_amount)}</TableCell>
                          <TableCell className="tr">
                            {CM.cfnAddCommaThousand(row.loan_balance)}
                            <Button
                              data-testid={'intergrated-detailButton' + index}
                              id={'intergrated-detailButton' + index}
                              className="btnMore"
                              aria-label="내용 상세보기"
                              onClick={() => handleOpen(index)}
                            >
                              <span>상세보기</span>
                              {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5} className="moreDetailarea">
                            <Collapse in={CM.cfnIsEmpty(row.open) ? false : row.open} timeout="auto" unmountOnExit>
                              <div className="moreDetail" data-testid={'intergrated-detailDiv' + index}>
                                <p className="tit">
                                  <AssignmentTurnedInIcon />
                                  상품유형별 대출잔액
                                </p>
                                {CM.cfnIsNotEmpty(row) && CM.cfnIsNotEmpty(row.balance_list) ? (
                                  <TableContainer className="loannumDetail">
                                    <Table className="tblType" aria-label="상품유형별 대출잔액 상세보기 목록">
                                      <colgroup>
                                        <col width="24%" />
                                        <col width="14%" />
                                        <col width="15%" />
                                        <col width="14%" />
                                        <col width="19%" />
                                        <col width="14%" />
                                      </colgroup>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'REAL_ESTATE_PF',
                                              ),
                                            ) ? (
                                              <p>
                                                <span>
                                                  {
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'REAL_ESTATE_PF',
                                                    ).product_type_name
                                                  }
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span>부동산 PF</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'REAL_ESTATE_PF',
                                              ),
                                            ) ? (
                                              <p>
                                                <span className="num">
                                                  {CM.cfnAddCommaThousand(
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'REAL_ESTATE_PF',
                                                    ).loan_balance,
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span className="num">0</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'REAL_ESTATE_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span>
                                                  {
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'REAL_ESTATE_MORTGAGE',
                                                    ).product_type_name
                                                  }
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span>부동산 담보</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'REAL_ESTATE_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span className="num">
                                                  {CM.cfnAddCommaThousand(
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'REAL_ESTATE_MORTGAGE',
                                                    ).loan_balance,
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span className="num">0</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'BILL_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span>
                                                  {
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'BILL_MORTGAGE',
                                                    ).product_type_name
                                                  }
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span>어음·매출채권 담보</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'BILL_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span className="num">
                                                  {CM.cfnAddCommaThousand(
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'BILL_MORTGAGE',
                                                    ).loan_balance,
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span className="num">0</span>
                                              </p>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'ETC_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span>
                                                  {
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'ETC_MORTGAGE',
                                                    ).product_type_name
                                                  }
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span>기타 담보</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'ETC_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span className="num">
                                                  {CM.cfnAddCommaThousand(
                                                    row.balance_list.find(
                                                      (element) => element.product_type_code === 'ETC_MORTGAGE',
                                                    ).loan_balance,
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span className="num">0</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'PERSONAL_CREDIT_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span>
                                                  {
                                                    row.balance_list.find(
                                                      (element) =>
                                                        element.product_type_code === 'PERSONAL_CREDIT_MORTGAGE',
                                                    ).product_type_name
                                                  }
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span>개인 신용</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'PERSONAL_CREDIT_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span className="num">
                                                  {CM.cfnAddCommaThousand(
                                                    row.balance_list.find(
                                                      (element) =>
                                                        element.product_type_code === 'PERSONAL_CREDIT_MORTGAGE',
                                                    ).loan_balance,
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span className="num">0</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'CORPORATE_CREDIT_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span>
                                                  {
                                                    row.balance_list.find(
                                                      (element) =>
                                                        element.product_type_code === 'CORPORATE_CREDIT_MORTGAGE',
                                                    ).product_type_name
                                                  }
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span>법인 신용</span>
                                              </p>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {CM.cfnIsNotEmpty(
                                              row.balance_list.find(
                                                (element) => element.product_type_code === 'CORPORATE_CREDIT_MORTGAGE',
                                              ),
                                            ) ? (
                                              <p>
                                                <span className="num">
                                                  {CM.cfnAddCommaThousand(
                                                    row.balance_list.find(
                                                      (element) =>
                                                        element.product_type_code === 'CORPORATE_CREDIT_MORTGAGE',
                                                    ).loan_balance,
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                <span className="num">0</span>
                                              </p>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                ) : null}
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow data-testid={'tablerow0'} key={'tablerow0'}>
                      <TableCell colSpan={5}>조회된 내용이 없습니다.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Popup></Popup>
    </Fragment>
  );
});

export default TotalStatisticsM;
