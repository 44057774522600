/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용기관용 자료실 상세 조회
 * - 첨부파일 다운로드 기능
 */
import { Button } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const BoardDetailM = ({ match, location }) => {
  const { seq_id } = match.params; //게시글 일련번호
  const [downloadsFlag, setDownloadsFalg] = useState(true); //자료실 상세 조회 여부
  const [downloadsDetail, setDownloadsDetail] = useState({}); //자료실 상세 정보

  //자료실 상세 조회
  useEffect(() => {
    userStore.handleNaviTrigger(); //네비 현행화
    //자료실 상세 조회 결과 세팅
    const getDownloadsDetail = () => {
      const resolve = (objStatus, response) => {
        handleDownloadsDetail(response);
      };
      let url = `web/companies/downloads/${seq_id}`;
      axiosUtil.cfnAxiosApi(url, 'GET', null, null, resolve, null);
    };
    const handleDownloadsDetail = (response) => {
      setDownloadsDetail(response);
      setDownloadsFalg(false);
    };
    if (downloadsFlag) getDownloadsDetail();
  }, [seq_id]);

  //게시글 제목 세팅
  const setTitle = () => {
    const registrationDate = downloadsDetail.registration_dtm;
    return (
      <div className="tit" data-testid="title">
        <span>{downloadsDetail.title}</span>
        {/* 3일 이내 등록된 게시글의 경우 new 아이콘 표시 */}
        {CM.cfnDateCalculation(CM.cfnGetDate(), String(registrationDate).substr(0, 8)) <= 3 ? (
          <span className="iconNew">NEW</span>
        ) : (
          ''
        )}
      </div>
    );
  };

  //첨부파일 다운로드
  const handleFileDownload = (row) => {
    let fileUrl = row.file_web_url;
    let filePath = fileUrl.substr(fileUrl.lastIndexOf('/') + 1, fileUrl.length);
    let url = 'web/file/down/private/' + filePath;
    axiosUtil.cfnAxiosGeneralFileDownload(url, 'GET', null, row.file_name, null, null, true);
  };

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>자료실</h4>
          <div className="listDetail">
            <ul>
              <li>
                {setTitle()}
                <div className="infoEtc">
                  <div>
                    {CM.cfnIsEmpty(downloadsDetail) ? null : downloadsDetail.file_list.length <= 0 ? (
                      <div className="iconFile"></div>
                    ) : (
                      downloadsDetail.file_list.map((row, index) => (
                        <div className="iconFile" key={'filerow' + index}>
                          {
                            <div>
                              <AttachFileIcon />
                              첨부파일 :
                              <a data-testid={'fileDownTest' + index} onClick={() => handleFileDownload(row)}>
                                <span>
                                  {row.file_name} ({CM.cfnFileSizeCalculation(row.file_size)})
                                </span>
                              </a>
                            </div>
                          }
                        </div>
                      ))
                    )}
                  </div>
                  <div className="etcR">
                    <span className="hide">게시일</span>
                    {<DescriptionOutlinedIcon />}
                    <span className="date">{CM.cfnDateFormat(downloadsDetail.registration_dtm, 'yyyyMMdd')}</span>
                    <span className="hide">조회수</span>
                    {<DraftsOutlinedIcon />}
                    <span>{CM.cfnAddComma(downloadsDetail.inquiry_count)}</span>
                  </div>
                </div>
              </li>
              <li>
                <pre>{downloadsDetail.contents}</pre>
              </li>
            </ul>
          </div>
          <div className="btnArea">
            <Link
              to={{
                pathname: '/board/downloads',
                state: {
                  requestParams: CM.cfnIsNotEmpty(location.state) ? location.state.requestParams : null,
                },
              }}
            >
              <Button vatiant="contained" className="btnLarge" id="listBtn">
                목록
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardDetailM;
