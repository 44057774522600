/*
 * @version : 2021.02.15
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * Navi
 */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/* 컨텐츠 영역_서브 네비게이션 */
function Navi() {
  let requestUri = window.location.href;
  const [style1depth, setStyle1depth] = useState({ display: 'none' });
  const [style2depth, setStyle2depth] = useState({ display: 'none' });

  //1depth 네비명 세팅
  const get1depthNm = () => {
    if (requestUri.indexOf('/statistics/') > 0) {
      return '감독당국용 통계조회';
    } else if (requestUri.indexOf('/registrationCompanies') > 0) {
      return '이용기관 현황';
    } else if (requestUri.indexOf('/board') > 0) {
      return '공지사항';
    } else {
      return '감독당국용 통계조회';
    }
  };

  //2depth 네비명 세팅
  const get2depthNm = () => {
    if (requestUri.indexOf('/statistics/intergratedinfo') > 0) {
      return 'P2P금융 통합정보';
    } else if (requestUri.indexOf('/statistics/supervisorDetail') > 0) {
      return '감독당국 세부통계';
    } else if (requestUri.indexOf('/registrationCompanies') > 0) {
      return '이용기관 현황';
    } else if (requestUri.indexOf('/board') > 0) {
      return '공지사항';
    } else {
      return 'P2P금융 통합정보';
    }
  };

  //2depth 네비 메뉴박스 세팅
  const get2depthNavi = () => {
    if (requestUri.indexOf('/statistics/') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/statistics/intergratedinfo">P2P금융 통합정보</Link>
          </li>
          <li>
            <Link to="/statistics/supervisorDetail">감독당국 세부통계</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/registrationCompanies') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/registrationCompanies">이용기관 현황</Link>
          </li>
        </ul>
      );
    } else if (requestUri.indexOf('/board') > 0) {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/board">공지사항</Link>
          </li>
        </ul>
      );
    } else {
      return (
        <ul
          className="depthDetail"
          style={style2depth}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <li>
            <Link to="/statistics/intergratedinfo">P2P금융 통합정보</Link>
          </li>
          <li>
            <Link to="/statistics/supervisorDetail">감독당국 세부통계</Link>
          </li>
        </ul>
      );
    }
  };

  return (
    <div className="navi">
      <ul className="naviWrap">
        <li>
          <Link to="/statistics/intergratedinfo" className="home">
            <span>HOME</span>
            <HomeIcon />
          </Link>
        </li>
        <li
          onClick={() => {
            setStyle1depth({ display: 'block' });
          }}
          onMouseLeave={() => {
            setStyle1depth({ display: 'none' });
          }}
        >
          <button>
            <span>
              <h2>{get1depthNm()}</h2>
            </span>
            <ExpandMoreIcon />
          </button>
          <ul
            className="depthDetail"
            style={style1depth}
            onMouseLeave={() => {
              setStyle1depth({ display: 'none' });
            }}
          >
            <li>
              <Link to="/statistics/intergratedinfo">감독당국용 통계조회</Link>
            </li>
            <li>
              <Link to="/registrationCompanies">이용기관 현황</Link>
            </li>
            <li>
              <Link to="/board">공지사항</Link>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            setStyle2depth({ display: 'block' });
          }}
          onMouseLeave={() => {
            setStyle2depth({ display: 'none' });
          }}
        >
          <button>
            <span>
              <h3>{get2depthNm()}</h3>
            </span>
            <ExpandMoreIcon />
          </button>
          {get2depthNavi()}
        </li>
        {/* <li>
                    <a href="#"><span>3뎁스</span></a>  
                    <ul className="depthDetail"> 
                        <li><a href="#">3뎁스</a></li>
                        <li><a href="#">3뎁스</a></li>
                    </ul>
                </li> */}
      </ul>
    </div>
  );
}
export default Navi;
