/*
 * @version : 2022.01.10
 * @author : 금융결제원
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국용 통계조회 - 감독당국 세부통계 메인화면
 */
import { Tab, Tabs } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { userStore } from '../../../../stores/supervisors/userStore';
import InvestStatusStatisticsM from './InvestStatusStatisticsM';
import LoanStatusStatisticsM from './LoanStatusStatisticsM';

const SupervisorDetailStatisticsM = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    userStore.handleNaviTrigger();
  }, []);

  function allyProps(index) {
    return {
      id: `${index}`,
      'aria-controls': `${index}`,
    };
  }

  /* 컨텐츠 영역_탭 */
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <div className="tabLen2">
            <div className="tabArea">
              <Tabs value={tabIndex} onChange={handleTabIndex} aria-label="감독당국 세부통계 메뉴선택">
                <Tab label="투자자별 연계투자 현황" {...allyProps(0)} />
                <Tab label="연계대출채권 현황" {...allyProps(1)} />
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <InvestStatusStatisticsM handleTabIndex={handleTabIndex}></InvestStatusStatisticsM>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <LoanStatusStatisticsM></LoanStatusStatisticsM>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupervisorDetailStatisticsM;
