/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 업무정보 조회 - 기록내역 조회
 * 연계투자기록 - 투자신청
 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const RecordHistoryInvestRequestS = (props) => {
  const effect = props;
  const currentTab = effect.currentTab;
  // const [investRequest, setInvestRequest] = useState([]); //투자신청
  // //투자연관기록
  // const [relationRequest, setRelationRequest] = useState({ "list": [] }); //연관기록내역 투자신청

  let msg = []; //오류 메시지

  //화면로딩 시 실행
  useEffect(() => {
    // 투자신청 handler
    const handleInvestRequest = (paramRelationRequest) => {
      let tmpTab = CM.cfnCopyObject(effect.tab);
      let tmpThisTab = tmpTab[currentTab];
      let tmp = tmpThisTab.contents;
      // if (CM.cfnIsNotEmpty(resultData) && resultData !== effect.tab[currentTab].contents.basic) {
      //     props.setSearchTime.call(null, resultData.api_trx_dtm);
      //     // setInvestRequest(resultData);
      //     tmpRp.view = true;
      //     tmpRp.basic = resultData
      // } else {
      //     tmpRp.view = false;
      // }
      if (CM.cfnIsNotEmpty(paramRelationRequest) && paramRelationRequest !== effect.tab[currentTab].contents.relation) {
        // setRelationRequest(props.sortInvestRelation(paramRelationRequest));
        tmp.relation = props.sortInvestRelation(paramRelationRequest);
      }
      effect.setTab(tmpTab);
    };
    //투자신청 조회
    const axiosInvestRequest = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/investments/requests/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M007);
            resolve(null);
          },
        );
      });
    };

    //연관기록내역 조회
    const axiosRelation = (param) => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/investments/relations/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;
        url += `&record_type=${param}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M008);
            resolve(null);
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    const startAxios = async () => {
      // const resultData = await axiosInvestRequest(); //투자신청
      //투자상품(INVEST_PRODUCT), 투자신청(INVEST_REQUEST), 투자계약(INVEST_CONTRACT)
      const relationRequest = await axiosRelation('INVEST_REQUEST'); //연관기록내역-투자신청
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handleInvestRequest(relationRequest);
    };

    if (effect.tab[currentTab].contents.view && CM.cfnIsEmpty(effect.tab[currentTab].contents.relation.list))
      startAxios();
  }, [effect.currentTab, effect.setCurrentTab]);

  return (
    <div className="inner">
      <h6 className="hide">조회결과 상세내용</h6>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">기록관리 ID</p>
          <div className="itemBox corpInfo histidImg">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="name">{effect.tab[currentTab].contents.basic.record_no}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">투자자 정보</p>
          <div className="itemBox borrower">
            <div className="infoMain">
              <ul>
                <li>
                  <span className="title">투자자 성명</span>
                  <span>{effect.tab[currentTab].contents.basic.investor_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">투자자 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.investor_type_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">고유식별번호</span>
                  <span>{CM.cfnIdentificationNoFormat(String(effect.tab[currentTab].contents.basic.identity_no))}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">상세조회내역</p>
          <div className="itemBox">
            <div className="itemArea">
              <p className="smallTit">투자신청 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">투자상품유형</span>
                  <span>{effect.tab[currentTab].contents.basic.product_type_name}</span>
                </li>
                <li>
                  <span className="tit">투자신청금액</span>
                  <span>{CM.cfnAddComma(effect.tab[currentTab].contents.basic.invest_request_amount)}원</span>
                </li>
                <li>
                  <span className="tit">투자신청일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.invest_request_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
                <li>
                  <span className="tit w240">계속적 반복적 투자여부</span>
                  <span>
                    {effect.tab[currentTab].contents.basic.continuous_repetative_investment_yn === 'Y'
                      ? '해당'
                      : '비해당'}
                  </span>
                </li>
                <li>
                  <span className="tit">반복적 투자일수</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.repetative_investment_days) ||
                    effect.tab[currentTab].contents.basic.repetative_investment_days === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.repetative_investment_days) + '일'}
                  </span>
                </li>
                <li>
                  <span className="tit">건당 반복적 투자금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.repetative_investment_amount) ||
                    effect.tab[currentTab].contents.basic.repetative_investment_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.repetative_investment_amount) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit w240">기간 중 총 반복적 투자금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.repetative_investment_sum_amount) ||
                    effect.tab[currentTab].contents.basic.repetative_investment_sum_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.repetative_investment_sum_amount) + '원'}
                  </span>
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">상태</p>
              <ul className="infoList">
                <li>
                  <span className="tit">투자신청 상태</span>
                  <span data-testid="test-status">{effect.tab[currentTab].contents.basic.invest_request_status}</span>
                  {/* <span className="red">진행 중</span> 진행중일 경우 글자색 레드 */}
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">이력 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">최초등록일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.first_registration_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">연관기록내역</p>
          <TableContainer>
            <Table
              className="tblTypeline"
              aria-label="대출신청, 투자상품, 대출계약, 대출상환으로 이루어진 연관 기록내역 목록"
            >
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="33%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>[참고]투자상품</TableCell>
                  <TableCell>투자신청</TableCell>
                  <TableCell>투자계약</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {effect.tab[currentTab].contents.relation.list.map((row, index) => {
                    if (row.record_type === 'INVEST_PRODUCT') {
                      return (
                        <TableCell key="investProduct" data-testid="renderTest">
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'INVEST_REQUEST') {
                      return (
                        <TableCell key="investRequest" className="bglightSky">
                          <p>{row.record_no}</p>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'INVEST_CONTRACT') {
                      return (
                        <TableCell key="investContract" rowSpan={effect.tab[currentTab].contents.relation.list.length}>
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'investments,연계투자,2,투자계약,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">(최초등록일시 {CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')})</p>
                        </TableCell>
                      );
                    }
                  })}
                  {effect.tab[currentTab].contents.relation.list.length < 3
                    ? [...Array(3 - effect.tab[currentTab].contents.relation.list.length)].map((n, index) => {
                        return <TableCell key={index} />;
                      })
                    : null}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RecordHistoryInvestRequestS;
