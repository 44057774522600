/*
 * @version : 2022.06.16
 * @author : 금융결제원
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 로그인 - KG 휴대폰 인증 팝업
 * - 대고객 로그인 화면(LoginS.js)에서 휴대폰 인증을 사용하여 인증하는 경우 kg의 인증 결과를 리턴받는 팝업 화면
 * - 인증 결과를 부모창으로 전달 후 콜백 함수 실행하고 팝업 닫음 처리
 * - 사용자에게 노출되지 않는 데이터 전달용 화면이기 때문에 별도 test code 작성하지 않음
 */
import { useEffect } from 'react';
import qs from 'qs';

const LoginKgP = ({ location }) => {
  //kg 휴대폰 인증 결과 리턴 페이지 로딩 시 실행
  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    //전달받은 인증 결과 부모창(LoginS.js)으로 전달
    window.opener.parent.document.phoneCertCallbackForm.company_service_id.value = 'KG_MOBILIANS';
    window.opener.parent.document.phoneCertCallbackForm.Ci.value = query.Ci;
    window.opener.parent.document.phoneCertCallbackForm.Commid.value = query.Commid;
    window.opener.parent.document.phoneCertCallbackForm.Signdate.value = query.Signdate;
    window.opener.parent.document.phoneCertCallbackForm.Di.value = query.Di;
    window.opener.parent.document.phoneCertCallbackForm.Foreigner.value = query.Foreigner;
    window.opener.parent.document.phoneCertCallbackForm.Keygb.value = query.Keygb;
    window.opener.parent.document.phoneCertCallbackForm.Mac.value = query.Mac;
    window.opener.parent.document.phoneCertCallbackForm.Mobilid.value = query.Mobilid;
    window.opener.parent.document.phoneCertCallbackForm.Name.value = query.Name;
    window.opener.parent.document.phoneCertCallbackForm.No.value = query.No;
    window.opener.parent.document.phoneCertCallbackForm.Sex.value = query.Sex;
    window.opener.parent.document.phoneCertCallbackForm.Socialno.value = query.Socialno;
    window.opener.parent.document.phoneCertCallbackForm.Tradeid.value = query.Tradeid;

    window.opener.parent.document.phoneCertCallbackForm.submitBtn.click(); // 데이터 전달 후 부모창의 휴대폰 인증 콜백 함수 실행
    window.self.close(); // 팝업 닫음
  });

  return (
    <div>
      <div className="section" />
    </div>
  );
};

export default LoginKgP;
