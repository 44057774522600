/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * (공통) axios API 호출
 */
import axios from 'axios';

const axiosApiInstance = axios.create({
  baseURL: 'https://p2p-mock-server/',
  params: {
    api_key: 'apiKey',
    language: 'en-US',
  },
});

const axiosApi = {
  axiosApiInstance,

  getData: (url) => {
    return axiosApiInstance({
      method: 'GET',
      url: url,
    });
  },
  postData: (url, data) => {
    return axiosApiInstance({
      method: 'POST',
      url: url,
      data: data,
    });
  },
  putData: (url, data) => {
    return axiosApiInstance({
      method: 'PUT',
      url: url,
      data: data,
    });
  },
  deleteData: (url, data) => {
    return axiosApiInstance({
      method: 'DELETE',
      url: url,
    });
  },
};

export default axiosApi;
