/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 업무정보 조회 - 기록내역 조회
 * 연계대출기록 - 대출상환
 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const RecordHistoryLoanRepaymentS = (props) => {
  const effect = props;
  const currentTab = effect.currentTab;
  // const [loanRepayment, setLoanRepayment] = useState([]); //대출상환
  // //대출연관기록
  // const [relationRepayment, setRelationRepayment] = useState({ "list": [] }); //연관기록내역 대출계약

  let msg = []; //오류 메시지

  //화면로딩 시 실행
  useEffect(() => {
    // 대출상환 handler
    const handleLoanRepayment = (paramRelationRepayment) => {
      let tmpTab = CM.cfnCopyObject(effect.tab);
      let tmpThisTab = tmpTab[currentTab];
      let tmp = tmpThisTab.contents;
      // if (CM.cfnIsNotEmpty(resultData) && resultData !== effect.tab[currentTab].contents.basic) {
      //     props.setSearchTime.call(null, resultData.api_trx_dtm);
      //     // setLoanRepayment(resultData);
      //     tmpRp.view = true;
      //     tmpRp.basic = resultData
      // } else {
      //     tmpRp.view = false;
      // }
      if (
        CM.cfnIsNotEmpty(paramRelationRepayment) &&
        paramRelationRepayment !== effect.tab[currentTab].contents.relation
      ) {
        // setRelationRepayment(props.sortLoanRelation(paramRelationRepayment));
        tmp.relation = props.sortLoanRelation(paramRelationRepayment);
      }
      effect.setTab(tmpTab);
    };
    //대출상환 조회
    const axiosLoanRepayment = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/repayments/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            let tmpRp = CM.cfnCopyObject(effect.requestParams.loans4);
            tmpRp.view = false;
            effect.setRequestParams((data) => ({
              ...data,
              loans4: tmpRp,
            }));
            msg.push(process.env.REACT_APP_M007);
            resolve(null);
          },
        );
      });
    };

    //연관기록내역 조회
    const axiosRelation = (param) => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/relations/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;
        url += `&record_type=${param}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M008);
            resolve(null);
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    const startAxios = async () => {
      // const resultData = await axiosLoanRepayment(); //대출상환
      //대출신청(LOAN_REQUEST), 투자상품(INVEST_PRODUCT), 대출계약(LOAN_CONTRACT), 대출상환(LOAN_REPAYMENT)
      const relationRepayment = await axiosRelation('LOAN_REPAYMENT'); //연관기록내역-대출상환
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handleLoanRepayment(relationRepayment);
    };

    if (effect.tab[currentTab].contents.view && CM.cfnIsEmpty(effect.tab[currentTab].contents.relation.list))
      startAxios();
  }, [effect.currentTab, effect.setCurrentTab]);

  return (
    <div className="inner">
      <h6 className="hide">조회결과 상세내용</h6>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">기록관리 ID</p>
          <div className="itemBox corpInfo histidImg">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="name">{effect.tab[currentTab].contents.basic.record_no}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">차입자 정보</p>
          <div className="itemBox borrower">
            <div className="infoMain">
              <ul>
                <li className="w50p">
                  <span className="title">차입자 성명</span>
                  <span>{effect.tab[currentTab].contents.basic.borrower_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">고유식별번호</span>
                  <span>{CM.cfnIdentificationNoFormat(String(effect.tab[currentTab].contents.basic.identity_no))}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">상세조회내역</p>
          <div className="itemBox">
            <div className="itemArea">
              <p className="smallTit">대출상환 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">상환회차</span>
                  <span>{effect.tab[currentTab].contents.basic.repayment_seqno}회차</span>
                </li>
                <li>
                  <span className="tit">예정 상환일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.schd_repayment_date, 'yyyyMMdd')}</span>
                </li>
                <li>
                  <span className="tit">예정 상환원금</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.schd_repayment_principal) ||
                    effect.tab[currentTab].contents.basic.schd_repayment_principal === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.schd_repayment_principal) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">예정 상환이자금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.schd_repayment_interest) ||
                    effect.tab[currentTab].contents.basic.schd_repayment_interest === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.schd_repayment_interest) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">예정 수수료</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.schd_fee) ||
                    effect.tab[currentTab].contents.basic.schd_fee === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.schd_fee) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">실제 상환일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.real_repayment_date, 'yyyyMMdd')}</span>
                </li>
                <li>
                  <span className="tit">상환원금</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.loan_repayment_principal) ||
                    effect.tab[currentTab].contents.basic.loan_repayment_principal === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.loan_repayment_principal) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">상환이자금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.loan_repayment_interest) ||
                    effect.tab[currentTab].contents.basic.loan_repayment_interest === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.loan_repayment_interest) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">연체이자금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.overdue_interest) ||
                    effect.tab[currentTab].contents.basic.overdue_interest === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.overdue_interest) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">수수료</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.fee) ||
                    effect.tab[currentTab].contents.basic.fee === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.fee) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">실제 상환금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.real_repayment_amount) ||
                    effect.tab[currentTab].contents.basic.real_repayment_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.real_repayment_amount) + '원'}
                  </span>
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">상태</p>
              <ul className="infoList">
                <li>
                  <span className="tit">대출상환 상태</span>
                  <span>{effect.tab[currentTab].contents.basic.loan_repayment_status}</span>
                  {/* <span className="red">진행 중</span> 진행중일 경우 글자색 레드 */}
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">이력 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">최초등록일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.first_registration_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">연관기록내역</p>
          <TableContainer>
            <Table
              className="tblTypeline"
              aria-label="대출신청, 투자상품, 대출계약, 대출상환으로 이루어진 연관 기록내역 목록"
            >
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="33%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>대출신청</TableCell>
                  <TableCell>투자상품</TableCell>
                  <TableCell>대출계약</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {effect.tab[currentTab].contents.relation.list.map((row, index) => {
                    if (row.record_type === 'LOAN_REQUEST') {
                      return (
                        <TableCell
                          key="loanRequest"
                          data-testid="renderTest"
                          rowSpan={
                            effect.tab[currentTab].contents.relation.productCnt < 2
                              ? 1
                              : effect.tab[currentTab].contents.relation.productCnt
                          }
                        >
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,1,대출신청,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (
                      row.record_type === 'INVEST_PRODUCT' &&
                      index <= effect.tab[currentTab].contents.relation.secondProductIndex
                    ) {
                      return (
                        <TableCell key="investProduct">
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'LOAN_CONTRACT') {
                      return (
                        <TableCell
                          key="loanContract"
                          rowSpan={effect.tab[currentTab].contents.relation.list.length}
                          rowSpan={
                            effect.tab[currentTab].contents.relation.productCnt < 2
                              ? 1
                              : effect.tab[currentTab].contents.relation.productCnt
                          }
                        >
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,3,대출계약,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    }
                  })}
                  {effect.tab[currentTab].contents.relation.list.length < 3
                    ? [...Array(3 - effect.tab[currentTab].contents.relation.list.length)].map((n, index) => {
                        return <TableCell key={index} />;
                      })
                    : null}
                </TableRow>
                {effect.tab[currentTab].contents.relation.productCnt > 1
                  ? effect.tab[currentTab].contents.relation.list.map((row, index) =>
                      row.record_type === 'INVEST_PRODUCT' &&
                      index > effect.tab[currentTab].contents.relation.secondProductIndex ? (
                        <TableRow key={'investProduct' + index}>
                          <TableCell>
                            <span onClick={props.handleLink}>
                              <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                                {row.record_no}
                              </p>
                            </span>
                            <p className="font14">
                              {CM.cfnIsNotEmpty(row.record_dtm) &&
                                '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                            </p>
                          </TableCell>
                        </TableRow>
                      ) : null,
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RecordHistoryLoanRepaymentS;
