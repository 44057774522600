/*
 * @version : 2021.02.04
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용 내역 통계
 */

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as CM from '../../../common/util/common';
import * as CF from '../../../common/template/ComponentForm';
import SearchIcon from '@material-ui/icons/Search';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';

//검색 영역
const SearchBox = (props) => {
  return (
    <div className="searchArea">
      <div className="tblSearch">
        <form>
          <table>
            <colgroup>
              <col width="12%" />
              <col width="35%" />
              <col width="38%" />
              <col width="15%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">조회기간</th>
                <td>
                  <div className="searchList">
                    <div className="datePick">
                      <CF.DatePickerForm
                        id="inquiryStartDate"
                        testId="inquiryStartDate"
                        value={props.requestParams.inquiryStartDate}
                        handleChange={props.handleInquiryStartDate}
                        customProps={{
                          maxDate: new Date(),
                        }}
                      />
                      <span className="rowline">―</span>
                      <CF.DatePickerForm
                        id="inquiryEndDate"
                        value={props.requestParams.inquiryEndDate}
                        handleChange={props.handleInquiryEndDate}
                        customProps={{
                          maxDate: new Date(),
                        }}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <span className="info">
                    ※ 현재기준 당일까지 조회가 가능합니다.
                    <br /> (최대 6개월의 범위 내에서 검색 가능합니다)
                  </span>
                </td>
                <td>
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    data-testid="searchBtn"
                    onClick={props.handleClick}
                  >
                    조회
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

const UsageHistoryStatisticsM = () => {
  const [usageHistory, setUsageHistory] = useState({});
  const [buttonState, setButtonState] = useState(true);
  const [requestParams, setRequestParams] = useState({
    inquiryStartDate: CM.cfnGetCustomDate('month', -1, 'string'),
    inquiryEndDate: CM.cfnGetDate(),
  });

  const handleInquiryStartDate = (date) => {
    setRequestParams((data) => ({ ...data, inquiryStartDate: CM.cfnConvertDateToString(date) }));
  };

  const handleInquiryEndDate = (date) => {
    setRequestParams((data) => ({ ...data, inquiryEndDate: CM.cfnConvertDateToString(date) }));
  };

  const handleUsageHistory = (response) => {
    setUsageHistory(response);
  };

  const handleClick = () => {
    setButtonState(true);
  };

  useEffect(() => {
    userStore.handleNaviTrigger();

    const getApiUsage = (params) => {
      return new Promise((resolve, reject) => {
        let url = 'web/companies/statistics/api-usage';
        url += `?inquiry_start_date=${requestParams.inquiryStartDate}&inquiry_end_date=${requestParams.inquiryEndDate}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          null,
        );
      });
    };

    const startGetApiUsage = async (params) => {
      setButtonState(false);
      const resultData = await getApiUsage(params);
      handleUsageHistory(resultData);
    };

    if (buttonState) {
      if (!CM.cfnCommonValidDate(requestParams.inquiryStartDate, requestParams.inquiryEndDate, 6)) {
        setButtonState(false);
        return false;
      }

      startGetApiUsage(requestParams);
    }
  }, [buttonState, requestParams]);

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>이용기관 정보</h4>
          <div className="contBox">
            <div className="contBox_item w100p">
              <div className="itemBox corpInfo">
                <div className="infoStatus">
                  <div className="StatusL">
                    <p className="title">이용기관명</p>
                    <p className="name">{userStore.getCompanyName()}</p>
                  </div>
                  <div className="StatusR">
                    <p className="title">이용기관 코드</p>
                    <p className="name">{userStore.getCompanyCode()}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 이용내역 통계 */}
        <div className="section">
          <h4>API 이용 내역 통계</h4>
          {/* 검색영역 */}
          <SearchBox
            requestParams={requestParams}
            handleInquiryStartDate={handleInquiryStartDate}
            handleInquiryEndDate={handleInquiryEndDate}
            handleClick={handleClick}
          />
          <TableContainer>
            <Table
              className="tblTypeline sumType"
              aria-label="순번, API 종류, 이용건수 항목으로 이루어진 이력정보 목록"
            >
              <colgroup>
                <col width="15%" />
                <col width="55%" />
                <col width="30%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>순번</TableCell>
                  <TableCell>API 종류</TableCell>
                  <TableCell>이용건수</TableCell>
                </TableRow>
              </TableHead>
              {CM.cfnIsEmpty(usageHistory) || usageHistory.list.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <p className="resultNo">조회된 내용이 없습니다.</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {usageHistory.list.map((row, index) => (
                    <TableRow key={'tablerow' + index} data-testid={'tablerow' + index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.api_name}</TableCell>
                      <TableCell>{row.api_usage_count}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="sum">
                    <TableCell component="th" scope="row" colSpan={2}>
                      합계
                    </TableCell>
                    <TableCell>{usageHistory.total_usage_count}</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default UsageHistoryStatisticsM;
