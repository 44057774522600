/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 관리자포탈 user store
 */
import { observable } from 'mobx';
import * as CM from '../../components/common/util/common';
import axiosUtil from '../../utils/axiosUtil';

const userStore = observable({
  naviLocation: '', //네비게이션 렌더링을 위한 주소
  timeLimit: 60 * 30, //로그인 유지 시간
  sessiontimeLimit: 60 * 5, //로그인 연장 시간

  //로그인 여부 불러오기
  getIsLogin() {
    return sessionStorage.getItem('svIsLogin');
  },

  //사용자 ID 불러오기
  getUserId() {
    return sessionStorage.getItem('svUserId');
  },

  //사용자명 불러오기
  getUserName() {
    return sessionStorage.getItem('svUserName');
  },

  //사용자 UUID 불러오기
  getUserToken() {
    return sessionStorage.getItem('svUserToken');
  },

  //API 인증 토큰 불러오기
  getAuthToken() {
    return sessionStorage.getItem('svAuthToken');
  },

  //기관명 불러오기
  getCompanyName() {
    return sessionStorage.getItem('svCompanyName');
  },

  //기관코드 불러오기
  getCompanyCode() {
    return sessionStorage.getItem('svCompanyCode');
  },

  //클라이언트 접속IP 불러오기
  getClientIp() {
    return sessionStorage.getItem('svClientIp');
  },

  // //로그인 시간 불러오기
  // getIsLoginTime() {
  //     return sessionStorage.getItem('svLoginTime');
  // },

  //네비게이션 렌더링
  handleNaviTrigger() {
    let requestUri = window.location.href;
    this.naviLocation = requestUri;
  },

  //로그인
  login(id, pin) {
    let url = 'api/login';
    const loginData = {
      userId: id,
      userPin: pin,
    };
  },

  //로그인 정보 등록
  registerLogin(id, userToken, authToken, userName, companyName, companyCode, clientIp) {
    sessionStorage.setItem('svIsLogin', true);
    sessionStorage.setItem('svUserId', id);
    sessionStorage.setItem('svUserToken', userToken);
    sessionStorage.setItem('svAuthToken', authToken);
    sessionStorage.setItem('svUserName', userName);
    sessionStorage.setItem('svCompanyName', companyName);
    sessionStorage.setItem('svCompanyCode', companyCode);
    sessionStorage.setItem('svClientIp', clientIp);
    sessionStorage.setItem('svLoginTime', new Date().getTime());
    sessionStorage.setItem('svLoginCheckTime', new Date().getTime());
  },

  //로그아웃
  logout() {
    let url = 'auth/logout';
    const resolve = (objStatus, response) => {
      sessionStorage.removeItem('svIsLogin');
      sessionStorage.removeItem('svUserId');
      sessionStorage.removeItem('svUserToken');
      sessionStorage.removeItem('svAuthToken');
      sessionStorage.removeItem('svUserName');
      sessionStorage.removeItem('svCompanyName');
      sessionStorage.removeItem('svCompanyCode');
      sessionStorage.removeItem('svLoginTime');
      sessionStorage.removeItem('svClientIp');
      sessionStorage.removeItem('svLoginCheckTime');
      window.location.replace('/'); //기본 화면 이동
    };
    axiosUtil.cfnAxiosApi(url, 'POST', null, this.getAuthToken(), resolve, resolve);
  },

  //로그인 만료 검사(30분)
  logoutCheck() {
    if (CM.cfnIsNotEmpty(sessionStorage.getItem('svIsLogin'))) {
      let nowTime = new Date().getTime();
      let loginTime = sessionStorage.getItem('svLoginTime');
      let timer = Math.floor((nowTime - loginTime) / 1000);
      if (timer >= this.timeLimit) {
        //만료 시 로그아웃 처리
        CM.cfnAlert('세션이 만료되어 로그인 화면으로 이동합니다.', () => {
          this.logout();
        });
      } else {
        let loginCheckTime = sessionStorage.getItem('svLoginCheckTime');
        timer = Math.floor((nowTime - loginCheckTime) / 1000);
        if (timer >= this.sessiontimeLimit) {
          //1분 주기로 세션 연장 API 요청
          let url = 'auth/session';
          //운영 서버가 아닌 경우 개발 로그인 모드 파라미터 사용
          if (process.env.NODE_ENV !== 'production') {
            // url += '?system_profile=LOCAL_SYSTEM_PROFILE';
          }
          const resolve = (objStatus, response) => {
            sessionStorage.setItem('svLoginTime', new Date().getTime());
            sessionStorage.setItem('svLoginCheckTime', new Date().getTime()); //로그인 세션 연장 체크 시간
          };
          const reject = (objStatus, response) => {
            CM.cfnAlert('세션이 만료되어 로그인 화면으로 이동합니다.', () => {
              this.logout();
            });
          };
          axiosUtil.cfnAxiosApi(url, 'POST', null, this.getAuthToken(), resolve, reject);
        }
      }
    }
  },

  //라우트용 로그인 만료 검사(30분)
  logoutCheckRoute() {
    let result = true;
    if (CM.cfnIsNotEmpty(sessionStorage.getItem('svIsLogin'))) {
      let nowTime = new Date().getTime();
      let loginTime = sessionStorage.getItem('svLoginTime');
      let timer = Math.floor((nowTime - loginTime) / 1000);
      if (timer >= this.timeLimit) {
        //만료 시 로그아웃 처리
        result = false;
        sessionStorage.removeItem('svIsLogin');
        sessionStorage.removeItem('svUserId');
        sessionStorage.removeItem('svUserToken');
        sessionStorage.removeItem('svAuthToken');
        sessionStorage.removeItem('svUserName');
        sessionStorage.removeItem('svCompanyName');
        sessionStorage.removeItem('svCompanyCode');
        sessionStorage.removeItem('svLoginTime');
        sessionStorage.removeItem('svClientIp');
        sessionStorage.removeItem('svLoginCheckTime');
      }
    }
    return result;
  },
});

export { userStore };
