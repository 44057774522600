import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/customers/userStore';

const IntroduceAgencyM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce">
          <h4>금융결제원 안내</h4>
          <div className="section infoKftc">
            <div className="areaL">
              <ul>
                <li>
                  <strong>금융결제원</strong>은<br />
                  안전하고 편리한 금융결제 서비스로
                  <br />더 나은 미래를 만들어 갑니다.
                </li>
                <li>
                  <strong>금융결제원</strong>은 자금결제와 정보유통을 원활하게 함으로써 금융산업의 발전 및 금융소비자
                  편의 증진 등 금융산업 발전에 기여할 목적으로 설립된 <strong>국내 유일의 지급결제전문기관</strong>
                  입니다.
                </li>
                <li>
                  주요 사업으로는 계좌이체, 결제, 인증, 정보중계, 지로, 어음 등 국민이 일상 금융생활에 이용하는 다양한
                  금융서비스를 제공하고 있으며, 금융결제서비스의 중심기관으로서의 역할을 수행하고 있습니다.
                  <br />
                  또한 최근에는 급변하는 금융시장의 변화에 맞춰 핀테크 산업의 발전을 지원하고자 오픈뱅킹 시스템을
                  구축하고 다양한 금융서비스가 금융시장에 제공될 수 있는 기반을 마련하였습니다.
                </li>
                <li>
                  금융결제원은 금융과 IT기술이 융합되는 금융혁신 흐름에 맞춰 더욱 편리하고 안전한 서비스를 제공하는 선진
                  글로벌 지급결제전문기관으로 나아가겠습니다.
                </li>
              </ul>
              <Button
                endIcon={<ArrowForwardIcon />}
                onClick={() => window.open(process.env.REACT_APP_EGOV_MAIN, '_blank')}
              >
                금융결제원 바로가기
              </Button>
            </div>
            <p className="imgKftc">
              <span className="hide">금융결제원 전경</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroduceAgencyM;
