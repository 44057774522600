/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 P2P 금융정보 조회 - 투자/대출내역조회 서브화면(2. 로그인)
 */
import { useEffect, useRef, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import { Button, Tab, Tabs, TextField } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const LoginS = (props) => {
  //state
  const [scriptState, setScriptState] = useState(true); //스크립트 추가 여부
  const [value, setValue] = useState(0); //탭 변수(0:개인, 1:법인)

  //ref
  const personNumber1Ref = useRef(null); //개인사용자 정보 앞자리
  const personNumber2Ref = useRef(null); //개인사용자 정보 뒷자리
  const corporationNumber1Ref = useRef(null); //법인사용자 정보 앞자리
  const corporationNumber2Ref = useRef(null); //법인사용자 정보 중간
  const corporationNumber3Ref = useRef(null); //법인사용자 정보 뒷자리

  //화면 최초 진입 시 nos 모듈 관련 스크립트 추가
  useEffect(() => {
    window.scrollTo(0, 0); //스크롤 처음으로 이동
    if (scriptState && process.env.REACT_APP_MOCK !== 'true') {
      //화면 최초 진입 확인 및 mock서버 실행환경 아닌 경우 스크립트 추가
      document.body.appendChild(makeScript('/lib/js/jquery-1.11.1.min.js'));
      document.body.appendChild(makeScript('/lib/js/' + process.env.REACT_APP_YESSIGN_NPPFS_INSTALL + '.js')); //nppfs-1.13.0_dev/pro
      document.body.appendChild(makeScript('/lib/js/nppfs.addon.js'));
      document.body.appendChild(makeScript('/lib/js/yessign-nppfs-1.5.0.min.js'));
      document.body.appendChild(makeScript('/lib/js/yessignUtil.js'));
      document.body.appendChild(makeScript('/lib/js/' + process.env.REACT_APP_YESSIGN_YESSIGNCRYPO_INSTALL + '.js')); //yessignCrypto_dev/pro
      document.body.appendChild(makeScript('/lib/js/yessignInstall.js'));
      document.body.appendChild(makeScript('/lib/js/TouchEnKey.js'));

      // KG 인증을 위한 스크립트 추가(ext_inc_comm.js)
      document.head.appendChild(makeScript('https://auth.mobilians.co.kr/js/ext/ext_inc_comm.js'));
      setScriptState(false);
    }
    if (CM.cfnIsNotEmpty(window.npPfsCtrl)) window.npPfsCtrl.RescanField(); //보안필드 적용함수 실행
  }, [scriptState]);

  //개인, 법인 사용자 탭 변경 시 키보드 보안 재적용
  useEffect(() => {
    if (!scriptState) {
      //최초 진입 이후 실행
      if (CM.cfnIsNotEmpty(window.npPfsCtrl)) window.npPfsCtrl.RescanField(); //보안필드 적용함수 재실행
    }
  });

  //스크립트 추가 함수
  const makeScript = (path) => {
    const script = document.createElement('script');
    script.src = path;
    script.async = false;
    return script;
  };

  //탭 정보
  function allyProps(index) {
    return {
      id: `${index}`,
      'aria-controls': `${index}`,
      'data-testid': 'testTabBtn' + `${index}`,
    };
  }

  //탭 변경 이벤트
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* 컨텐츠 영역_로그인_탭 */
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  const isMobile = () => {
    let result = false;
    var UserAgent = navigator.userAgent;
    //모바일일 경우
    if (
      UserAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
      ) != null ||
      UserAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      result = true;
    }
    return result;
  };

  //핸드폰 인증서
  const handleSignPhone = () => {
    // 휴대폰 인증창 OPEN을 위한 정보 조회용 API 호출
    const axios = (url) => {
      return new Promise((resolve, reject) => {
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내용이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve([]);
          },
        );
      });
    };

    const startAxios = async () => {
      //개인정보 입력 검증
      if (
        CM.cfnInputRefValidation('개인정보', personNumber1Ref, CM.VALID_NATIONAL_ID_NUMBER_FIRST) &&
        CM.cfnInputRefValidation('개인정보', personNumber2Ref, CM.VALID_NATIONAL_ID_NUMBER_LAST)
      ) {
        let url = 'auth/mobiles/certifications/windows?';
        url += 'kmc_call_type=N&kg_call_type=P'; // kmc_call_type은 N(PopUp), kg_call_type은 P(PopUp)으로 지정

        const resultData = await axios(url);
        if (resultData.company_service_id === 'A_KMCI_000') {
          openKMC(resultData.kmc);
        } else if (resultData.company_service_id === 'A_KG_000') {
          openKG(resultData.kg);
        }
      }
    };

    startAxios();
  };

  // KMC 웹표준창 호출 함수
  const openKMC = (data) => {
    window.name = 'kmcis_web';
    let KMCIS_window;

    // KMC 요청 폼 세팅
    let newForm;
    if (typeof document.reqKMCISForm === 'undefined') {
      newForm = document.createElement('form');
      newForm.name = 'reqKMCISForm';
      document.body.appendChild(newForm);
    }
    let reqKMCISForm = document.reqKMCISForm;
    if (typeof reqKMCISForm.tr_cert === 'undefined') {
      let certInput = document.createElement('input');
      certInput.type = 'hidden';
      certInput.name = 'tr_cert';
      reqKMCISForm.appendChild(certInput);
    }
    if (typeof reqKMCISForm.tr_url === 'undefined') {
      let certUrl = document.createElement('input');
      certUrl.type = 'hidden';
      certUrl.name = 'tr_url';
      reqKMCISForm.appendChild(certUrl);
    }
    if (typeof reqKMCISForm.tr_add === 'undefined') {
      let certAdd = document.createElement('input');
      certAdd.type = 'hidden';
      certAdd.name = 'tr_add';
      reqKMCISForm.appendChild(certAdd);
    }
    reqKMCISForm.tr_cert.value = data.tr_cert; //kmc 인증 요청 정보
    reqKMCISForm.tr_url.value = data.tr_url; //kmc 인증 결과 리턴 url
    reqKMCISForm.tr_add.value = data.tr_add; //iframe 여부

    var UserAgent = navigator.userAgent;
    //모바일일 경우
    if (
      UserAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
      ) != null ||
      UserAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
      KMCIS_window = window.open(
        '',
        'KMCISWindow',
        'width=425, height=640, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250',
      );
      if (KMCIS_window === null) {
        CM.cfnAlert(
          ' ※ 윈도우 XP SP2 또는 인터넷 익스플로러 7 사용자일 경우에는 \n    화면 상단에 있는 팝업 차단 알림줄을 클릭하여 팝업을 허용해 주시기 바랍니다. \n\n※ MSN,야후,구글 팝업 차단 툴바가 설치된 경우 팝업허용을 해주시기 바랍니다.',
        );
      }
      reqKMCISForm.target = 'KMCISWindow';
    }
    //모바일이 아닐 경우
    else {
      KMCIS_window = window.open(
        '',
        'KMCISWindow',
        'width=425, height=640, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250',
      );
      if (KMCIS_window === null) {
        CM.cfnAlert(
          ' ※ 윈도우 XP SP2 또는 인터넷 익스플로러 7 사용자일 경우에는 \n    화면 상단에 있는 팝업 차단 알림줄을 클릭하여 팝업을 허용해 주시기 바랍니다. \n\n※ MSN,야후,구글 팝업 차단 툴바가 설치된 경우 팝업허용을 해주시기 바랍니다.',
        );
      }
      reqKMCISForm.target = 'KMCISWindow';
    }
    reqKMCISForm.action = 'https://www.kmcert.com/kmcis/web/kmcisReq.jsp';
    reqKMCISForm.method = 'POST';
    reqKMCISForm.submit(); //자식 팝업 화면에 kmc에서 제공하는 휴대폰 인증 화면 표시
  };

  // KG 웹표준창 호출 함수
  const openKG = (data) => {
    // KG 요청 폼 세팅
    let newForm;
    if (typeof document.payForm === 'undefined') {
      newForm = document.createElement('form');
      newForm.name = 'payForm';
      document.body.appendChild(newForm);
    }
    let payForm = document.payForm;

    if (typeof payForm.CASH_GB === 'undefined') {
      let CASH_GB = document.createElement('input');
      CASH_GB.type = 'hidden';
      CASH_GB.name = 'CASH_GB';
      payForm.appendChild(CASH_GB);
    }
    if (typeof payForm.CI_SVCID === 'undefined') {
      let CI_SVCID = document.createElement('input');
      CI_SVCID.type = 'hidden';
      CI_SVCID.name = 'CI_SVCID';
      payForm.appendChild(CI_SVCID);
    }
    if (typeof payForm.PAY_MODE === 'undefined') {
      let PAY_MODE = document.createElement('input');
      PAY_MODE.type = 'hidden';
      PAY_MODE.name = 'PAY_MODE';
      payForm.appendChild(PAY_MODE);
    }
    if (typeof payForm.Okurl === 'undefined') {
      let okUrl = document.createElement('input');
      okUrl.type = 'hidden';
      okUrl.name = 'Okurl';
      payForm.appendChild(okUrl);
    }
    if (typeof payForm.Cryptyn === 'undefined') {
      let cryptYn = document.createElement('input');
      cryptYn.type = 'hidden';
      cryptYn.name = 'Cryptyn';
      payForm.appendChild(cryptYn);
    }
    if (typeof payForm.Keygb === 'undefined') {
      let keyGb = document.createElement('input');
      keyGb.type = 'hidden';
      keyGb.name = 'Keygb';
      payForm.appendChild(keyGb);
    }
    if (typeof payForm.Siteurl === 'undefined') {
      let siteUrl = document.createElement('input');
      siteUrl.type = 'hidden';
      siteUrl.name = 'Siteurl';
      payForm.appendChild(siteUrl);
    }
    if (typeof payForm.Tradeid === 'undefined') {
      let tradeId = document.createElement('input');
      tradeId.type = 'hidden';
      tradeId.name = 'Tradeid';
      payForm.appendChild(tradeId);
    }
    if (typeof payForm.CI_Mode === 'undefined') {
      let CI_Mode = document.createElement('input');
      CI_Mode.type = 'hidden';
      CI_Mode.name = 'CI_Mode';
      payForm.appendChild(CI_Mode);
    }
    if (typeof payForm.CALL_TYPE === 'undefined') {
      let callType = document.createElement('input');
      callType.type = 'hidden';
      callType.name = 'CALL_TYPE';
      payForm.appendChild(callType);
    }
    if (typeof payForm.Sendtype === 'undefined') {
      let sendType = document.createElement('input');
      sendType.type = 'hidden';
      sendType.name = 'Sendtype';
      payForm.appendChild(sendType);
    }

    payForm.CASH_GB.value = data.cash_gb; // 대표결제수단 : "CI" 고정값 (수정 불가)
    payForm.CI_SVCID.value = data.ci_svcid; // 모빌리언스에서 부여한 서비스 아이디
    payForm.PAY_MODE.value = data.pay_mode; // 거래종류
    payForm.Okurl.value = data.okurl; // 성공 URL
    payForm.Cryptyn.value = data.cryptyn; // 암호화 사용 여부 : "Y" 고정
    payForm.Keygb.value = data.keygb; // 암호화 key 구분
    payForm.Siteurl.value = data.siteurl; // 가맹점 도메인
    payForm.Tradeid.value = data.tradeid; // 가맹점 거래번호
    payForm.CI_Mode.value = data.ci_mode; // 결제단계구분
    payForm.CALL_TYPE.value = data.call_type; // 결제창 호출방식
    payForm.Sendtype.value = 'GET'; // 결과값 리턴방식 : 'GET' 고정값

    payForm.action = 'https://authtest.mobilians.co.kr/goCashMain.mcash';
    payForm.target = 'PAY_WIN';
    payForm.method = 'POST';

    // ext_inc_comm.js에 선언되어 있는 요청 함수 호출
    MCASH_PAYMENT(payForm);
  };

  //개인 인증서
  const handleSignIndividual = (keyword) => {
    //개인정보 입력 검증
    if (
      CM.cfnInputRefValidation('개인정보', personNumber1Ref, CM.VALID_NATIONAL_ID_NUMBER_FIRST) &&
      CM.cfnInputRefValidation('개인정보', personNumber2Ref, CM.VALID_NATIONAL_ID_NUMBER_LAST)
    ) {
      let dataForm = document.formSignIndividual; //인증서 인증 성공 이후 전자서명문 값 지정할 form
      if (typeof document.formSignIndividual.customerType === 'undefined') {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.value = 'INDIVIDUAL';
        input.name = 'customerType';
        input.setAttribute('sign', 'on');
        dataForm.appendChild(input);
      }
      let successFunc = onPersonalCertificationSuccess; //인증서 인증 성공 콜백 함수
      let cancelFunc = null; //인증서 인증 실패 콜백 함수의 경우 따로 처리하지 않음
      let signWtPersonal;
      if (keyword === 'sign') {
        //공동인증서 인증인 경우 yessignCrypto 파일의 sign() 사용
        signWtPersonal = window.sign;
      } else if (keyword === 'signFin') {
        //금융인증서 인증인 경우 yessignCrypto 파일의 signFin() 사용
        signWtPersonal = window.signFin;
      }
      /*
       * 인증서 파라미터 참고 주석
       * 1. dataForm : 공인인증서 전자서명문 결과 리턴할 form 지정
       * 2. dummy: ssn 설정을 위한 디폴트값 지정
       * 3. window.DISABLE_BROWSER : 브라우저 인증서 미사용 옵션
       */
      signWtPersonal(dataForm, 'dummy', null, window.DISABLE_BROWSER, null, successFunc, cancelFunc);
    }
  };

  //인증서 성공 콜백 함수
  const onPersonalCertificationSuccess = () => {
    //로그인 성공 시 후처리 (store에 로그인 사용자 정보 입력)
    const resolve = (objStatus, response) => {
      userStore.registerLogin(
        response.user_type,
        response.user_name,
        response.authentication_token,
        response.user_key,
        response.p2p_company_code_as_member,
        response.p2p_company_name_as_member,
      );
      props.handleNext(); //화면 이동
    };
    //로그인 실패 시 알림 문구
    const reject = (objStatus, response) => {
      let requestUri = window.location.pathname;
      let url = '';
      //대출,투자별 새로고침 주소 설정
      if (requestUri.indexOf('/loandetails') > -1) {
        url = '/myp2pfinanceinfo/loandetails?loginS=Y';
      } else {
        url = '/myp2pfinanceinfo/investmentdetails?loginS=Y';
      }
      if (CM.cfnIsNotEmpty(response) && CM.cfnIsNotEmpty(response.rsp_message)) {
        CM.cfnAlert(response.rsp_message, () => {
          window.location.replace(url); //로그인 실패 시 nos 모듈 관련 보안을 위해 화면 새로고침
        });
      } else {
        CM.cfnAlert('로그인 중 문제가 발생하였습니다.', () => {
          window.location.replace(url); //로그인 실패 시 nos 모듈 관련 보안을 위해 화면 새로고침
        });
      }
    };
    //본인인증 API 호출
    let url = 'auth/login/identification-number';
    if (value === 0) {
      //개인 로그인
      axiosUtil.cfnAxiosLoginApi(url, 'POST', window.$('#formSignIndividual').serialize(), null, resolve, reject);
    } else {
      //법인 로그인
      axiosUtil.cfnAxiosLoginApi(url, 'POST', window.$('#formSignCorporation').serialize(), null, resolve, reject);
    }
  };

  //법인 인증서 (수정 예정)
  const handleSignCorporation = () => {
    //사업자 등록번호 입력 검증
    if (
      CM.cfnInputRefValidation('사업자등록번호', corporationNumber1Ref, CM.VALID_NATIONAL_IS_NUMBER) &&
      CM.cfnInputRefValidation('사업자등록번호', corporationNumber2Ref, CM.VALID_NATIONAL_IS_NUMBER) &&
      CM.cfnInputRefValidation('사업자등록번호', corporationNumber3Ref, CM.VALID_NATIONAL_IS_NUMBER)
    ) {
      let dataForm = document.formSignCorporation; //인증서 인증 성공 이후 전자서명문 값 지정할 form
      if (typeof document.formSignCorporation.customerType === 'undefined') {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.value = 'CORPORATION';
        input.name = 'customerType';
        input.setAttribute('sign', 'on');
        dataForm.appendChild(input);
      }
      let successFunc = onPersonalCertificationSuccess; //인증서 인증 성공 콜백 함수
      let cancelFunc = null; //인증서 인증 실패 콜백 함수의 경우 따로 처리하지 않음
      let signWtCorporation = window.sign; //공동인증서 인증인 경우 yessignCrypto 파일의 sign() 사용
      /*
       * 인증서 파라미터 참고 주석
       * 1. dataForm : 공인인증서 전자서명문 결과 리턴할 form 지정
       * 2. dummy: ssn 설정을 위한 디폴트값 지정
       * 3. window.DISABLE_BROWSER : 브라우저 인증서 미사용 옵션
       */
      signWtCorporation(dataForm, 'dummy', null, window.DISABLE_BROWSER, null, successFunc, cancelFunc);
    }
  };

  //핸드폰 인증 콜백 함수
  const phoneCertCallback = () => {
    //로그인 성공 시 후처리 (store에 로그인 사용자 정보 입력)
    const resolve = (objStatus, response) => {
      userStore.registerLogin(
        response.user_type,
        response.user_name,
        response.authentication_token,
        response.user_key,
        response.p2p_company_code_as_member,
        response.p2p_company_name_as_member,
      );
      props.handleNext(); //화면 이동
    };

    //로그인 실패 시 알림 문구
    const reject = (objStatus, response) => {
      let requestUri = window.location.pathname;
      let url = '';
      //대출,투자별 새로고침 주소 설정
      if (requestUri.indexOf('/loandetails') > -1) {
        url = '/myp2pfinanceinfo/loandetails?loginS=Y';
      } else {
        url = '/myp2pfinanceinfo/investmentdetails?loginS=Y';
      }
      if (CM.cfnIsNotEmpty(response) && CM.cfnIsNotEmpty(response.rsp_message)) {
        CM.cfnAlert(response.rsp_message, () => {
          window.location.replace(url); //로그인 실패 시 nos 모듈 관련 보안을 위해 화면 새로고침
        });
      } else {
        CM.cfnAlert('로그인 중 문제가 발생하였습니다.', () => {
          window.location.replace(url); //로그인 실패 시 nos 모듈 관련 보안을 위해 화면 새로고침
        });
      }
    };

    //사용자 개인정보 보안필드 정보 추출하기 위한 form 설정
    let dataForm = document.formSignIndividual;
    if (typeof document.formSignIndividual.customerType === 'undefined') {
      let input = document.createElement('input');
      input.type = 'hidden';
      input.setAttribute('sign', 'on');
      dataForm.appendChild(input);
    }
    //사용자 개인정보 보안필드 정보 세팅
    let data = {};
    data = window.npPfsCtrl.toJson('formSignIndividual');
    data.user_type = 'P2P_WEB_PERSONAL_CUSTOMER';
    let formBody = [];
    for (let key in data) {
      formBody.push(key + '=' + data[key]);
    }

    if (document.phoneCertCallbackForm.company_service_id.value === 'KOREA_MOBILE_CERT') {
      // KMC CI 정보 세팅
      formBody.push('company_service_id=' + document.phoneCertCallbackForm.company_service_id.value); // 업체서비스 ID : KMC
      formBody.push('ci=' + document.phoneCertCallbackForm.Ci.value); // 암호화된 인증 결과
      formBody.push('mac=' + document.phoneCertCallbackForm.Mac.value); // 복호화 정보
    } else {
      // KG CI 정보 세팅
      formBody.push('company_service_id=' + document.phoneCertCallbackForm.company_service_id.value); // 업체서비스 ID : KG
      formBody.push('ci=' + document.phoneCertCallbackForm.Ci.value); // (암호화) CI
      formBody.push('commid=' + document.phoneCertCallbackForm.Commid.value); // (암호화) 이동통신사
      formBody.push('date=' + document.phoneCertCallbackForm.Signdate.value); // 인증일자
      formBody.push('di=' + document.phoneCertCallbackForm.Di.value); // (암호화) DI
      formBody.push('foreigner=' + document.phoneCertCallbackForm.Foreigner.value); // (암호화) 외국인여부
      formBody.push('keygb=' + document.phoneCertCallbackForm.Keygb.value); // 암호화 키 지정값
      formBody.push('mac=' + document.phoneCertCallbackForm.Mac.value); // (암호화) 검증키
      formBody.push('mobilid=' + document.phoneCertCallbackForm.Mobilid.value); // 모빌리언스 거래번호
      formBody.push('name=' + document.phoneCertCallbackForm.Name.value); // (암호화) 이름
      formBody.push('no=' + document.phoneCertCallbackForm.No.value); // (암호화) 휴대폰번호
      formBody.push('sex=' + document.phoneCertCallbackForm.Sex.value); // (암호화) 성별
      formBody.push('socialno=' + document.phoneCertCallbackForm.Socialno.value); // (암호화) 생년월일
      formBody.push('tradeid=' + document.phoneCertCallbackForm.Tradeid.value); // 거래번호
    }
    formBody = formBody.join('&');

    //휴대폰 인증 로그인 API 호출
    let url = 'auth/login/mobile-certification';
    axiosUtil.cfnAxiosLoginApi(url, 'POST', formBody, null, resolve, reject);
  };

  return (
    <div>
      <div className="section">
        <h4>로그인</h4>
        <div className="loginArea p2pLogin">
          <div className={isMobile() ? 'tabLen1' : 'tabLen2'}>
            <div className="tabArea">
              {isMobile() ? (
                <Tabs value={value} onChange={handleChange} aria-label="로그인 메뉴선택">
                  <Tab label="개인 사용자(개인사업자 포함)" {...allyProps(0)} />
                </Tabs>
              ) : (
                <Tabs value={value} onChange={handleChange} aria-label="로그인 메뉴선택">
                  <Tab label="개인 사용자(개인사업자 포함)" {...allyProps(0)} />
                  <Tab label="법인 사용자" {...allyProps(1)} />
                </Tabs>
              )}
              <TabPanel value={value} index={0} data-testid="testPersonPanel">
                <div className="userArea">
                  <div className="box">
                    <div className="titleWrap">
                      <div>
                        <p className="title">사용자 본인인증</p>
                        <p>
                          <span className="userStep">1단계</span>주민등록번호 또는 외국인등록번호(국내거소신고번호)를
                          입력해 주세요.
                        </p>
                      </div>
                    </div>
                    <div className="inputArea">
                      <form
                        className="inputTxt"
                        name="formSignIndividual"
                        id="formSignIndividual"
                        noValidate
                        autoComplete="off"
                      >
                        <input name="user_type" type="hidden" value="P2P_WEB_PERSONAL_CUSTOMER" />
                        <TextField
                          name="identification_number_part1"
                          type="text"
                          variant="outlined"
                          placeholder="생년월일"
                          inputProps={{
                            ref: personNumber1Ref,
                            'data-testid': 'idFirst',
                            maxLength: '6',
                          }}
                        />
                        <span className="rowline">―</span>
                        <TextField
                          name="identification_number_part2"
                          type="password"
                          placeholder="뒤 7자리"
                          inputProps={{
                            enc: 'on',
                            ref: personNumber2Ref,
                            'data-keypad-thema': 'default',
                            'data-keypad-type': 'num',
                            'data-keypad-useyn-type': 'toggle',
                            'data-keypad-useyn-input': 'birthLastToggle',
                            'data-testid': 'idLast',
                            maxLength: '7',
                          }}
                        />
                        <div className="txt-mouse">
                          <img id="birthLastToggle" src="/images/icon/nos/icon_mouse_off.gif" />
                          <span>마우스로 입력</span>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="box">
                    <div className="titleWrap msecurity">
                      <div>
                        <p>
                          <span className="userStep">2단계</span>다음 중 원하시는 인증방법을 한 가지 선택하여 인증해
                          주세요.
                        </p>
                      </div>
                    </div>
                    <div className="inputArea">
                      {isMobile() ? (
                        ''
                      ) : (
                        <Button
                          className="mobile"
                          onClick={() => {
                            handleSignIndividual('sign');
                          }}
                          data-testid="signBtn"
                        >
                          <span>공동인증서 인증</span>
                          <p>(구, 공인인증서)</p>
                        </Button>
                      )}
                      <Button
                        className="mobile"
                        onClick={() => {
                          handleSignIndividual('signFin');
                        }}
                      >
                        금융인증서 인증
                      </Button>
                      <Button
                        className="mobile"
                        onClick={() => {
                          handleSignPhone();
                        }}
                      >
                        휴대폰 인증
                      </Button>
                      {isMobile() ? (
                        <p className="info">
                          <InfoIcon />
                          <span>모바일 기기</span>를 통한 내역조회는 <span>금융인증서 인증, 휴대폰 인증만</span>
                          가능합니다. (법인의 내역조회는 <span>PC에서만 가능</span>합니다)
                        </p>
                      ) : (
                        <p className="info">
                          <InfoIcon />
                          <span>모바일 기기</span>를 통한 내역조회는 <span>금융인증서 인증, 휴대폰 인증만</span>
                          가능합니다.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <form
                  className="inputTxt"
                  name="phoneCertCallbackForm"
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                    phoneCertCallback();
                  }}
                >
                  <input type="hidden" name="company_service_id" />
                  <input type="hidden" name="Ci" />
                  <input type="hidden" name="Commid" />
                  <input type="hidden" name="Signdate" />
                  <input type="hidden" name="Di" />
                  <input type="hidden" name="Foreigner" />
                  <input type="hidden" name="Keygb" />
                  <input type="hidden" name="Mac" />
                  <input type="hidden" name="Mobilid" />
                  <input type="hidden" name="Name" />
                  <input type="hidden" name="No" />
                  <input type="hidden" name="Sex" />
                  <input type="hidden" name="Socialno" />
                  <input type="hidden" name="Tradeid" />
                  <button style={{ visibility: 'hidden', height: 0, width: 0 }} name="submitBtn" type="submit" />
                </form>
              </TabPanel>
              <TabPanel value={value} index={1} data-testid="testCorporationPanel">
                <div className="userArea corp">
                  <div className="box">
                    <div className="titleWrap">
                      <div>
                        <p className="title">사용자 본인인증</p>
                        <p>
                          <span className="userStep">1단계</span>사업자등록번호를 입력해 주세요.
                        </p>
                      </div>
                    </div>
                    <div className="inputArea">
                      <form
                        className="inputTxt"
                        name="formSignCorporation"
                        id="formSignCorporation"
                        noValidate
                        autoComplete="off"
                      >
                        <input name="user_type" type="hidden" value="P2P_WEB_CORPORATE_CUSTOMER" />
                        <TextField
                          name="identification_number_part1"
                          type="text"
                          variant="outlined"
                          placeholder="앞 3자리"
                          inputProps={{
                            ref: corporationNumber1Ref,
                            'data-testid': 'corporIdFirst',
                            maxLength: '3',
                          }}
                        />
                        <span className="rowline">―</span>
                        <TextField
                          name="identification_number_part2"
                          type="text"
                          variant="outlined"
                          placeholder="중간 2자리"
                          inputProps={{
                            ref: corporationNumber2Ref,
                            'data-testid': 'corporIdMiddle',
                            maxLength: '2',
                          }}
                        />
                        <span className="rowline">―</span>
                        <TextField
                          name="identification_number_part3"
                          type="text"
                          variant="outlined"
                          placeholder="뒤 5자리"
                          inputProps={{
                            ref: corporationNumber3Ref,
                            'data-testid': 'corporIdLast',
                            maxLength: '5',
                          }}
                        />
                      </form>
                    </div>
                  </div>
                  <div className="box">
                    <div className="titleWrap msecurity">
                      <div>
                        <p>
                          <span className="userStep">2단계</span>다음 방법을 선택하여 인증해 주세요.
                        </p>
                      </div>
                    </div>
                    <div className="inputArea">
                      {isMobile() ? (
                        ''
                      ) : (
                        <Button className="mobile" onClick={handleSignCorporation}>
                          <span>공동인증서 인증</span>
                          <p>(구, 공인인증서)</p>
                        </Button>
                      )}
                      {/* <Button className="mobile" onClick={handleSignCorporation}>
                                                <span>공동인증서 인증</span><p>(구, 공인인증서)</p>
                                            </Button> */}
                      <p className="info">
                        <InfoIcon />
                        법인의 내역조회는 <span>PC에서만 가능</span>합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginS;
