/*
 * @version : 2021.03.03
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용안내 - 이용기관(온투업자)안내 - 오픈 API 서비스
 */

import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import IntroAPI from '../../../../resource/styles/images/intro10.png';

const OpenApiServiceM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce">
          <h4>오픈 API 서비스</h4>
          <div className="section">
            <h5>오픈 API란?</h5>
            <p className="areaR">
              <strong>오픈 API(Open Application Programming Interface</strong>는 누구나 사용할 수 있도록 공개된 API로서,
              개발자 등에게 소프트웨어와 서비스 개발을 위한 환경을 제공하는 것이 목적입니다. 즉, 하나의 웹사이트와 같은
              기관이 자신의 기능을 공개한 프로그래밍 인터페이스라고 할 수 있습니다.
              <p className="importImg w80p mt30-auto tc">
                <img src={IntroAPI} alt="" />
              </p>
              <p className="mt40">
                오픈API방식은 공공 영역의 정부 공공데이터포털을 비롯하여 민간 영역의 네이버 지도, 구글맵 등 다양한
                서비스로 제공되고 있습니다.
              </p>
              <ul className="apiBox">
                <li>
                  <span className="hide">네이버오픈API</span>
                </li>
                <li>
                  <span className="hide">구글develores</span>
                </li>
                <li>
                  <span className="hide">공공데이터포털</span>
                </li>
              </ul>
              <p>
                금융결제원이 운영하는 온라인투자연계금융 중앙기록관리기관의 오픈 API는 다음을 준용하여 개발되었습니다.
              </p>
              <TableContainer className="statute api">
                <Table aria-label="온라인투자연계금융 중앙기록관리기관의 오픈 API 개발 기준">
                  <colgroup>
                    <col width="50%" />
                    <col width="50%" />
                  </colgroup>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <span className="primaryBlue">REST(Representational State Transfer) API</span>
                      </TableCell>
                      <TableCell>URI 주소기반 웹서비스 호출</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span className="primaryBlue">OAuth(Open Authorization)</span>
                      </TableCell>
                      <TableCell>이용기관 인증 프로토콜</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </p>
          </div>
          <div className="section">
            <h5>금융결제원 오픈 API</h5>
            <div className="areaR">
              <ul className="apikindBox">
                <li>
                  <p className="names">금융결제원</p>
                  <p className="tit">
                    <strong>오픈API</strong>통합포털
                  </p>
                  <p>금융결제원이 제공하는 다양한 오픈API 카탈로그 제공 및 API 신청/관리</p>
                  <Button
                    vatiant="contained"
                    onClick={() => window.open(process.env.REACT_APP_OPEN_API_URL, '_blank')}
                    startIcon={<SearchIcon />}
                  >
                    자세히 보기
                  </Button>
                </li>
                <li>
                  <p className="names">금융결제원</p>
                  <p className="tit">
                    <strong>오픈API</strong>개발자 사이트
                  </p>
                  <p>금융결제원 API 서비스 개발 및 테스트</p>
                  <Button
                    vatiant="contained"
                    onClick={() => window.open(process.env.REACT_APP_OPEN_API_DEVELOP_URL, '_blank')}
                    startIcon={<SearchIcon />}
                  >
                    자세히 보기
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenApiServiceM;
