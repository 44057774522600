/*
 * @version : 2021.02.02
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 등록 이용기관 목록 조회
 */
import { IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { React, useEffect, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const RegistrationCompaniesM = () => {
  const DEFAULT_HOOKS = {
    requestParams: {
      pageNumber: 0,
      pageSize: 9999,
      sortProperty: 'name',
      sortDirection: 'ASC',
    },
  };

  const [requestParams, setRequestParams] = useState(DEFAULT_HOOKS.requestParams);
  const [totalCompanies, setTotalCompanies] = useState(0); // 금융위원회 등록 온투업자 갯수
  const [registrationCompaniesList, setRegistrationCompaniesList] = useState({}); // 이용계약 체결 온투업자 정보
  const [pagination, setPagination] = useState(CM.cfnSetPagination()); //페이지 관련 상태
  const [reloadState, setReloadState] = useState(true); //화면 리로드 여부

  useEffect(() => {
    userStore.handleNaviTrigger();

    //금융위원회 등록 업체 조회
    const getTotalCompanies = (param) => {
      return new Promise((resolve) => {
        let api = 'web/common/p2p-companies/fnc-registration';
        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
          },
        );
      });
    };
    const startTotalCompanies = async () => {
      const resultData = await getTotalCompanies();
      setTotalCompanies(resultData.fnc_registered_p2p_company_list.length); //등록 업체수 세팅
    };

    startTotalCompanies();
  }, []);

  useEffect(() => {
    // 이용계약 체결 업체 정보 조회
    const getCompaniesList = (param) => {
      return new Promise((resolve) => {
        let api = 'web/common/p2p-companies/temp-active?';

        // let api = 'web/common/p2p-companies/active?';
        api += `page_number=${param.pageNumber}&page_size=${param.pageSize}`;
        api += `&sort_directions=${param.sortDirection}&sort_properties=${param.sortProperty}`;
        axiosUtil.cfnAxiosApi(
          api,
          'GET',
          null,
          null,
          (objStatus, response) => {
            resolve(response);
          },
          (objStatus, response) => {
            CM.cfnAlert('이용기관 현황 ' + process.env.REACT_APP_M001);
            resolve();
          },
        );
      });
    };
    const startCompaniesList = async (params) => {
      const resultData = await getCompaniesList(params);
      handleCompaniesList(resultData);
    };
    if (reloadState) startCompaniesList(requestParams);
  }, [reloadState]);

  const handleCompaniesList = (response) => {
    setRegistrationCompaniesList(response);
    handlePaginationChange(response);
    setReloadState(false);
  };

  //페이지 상태 세팅
  const handlePaginationChange = (param) => {
    setPagination(CM.cfnSetPagination(param));
  };

  //정렬 아이콘 이벤트
  const createSortHandler = (e) => {
    let sortOrder = '';
    let sortProp = '';
    if (CM.cfnIsEmpty(e.currentTarget.name)) {
      sortProp = requestParams.sortProperty;
    } else {
      sortProp = e.currentTarget.name;
    }
    if (requestParams.sortDirection === 'DESC') {
      sortOrder = 'ASC';
    } else {
      sortOrder = 'DESC';
    }
    setRequestParams((data) => ({
      ...data,
      sortDirection: sortOrder,
      sortProperty: sortProp,
    }));
    setReloadState(true);
  };

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>이용기관 현황</h4>
          <p>
            금융위원회에 등록된 온투업체({CM.cfnAddComma(totalCompanies)}개) 중 중앙기록관리기관과 이용계약이 완료된
            업체({CM.cfnAddComma(pagination.total)}개)에 한하여 제공됩니다.
          </p>
          <div className="tblTop">
            {/* <div>
              전체 <strong>{CM.cfnAddComma(pagination.total)}</strong>개
            </div> */}
          </div>
          {CM.cfnIsEmpty(registrationCompaniesList) ? (
            <TableContainer>
              <Table
                className="tblTypeline"
                aria-label="(로고)업체명(플랫폼 주소), 사업장 소재지, 예치기관 정보, 금융위 등록일자, 비고로 이루어진 이용기관 현황 목록"
              >
                <colgroup>
                  <col width="30%" />
                  <col width="25%" />
                  <col width="14%" />
                  <col width="19%" />
                  <col width="12%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      업체명
                      {requestParams.sortProperty !== 'name' ? (
                        <IconButton name="name" onClick={(e) => createSortHandler(e)}>
                          <ArrowDropDownIcon color={requestParams.sortProperty === 'name' ? '' : 'disabled'} />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                    <TableCell>사업장 소재지</TableCell>
                    <TableCell>예치기관 정보</TableCell>
                    <TableCell>
                      금융위 등록일자
                      {requestParams.sortProperty !== 'fnc_registration_date' ? (
                        <IconButton name="fnc_registration_date" onClick={(e) => createSortHandler(e)}>
                          <ArrowDropDownIcon
                            color={requestParams.sortProperty === 'fnc_registration_date' ? '' : 'disabled'}
                          />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                    <TableCell>비고</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow data-testid="tablerow0" key="tablerow0">
                    <TableCell colSpan="5">조회된 내용이 없습니다.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer>
              <Table
                className="tblTypeline mscrollY minW960"
                aria-label="(로고)업체명(플랫폼 주소), 사업장 소재지, 예치기관 정보, 금융위 등록일자, 비고로 이루어진 이용기관 현황 목록"
              >
                <colgroup>
                  <col width="30%" />
                  <col width="25%" />
                  <col width="14%" />
                  <col width="19%" />
                  <col width="12%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      업체명
                      {requestParams.sortProperty !== 'name' ? (
                        <IconButton name="name" onClick={(e) => createSortHandler(e)}>
                          <ArrowDropDownIcon color={requestParams.sortProperty === 'name' ? '' : 'disabled'} />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                    <TableCell>사업장 소재지</TableCell>
                    <TableCell>예치기관 정보</TableCell>
                    <TableCell>
                      금융위 등록일자
                      {requestParams.sortProperty !== 'fnc_registration_date' ? (
                        <IconButton name="fnc_registration_date" onClick={(e) => createSortHandler(e)}>
                          <ArrowDropDownIcon
                            color={requestParams.sortProperty === 'fnc_registration_date' ? '' : 'disabled'}
                          />
                        </IconButton>
                      ) : requestParams.sortDirection === 'DESC' ? (
                        <ArrowDropDownIcon onClick={createSortHandler} />
                      ) : (
                        <ArrowDropUpIcon onClick={createSortHandler} />
                      )}
                    </TableCell>
                    <TableCell>비고</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registrationCompaniesList.contents.map((row, index) => (
                    <TableRow data-testid={'tablerow' + index} key={'tablerow' + index}>
                      <TableCell className="tl">
                        <ul className="companyInfo">
                          <li>
                            <div>
                              <img
                                src={
                                  CM.cfnIsNotEmpty(row.logo)
                                    ? process.env.REACT_APP_BASE_URL +
                                      row.logo.file_web_url.substr(
                                        row.logo.file_web_url.indexOf('/') + 1,
                                        row.logo.file_web_url.length,
                                      )
                                    : null
                                }
                                alt="업체로고 이미지"
                              />
                            </div>
                          </li>
                          <li>
                            <p>{row.p2p_company_name}</p>
                            <p>
                              <span className="hide">플랫폼주소</span>
                              {row.website}
                            </p>
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>{row.account_info}</TableCell>
                      <TableCell>{CM.cfnDateFormat(row.fnc_registration_date, 'yyyyMMdd')}</TableCell>
                      <TableCell>{row.remark}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationCompaniesM;
