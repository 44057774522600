import { action, observable } from 'mobx';
import axiosUtil from '../utils/axiosUtil';
import axiosApi from '../utils/axiosApi';

const rootStore = observable({
  //포탈유형
  portalType: '',

  selectPortal(type) {
    this.portalType = type;
    p2pCompanyAction(rootStore);
  },

  // 이용기관목록
  InstituteList: [],

  registerInstituteList(list) {
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      element.value = element.p2p_company_code;
      element.label = element.p2p_company_name;
    }
    this.InstituteList = list;
  },

  // 상품유형목록
  ProductTypeList: [],

  registerProductTypeList(list) {
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      element.value = element.product_type_code;
      element.label = element.product_type_name;
      element.number = index + 1; //1:부동산개발(PF), 2.
    }
    this.ProductTypeList = list;
  },
});

const p2pCompanyAction = action(async (rootStore) => {
  // axiosApi.getData('web/common/p2p-companies?page_number=0&page_size=9999&sort_directions=ASC,ASC&sort_properties=fnc_registration_date,name')
  //     .then(async function (response) {
  //         if (response.status === 200) {
  //             await rootStore.registerInstituteList(response.data.contents)
  //         }
  //     })
  //     .catch(async function (error) {
  //         console.log("Get method Error : " + error);
  //     })
  //     .finally(function () {
  //         // console.log("GET method end");
  //     });
  return new Promise((resolve) => {
    let api;
    if (rootStore.portalType == 'customers') {
      // 대고객사이트의 경우에는 Active 상태의 p2p이용기관 목록만 사용한다.
      api =
        'web/common/p2p-companies/active?page_number=0&page_size=9999&sort_directions=ASC,ASC&sort_properties=fnc_registration_date,name';
    } else {
      api =
        'web/common/p2p-companies?page_number=0&page_size=9999&sort_directions=ASC,ASC&sort_properties=fnc_registration_date,name';
    }

    axiosUtil.cfnAxiosApi(api, 'GET', null, null, (objStatus, response) => {
      resolve(rootStore.registerInstituteList(response.contents));
    });
  });
});

const productTypesAction = action(async (rootStore) => {
  // axiosApi.getData('web/common/product-types')
  //     .then(async function (response) {
  //         if (response.status === 200) {
  //             await rootStore.registerProductTypeList(response.data.list)
  //         }
  //     })
  //     .catch(function (error) {
  //         console.log("Get method Error : " + error);
  //     })
  //     .finally(function () {
  //         // console.log("GET method end");
  //     });
  return new Promise((resolve) => {
    let api = 'web/common/product-types';
    axiosUtil.cfnAxiosApi(api, 'GET', null, null, (objStatus, response) => {
      resolve(rootStore.registerProductTypeList(response.list));
    });
  });
});
productTypesAction(rootStore);

export { rootStore, p2pCompanyAction, productTypesAction };
