/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국포탈 routes
 */
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';
import * as CM from '../components/common/util/common';
import BoardDetailM from '../components/supervisors/board/BoardDetailM';
import BoardListM from '../components/supervisors/board/BoardListM';
import Footer from '../components/supervisors/common/Footer';
import HeadTop from '../components/supervisors/common/HeadTop';
import Navi from '../components/supervisors/common/Navi';
import RegistrationCompaniesM from '../components/supervisors/companies/RegistrationCompaniesM';
import LoginM from '../components/supervisors/login/LoginM';
import MemberInfoM from '../components/supervisors/memberinfo/MemberInfoM';
import SupervisorDetailStatisticsM from '../components/supervisors/statistics/supervisorDetail/SupervisorDetailStatisticsM';
import TotalStatisticsM from '../components/supervisors/statistics/intergratedinfo/TotalStatisticsM';
import { userStore } from '../stores/supervisors/userStore';
import Page404 from '../components/common/Page404';

// const portalPath = "/";
const menuJson = [
  {
    // path: portalPath + "/",
    path: '/',
    main: 'main-root',
    component: TotalStatisticsM,
    loginStatus: false,
  },
  {
    // path: portalPath + "/registrationCompanies",
    path: '/registrationCompanies',
    main: 'main-root',
    component: RegistrationCompaniesM,
    loginStatus: false,
  },
  {
    // path: portalPath + "/statistics/intergratedinfo",
    path: '/statistics/intergratedinfo',
    main: 'main-root',
    component: TotalStatisticsM,
    loginStatus: false,
  },
  {
    // path: portalPath + "/statistics/supervisorDetail",
    path: '/statistics/supervisorDetail',
    main: 'main-root',
    component: SupervisorDetailStatisticsM,
  },
  {
    // path: portalPath + "/board",
    path: '/board',
    main: 'main-root',
    component: BoardListM,
    loginStatus: false,
  },
  {
    // path: portalPath + "/board/:seq_id",
    path: '/board/:seq_id',
    main: 'main-root',
    component: BoardDetailM,
    loginStatus: false,
  },
  {
    // path: portalPath + "/memberinfo",
    path: '/memberinfo',
    main: 'main-root',
    component: MemberInfoM,
    loginStatus: true,
  },
];

/* 컨텐츠 위 공간, 서브 각페이지마다 무조건 들어감(영역잡음) */
function EmptyBox() {
  return <div className="empty-box"></div>;
}

const Routes = observer(() => {
  //세션 만료 체크
  const handleLoginCheck = () => {
    userStore.logoutCheck();
  };

  return (
    <Router>
      <ScrollToTop>
        <div className="App supervisory" onClick={handleLoginCheck} onKeyDown={handleLoginCheck}>
          {CM.cfnIsNotEmpty(userStore.getIsLogin()) && userStore.logoutCheckRoute() ? (
            <Fragment>
              <HeadTop></HeadTop>
              <EmptyBox></EmptyBox>
            </Fragment>
          ) : null}
          <div className="container">
            {CM.cfnIsNotEmpty(userStore.getIsLogin()) ? <Navi naviTrigger={userStore.naviLocation}></Navi> : null}
            <Switch>
              {menuJson.map((value, key) => {
                let Component = CM.cfnIsEmpty(userStore.getIsLogin()) ? LoginM : value['component'];
                return <Route exact key={key} path={value['path']} component={Component} />;
              })}
              <Route component={Page404} />
            </Switch>
          </div>
          {CM.cfnIsNotEmpty(userStore.getIsLogin()) ? <Footer></Footer> : null}
        </div>
      </ScrollToTop>
    </Router>
  );
});

export default Routes;
