/*
 * @version : 2022.01.12
 * @author : 금융결제원
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 감독당국용 통계조회 - 감독당국 세부통계 > 연계대출채권 현황 - 엑셀 다운로드
 */
import React from 'react';
import ReactExport from 'react-export-excel';
import Button from '@material-ui/core/Button';
import * as CM from '../../../common/util/common';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const LoanStatusStatisticsExcelS = ({ statistics }) => {
  const convertStatisticsTypeName = (statisticsType) => {
    return statisticsType === 'BASE_POT_STATISTICS'
      ? '당월말현재'
      : statisticsType === 'NEW_CONTRACT_STATISTICS'
      ? '당월신규'
      : '당월상환';
  };

  const findBorrowerTypeInfo = (borrowerType, borrowerTypeInfoList) => {
    return borrowerTypeInfoList.find((e) => {
      return e.borrower_type === borrowerType;
    });
  };

  const getDataSetForm = () => {
    if (CM.cfnIsEmpty(statistics)) {
      return;
    }
    const flatData1 = statistics.statistics_loan_list.flatMap(({ p2p_company_name, statistics_loan_detail_list }) => {
      return statistics_loan_detail_list.map(({ loan_status_stat_type_code, borrower_type_info_list, total_info }) => {
        return { p2p_company_name, loan_status_stat_type_code, borrower_type_info_list, total_info };
      });
    });

    let dataList = [];
    flatData1.map((row, index) => {
      const borrower_count_data = {
        p2p_company_name: CM.cfnIsNotEmpty(row.p2p_company_name) ? row.p2p_company_name : '합계',
        statistics_type: convertStatisticsTypeName(row.loan_status_stat_type_code) + ' 차입자수',
        person_pf: findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).real_estate_pf_info.borrower_count,
        person_real: findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).real_estate_mortgage_info
          .borrower_count,
        person_bill: findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).bill_mortgage_info.borrower_count,
        person_etc: findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).etc_mortgage_info.borrower_count,
        person_pers_credit: findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).personal_credit_info
          .borrower_count,
        person_corp_credit: findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).corporate_credit_info
          .borrower_count,

        person_business_pf: findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).real_estate_pf_info
          .borrower_count,
        person_business_real: findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list)
          .real_estate_mortgage_info.borrower_count,
        person_business_bill: findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).bill_mortgage_info
          .borrower_count,
        person_business_etc: findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).etc_mortgage_info
          .borrower_count,
        person_business_pers_credit: findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list)
          .personal_credit_info.borrower_count,
        person_business_corp_credit: findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list)
          .corporate_credit_info.borrower_count,

        corp_pf: findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).real_estate_pf_info.borrower_count,
        corp_real: findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).real_estate_mortgage_info
          .borrower_count,
        corp_bill: findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).bill_mortgage_info.borrower_count,
        corp_etc: findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).etc_mortgage_info.borrower_count,
        corp_pers_credit: findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).personal_credit_info
          .borrower_count,
        corp_corp_credit: findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).corporate_credit_info
          .borrower_count,

        total: row.total_info.borrower_count,
      };
      dataList = dataList.concat(borrower_count_data);

      const loan_amount_data = {
        p2p_company_name: CM.cfnIsNotEmpty(row.p2p_company_name) ? row.p2p_company_name : '합계',
        statistics_type: convertStatisticsTypeName(row.loan_status_stat_type_code) + ' 금액',
        person_pf: Number(
          findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).real_estate_pf_info.loan_amount,
        ),
        person_real: Number(
          findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).real_estate_mortgage_info.loan_amount,
        ),
        person_bill: Number(
          findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).bill_mortgage_info.loan_amount,
        ),
        person_etc: Number(findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).etc_mortgage_info.loan_amount),
        person_pers_credit: Number(
          findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).personal_credit_info.loan_amount,
        ),
        person_corp_credit: Number(
          findBorrowerTypeInfo('PERSONAL', row.borrower_type_info_list).corporate_credit_info.loan_amount,
        ),

        person_business_pf: Number(
          findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).real_estate_pf_info.loan_amount,
        ),
        person_business_real: Number(
          findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).real_estate_mortgage_info.loan_amount,
        ),
        person_business_bill: Number(
          findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).bill_mortgage_info.loan_amount,
        ),
        person_business_etc: Number(
          findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).etc_mortgage_info.loan_amount,
        ),
        person_business_pers_credit: Number(
          findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).personal_credit_info.loan_amount,
        ),
        person_business_corp_credit: Number(
          findBorrowerTypeInfo('PERSONAL_BUSINESS', row.borrower_type_info_list).corporate_credit_info.loan_amount,
        ),

        corp_pf: Number(findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).real_estate_pf_info.loan_amount),
        corp_real: Number(
          findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).real_estate_mortgage_info.loan_amount,
        ),
        corp_bill: Number(
          findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).bill_mortgage_info.loan_amount,
        ),
        corp_etc: Number(findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).etc_mortgage_info.loan_amount),
        corp_pers_credit: Number(
          findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).personal_credit_info.loan_amount,
        ),
        corp_corp_credit: Number(
          findBorrowerTypeInfo('CORPORATE', row.borrower_type_info_list).corporate_credit_info.loan_amount,
        ),

        total: Number(row.total_info.loan_amount),
      };
      dataList = dataList.concat(loan_amount_data);
    });
    return dataList;
  };
  return (
    <ExcelFile element={<Button className="btn iconExcel down"> 엑셀 다운로드 </Button>}>
      <ExcelSheet data={getDataSetForm()} name="invest status statistics">
        <ExcelColumn value="p2p_company_name" label="업체명" />
        <ExcelColumn value="statistics_type" label="통계구분" />

        <ExcelColumn value="person_pf" label="개인(PF)" />
        <ExcelColumn value="person_real" label="개인(부동산)" />
        <ExcelColumn value="person_bill" label="개인(채권)" />
        <ExcelColumn value="person_etc" label="개인(기타)" />
        <ExcelColumn value="person_pers_credit" label="개인(개인신용)" />
        <ExcelColumn value="person_corp_credit" label="개인(법인신용)" />

        <ExcelColumn value="person_business_pf" label="개인사업자(PF)" />
        <ExcelColumn value="person_business_real" label="개인사업자(부동산)" />
        <ExcelColumn value="person_business_bill" label="개인사업자(채권)" />
        <ExcelColumn value="person_business_etc" label="개인사업자(기타)" />
        <ExcelColumn value="person_business_pers_credit" label="개인사업자(개인신용)" />
        <ExcelColumn value="person_business_corp_credit" label="개인사업자(법인신용)" />

        <ExcelColumn value="corp_pf" label="법인(PF)" />
        <ExcelColumn value="corp_real" label="법인(부동산)" />
        <ExcelColumn value="corp_bill" label="법인(채권)" />
        <ExcelColumn value="corp_etc" label="법인(기타)" />
        <ExcelColumn value="corp_pers_credit" label="법인(개인신용)" />
        <ExcelColumn value="corp_corp_credit" label="법인(법인신용)" />

        <ExcelColumn value="total" label="합계" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default LoanStatusStatisticsExcelS;
