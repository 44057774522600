/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 P2P 금융정보 조회 - 투자내역조회 컴포넌트(투자정보)
 */
import { React } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import * as CM from '../../../common/util/common';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

const isMobile = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMediaQuery('(max-width : 32.5rem)') ? true : false;
};

const InvestmentDetailsInfoC = (props) => {
  const handleClick = () => {
    props.handleScrolling('CONTRACT_PROGRESS', 'TOTAL_PRODUCT');
  };

  return (
    <div className="section">
      <h4>투자자 정보</h4>
      <div className="contBox">
        <div className={userStore.getUserType() === 'P2P_WEB_PERSONAL_CUSTOMER' ? 'contBox_item' : 'contBox_item w40p'}>
          <h5>투자자 정보 및 유형</h5>
          {CM.cfnIsNotEmpty(props.investorInfo) ? (
            <div
              className={
                userStore.getUserType() === 'P2P_WEB_PERSONAL_CUSTOMER' ? 'itemBox investor' : 'itemBox borrower corp'
              }
            >
              {userStore.getUserType() === 'P2P_WEB_PERSONAL_CUSTOMER' ? (
                <>
                  <div className="infoMain">
                    <div className="infoL">
                      <p>
                        <span className="name">{props.investorInfo.investor_name}</span> 님
                      </p>
                    </div>
                    <div className="infoR">
                      <ul>
                        <li>
                          <span className="title">개인투자자</span>
                          {CM.cfnIsNotEmpty(props.investorInfo.general_investment_register_dtm) ? (
                            <>
                              <span>등록</span>
                              <span className="firstDate">
                                {CM.cfnDateFormat(props.investorInfo.general_investment_register_dtm, 'yyyyMMdd')}
                              </span>
                              <span>(최초 등록일)</span>
                            </>
                          ) : (
                            <span className="red">미등록</span>
                          )}
                        </li>
                        <li>
                          <span className="title">소득적격투자자</span>
                          {CM.cfnIsNotEmpty(props.investorInfo.income_suitability_register_dtm) ? (
                            <>
                              <span>등록</span>
                              <span className="firstDate">
                                {CM.cfnDateFormat(props.investorInfo.income_suitability_register_dtm, 'yyyyMMdd')}
                              </span>
                              <span>(최초 등록일)</span>
                            </>
                          ) : (
                            <span className="red">미등록</span>
                          )}
                        </li>
                        <li>
                          <span className="title">전문투자자</span>
                          {CM.cfnIsNotEmpty(props.investorInfo.pro_investment_register_dtm) ? (
                            <>
                              <span>등록</span>
                              <span className="firstDate">
                                {CM.cfnDateFormat(props.investorInfo.pro_investment_register_dtm, 'yyyyMMdd')}
                              </span>
                              <span>(최초 등록일)</span>
                            </>
                          ) : (
                            <span className="red">미등록</span>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <p className="info gold">
                    {isMobile() ? (
                      <>
                        ※ 투자자 유형은 온라인투자연계금융업자(P2P금융업체)별로 관리됩니다. 투자자 유형에 대한 자세한
                        확인은 이용하시는 P2P금융업체로 문의 부탁드립니다.
                      </>
                    ) : (
                      <>
                        ※ 투자자 유형은 온라인투자연계금융업자(P2P금융업체)별로 관리됩니다.
                        <p style={{ marginLeft: '0.9rem' }}>
                          투자자 유형에 대한 자세한 확인은 이용하시는 P2P금융업체로 문의 부탁드립니다.
                        </p>
                      </>
                    )}
                  </p>
                </>
              ) : (
                <div className="infoMain">
                  <p className="name">
                    <span>{props.investorInfo.investor_name}</span> 님
                  </p>
                  <p className="tit">법인 투자자</p>
                </div>
              )}
            </div>
          ) : (
            <div className="itemBox borrower corp resultnoBox">
              <p className="resultNo">
                <ErrorOutlineIcon />
                조회된 내용이 없습니다.
              </p>
            </div>
          )}
        </div>
        <div className={userStore.getUserType() === 'P2P_WEB_PERSONAL_CUSTOMER' ? 'contBox_item' : 'contBox_item w60p'}>
          <h5>전체 투자 현황</h5>

          {CM.cfnIsEmpty(props.status) || CM.cfnIsEmpty(props.status.status_list) ? (
            <div className="itemBox borrower resultnoBox">
              <p className="resultNo">
                <ErrorOutlineIcon />
                조회된 내용이 없습니다.
              </p>
            </div>
          ) : userStore.getUserType() === 'P2P_WEB_PERSONAL_CUSTOMER' ? (
            <div className="itemBox">
              <div className="infoStatus">
                <div className="StatusL">
                  <Link className="number scrBtn" to="#detail_01" onClick={handleClick}>
                    {CM.cfnIsNotEmpty(props.status.status_list) ? props.status.total_invest_count : 0}
                  </Link>
                  <p className="title">전체 투자건수 (건)</p>
                </div>
                <div className="StatusR">
                  <p className="number">
                    {CM.cfnIsNotEmpty(props.status.status_list) ? CM.cfnAddComma(props.status.total_invest_balance) : 0}
                  </p>
                  <p className="title">전체 투자잔액 (원)</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="itemBox borrower">
              <div className="infoStatus">
                <div className="StatusL">
                  {CM.cfnIsNotEmpty(props.status) ? (
                    <Link className="number scrBtn" to="#detail_01" onClick={handleClick}>
                      {CM.cfnIsNotEmpty(props.status.status_list) ? props.status.total_invest_count : 0}
                    </Link>
                  ) : (
                    <Link className="number scrBtn" to="#detail_01" onClick={handleClick}>
                      {0}
                    </Link>
                  )}
                  <p className="title">전체 투자건수 (건)</p>
                </div>
                <div className="StatusR">
                  {CM.cfnIsNotEmpty(props.status) ? (
                    <p className="number">
                      {CM.cfnIsNotEmpty(props.status.status_list)
                        ? CM.cfnAddComma(props.status.total_invest_balance)
                        : 0}
                    </p>
                  ) : (
                    <p className="number">{0}</p>
                  )}
                  <p className="title">전체 투자잔액 (원)</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetailsInfoC;
