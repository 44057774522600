/*
 * @version : 2021.02.19
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 대고객용 메인 페이지
 *   - 메인 배너
 *   - 전체 통계
 *   - 주요 메뉴 소개
 *   - 공지사항 및 자료실
 */

import { Button } from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import MainStatisticsC from './MainStatisticsC';
import { userStore } from '../../../stores/customers/userStore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import axiosUtil from '../../../utils/axiosUtil';
import * as CM from '../../common/util/common';
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel';
import slide1 from '../../../resource/styles/images/bg_slider01.png';
import slide2 from '../../../resource/styles/images/bg_slider02.png';
import slide3 from '../../../resource/styles/images/bg_slider03.png';
import MicNoneIcon from '@material-ui/icons/MicNone';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from '../../../../node_modules/react-slick';
import MainPopup from './MainPopup';

const SimpleSlider = () => {
  const slideRef = useRef(null);
  const [index, setIndex] = useState(0); //sliderIndex

  const items = [
    {
      id: 1,
      url: slide1,
      title: (
        <span>
          <span>
            온라인투자연계금융업
            <br />
            중앙기록관리기관
          </span>
          과 함께
          <br /> <strong>안전한 P2P 금융거래</strong>를<br /> 시작하세요!
        </span>
      ),
      subtitle: (
        <span>
          중앙기록관리기관은 <br />
          <strong>온라인투자연계금융 정보의 집중·관리</strong>
          <br />
          업무를 수행합니다.
        </span>
      ),
      className: 'slide01',
    },
    {
      id: 2,
      url: slide2,
      title: (
        <span>
          <span>이제 중앙기록관리기관 포털에서</span>
          <br /> <strong>내 P2P 금융정보를 한번에</strong>
          <br /> 확인하세요!
        </span>
      ),
      subtitle: (
        <span>
          <strong>내 투자내역과 대출내역</strong>을<br /> 편리하고 간편하게 살펴볼 수 있습니다.
        </span>
      ),
      className: 'slide02',
    },
    {
      id: 3,
      url: slide3,
      title: (
        <span>
          <span>중앙기록관리기관</span>은<br />
          <strong>
            온라인투자연계금융 시장의
            <br /> 건전한 육성
          </strong>
          과<strong> 이용자 보호</strong>를 <br />
          위해 운영됩니다.
        </span>
      ),
      subtitle: (
        <span>
          금융결제원은 <br />
          <strong>이용자로부터 신뢰받는 서비스를 제공</strong>
          <br />할 수 있도록 더욱 노력하겠습니다.
        </span>
      ),
      className: 'slide03',
    },
  ];

  const handlePlay = (e) => {
    let propName = '';
    if (CM.cfnIsNotEmpty(e.currentTarget.name)) {
      propName = e.currentTarget.name;
    } else if (CM.cfnIsNotEmpty(e.currentTarget.getAttribute('name'))) {
      propName = e.currentTarget.getAttribute('name');
    } else {
      return false;
    }

    if (propName === 'play') {
      slideRef.current.slickPlay();
    } else if (propName === 'stop') {
      slideRef.current.slickPause();
    } else {
      return false;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    pauseOnFocus: true,
  };

  const handleIndex = (e) => {
    const targetIdx = e.currentTarget.name;
    slideRef.current.slickGoTo(Number(targetIdx));
    setIndex(Number(targetIdx));
  };

  const getSliderIndex = (index) => {
    setIndex(index);
  };
  return (
    <div className="visualArea">
      <div className="inner">
        <h2 className="hide">메인 베너</h2>
        <div className="visualCont">
          <Slider {...settings} ref={slideRef} afterChange={getSliderIndex}>
            {items.map((item) => {
              return (
                <div key={item.id}>
                  <Slide
                    media={<img src={item.url} alt="" />}
                    subtitle={item.subtitle}
                    title={item.title}
                    className={item.className}
                  />
                </div>
              );
            })}
          </Slider>
          <div className="btncurrentArea">
            {[...Array(3)].map((n, idx) => {
              return (
                <Button
                  className={idx === index ? 'btncurrent on' : 'btncurrent'}
                  key={idx}
                  name={idx}
                  onClick={(e) => handleIndex(e)}
                ></Button>
              );
            })}
            <Button className="btnStop" name="stop" key="stop" onClick={(e) => handlePlay(e)}>
              <span className="hide">정지</span>
            </Button>
            <Button className="btnPlay" name="play" key="play" onClick={(e) => handlePlay(e)}>
              <span className="hide">재생</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

// 주요 메뉴 바로가기
const QuickMenu = () => {
  return (
    <div className="quickMenu">
      <h2 className="hide">주요 메뉴 바로가기 버튼 모음</h2>
      <ul className="inner">
        <li>
          <Link to="myp2pfinanceinfo/investmentdetails">
            <p>내 투자내역 조회</p>
          </Link>
        </li>
        <li>
          <Link to="myp2pfinanceinfo/loandetails">
            <p>내 대출내역 조회</p>
          </Link>
        </li>
        <li>
          <Link to="statistics/intergratedinfo">
            <p>P2P 금융 통계</p>
          </Link>
        </li>
        <li>
          <Link to="statistics/productinfo">
            <p>연계투자 상품정보</p>
          </Link>
        </li>
        <li>
          <Link to="serviceintro/registrationCompanies">
            <p>이용기관 현황</p>
          </Link>
        </li>
        <li>
          <Link to="useinfo/companiesguide/openapiservice">
            <p>오픈 API 서비스</p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

// 공지사항
const MainNews = () => {
  const [noticeList, setNoticeList] = useState({}); // 공지사항
  const requestParams = {
    sortDirections: 'DESC',
    sortProperties: 'registration_dtm',
    pageNumber: 0,
    pageSize: 1,
  };

  const handleList = (notice) => {
    setNoticeList(notice);
  };

  const getNoticeList = (param) => {
    return new Promise((resolve) => {
      let api = `web/common/notices?`;
      api += `page_number=${param.pageNumber}&page_size=${param.pageSize}`;
      api += `&sort_directions=${param.sortDirections}&sort_properties=${param.sortProperties}`;

      axiosUtil.cfnAxiosApi(
        api,
        'GET',
        null,
        null,
        (objStatus, response) => {
          resolve(response);
        },
        null,
      );
    });
  };

  const startAxios = async (params) => {
    const noticeList = await getNoticeList(params);

    handleList(noticeList);
  };

  useEffect(() => {
    startAxios(requestParams);
  }, []);

  return (
    <div className="mainNews">
      <div className="inner">
        <div className="txt">
          <h2>
            <MicNoneIcon />
            공지사항
          </h2>
          {CM.cfnIsNotEmpty(noticeList) && CM.cfnIsNotEmpty(noticeList.contents) && (
            <p>
              <Link to={`/board/notice/${noticeList.contents[0].seq_id}`}>{noticeList.contents[0].title}</Link>
            </p>
          )}
        </div>
        <Link to="/board/notice">
          <Button className="btnmore">
            <span>더보기</span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

// 메인하단 기타내용들
const MainEtc = () => {
  const [faqList, setFaqList] = useState({}); // faq
  const [downloadsList, setDownloadsList] = useState({}); // 자료실
  const requestParams = {
    sortDirections: 'DESC',
    sortProperties: 'registration_dtm',
    pageNumber: 0,
    pageSize: 1,
  };

  const handleList = (faq, download) => {
    setFaqList(faq);
    setDownloadsList(download);
  };

  let msg = []; //오류 메시지

  const getDownloadsList = (param) => {
    return new Promise((resolve) => {
      let api = `web/common/downloads?`;
      api += `page_number=${param.pageNumber}&page_size=${param.pageSize}`;
      api += `&sort_directions=${param.sortDirections}&sort_properties=${param.sortProperties}`;

      axiosUtil.cfnAxiosApi(
        api,
        'GET',
        null,
        null,
        (objStatus, response) => {
          resolve(response);
        },
        null,
      );
    });
  };

  const getFaqList = (param) => {
    return new Promise((resolve) => {
      let api = `web/common/faq?`;
      api += `page_number=${param.pageNumber}&page_size=${param.pageSize}`;
      api += `&sort_directions=${param.sortDirections}&sort_properties=${param.sortProperties}`;

      axiosUtil.cfnAxiosApi(
        api,
        'GET',
        null,
        null,
        (objStatus, response) => {
          resolve(response);
        },
        null,
      );
    });
  };

  const printMsg = () => {
    let tempMsg = '';
    for (let index = 0; index < msg.length; index++) {
      const element = msg[index];
      tempMsg += element + '\n';
    }
    CM.cfnAlert(tempMsg);
  };

  const startAxios = async (params) => {
    const faqList = await getFaqList(params);
    const downloadList = await getDownloadsList(params);

    if (CM.cfnIsNotEmpty(msg)) {
      await printMsg();
    }

    handleList(faqList, downloadList);
  };

  useEffect(() => {
    startAxios(requestParams);
  }, []);

  const ivrPopOpen = () => {
    let winHeight = document.body.clientHeight;
    let winWidth = document.body.clientWidth;
    let winX = window.screenLeft;
    let winY = window.screenTop;

    let popX = winX + (winWidth - 850) / 2;
    let popY = winY + (winHeight - 780) / 2;
    window.open(
      'http://www.kftc.or.kr/kftc/ivr_guide/pop_ivr.html',
      'scrollbars=no',
      `width=850, height=780, top=${popY}, left=${popX}`,
    );
  };

  return (
    <div className="mainEtc">
      <div className="leftEtc">
        <div className="area">
          <div className="inn">
            <div className="title">
              <h3>FAQ</h3>
              <Link to="/board/faq">
                <Button className="btnmore">
                  <span>더보기</span>
                </Button>
              </Link>
            </div>
            {CM.cfnIsNotEmpty(faqList) && CM.cfnIsNotEmpty(faqList.contents) ? (
              <Link
                to={{
                  pathname: '/board/faq',
                  state: {
                    seqId: faqList.contents[0].seq_id,
                  },
                }}
              >
                <p>{faqList.contents[0].question}</p>
              </Link>
            ) : (
              <p>조회된 내역이 없습니다.</p>
            )}
          </div>
          <div className="inn">
            <div className="title">
              <h3>자료실</h3>
              <Link to="/board/downloads">
                <Button className="btnmore">
                  <span>더보기</span>
                </Button>
              </Link>
            </div>
            {CM.cfnIsNotEmpty(downloadsList) && CM.cfnIsNotEmpty(downloadsList.contents) ? (
              <Link to={`/board/downloads/${downloadsList.contents[0].seq_id}`}>
                <p>{downloadsList.contents[0].title}</p>
              </Link>
            ) : (
              <p>조회된 내역이 없습니다.</p>
            )}
          </div>
        </div>
        <div className="area">
          <div className="inn">
            <h3>관련법령 및 이용절차</h3>
            <p>P2P금융 관련법령과 이용절차에 대한 안내를 제공합니다.</p>
            <Link to="/serviceintro/p2pfinanceintro/introducep2plaw">
              <Button className="btnmore">
                <span>P2P 금융 법령</span>
              </Button>
            </Link>
            <Link to="/useinfo/userguide/p2pfinanceusage">
              <Button className="btnmore">
                <span>P2P 금융 이용절차</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="customCenter">
        <h3>고객센터</h3>
        <p>금융결제원 고객센터는 언제나 최상의 고객중심 금융결제 서비스를 제공합니다.</p>
        <br />
        <div className="callNum">
          1577-5500
          <p>(평일 09:00~17:45)</p>
        </div>
      </div>
    </div>
  );
};

// // 주요 메뉴 소개
// const MenuInfo = () => {
//     return (
//         <div className="inner menuInfo">
//             <h2>주요 메뉴 소개</h2>
//             <ul>
//                 <li>
//                     <div className="txt">
//                         <div>
//                             <h3>내 P2P금융정보 조회</h3>
//                             <p>
//                                 등록된 온라인투자연계금융업자에 대해<br />
//                                 중앙기록관리기관에 기록된<br />
//                                 내 P2P금융정보를 조회합니다.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="btnArea" data-testid="menuInfoTest">
//                         <Link to="/myp2pfinanceinfo/investmentdetails">
//                             <Button endIcon={<ChevronRightIcon />}>투자내역 조회</Button>
//                         </Link>
//                         <Link to="/myp2pfinanceinfo/loandetails">
//                             <Button endIcon={<ChevronRightIcon />}>대출내역 조회</Button>
//                         </Link>

//                     </div>
//                 </li>
//                 <li>
//                     <div className="txt">
//                         <div>
//                             <h3>P2P 통계 조회</h3>
//                             <p>
//                                 P2P금융 통합정보 및<br />
//                                 (모집완료된) 연계투자 상품정보를<br />
//                                 제공합니다.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="btnArea">
//                         <Link to="/statistics/intergratedinfo">
//                             <Button endIcon={<ChevronRightIcon />}>P2P금융 통합정보</Button>
//                         </Link>
//                         <Link to="/statistics/productinfo">
//                             <Button endIcon={<ChevronRightIcon />}>연계투자 상품정보</Button>
//                         </Link>
//                     </div>
//                 </li>
//                 <li>
//                     <div className="txt">
//                         <div>
//                             <h3>서비스 소개 및 이용안내</h3>
//                             <p>
//                                 P2P금융 및 중앙기록관리기관에 대해<br />
//                                 소개합니다. 또한 P2P금융<br />
//                                 이용과정을 안내합니다.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="btnArea">
//                         <Link to="/serviceintro/p2pfinanceintro/introducep2p">
//                             <Button endIcon={<ChevronRightIcon />}>서비스 소개</Button>
//                         </Link>
//                         <Link to="/useinfo/userguide/p2pfinanceusage">
//                             <Button endIcon={<ChevronRightIcon />}>이용안내</Button>
//                         </Link>
//                     </div>
//                 </li>
//             </ul>
//         </div>
//     );
// }

// 메인 페이지
const MainM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <>
      {/* 메인 배너 */}
      <SimpleSlider></SimpleSlider>
      {/* 메인 컨텐츠 */}
      <div className="mainContent">
        {/* 주요메뉴바로가기 */}
        <QuickMenu></QuickMenu>

        {/* 공지사항 */}
        <MainNews></MainNews>

        <div className="inner">
          {/* 전체통계 */}
          <MainStatisticsC></MainStatisticsC>

          {/* 하단 기타 */}
          <MainEtc></MainEtc>
        </div>

        {/* 전체 통계
                <MainStatisticsC></MainStatisticsC> */}

        {/* 주요 메뉴 소개
                <MenuInfo></MenuInfo> */}

        {/* 공지사항 및 자료실
                <MainBoardC></MainBoardC> */}

        {/* 팝업 */}
        <MainPopup />
      </div>
    </>
  );
};

export default MainM;
