import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';
import 'core-js/stable';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import Page404 from './components/common/Page404';
import AlertForm from './components/common/template/AlertForm';
import ConfirmForm from './components/common/template/ConfirmForm';
import { rootStore } from './stores/rootStore';
import CompaniesRoutes from './utils/companiesRoutes';
import CustomersRoutes from './utils/customersRoutes';
// import Routes from './utils/routes';
import SupervisorsRoutes from './utils/supervisorsRoutes';
import './resource/styles/scss/App.scss';

const theme = unstable_createMuiStrictModeTheme();

//테스트 코드를 위해 mock server 설정된 경우 mock service worker 기동
if (process.env.REACT_APP_MOCK === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

//접속 도메인에 따른 포탈 상태 저장
let domain = window.location.hostname;
if (domain === process.env.REACT_APP_CUSTOMERS) {
  //대고객
  rootStore.selectPortal('customers');
} else if (domain === process.env.REACT_APP_COMPANIES) {
  //이용기관
  rootStore.selectPortal('companies');
} else if (domain === process.env.REACT_APP_SUPERVISORS) {
  //감독당국
  rootStore.selectPortal('supervisors');
}
// else if (window.location.pathname.indexOf('/publish') > -1) { //퍼블리싱 테스트
//   rootStore.selectPortal('publish');
// }

//포탈유형없는경우 에러페이지연결
const ErrorRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route component={Page404} />
      </Switch>
    </Router>
  );
};

//rootStore.portalType별 router구분
const switchRoutes = (param) => {
  switch (param) {
    case 'customers':
      return <CustomersRoutes />;
    case 'companies':
      return <CompaniesRoutes />;
    case 'supervisors':
      return <SupervisorsRoutes />;
    // case 'publish':
    //   return <Routes />
    default:
      return <ErrorRoutes />;
  }
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AlertForm />
    <ConfirmForm />
    {switchRoutes(rootStore.portalType)}
  </ThemeProvider>,
  document.getElementById('root'),
);
