/*
 * @version : 2021.02.04
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 이용기관용 로그인 페이지
 */
import { useEffect, useState, useRef } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { userStore } from '../../../stores/companies/userStore';
import axiosUtil from '../../../utils/axiosUtil';
import bg from '../../../resource/styles/images/bg_login_comp.png';
import logo from '../../../resource/styles/images/loginlogo.png';
import * as CM from '../../common/util/common';

const LoginM = ({ history }) => {
  //state
  const [scriptState, setScriptState] = useState(true); //스크립트 추가 여부
  const [loginInfo, setLoginInfo] = useState({ user_id: '', user_pwd: '' }); //로그인 계정 정보
  const [idSaveState, setIdSaveState] = useState(true); //ID 저장 정보

  //ref
  const userIdRef = useRef(null); //계정 아이디 ref
  const userPwRef = useRef(null); //계정 암호 ref

  //스크립트 추가 함수
  const makeScript = (path) => {
    const script = document.createElement('script');
    script.src = path;
    script.async = false;
    return script;
  };

  //화면 최초 진입 시 nos 모듈 관련 스크립트 추가
  useEffect(() => {
    userStore.handleNaviTrigger(); //네비 현행화
    //로컬스토리지에 저장된 ID 정보가 있는 경우 자동 세팅 처리
    if (CM.cfnIsNotEmpty(localStorage.getItem('cpUserSaveId'))) {
      setIdSaveState(true);
      setLoginInfo({
        ...loginInfo,
        user_id: localStorage.getItem('cpUserSaveId'),
      });
    } else {
      setIdSaveState(false);
    }

    //화면 최초 진입 시 공인인증서 모듈 관련 스크립트 추가
    if (scriptState && process.env.REACT_APP_MOCK !== 'true') {
      document.body.appendChild(makeScript('/lib/js/jquery-1.11.1.min.js'));
      document.body.appendChild(makeScript('/lib/js/' + process.env.REACT_APP_YESSIGN_NPPFS_INSTALL + '.js')); //nppfs-1.13.0_dev/pro
      document.body.appendChild(makeScript('/lib/js/nppfs.addon.js'));
      document.body.appendChild(makeScript('/lib/js/yessign-nppfs-1.5.0.min.js'));
      document.body.appendChild(makeScript('/lib/js/yessignUtil.js'));
      document.body.appendChild(makeScript('/lib/js/' + process.env.REACT_APP_YESSIGN_YESSIGNCRYPO_INSTALL + '.js')); //yessignCrypto_dev/pro
      document.body.appendChild(makeScript('/lib/js/yessignInstall.js'));
      document.body.appendChild(makeScript('/lib/js/TouchEnKey.js'));
      setScriptState(false);
    }
  }, [scriptState]);

  //계정 정보 입력 처리
  const handleChange = (e) => {
    setLoginInfo({
      ...loginInfo,
      [e.target.name]: e.target.value,
    });
  };

  //ID 저장 클릭 이벤트
  const handleIdSave = () => {
    setIdSaveState(!idSaveState);
  };

  //법인 공동인증서 호출
  const handleSignCorporation = () => {
    //계정 정보 입력 검증
    if (!CM.cfnInputRefValidation('계정 아이디', userIdRef) || !CM.cfnInputRefValidation('계정 비밀번호', userPwRef)) {
      return false;
    }
    let dataForm = document.formSignCorporation; //인증서 인증 성공 이후 전자서명문 값 지정할 form
    if (typeof document.formSignCorporation.customerType === 'undefined') {
      let input = document.createElement('input');
      input.type = 'hidden';
      input.value = 'CORPORATION';
      input.name = 'customerType';
      input.setAttribute('sign', 'on');
      dataForm.appendChild(input);
    }
    let successFunc = onCertificationSuccess; //인증서 인증 성공 콜백 함수
    let cancelFunc = null; //인증서 인증 실패 콜백 함수의 경우 따로 처리하지 않음
    let signWtCorporation = window.sign; //법인 공동인증서 인증인 경우 yessignCrypto 파일의 sign() 사용
    /*
     * 인증서 파라미터 참고 주석
     * 1. dataForm : 공인인증서 전자서명문 결과 리턴할 form 지정
     * 2. dummy: ssn 설정을 위한 디폴트값 지정
     * 3. window.DISABLE_BROWSER : 브라우저 인증서 미사용 옵션
     */
    signWtCorporation(dataForm, 'dummy', null, window.DISABLE_BROWSER, null, successFunc, cancelFunc);
  };

  //공동인증서 인증 성공 콜백 함수
  const onCertificationSuccess = (response) => {
    //로그인 성공 시 후처리 (store에 로그인 사용자 정보 입력)
    const resolve = (objStatus, response) => {
      /*
       * 로그인 API 반환 정보
       * authenticated_codes
       * authentication_token (인증토큰)
       * client_ip (클라이언트IP주소)
       * institute_name
       * job_title
       * p2p_company_code_as_member (이용기관 코드)
       * p2p_company_name_as_member (이용기관명)
       * user_id (사용자 ID)
       * user_key (사용자 UUID)
       * user_name (사용자명)
       * user_type (사용자구분)
       */
      userStore.registerLogin(
        response.user_id,
        null,
        response.authentication_token,
        response.user_name,
        response.institute_name,
        response.institute_code,
        response.institute_business_number,
      );
      if (idSaveState) {
        //로그인 성공 시 아이디 저장 체크된 경우 로컬스토리지에 저장
        localStorage.setItem('cpUserSaveId', loginInfo.user_id);
      } else {
        //로그인 성공 시 아이디 저장 체크 해제된 경우 로컬스토리지에서 제거
        localStorage.removeItem('cpUserSaveId');
      }
      //90일 이상 비밀번호 변경하지 않은 계정인 경우 비밀번호 변경 페이지로 이동 처리
      if (
        CM.cfnIsEmpty(response.password_change_datetime) ||
        CM.cfnDateCalculation(CM.cfnGetDate(), response.password_change_datetime.substr(0, 8), 'd') >= 90
      ) {
        userIdRef.current.focus();
        CM.cfnAlert(
          '비밀번호를 변경한지 90일이 지났습니다.\n안전한 계정관리를 위해 비밀번호 변경 페이지로 이동합니다.',
          () => {
            window.location.replace('/memberinfo'); //비밀번호 화면으로 이동
          },
        );
      } else {
        window.location.replace('/'); //기본 화면 이동
      }
    };
    //로그인 실패 시 알림 문구
    const reject = (objStatus, response) => {
      if (CM.cfnIsNotEmpty(response) && CM.cfnIsNotEmpty(response.rsp_message)) {
        CM.cfnAlert(response.rsp_message, () => {
          window.location.replace('/'); //로그인 실패 시 nos 모듈 관련 보안을 위해 화면 새로고침
        });
      } else {
        CM.cfnAlert('로그인 중 문제가 발생하였습니다.', () => {
          window.location.replace('/'); //로그인 실패 시 nos 모듈 관련 보안을 위해 화면 새로고침
        });
      }
    };
    let url = 'auth/login?';
    let data = window.npPfsCtrl.toJson('formSignCorporation');
    data.user_type = 'P2P_COMPANY_CHARGER'; //이용기관 사용자 구분값
    axiosUtil.cfnAxiosLoginApi(url, 'POST', window.$('#formSignCorporation').serialize(), null, resolve, reject);
  };

  //비밀번호 입력란 엔터키 이벤트
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSignCorporation();
    }
  };

  return (
    <div className="basicLogin">
      <div className="loginTit">
        <img src={logo} className="logo" alt="금융결제원" />
        <span>온라인투자연계금융업 중앙기록관리기관 포털사이트 [이용기관용]</span>
      </div>
      <div className="loginBox">
        <div className="loginL">
          <img src={bg} alt="" />
        </div>
        <div className="loginR">
          <p className="tit">로그인</p>
          <form
            name="formSignCorporation"
            id="formSignCorporation"
            noValidate
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input name="user_type" type="hidden" value="P2P_COMPANY_CHARGER" />
            <ul>
              <li>
                <input
                  name="user_id"
                  data-testid="userId"
                  ref={userIdRef}
                  value={loginInfo.user_id}
                  maxLength="50"
                  onChange={handleChange}
                  placeholder="계정 아이디"
                  aria-label="계정 아이디 입력"
                />
              </li>
              <li>
                <input
                  name="user_pwd"
                  type="password"
                  enc="on"
                  ref={userPwRef}
                  data-testid="userPw"
                  data-keypad-type="alpha"
                  maxLength="30"
                  onChange={handleChange}
                  onKeyUp={handleKeyPress}
                  placeholder="계정 비밀번호"
                  aria-label="계정 비밀번호 입력"
                />
              </li>
            </ul>
            <div className="footbox">
              <FormControlLabel
                control={<Checkbox name="termcheck" id="chk2" checked={idSaveState} onClick={handleIdSave} />}
                label="아이디 저장"
              />
            </div>
          </form>
          <div className="btnArea">
            <button data-testid="loginBtn" onClick={handleSignCorporation}>
              법인 공동인증서 로그인
            </button>
            <button
              onClick={() => {
                history.push('/login/reset');
              }}
            >
              비밀번호 초기화 신청
            </button>
          </div>
        </div>
      </div>
      <p className="copy">
        Copyright ⓒ 2021 KFTC(Korea Financial Telecommunications & Clearings Institute) All rights reserved.
      </p>
    </div>
  );
};

export default LoginM;
