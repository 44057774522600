/*
 * @version : 2021.02.04
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * HeadTop
 */
import CloseRoundeIcon from '@material-ui/icons/CloseRounded';
import { Button, Popper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useState, Fragment, useRef } from 'react';
import * as CM from '../../common/util/common';
import logo from '../../../resource/styles/images/p2plogo.png';
import PersonIcon from '@material-ui/icons/Person';
import { userStore } from '../../../stores/companies/userStore';
import Collapse from '@material-ui/core/Collapse';

/* 헤더 */
function HeadTop() {
  //회원정보팝업관련
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick1 = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const scrollRef = useRef(null);
  document.addEventListener('scroll', function () {
    scrollRef.current.style.left = 0 - window.pageXOffset + 'px';
  });

  return (
    <div ref={scrollRef} className="header">
      <div className="headerTop">
        <header className="inr">
          <div className="headerLogo">
            <Link to="/">
              <img src={logo} className="logo" alt="온라인투자연계금융업 중앙기록관리기관 이용기관 사이트" />
            </Link>
          </div>
          <HeadGnb></HeadGnb>
          <div className="headerEtc">
            <div className="userInfo">
              {CM.cfnIsEmpty(userStore.getIsLogin()) ? null : (
                <Fragment>
                  <Button className="small" startIcon={<PersonIcon />} onClick={handleClick1}>
                    <span className="name">{userStore.getCompanyName()}</span>
                  </Button>
                  <Popper id={id} open={open} anchorEl={anchorEl} className="userinfoPop">
                    <Button className="popClose" onClick={handleClick1}>
                      <CloseRoundeIcon />
                      <span className="hide">팝업창 닫기</span>
                    </Button>
                    <p className="user">
                      <span>{userStore.getCompanyName()}</span>님 회원정보
                    </p>
                    <ul>
                      <li>
                        <span>계정 ID</span>
                        <span>{userStore.getUserId()}</span>
                      </li>
                      <li>
                        <span>이용기관</span>
                        <span>{userStore.getCompanyName()}</span>
                      </li>
                    </ul>
                    <div className="btnBottom">
                      <Link to="/memberinfo">
                        <Button onClick={handleClick1}>회원정보변경</Button>
                      </Link>
                      <Button onClick={() => userStore.logout()} className="logout">
                        로그아웃
                      </Button>
                    </div>
                  </Popper>
                </Fragment>
              )}
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

const HeadGnb = (props) => {
  const [isOn1, setIsOn1] = useState('gnbWrap');
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (param) => {
    setExpanded(param);
  };

  return (
    <div className="gnb">
      <ul
        className="gnbWrap on"
        onMouseOver={() => handleExpandClick(true)}
        onMouseLeave={() => handleExpandClick(false)}
      >
        <li>
          <Link to="/businessinfo/recordhistory">업무정보 조회</Link>
          <div className="allMenu">
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <ul className="allmenuList">
                <li>
                  <div>
                    <Link to="/businessinfo/recordhistory">업무정보 조회</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/businessinfo/recordhistory" onClick={() => handleExpandClick(false)}>
                          기록내역 조회
                        </Link>
                      </li>
                      <li>
                        <Link to="/businessinfo/usagehistorystatistics" onClick={() => handleExpandClick(false)}>
                          이용내역 통계
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="/businessreference/linkedloan">업무관련 참고자료</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/businessreference/linkedloan" onClick={() => handleExpandClick(false)}>
                          연계대출 관련 참고자료
                        </Link>
                      </li>
                      <li>
                        <Link to="/businessreference/linkedinvestment" onClick={() => handleExpandClick(false)}>
                          연계투자 관련 참고자료
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="/board/notice">공지사항 및 자료실</Link>
                    <ul className="depthDetail">
                      <li>
                        <Link to="/board/notice" onClick={() => handleExpandClick(false)}>
                          공지사항
                        </Link>
                      </li>
                      <li>
                        <Link to="/board/downloads" onClick={() => handleExpandClick(false)}>
                          자료실
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </Collapse>
          </div>
        </li>
        <li>
          <Link to="/businessreference/linkedloan" onClick={() => handleExpandClick(false)}>
            업무관련 참고자료
          </Link>
        </li>
        <li>
          <Link to="/board/notice" onClick={() => handleExpandClick(false)}>
            공지사항 및 자료실
          </Link>
        </li>
      </ul>
    </div>
  );
};

// const HeadGnb = (props) => {

//     const [isOn1, setIsOn1] = useState("");
//     const [isOn2, setIsOn2] = useState("");
//     const [isOn3, setIsOn3] = useState("");

//     return (
//         <div className="gnb">
//             <ul className="gnbWrap">
//                 <li className={isOn1}>
//                     <Link to="/businessinfo/recordhistory" onMouseOver={() => setIsOn1("on")} onMouseLeave={() => setIsOn1("")}>업무정보 조회</Link>
//                     <ul className="depthDetail" onMouseOver={() => setIsOn1("on")} onMouseLeave={() => setIsOn1("")}>
//                         <li><Link to="/businessinfo/recordhistory">기록내역 조회</Link></li>
//                         <li><Link to="/businessinfo/usagehistorystatistics">이용내역 통계</Link></li>
//                     </ul>
//                 </li>
//                 <li className={isOn2}>
//                     <Link to="/businessreference/linkedloan" onMouseOver={() => setIsOn2("on")} onMouseLeave={() => setIsOn2("")}>업무관련 참고자료</Link>
//                     <ul className="depthDetail" onMouseOver={() => setIsOn2("on")} onMouseLeave={() => setIsOn2("")}>
//                         <li><Link to="/businessreference/linkedloan">연계대출 관련 참고자료</Link></li>
//                         <li><Link to="/businessreference/linkedinvestment">연계투자 관련 참고자료</Link></li>
//                     </ul>
//                 </li>
//                 <li className={isOn3}>
//                     <Link to="/board/notice" onMouseOver={() => setIsOn3("on")} onMouseLeave={() => setIsOn3("")}>공지사항 및 자료실</Link>
//                     <ul className="depthDetail" onMouseOver={() => setIsOn3("on")} onMouseLeave={() => setIsOn3("")}>
//                         <li><Link to="/board/notice">공지사항</Link></li>
//                         <li><Link to="/board/downloads">자료실</Link></li>
//                     </ul>
//                 </li>
//             </ul>
//         </div>
//     );
// }

export default HeadTop;
