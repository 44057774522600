import { TableContainer, TableHead, TableRow, Table, TableBody, TableCell } from '@material-ui/core';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import IntroP2P04 from '../../../../resource/styles/images/intro04.png';

const IntroduceTaskM = () => {
  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce">
          <h4>주요역할 및 특징</h4>
          <div className="section">
            <h5>개요</h5>
            <p className="areaR">
              <strong>온라인투자연계금융업 중앙기록관리기관</strong>(이하 '중앙기록관리기관')은
              온라인투자연계금융업자로부터 온라인투자연계금융 및 이용자에 관한 정보를 제공받아 관리하는 기관을
              의미합니다.
            </p>
          </div>
          <div className="section">
            <h5>수행업무</h5>
            <div className="areaR">
              <p>
                <strong>중앙기록관리기관이 관련 법령에 따라 수행하는 업무</strong>는 다음과 같습니다.
              </p>
              <TableContainer className="statute">
                <Table aria-label="중앙기록관리기관 수행 업무">
                  <colgroup>
                    <col width="50%" />
                    <col width="50%" />
                  </colgroup>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <span className="primaryBlue">01</span>법 제33조에 따른 업무
                      </TableCell>
                      <TableCell>
                        <span className="primaryBlue">02</span>
                        온라인투자연계금융 정보의 집중·관리
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span className="primaryBlue">03</span>
                        온라인투자연계금융업자를 통한 연계투자 한도 관리
                      </TableCell>
                      <TableCell>
                        <span className="primaryBlue">04</span>
                        제1호부터 제3호까지의 규정에 따른 업무에 부수하는 업무
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="statBox">
                중앙기록관리기관은 온투업자와 업무처리에 관한 계약을 체결함으로써, 온라인투자연계금융 관련 자료 수집,
                보관관리 및 제공 등에 필요한 업무를 수행합니다.
                <p className="red">
                  ※ 한편, 중앙기록관리기관은 법령에서 정한 예외적인 경우를 제외하고는 보관관리하고 있는 자료를 타인에게
                  제공하지 않음
                </p>
              </div>
            </div>
          </div>
          <div className="section">
            <h5>업무 흐름도</h5>
            <p className="areaR">
              <div className="importImg w100p">
                <img src={IntroP2P04} alt="중앙기록관리기관 업무 흐름도" />
              </div>
              <div className="taskBox">
                <h6>기관별 역할</h6>
                {/* pc용 표 */}
                <TableContainer className="statute pc">
                  <Table aria-label="중앙기록관리기관 기관별 역할">
                    <colgroup>
                      <col width="33.3%" />
                      <col width="33.3%" />
                      <col width="33.3%" />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <p>중앙기록관리기관</p>
                          <p className="task1">
                            <strong>- 이용자 (투자자, 차입자)</strong>
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>중앙기록관리기관</p>
                          <p className="task2">
                            <strong>- 이용기관 (온투업자)</strong>
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>중앙기록관리기관</p>
                          <p className="task3">
                            <strong>- 예치기관</strong>
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <p>- 내 P2P금융정보 조회 서비스 제공</p>
                          <p>- P2P관련 통계정보 제공</p>
                        </TableCell>
                        <TableCell>
                          <p>
                            - 온라인투자연계금융 자료 수집, 기록 및 제공 (대출 및 투자신청 내역, 대출 및 투자계약 내용,
                            원리금수취권 기록 등)
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>- 투자금 지급 또는 반환 시 관련 정보 확인요청</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* 모바일용 표 */}
                <TableContainer className="statute mobile">
                  <Table aria-label="중앙기록관리기관 업무관계 요약">
                    <colgroup>
                      <col width="45%" />
                      <col width="55%" />
                    </colgroup>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <p>중앙기록관리기관</p>
                          <p className="task1">
                            <strong>- 이용자 (투자자, 차입자)</strong>
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>- 내 P2P금융정보 조회 서비스 제공</p>
                          <p>- P2P관련 통계정보 제공</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <p>중앙기록관리기관</p>
                          <p className="task2">
                            <strong>- 이용기관 (온투업자)</strong>
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>
                            - 온라인투자연계금융 자료 수집, 기록 및 제공 (대출 및 투자신청 내역, 대출 및 투자계약 내용,
                            원리금수취권 기록 등)
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <p>중앙기록관리기관</p>
                          <p className="task3">
                            <strong>- 예치기관</strong>
                          </p>
                        </TableCell>
                        <TableCell>
                          <p>- 투자금 지급 또는 반환 시 관련 정보 확인요청</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* 모바일용 표 600 이하 */}
                <TableContainer className="statute mobile xsmall">
                  <Table aria-label="중앙기록관리기관 업무관계 요약">
                    <colgroup>
                      <col width="100%" />
                    </colgroup>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <p>중앙기록관리기관</p>
                          <p className="task1">
                            <strong>- 이용자 (투자자, 차입자)</strong>
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p>- 내 P2P금융정보 조회 서비스 제공</p>
                          <p>- P2P관련 통계정보 제공</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <p>중앙기록관리기관</p>
                          <p className="task2">
                            <strong>- 이용기관 (온투업자)</strong>
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p>
                            - 온라인투자연계금융 자료 수집, 기록 및 제공 (대출 및 투자신청 내역, 대출 및 투자계약 내용,
                            원리금수취권 기록 등)
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <p>중앙기록관리기관</p>
                          <p className="task3">
                            <strong>- 예치기관</strong>
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p>- 투자금 지급 또는 반환 시 관련 정보 확인요청</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroduceTaskM;
