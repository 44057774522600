/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 공통 확인 컴포넌트
 */
import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import * as CM from '../../common/util/common';

let openConfirmFn = () => {};

const ConfirmForm = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [callback, setCallback] = React.useState({ function: '' });
  const [btnText, setBtnText] = React.useState('');

  React.useEffect(() => {
    openConfirmFn = openConfirm;
  }, []);

  const openConfirm = (message, callback, btnText) => {
    setOpen(true);
    setMessage(message);
    setCallback({ function: callback });
    if (CM.cfnIsEmpty(btnText)) setBtnText('확인');
    else setBtnText(btnText);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleConfirmOk();
    } else if (e.keyCode === 27) {
      handleConfirmClose();
    }
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  const handleConfirmOk = () => {
    setOpen(false);
    if (typeof callback.function === 'function') callback.function();
  };

  return (
    <Dialog
      open={open}
      onClose={handleConfirmClose}
      transitionDuration={{ enter: 100, exit: 100 }}
      scroll="paper"
      disableBackdropClick={true}
      onKeyUp={handleKeyUp}
      className="alertPop"
    >
      <DialogContent>
        <h1>확인</h1>
        <DialogContentText id="alert-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="table-bottom-area">
          <button className="btnOk" onClick={handleConfirmOk} data-testid="common-confirm-ok">
            {btnText}
          </button>
          <button onClick={handleConfirmClose} data-testid="common-confirm-cancel">
            취소
          </button>
        </div>
      </DialogActions>
      <button onClick={handleConfirmClose} className="closePop">
        <CloseRoundedIcon />
        <span className="hide">팝업창 닫기</span>
      </button>
    </Dialog>
  );
};

export function openConfirm(message, callback, bntText) {
  openConfirmFn(message, callback, bntText);
}

export default ConfirmForm;
