/*
 * @version : 2021.02.04
 * @author : 인사이드정보 사업본부팀 김정학 (kjh1624@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 P2P 금융정보 조회 - 투자/대출내역조회 서브화면(1. 이용동의)
 */
import { useState } from 'react';
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import * as CM from '../../../common/util/common';
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';

const LoginCheckS = (props) => {
  const [allCheck, setAllCheck] = useState(false); //전체선택 체크 여부
  const [termcheck1, setTermcheck1] = useState(false); //서비스 이용 및 개인(신용)정보 수집·이용 동의 체크 여부
  const [termcheck2, setTermcheck2] = useState(false); //개인(신용)정보 제3자 제공 동의 체크 여부
  const [termcheck3, setTermcheck3] = useState(false); //내P2P금융정보조회 서비스 이용약관 체크 여부

  //다음단계로 이동 버튼 클릭
  const handlePageMove = () => {
    if (termcheck1 && termcheck2 && termcheck3) {
      nextPageRequest();
    } else {
      CM.cfnAlert('모든 사항에 동의하셔야 서비스 시용이 가능합니다.');
    }
  };

  //전체 동의하기 클릭
  const handelAllCheck = () => {
    setAllCheck(!allCheck);
    setTermcheck1(!allCheck);
    setTermcheck2(!allCheck);
    setTermcheck3(!allCheck);
  };

  //다음단계 이동 이벤트
  function nextPageRequest() {
    props.handleNext();
  }

  const handleTermcheck1 = () => setTermcheck1(!termcheck1); //수집 동의 클릭 이벤트
  const handleTermcheck2 = () => setTermcheck2(!termcheck2); //제공 동의 클릭 이벤트
  const handleTermcheck3 = () => setTermcheck3(!termcheck3); //제공 동의 클릭 이벤트

  return (
    <div>
      <div className="section">
        <h4>개인정보 수집 및 이용동의</h4>
        <div className="loginArea p2pLogin terms">
          <FormGroup>
            <FormLabel component="legend">개인정보 수집 및 이용동의</FormLabel>
            <div className="box">
              <div className="inner">
                <div className="mb30">
                  <h6>서비스 이용 및 개인(신용)정보 수집·이용 동의</h6>
                  <div className="inbox">
                    <div className="scrollArea">
                      <Scrollbars>
                        <div className="scrollIn">
                          금융결제원은 “내 P2P금융정보 조회 서비스”와 관련하여 아래와 같이 서비스를 제공하고
                          개인(신용)정보를 수집, 이용하고자 합니다.
                          <br />
                          내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.
                          <br />
                          <br />
                          □ 제공 서비스
                          <br />
                          <br />
                          - 내 P2P금융정보 조회 서비스 : 온라인투자연계금융업 중앙기록관리기관에 기록된 본인의
                          온라인투자연계금융 투자 및 대출내역을 조회할 수 있는 서비스
                          <br />
                          <br />
                          □ 개인(신용)정보 수집, 이용 내역 (필수)
                          <br />
                          <br />
                          <TableContainer>
                            <Table
                              className="tblTypeline"
                              aria-label="수집/이용정보(필수항목), 수집이용/목적, 보유기간으로 이루어진 개인(신용)정보 수집, 이용 내역 표"
                            >
                              <colgroup>
                                <col width="50%" />
                                <col width="30%" />
                                <col width="20%" />
                              </colgroup>
                              <TableHead>
                                <TableRow>
                                  <TableCell>수집, 이용정보(필수항목)</TableCell>
                                  <TableCell>수집, 이용 목적</TableCell>
                                  <TableCell>보유기간</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <div style={{ textAlign: 'left' }}>
                                      <p>
                                        - 개인 : 개인식별번호* 또는 본인확인용 정보 (성명, 생년월일, 성별, 내/외국인
                                        구분, 통신사, 휴대폰번호, CI/DI)
                                        <br />
                                        <br />- 기업 : 법인식별번호**
                                      </p>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <p>내 P2P금융정보 조회 서비스 제공</p>
                                  </TableCell>
                                  <TableCell>
                                    <p>저장하지 않음</p>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          * 주민등록번호, 외국인등록번호(국내거소신고번호)
                          <br />
                          ** 사업자등록번호
                          <br />
                          <br />
                          ※ 기타 서비스 이용과정에서 이용자의 IP주소, MAC주소, 쿠키, 방문기록, 서비스 이용기록 등이
                          자동으로 생성되어 수집될 수 있습니다.
                          <br />
                          <br />※ 이용자는 개인(신용)정보 수집, 이용에 대한 동의를 거부할 권리가 있으며, 거부 시 내
                          P2P금융정보 조회 서비스 이용에 제약을 받을 수 있습니다.
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                  <div className="footbox">
                    <FormControlLabel
                      control={<Checkbox name="termcheck" id="chk2" checked={termcheck1} onClick={handleTermcheck1} />}
                      label="동의합니다."
                    />
                  </div>
                </div>
                <div className="mb30">
                  <h6>개인(신용)정보 제3자 제공 동의</h6>
                  <div className="inbox h464">
                    <div className="scrollArea">
                      <Scrollbars>
                        <div className="scrollIn">
                          금융결제원은 “내 P2P금융정보 조회 서비스”와 관련하여 아래와 같이 제3자에게 개인(신용)정보를
                          제공하고자 합니다.
                          <br />
                          내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.
                          <br />
                          <br />
                          □ 개인(신용)정보 제3자 제공 내역
                          <br />
                          <br />
                          <TableContainer>
                            <Table
                              className="tblTypeline"
                              aria-label="제공받는자, 제공 목적, 제공 항목, 보유 및 이용기간으로 이루어진 개인(신용)정보 제3자 제공 내역 표"
                            >
                              <colgroup>
                                <col width="30%" />
                                <col width="20%" />
                                <col width="20%" />
                                <col width="30%" />
                              </colgroup>
                              <TableHead>
                                <TableRow>
                                  <TableCell>제공받는자</TableCell>
                                  <TableCell>제공 목적</TableCell>
                                  <TableCell>제공 항목</TableCell>
                                  <TableCell>보유 및 이용기간</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <p>이동통신사 (SKT, KT, LGU+, MVNO)</p>
                                  </TableCell>
                                  <TableCell rowSpan="2">
                                    <p>휴대폰 본인확인</p>
                                  </TableCell>
                                  <TableCell rowSpan="2">
                                    <p>성명, 생년월일, 내/외국인 구분, 통신사, 휴대폰번호</p>
                                  </TableCell>
                                  <TableCell>
                                    <p>이동통신사에서 보유중인 정보로 별도의 보유기간 없음</p>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <p>한국모바일 인증㈜</p>
                                  </TableCell>
                                  <TableCell>
                                    <p>서비스 제공일로부터 200일 보관 후 영구삭제</p>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <br />※ 이용자는 개인(신용)정보 제공에 대한 동의를 거부할 권리가 있으며, 거부 시 내
                          P2P금융정보 조회 서비스 이용에 제약을 받을 수 있습니다.
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                  <div className="footbox">
                    <FormControlLabel
                      control={<Checkbox name="termcheck" id="chk3" checked={termcheck2} onClick={handleTermcheck2} />}
                      label="동의합니다."
                    />
                  </div>
                </div>
                <div>
                  <h6>내P2P금융정보조회 서비스 이용약관</h6>
                  <div className="inbox h464">
                    <div className="scrollArea">
                      <Scrollbars>
                        <div className="scrollIn">
                          <strong>제1조(목적)</strong>
                          <br />
                          본 약관은 「온라인투자연계금융업 및 이용자 보호에 관한 법률」에 따라 온라인투자연계금융업
                          중앙기록관리업무를 수행하는 사단법인 금융결제원(이하 “결제원”이라 한다)과 “내P2P금융정보조회
                          서비스”를 이용하는 이용자 간의 서비스 이용에 관한 제반 사항을 정함을 목적으로 합니다.
                          <br />
                          <br />
                          <strong>제2조(용어의 정의)</strong>
                          <br />
                          본 약관에서 사용하는 용어의 의미는 다음 각 호와 같습니다.
                          <br />
                          1. “내P2P금융정보조회 서비스”란 결제원에 등록된 본인의 온라인투자연계금융 투자내역 및
                          대출내역을 조회할 수 있는 서비스를 의미합니다.
                          <br />
                          2. “중앙기록관리기관 대고객 포털(이하 “대고객 포털”이라 한다)”이란 이용자가 중앙기록관리업무
                          관련 서비스를 이용할 수 있는 웹페이지를 의미합니다.
                          <br />
                          3. “인증서”란 실지명의가 확인된 전자서명법상 인증서를 의미합니다.
                          <br />
                          4. “휴대폰 본인확인”이란 이용자가 통신사의 본인명의 휴대폰을 통해 인증을 받는 것을 의미합니다.
                          <br />
                          <br />
                          <strong>제3조(약관의 효력 및 변경)</strong>
                          <br />
                          ① 이 약관은 대고객 포털에 게시하며, 서비스를 이용하는 이용자가 ‘동의’ 버튼을 클릭함으로써
                          효력이 발생합니다.
                          <br />
                          ② 결제원은 필요하다고 인정되는 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 변경할 수
                          있습니다. 본 약관을 변경하고자 할 경우 그 변경내역을 변경예정일 직전 1개월간 대고객 포털을
                          통해 게시하여야 합니다.
                          <br />
                          <br />
                          <strong>제4조(서비스 이용시간)</strong>
                          <br />
                          내P2P금융정보조회 서비스는 연중무휴를 원칙으로 합니다. 단, 시스템 유지보수 및 교체작업 등의
                          사유가 발생한 경우에는 서비스 제공을 일시적으로 중단할 수 있으며 결제원은 이를 대고객 포털에
                          공지합니다.
                          <br />
                          <br />
                          <strong>제5조(서비스 이용방법)</strong>
                          <br />
                          ① 개인 이용자는 대고객 포털에서 주민등록번호를 입력하고 본인명의 인증서 인증 또는 휴대폰
                          본인확인을 완료한 후 이용이 가능합니다.
                          <br />
                          ② 법인 이용자는 대고객 포털에서 사업자등록번호를 입력하고 본인명의 인증서 인증을 완료한 후
                          이용이 가능합니다.
                          <br />
                          <br />
                          <strong>제6조(조회정보)</strong>
                          <br />
                          본 서비스에서는 온라인투자연계금융업자가 결제원에 등록한 온라인투자연계금융 투자내역 및
                          대출내역에 한해서만 제공됩니다.
                          <br />
                          <br />
                          <strong>제7조(결제원의 의무)</strong>
                          <br />
                          ① 결제원은 대고객 포털을 운영함에 있어서 이용자의 동의없이 이용자의 개인(신용)정보를 타인에게
                          제공 또는 누설하거나 업무상 목적 외에 사용하지 않습니다. 다만, 금융실명거래 및 비밀보장에 관한
                          법률 및 그 밖에 다른 법률에서 정하는 바에 따른 경우에는 그러하지 아니합니다.
                          <br />
                          ② 결제원은 개인정보보호를 위해 개인정보처리방침을 대고객 포털에 게시하고 이를 준수합니다.
                          <br />
                          <br />
                          <strong>제8조(이용자의 의무)</strong>
                          <br />
                          이용자는 접근매체를 제3자에게 대여, 사용위임, 양도 또는 담보제공하거나 본인 이외의 제3자에게
                          누설 하여서는 아니되며, 접근매체의 도용이나 위조 또는 변조를 방지하기 위한 관리에 충분한
                          주의를 기울여야 합니다.
                          <br />
                          <br />
                          <strong>제9조(면책)</strong>
                          <br />
                          결제원은 다음 각 호의 어느 하나에 의하여 발생한 손해에 대하여는 책임을 지지 않습니다. 다만,
                          제1호 내지 제2호는 결제원의 고의·중과실없이 발생한 경우에 한합니다.
                          <br />
                          1. 이용자가 접근매체를 분실 또는 유출하여 발생된 경우
                          <br />
                          2. 이용자가 이 약관을 위반하여 발생된 경우
                          <br />
                          3. 온라인투자연계금융업자가 결제원에 정확한 온라인투자연계금융정보를 제공하지 않은 경우
                          <br />
                          4. 결제원의 귀책사유가 없는 천재지변, 정전, 화재, 통신장애 그 밖의 불가항력의 사유로 발생된
                          경우
                          <br />
                          <br />
                          <strong>제10조(이의제기와 분쟁처리)</strong>
                          <br />
                          이용자는 서비스 이용과 관련하여 이의가 있을 때에는 결제원에 그 해결을 요구하거나 금융감독원
                          금융분쟁조정위원회 등을 통하여 분쟁조정을 신청할 수 있습니다.
                          <br />
                          <br />
                          <strong>제11조(관할법원)</strong>
                          <br />
                          이 약관과 관련하여 발생한 분쟁의 관할법원은 서울중앙지방법원으로 합니다.
                          <br />
                          <br />
                          <strong>부칙</strong>
                          <br />이 약관은 2021. 5. 1.부터 시행합니다.
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                  <div className="footbox">
                    <FormControlLabel
                      control={<Checkbox name="termcheck" id="chk3" checked={termcheck3} onClick={handleTermcheck3} />}
                      label="동의합니다."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box all-check">
              <div className="inner">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="termcheck"
                      id="chk1"
                      data-testid="allCheck"
                      checked={allCheck}
                      onClick={handelAllCheck}
                    />
                  }
                  label="전체 동의하기"
                />
              </div>
            </div>
          </FormGroup>
        </div>
        <div className="btnArea">
          <Button vatiant="contained" className="btnLarge" onClick={handlePageMove} data-testid="nextBtn">
            다음단계로 이동
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginCheckS;
