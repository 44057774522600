/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 이경민 (lkm0823@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 차입자 정보 조회
 */
import { useEffect, useState } from 'react';
import { userStore } from '../../../../stores/customers/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Link } from 'react-router-dom';

const LoanDetailsInfoC = (props) => {
  const [borrowerInfo, setBorrowerInfo] = useState(); //차입자 정보

  const handleClick = () => {
    props.handleScrolling('CONTRACT_PROGRESS', 'TOTAL_PRODUCT');
  };

  useEffect(() => {
    //차입자 정보 조회
    const getBorrowerInfo = () => {
      return new Promise((resolve) => {
        let url = 'web/customers/loans/basic-info';
        url += `?uuid=${userStore.getUuid()}`;
        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          null,
        );
      });
    };
    const startAxios = async () => {
      const resultData = await getBorrowerInfo();
      setBorrowerInfo(resultData);
    };
    startAxios();
  }, []);

  return (
    <div className="section">
      <h4>차입자 정보</h4>
      <div className="contBox">
        <div className="contBox_item w40p">
          <h5>차입자 정보 및 유형</h5>
          {CM.cfnIsEmpty(borrowerInfo) ? (
            <div className="itemBox borrower corp resultnoBox">
              <p className="resultNo">
                <ErrorOutlineIcon />
                조회된 내용이 없습니다.
              </p>
            </div>
          ) : (
            <div className={userStore.getUserType() === 'Individual' ? 'itemBox borrower' : 'itemBox borrower corp'}>
              <div className="infoMain">
                <p className="name">
                  <span>{borrowerInfo.borrower_name}</span> 님
                </p>
                <p className="tit">{borrowerInfo.borrower_type_name} 차입자</p>
              </div>
            </div>
          )}
        </div>
        <div className="contBox_item w60p">
          <h5>전체 대출 현황</h5>
          {CM.cfnIsEmpty(props.status) || CM.cfnIsEmpty(props.status.status_list) ? (
            <div className="itemBox borrower resultnoBox">
              <p className="resultNo">
                <ErrorOutlineIcon />
                조회된 내용이 없습니다.
              </p>
            </div>
          ) : (
            <div className="itemBox borrower">
              <div className="infoStatus">
                <div className="StatusL">
                  <Link className="number scrBtn" to="#detail_01" onClick={handleClick}>
                    {props.status.total_loan_count}
                  </Link>
                  <p className="title">전체 대출건수 (건)</p>
                </div>
                <div className="StatusR">
                  <p className="number">{CM.cfnAddComma(props.status.total_loan_balance)}</p>
                  <p className="title">전체 대출잔액 (원)</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoanDetailsInfoC;
