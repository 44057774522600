import { React, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useEffect } from 'react';
import { userStore } from '../../../../stores/customers/userStore';

const IntroduceP2PLawM = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const p2pLawLink = 'https://www.law.go.kr/법령/온라인투자연계금융업및이용자보호에관한법률';
  //"https://www.law.go.kr/LSW/lsInfoP.do?efYd=20200827&lsiSeq=211727";

  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="introduce">
          <h4>P2P 금융 관련 법령</h4>
          <div className="section w100p">
            <div className="lawBox">
              <p className="tit">온라인투자연계금융업 및 이용자 보호에 관한 법률 (이하 '온투법')</p>
              <p>
                <strong>온투법</strong>은 P2P금융을 법제화함으로써 핀테크 산업으로 건전하게 육성하고 이용자를 보호하는
                것을 목적으로 합니다.
              </p>
              <Button vatiant="contained" startIcon={<SearchIcon />} onClick={() => window.open(p2pLawLink, '_blank')}>
                법령 자세히 보기
              </Button>
            </div>
            <div className="lawDetail">
              <p className="infos">온라인투자연계금융 진행과정별 법령의 주요내용은 다음과 같습니다.</p>
              <TableContainer>
                <Table className="tblType lawTable" aria-label="온라인투자연계금융 진행과정별 법령 주요내용 목록">
                  <colgroup>
                    <col width="" />
                  </colgroup>
                  <TableBody>
                    <TableRow>
                      <TableCell className={'clickmore'} component="th" scope="row" onClick={() => setOpen1(!open1)}>
                        <span>1. 차입자의 연계대출 신청</span>
                        <Button className="btnMore" aria-label="내용 상세보기">
                          {open1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="moreDetailarea">
                        <Collapse in={open1} timeout="auto" unmountOnExit>
                          <div className="moreDetail">
                            <ul>
                              <li>
                                <p className="tit">대출한도 (동일차입자에 대한)</p>
                                <p>
                                  온투업자는 동일한 차입자에 대해 자신이 보유하고 있는 총 연계대출채권 잔액의 100분의
                                  7과 70억원 중 작은 값을 초과하는 연계대출을 할 수 없습니다.
                                  <p className="red">
                                    (단, 온투법 제32조 제1항 단서에 해당하는 경우 대출한도 적용 예외)
                                  </p>
                                </p>
                              </li>
                              <li>
                                <p className="tit">차입자에 대한 금리 및 수수료</p>
                                <p>
                                  온투업자는 차입자로부터
                                  <strong>
                                    「대부업 등의 등록 및 금융이용자 보호에 관한 법률」제15조제1항에서 정하는 율
                                  </strong>
                                  을 초과하여 연계대출에 대한 <span className="star">이자</span>를 받을 수 없습니다.
                                  <p className="primaryBlue">
                                    <span className="star">
                                      <span className="hide">주석 설명</span>
                                    </span>
                                    온투업자가 차입자로부터 수취하는 수수료 포함
                                  </p>
                                  <p className="red">
                                    (단, 해당 거래의 체결과 변제 등에 관한 부대비용으로 대통령령으로 정하는 사항은 위의
                                    수수료 규제사항에서 제외)
                                  </p>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={'clickmore'} component="th" scope="row" onClick={() => setOpen2(!open2)}>
                        <span>2. 투자자의 연계투자 신청</span>
                        <Button className="btnMore" aria-label="내용 상세보기">
                          {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="moreDetailarea">
                        <Collapse in={open2} timeout="auto" unmountOnExit>
                          <div className="moreDetail">
                            <ul>
                              <li>
                                <p className="tit">투자한도</p>
                                <p>투자자 유형별 투자한도는 다음과 같습니다.</p>
                                <TableContainer>
                                  <Table aria-label="투자자 유형별 투자한도 목록">
                                    {/* thead 부분이 없는 테이블 스타일 className="tblTypenohead", 노출은 안되도 소스 내용은 있어야 함(접근성) */}
                                    <colgroup>
                                      <col width="30%" />
                                      <col width="30%" />
                                      <col width="40%" />
                                    </colgroup>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>구분</TableCell>
                                        <TableCell>동일차입자 투자한도</TableCell>
                                        <TableCell>총 투자한도</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>일반개인투자자</TableCell>
                                        <TableCell>5백만원</TableCell>
                                        <TableCell>
                                          4천만원 <br />
                                          (부동산관련상품 : 2천만원, 부동산PF상품 : 1천만원)
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>소득적격투자자</TableCell>
                                        <TableCell>2천만원</TableCell>
                                        <TableCell>1억원</TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>개인 전문투자자 및 법인투자자</TableCell>
                                        <TableCell>한도없음</TableCell>
                                        <TableCell>한도없음</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <p className="red">※ 세부 투자한도 규정 등은 온투법 참고</p>
                              </li>
                              <li>
                                <p className="tit">투자자에게 제공해야 하는 정보</p>
                                <p>
                                  온투업자는 법령에 규정된 정보를 투자자가 쉽게 이해할 수 있도록 자신의 온라인플랫폼을
                                  통하여 제공하여야 합니다.
                                  <p>- 연계대출의 내용, 차입자에 관한 사항, 수수료율, 예상 수익률 등</p>
                                  <p>- 연계투자상품 유형별 세부정보</p>
                                </p>
                                <p className="ml24">예) 부동산 PF대출 : 시행사·시공사 정보, 담보물가치의 증빙자료 등</p>
                                <p className="ml48">부동산 담보대출 : 담보물가치의 증빙자료, 선순위 채권 현황 등</p>
                              </li>
                            </ul>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={'clickmore'} component="th" scope="row" onClick={() => setOpen3(!open3)}>
                        <span>3. 투자진행 및 종료</span>
                        <Button className="btnMore" aria-label="내용 상세보기">
                          {open3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="moreDetailarea">
                        <Collapse in={open3} timeout="auto" unmountOnExit>
                          <div className="moreDetail">
                            <ul>
                              <li>
                                <p className="tit">온투업자의 연계대출채권, 연체율 관리의무</p>
                                <p>
                                  온투업자는 연계투자계약의 조건에 따라 연계대출채권의 원리금 상환, 담보 등을 관리해야
                                  하며, 공정하고 투명한 청산업무 처리절차를 마련해야 합니다.
                                  <br />
                                  또한 온투업자는 <strong>금융위원회가 정하여 고시하는 연체율(20%)</strong>을 초과하지
                                  않도록 관리 의무를 다해야 합니다.
                                </p>
                              </li>
                              <li>
                                <p className="tit">원리금수취권 양도·양수</p>
                                <p>
                                  <strong>원리금수취권</strong>은 연계대출 상환금을, 해당 연계대출에 제공된 연계투자
                                  금액에 비례하여 지급받기로 약정함으로써 투자자가 취득하는 권리를 의미합니다.
                                  <br />
                                  관련 법령은 원리금수취권에 대해&nbsp;
                                  <strong>해당 원리금수취권을 제공한 온투업자의 중개를 통해서만</strong> 양도·양수가
                                  가능하다고 규정하고 있습니다.
                                </p>
                              </li>
                              <li>
                                <p className="tit">영업중단 시 투자금 우선지급의무</p>
                                <p>
                                  온투업자는&nbsp;
                                  <strong>
                                    등록취소, 해산결의, 파산선고 등의 사유가 발생한 경우에는 예치 또는 신탁된 투자금
                                    등이 투자자에게 우선하여 지급
                                  </strong>
                                  될 수 있도록 조치하여야 합니다.
                                  <br />
                                  또한 투자자는 연계대출채권으로부터 제삼자에 우선하여 변제받을 권리를 가집니다.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={'clickmore'} component="th" scope="row" onClick={() => setOpen4(!open4)}>
                        <span>※ 기타규정</span>
                        <Button className="btnMore" aria-label="내용 상세보기">
                          {open4 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="moreDetailarea">
                        <Collapse in={open4} timeout="auto" unmountOnExit>
                          <div className="moreDetail">
                            <ul>
                              <li>
                                <p className="tit">온투업자 금융위 등록의무</p>
                                <p>
                                  온라인투자연계금융업을 하려는 자는 일정한 <span className="star">요건</span>을 갖춰
                                  금융위원회에 등록하여야 합니다.
                                  <p className="primaryBlue">
                                    <span className="star">
                                      <span className="hide">주석 설명</span>
                                    </span>
                                    연계대출잔액 규모에 따른 자기자본 요건, 인적·물적 요건 등
                                  </p>
                                  <p>
                                    온투법에 따른 온라인투자연계금융업 등록을 하지 않고서는 온라인투자연계금융업을
                                    영위해서는 안됩니다.
                                  </p>
                                  <p className="red">
                                    (단, 법 시행 시점('20.8.27) 당시 온라인투자연계금융업에 준하는 업무를 영위하는 자는
                                    등록 유예기간 이내(~'21.8.26)에 등록해야 해당기간 이후 업무수행 가능)
                                  </p>
                                </p>
                              </li>
                              <li>
                                <p className="tit">예치기관의 역할</p>
                                <p>
                                  <strong>예치기관</strong>은 투자자의 투자금과 차입자의 상환금을 온투업자의 고유재산
                                  등과 구분하여 자금보관 및 관리업무를 적절히 수행할 수 있는 기관을 의미합니다.
                                  <br />
                                  온투업자는&nbsp;
                                  <strong>투자금등의 보관 및 관리를 위해 예치기관에 별도의 계좌를 개설</strong>해야
                                  하며, 해당 계좌 이외의 계좌를 통하여 이용자로부터 투자금등을 수취하거나 이용자에게
                                  투자금등을 지급·반환할 수 없습니다.
                                  <br />
                                  한편, 예치기관은 관련 법령에 따라 차입자 또는 투자자에게 대출금 또는 투자금 등을 지급
                                  또는 반환하려는 경우, <strong>중앙기록관리기관에 관련 자료의 확인을 요청</strong>할 수
                                  있습니다.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroduceP2PLawM;
