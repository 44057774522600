/*
 * @version : 2021.02.05
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 업무정보 조회 - 기록내역 조회
 * 연계대출기록 - 대출계약
 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Fragment, useEffect } from 'react';
import { userStore } from '../../../../stores/companies/userStore';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

const RecordHistoryLoanContractS = (props) => {
  const effect = props;
  const currentTab = effect.currentTab;
  // const [loanContract, setLoanContract] = useState({ "list": [] }); //대출계약
  // const [repayment, setRepayment] = useState({ "list": [] }); //대출계약 상환정보
  // const [statusHistory, setStatusHistory] = useState({ "list": [] }); //대출계약 상태이력
  // //대출연관기록
  // const [relationContract, setRelationContract] = useState({ "list": [] }); //대출계약

  let msg = []; //오류 메시지

  //화면로딩 시 실행
  useEffect(() => {
    //대출계약 handler
    const handleLoanContract = (paramRelationContract, paramRepayment, paramStatusHistory) => {
      let tmpTab = CM.cfnCopyObject(effect.tab);
      let tmpThisTab = tmpTab[currentTab];
      let tmp = tmpThisTab.contents;
      // //basic
      // if (CM.cfnIsNotEmpty(resultData) && resultData !== effect.tab[currentTab].contents.basic) {
      //     props.setSearchTime.call(null, resultData.api_trx_dtm);
      //     // setLoanContract(resultData);
      //     tmpRp.view = true;
      //     tmpRp.basic = resultData
      // } else {
      //     tmpRp.view = false;
      // }
      //relation
      if (
        CM.cfnIsNotEmpty(paramRelationContract) &&
        paramRelationContract !== effect.tab[currentTab].contents.relation
      ) {
        // setRelationContract(props.sortLoanRelation(paramRelationContract));
        tmp.relation = props.sortLoanRelation(paramRelationContract);
      }
      //repayment
      if (CM.cfnIsNotEmpty(paramRepayment) && paramRepayment !== effect.tab[currentTab].contents.repayment) {
        // setRepayment(paramRepayment);
        tmp.repayment = paramRepayment;
      }
      if (
        CM.cfnIsNotEmpty(paramStatusHistory) &&
        paramStatusHistory !== effect.tab[currentTab].contents.statusHistory
      ) {
        // setStatusHistory(paramStatusHistory);
        tmp.statusHistory = paramStatusHistory;
      }
      effect.setTab(tmpTab);
    };

    //대출계약 조회
    const axiosLoanContract = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/contracts/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsEmpty(response)) {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            } else {
              resolve(response);
            }
          },
          (objStatus, response) => {
            let tmpRp = CM.cfnCopyObject(effect.requestParams.loans3);
            tmpRp.view = false;
            effect.setRequestParams((data) => ({
              ...data,
              loans3: tmpRp,
            }));
            msg.push(process.env.REACT_APP_M007);
            resolve(null);
          },
        );
      });
    };

    //연관기록내역 조회
    const axiosRelation = (param) => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/relations/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;
        url += `&record_type=${param}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M008);
            resolve(null);
          },
        );
      });
    };

    // //대출계약 상환정보 handler
    // const handleLoanContractRepayment = (paramRepayment) => {
    //     if (CM.cfnIsNotEmpty(paramRepayment) && paramRepayment !== repayment) {
    //         setRepayment(paramRepayment);
    //     }
    // };

    //대출계약 상환정보 조회
    const axiosRepayment = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/contracts/repayments/${effect.tab[currentTab].contents.recordId}`;
        url += `?p2p_company_code=${userStore.getCompanyCode()}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M011);
            resolve(null);
          },
        );
      });
    };

    // //대출계약 상태이력 handler
    // const handleLoanContractStatus = (paramStatusHistory) => {
    //     if (CM.cfnIsNotEmpty(paramStatusHistory) && paramStatusHistory !== statusHistory) {
    //         setStatusHistory(paramStatusHistory);
    //     }
    // };

    //대출계약 상태이력 조회
    const axiosStatusHistory = () => {
      return new Promise((resolve, reject) => {
        let url = `web/companies/loans/contracts/records/${effect.tab[currentTab].contents.recordId}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (!CM.cfnIsEmpty(response)) {
              resolve(response);
            } else {
              CM.cfnAlert('조회된 내역이 없습니다.' + url);
            }
          },
          (objStatus, response) => {
            msg.push(process.env.REACT_APP_M012);
            resolve(null);
          },
        );
      });
    };

    const printMsg = () => {
      let tempMsg = '';
      for (let index = 0; index < msg.length; index++) {
        const element = msg[index];
        tempMsg += element + '\n';
      }
      CM.cfnAlert(tempMsg);
    };

    const startAxios = async () => {
      // const resultData = await axiosLoanContract(); //대출계약
      //대출신청(LOAN_REQUEST), 투자상품(INVEST_PRODUCT), 대출계약(LOAN_CONTRACT)
      const relationContract = await axiosRelation('LOAN_CONTRACT'); //연관기록내역-대출계약
      const repayment = await axiosRepayment(); //대출계약 상환정보
      const statusHistory = await axiosStatusHistory(); //대출계약 상태이력
      if (CM.cfnIsNotEmpty(msg)) {
        await printMsg();
      }
      handleLoanContract(relationContract, repayment, statusHistory);
      // handleLoanContractRepayment(repayment);
      // handleLoanContractStatus(statusHistory);
    };

    if (effect.tab[currentTab].contents.view && CM.cfnIsEmpty(effect.tab[currentTab].contents.relation.list))
      startAxios();
  }, [effect.currentTab, effect.setCurrentTab]);

  return (
    <div className="inner">
      <h6 className="hide">조회결과 상세내용</h6>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">기록관리 ID</p>
          <div className="itemBox corpInfo histidImg">
            <div className="infoStatus">
              <div className="StatusL">
                <p className="name">{effect.tab[currentTab].contents.basic.record_no}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item">
          <p className="middleTit">차입자 정보</p>
          <div className="itemBox borrower">
            <div className="infoMain">
              <ul>
                <li>
                  <span className="title">차입자 성명</span>
                  <span>{effect.tab[currentTab].contents.basic.borrower_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">차입자 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.borrower_type_name}</span>
                </li>
                <li className="w50p">
                  <span className="title">고유식별번호</span>
                  <span>{CM.cfnIdentificationNoFormat(String(effect.tab[currentTab].contents.basic.identity_no))}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">상세조회내역</p>
          <div className="itemBox">
            <div className="itemArea">
              <p className="smallTit">대출계약 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">대출상품 유형</span>
                  <span>{effect.tab[currentTab].contents.basic.loan_product_type_name}</span>
                </li>
                <li>
                  <span className="tit">대출계약체결일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.loan_contract_date, 'yyyyMMddhhmmss')}
                  </span>
                </li>
                <li>
                  <span className="tit">대출금액</span>
                  <span>{CM.cfnAddComma(effect.tab[currentTab].contents.basic.loan_amount)}원</span>
                </li>
                <li>
                  <span className="tit">대출일수</span>
                  <span>{effect.tab[currentTab].contents.basic.loan_days}일</span>
                </li>
                <li>
                  <span className="tit">대출시작일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.loan_start_date, 'yyyyMMdd')}</span>
                </li>
                <li>
                  <span className="tit">대출종료일</span>
                  <span>{CM.cfnDateFormat(effect.tab[currentTab].contents.basic.loan_end_date, 'yyyyMMdd')}</span>
                </li>
                <li>
                  <span className="tit">대출금리</span>
                  <span>{effect.tab[currentTab].contents.basic.loan_interest}%</span>
                </li>
                <li>
                  <span className="tit">연체금리</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.overdue_interest) ||
                    effect.tab[currentTab].contents.basic.overdue_interest === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.overdue_interest) + '%'}
                  </span>
                </li>
                <li>
                  <span className="tit">대출잔액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.loan_balance) ||
                    effect.tab[currentTab].contents.basic.loan_balance === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.loan_balance) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">실제상환금액</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.real_repayment_amount) ||
                    effect.tab[currentTab].contents.basic.real_repayment_amount === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.real_repayment_amount) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">수수료</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.fee) ||
                    effect.tab[currentTab].contents.basic.fee === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.fee) + '원'}
                  </span>
                </li>
                <li>
                  <span className="tit">수수료율</span>
                  <span>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.basic.fee_rate) ||
                    effect.tab[currentTab].contents.basic.fee_rate === 'null'
                      ? ''
                      : CM.cfnAddComma(effect.tab[currentTab].contents.basic.fee_rate) + '%'}
                  </span>
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">상환정보</p>
              <div className="tblTop">
                <div className="topL">
                  <span className="listNum">
                    전체
                    <strong>
                      {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.repayment)
                        ? effect.tab[currentTab].contents.repayment.list.length
                        : 0}
                    </strong>
                    건
                  </span>
                </div>
              </div>
              <TableContainer className="maxH698 mb30 repayTbl scrollY">
                <Table stickyHeader aria-label="순번, 기록일시, 상태정보 갱신내역으로 이루어진 이력정보 목록">
                  <colgroup>
                    <col width="9%" />
                    <col width="34%" />
                    <col width="14%" />
                    <col width="15%" />
                    <col width="14%" />
                    <col width="14%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>상환회차</TableCell>
                      <TableCell>대출상환 기록관리 ID</TableCell>
                      <TableCell>예정 상환일</TableCell>
                      <TableCell>예정 상환원금</TableCell>
                      <TableCell>예정 상환이자금액</TableCell>
                      <TableCell>예정 수수료</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CM.cfnIsEmpty(effect.tab[currentTab].contents.repayment) ? (
                      <TableRow key={'tableRow0'}>
                        <TableCell colSpan="6">조회된 내용이 없습니다.</TableCell>
                      </TableRow>
                    ) : (
                      effect.tab[currentTab].contents.repayment.list.map((row, index) => (
                        <Fragment key={'tableRow' + index}>
                          <TableRow>
                            <TableCell rowSpan="2">{row.repayment_seqno}</TableCell>
                            <TableCell>
                              <span onClick={props.handleLink}>
                                <p className="txtLine" name={'loans,연계대출,4,대출상환,' + row.record_no}>
                                  {row.record_no}
                                </p>
                              </span>
                            </TableCell>
                            <TableCell>{CM.cfnDateFormat(row.schd_repayment_date, 'yyyyMMdd')}</TableCell>
                            <TableCell>{CM.cfnAddComma(row.schd_repayment_principal)}원</TableCell>
                            <TableCell>{CM.cfnAddComma(row.schd_repayment_interest)}원</TableCell>
                            <TableCell>{CM.cfnAddComma(row.schd_repayment_fee)}원</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan="5" className="moreDetailarea listType">
                              <div className="moreDetail" data-testid={'intergrated-detailDiv' + index}>
                                <p className="date">
                                  <EventAvailableIcon />
                                  실제 상환일 : <span>{CM.cfnDateFormat(row.real_repayment_date, 'yyyyMMdd')}</span>
                                </p>
                                <ul>
                                  <li>
                                    [상환원금]<span>{CM.cfnAddComma(row.loan_repayment_principal)}원</span>
                                  </li>
                                  <li>
                                    [상환이자금액]<span>{CM.cfnAddComma(row.loan_repayment_interest)}원</span>
                                  </li>
                                  <li>
                                    [연체이자금액]<span>{CM.cfnAddComma(row.overdue_interest)}원</span>
                                  </li>
                                  <li>
                                    [수수료]<span>{CM.cfnAddComma(row.fee)}원</span>
                                  </li>
                                  <li>
                                    [실제 상환금액]<span>{CM.cfnAddComma(row.real_repayment_amount)}원</span>
                                  </li>
                                </ul>
                              </div>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="itemArea">
              <p className="smallTit">상태</p>
              <ul className="infoList">
                <li>
                  <span className="tit">대출계약 상태</span>
                  <span data-testid="test-status">{effect.tab[currentTab].contents.basic.loan_contract_status}</span>
                  {/* <span className="red">진행 중</span> 진행중일 경우 글자색 레드 */}
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">이력 정보</p>
              <ul className="infoList">
                <li>
                  <span className="tit">최초등록일시</span>
                  <span>
                    {CM.cfnDateFormat(effect.tab[currentTab].contents.basic.first_registration_dtm, 'yyyyMMddhhmmss')}
                  </span>
                </li>
              </ul>
            </div>
            <div className="itemArea">
              <p className="smallTit">대출계약 상태변경 내역</p>
              <div className="tblTop">
                <div className="topL">
                  <span className="listNum">
                    전체
                    <strong>
                      {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.statusHistory)
                        ? effect.tab[currentTab].contents.statusHistory.list.length
                        : 0}
                    </strong>
                    건
                  </span>
                </div>
              </div>
              <TableContainer className="maxH520 mb30 scrollY">
                <Table stickyHeader aria-label="순번, 기록일시, 상태정보 갱신내역으로 이루어진 이력정보 목록">
                  <colgroup>
                    <col width="15%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="45%" />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>순번</TableCell>
                      <TableCell>변경시작일</TableCell>
                      <TableCell>변경종료일</TableCell>
                      <TableCell>상태정보</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CM.cfnIsNotEmpty(effect.tab[currentTab].contents.statusHistory) ? (
                      effect.tab[currentTab].contents.statusHistory.list.map((row, index) => (
                        <TableRow key={'tableRow' + index}>
                          <TableCell>{effect.tab[currentTab].contents.statusHistory.list.length - index}</TableCell>
                          <TableCell>{CM.cfnDateFormat(row.start_dtm, 'yyyyMMdd')}</TableCell>
                          <TableCell>{CM.cfnDateFormat(row.end_dtm, 'yyyyMMdd')}</TableCell>
                          <TableCell>{row.status_info}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={'tableRow0'}>
                        <TableCell colSpan="4">조회된 내용이 없습니다.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="contBox">
        <div className="contBox_item w100p">
          <p className="middleTit">연관기록내역</p>
          <TableContainer>
            <Table
              className="tblTypeline"
              aria-label="대출신청, 투자상품, 대출계약, 대출상환으로 이루어진 연관 기록내역 목록"
            >
              <colgroup>
                <col width="33%" />
                <col width="33%" />
                <col width="33%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>대출신청</TableCell>
                  <TableCell>투자상품</TableCell>
                  <TableCell>대출계약</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {effect.tab[currentTab].contents.relation.list.map((row, index) => {
                    if (row.record_type === 'LOAN_REQUEST') {
                      return (
                        <TableCell
                          key="loanRequest"
                          data-testid="renderTest"
                          rowSpan={
                            effect.tab[currentTab].contents.relation.productCnt < 2
                              ? 1
                              : effect.tab[currentTab].contents.relation.productCnt
                          }
                        >
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,1,대출신청,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (
                      row.record_type === 'INVEST_PRODUCT' &&
                      index <= effect.tab[currentTab].contents.relation.secondProductIndex
                    ) {
                      return (
                        <TableCell key="investProduct">
                          <span onClick={props.handleLink}>
                            <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                              {row.record_no}
                            </p>
                          </span>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    } else if (row.record_type === 'LOAN_CONTRACT') {
                      return (
                        <TableCell
                          key="loanContract"
                          rowSpan={effect.tab[currentTab].contents.relation.list.length}
                          className="bglightSky"
                          rowSpan={
                            effect.tab[currentTab].contents.relation.productCnt < 2
                              ? 1
                              : effect.tab[currentTab].contents.relation.productCnt
                          }
                        >
                          <p>{row.record_no}</p>
                          <p className="font14">
                            {CM.cfnIsNotEmpty(row.record_dtm) &&
                              '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                          </p>
                        </TableCell>
                      );
                    }
                  })}
                  {effect.tab[currentTab].contents.relation.list.length < 3
                    ? [...Array(3 - effect.tab[currentTab].contents.relation.list.length)].map((n, index) => {
                        return <TableCell key={index} />;
                      })
                    : null}
                </TableRow>
                {effect.tab[currentTab].contents.relation.productCnt > 1
                  ? effect.tab[currentTab].contents.relation.list.map((row, index) =>
                      row.record_type === 'INVEST_PRODUCT' &&
                      index > effect.tab[currentTab].contents.relation.secondProductIndex ? (
                        <TableRow key={'investProduct' + index}>
                          <TableCell>
                            <span onClick={props.handleLink}>
                              <p className="txtLine" name={'loans,연계대출,2,투자상품,' + row.record_no}>
                                {row.record_no}
                              </p>
                            </span>
                            <p className="font14">
                              {CM.cfnIsNotEmpty(row.record_dtm) &&
                                '(최초등록일시 ' + CM.cfnDateFormat(row.record_dtm, 'yyyyMMddhhmm')}
                            </p>
                          </TableCell>
                        </TableRow>
                      ) : null,
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RecordHistoryLoanContractS;
