/*
 * @version : 2021.01.27
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 내 P2P 금융정보 조회 - 투자내역조회 컴포넌트(투자현황)
 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import * as CM from '../../../common/util/common';
import { Scrollbars } from 'react-custom-scrollbars';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Link } from 'react-router-dom';

let countData = [];
let balanceData = [];
let legendData = [];

const COLORS = ['#e95b54', '#fbce4a', '#3caf85', '#309fdb', '#854e9b', '#ac8c2e'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.68;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'middle' : 'middle'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}`}
    </text>
  );
};

function setChartData(data) {
  countData = [];
  balanceData = [];
  legendData = [];

  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    let productName = element.goods_type_name;
    let countRatio = element.invest_count_ratio;
    let balanceRatio = element.invest_balance_ratio;

    countData.push({
      id: productName,
      name: productName,
      value: Number(countRatio),
    });
    balanceData.push({
      name: productName,
      value: Number(balanceRatio),
    });
    legendData.push({
      value: productName,
      type: 'square',
      color: COLORS[index],
    });
  }
}

const SimplePieChart = (props) => {
  if (CM.cfnIsNotEmpty(props.data)) {
    setChartData(props.data);
  }

  return (
    <div className="graphArea minH301">
      <ul>
        <li>
          <div className="graphL">
            <p>투자건수 기준</p>
            <div className="graphBox">
              <PieChart width={200} height={300}>
                <Pie
                  data={countData}
                  cx={96}
                  cy={96}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  fill="#8884d8"
                  isAnimationActive={false}
                >
                  {countData.map((entry, index) => (
                    <Cell key={'countDataCell' + index} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend width={500} align="center" layout="horizontal" payload={legendData} />
              </PieChart>
            </div>
          </div>
          <p className="graphL">(단위 : %)</p>
          <div className="graphR">
            <p>투자잔액 기준</p>
            <div className="graphBox">
              <PieChart width={200} height={400}>
                <Pie
                  data={balanceData}
                  cx={96}
                  cy={96}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  fill="#8884d8"
                  isAnimationActive={false}
                >
                  {balanceData.map((entry, index) => (
                    <Cell key={'balanceDataCell' + index} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

const InvestmentDetailsStatusC = (props) => {
  // 스크롤 박스 영역
  const renderThumb = ({ ...props }) => {
    return <div className="scrollBar" {...props} />;
  };

  const CustomScrollbars = (props) => (
    <Scrollbars renderThumbHorizontal={renderThumb} renderThumbVertical={renderThumb} {...props} />
  );

  const handleClick = (code) => {
    props.handleScrolling('CONTRACT_PROGRESS', code);
  };

  return (
    <div className="section">
      <h4>투자진행 중 상품 현황</h4>
      <div className="contBox">
        <div className="contBox_item flex_g4">
          <h5>상품 유형별 투자 내역</h5>
          {CM.cfnIsEmpty(props.status) || CM.cfnIsEmpty(props.status.status_list) ? (
            <div className="itemBox resultnoBox">
              <p className="resultNo">
                <ErrorOutlineIcon />
                조회된 내용이 없습니다.
              </p>
            </div>
          ) : (
            <div className="itemBox pd0 h349">
              <div className="scrollArea">
                <CustomScrollbars>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>구분</TableCell>
                          <TableCell>투자건수 (비중%)</TableCell>
                          <TableCell>투자잔액 (비중%)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.status.status_list.map((row, index) => (
                          <TableRow key={'tableRows' + index}>
                            <TableCell component="th" scope="row">
                              {row.goods_type_name}
                            </TableCell>
                            <TableCell>
                              <Link className="scrBtn" to="#detail_01" onClick={() => handleClick(row.goods_type_code)}>
                                {CM.cfnAddComma(row.invest_count)}건 ({row.invest_count_ratio}%)
                              </Link>
                            </TableCell>
                            <TableCell>
                              {CM.cfnAddComma(row.invest_balance)}원 ({row.invest_balance_ratio}%)
                            </TableCell>
                          </TableRow>
                        ))}
                        {[...Array(6 - props.status.status_list.length)].map((n, index) => {
                          if (index < 6 - props.status.status_list.length - 1) {
                            return (
                              <TableRow key={'tableRow' + index}>
                                <TableCell className="brbhide" colSpan={3}>
                                  &nbsp;
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            return (
                              <TableRow key={'tableRow' + index}>
                                <TableCell colSpan={3}>&nbsp;</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                        <TableRow key="total">
                          <TableCell component="th" scope="row">
                            전체
                          </TableCell>
                          <TableCell>
                            <Link to="#detail_01" onClick={() => handleClick('TOTAL_PRODUCT')}>
                              {CM.cfnAddComma(props.status.total_invest_count)}건
                            </Link>
                          </TableCell>
                          <TableCell>{CM.cfnAddComma(props.status.total_invest_balance)}원</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomScrollbars>
              </div>
            </div>
          )}
        </div>

        <div className="contBox_item flex_g1">
          <h5>상품 유형별 투자 비중</h5>
          {CM.cfnIsEmpty(props.status) || CM.cfnIsEmpty(props.status.status_list) ? (
            <div className="itemBox resultnoBox">
              <p className="resultNo">
                <ErrorOutlineIcon />
                조회된 내용이 없습니다.
              </p>
            </div>
          ) : (
            <div className="itemBox">
              <SimplePieChart data={props.status.status_list} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetailsStatusC;
