/*
 * @version : 2021.02.04
 * @author : 인사이드정보 사업본부팀 김소정 (iyys1130@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 연계대출 관련 참고자료 - 오즈리포트 출력 모달화면
 */
import { Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CloseRoundeIcon from '@material-ui/icons/CloseRounded';
import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import axiosUtil from '../../../../utils/axiosUtil';
import * as CM from '../../../common/util/common';

const LinkedInvestmentP = (props) => {
  const [referenceDetail, setReferenceDetail] = useState({
    p2p_company_code: '',
    p2p_company_name: '',
    loan_reference_type: '',
    inquiry_status_type: '',
    inquiry_start_date: '',
    inquiry_end_date: '',
    inquiry_date: '',
    rsp_code: '',
    rsp_message: '',
    total_count: '',
    total_amount: '',
    reference_info_list: [''],
  });

  const [loanShow, setLoanShow] = useState('ozReport hide');

  const effect = props;

  //모달 클로즈
  const handleClose = () => {
    var oz;
    oz = document.getElementById('OZViewer');
    while (oz.firstChild) {
      oz.removeChild(oz.firstChild);
    }
    setFlagState(false);
    props.setOpen(false);
    setLoanShow('hide');
  };

  const [flagState, setFlagState] = useState(false);

  //화면로딩 시 실행
  useEffect(() => {
    const getLoanReference = () => {
      return new Promise((resolve, reject) => {
        let url = 'web/companies/loans/references';
        url += `?loan_reference_type=${effect.loanReferenceType}&inquiry_status_type=${effect.inquiryStatusType}`;

        if (effect.inquiryStatusType === 'CONTRACT_PROGRESS') {
          url += `&inquiry_start_date=${effect.inquiryDate}&inquiry_end_date=${effect.inquiryDate}`;
        } else if (effect.inquiryStatusType === 'NEW_CONTRACT') {
          url += `&inquiry_start_date=${effect.inquiryStartDate}&inquiry_end_date=${effect.inquiryEndDate}`;
        }
        url += `&p2p_company_code=${effect.p2pCompanyCode}`;

        axiosUtil.cfnAxiosApi(
          url,
          'GET',
          null,
          null,
          (objStatus, response) => {
            if (CM.cfnIsNotEmpty(response)) {
              resolve(response);
            }
          },
          (objStatus, response) => {
            CM.cfnAlert(response.rsp_message);
            resolve();
          },
        );
      });
    };

    const startAxios = async () => {
      const resultData = await getLoanReference(); //연계대출 상세내역
      await setReferenceDetail({
        p2p_company_code: effect.p2pCompanyCode,
        p2p_company_name: CM.cfnReturnInstituteName(effect.p2pCompanyCode),
        loan_reference_type:
          effect.loanReferenceType === 'GENERAL_TYPE'
            ? '일반(온투업자 고유계정으로 계약체결한 경우 제외)'
            : '온투업자 고유계정으로 계약체결한 경우',
        inquiry_status_type:
          effect.inquiryStatusType === 'CONTRACT_PROGRESS' ? '대출진행 중 상품' : '신규 대출계약 체결 상품',
        inquiry_start_date: effect.inquiryStartDate,
        inquiry_end_date: effect.inquiryEndDate,
        inquiry_date: effect.inquiryDate,
        rsp_code: resultData.rsp_code,
        rsp_message: resultData.rsp_message,
        total_count: resultData.total_count,
        total_amount: resultData.total_amount,
        reference_info_list: resultData.reference_info_list,
      });
      setFlagState(true);
      //handleDetailHistory(resultData);
    };

    if (effect.open) {
      if (!flagState) {
        startAxios();
        setLoanShow('sublayerPop ozReport');
      } else if (flagState && referenceDetail.p2p_company_code !== '') {
        let elem = document.getElementById('OZViewer');
        if (elem !== null) {
          window.SetOZParamters_OZViewer = () => {
            var oz;
            oz = document.getElementById('OZViewer');
            oz.sendToActionScript('connection.servlet', process.env.REACT_APP_OZ_URL);
            oz.sendToActionScript('connection.reportname', 'LinkedLoanR.ozr');
            oz.sendToActionScript('connection.pcount', '1');
            oz.sendToActionScript('connection.args1', 'jsondata=' + JSON.stringify(referenceDetail));
            oz.sendToActionScript('viewer.isframe', 'false');
            return true;
          };
          window.start_ozjs('OZViewer', '/lib/ozhviewer/');
        }
      }
    }
  }, [effect, flagState]);

  return (
    <div className={loanShow}>
      <div className="popInner">
        <div className="popHead">
          <h1>연계대출 참고자료 리포트</h1>
          <Button onClick={handleClose} data-testid="close-modal">
            <CloseRoundeIcon />
            <span className="hide">팝업창 닫기</span>
          </Button>
        </div>
        <div className="popcontWrap">
          <div className="popCont">
            <div id="OZViewer" style={{ height: '700px' }} />
          </div>
          <div className="popBottom">
            <div className="btnArea">
              {/* <Button vatiant="contained">목록</Button>  
                            <Button vatiant="contained" className="btnCancel">취소</Button>  
                            <Button vatiant="contained" className="btnOk">확인</Button>   */}
              <Button vatiant="contained" className="btnClose" onClick={handleClose} data-testid="close-modal">
                닫기
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInvestmentP;
