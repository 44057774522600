/*
 * @version : 2021.02.22
 * @author : 인사이드정보 사업본부팀 이규섭 (gslee@insideinfo.co.kr)
 * @Copyright Notice : Copyright 2008. 금융결제원.
 * 회원정보변경
 */
import { Button, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { userStore } from '../../../stores/companies/userStore';
import axiosUtil from '../../../utils/axiosUtil';
import * as CM from '../../common/util/common';
import MemberInfoUpdateHistoryS from '../memberinfo/MemberInfoUpdateHistoryS';

const DEFALUT_HOOKS = {
  postParams: {
    current_pwd: '',
    new_pwd: '',
    new_pwd_re: '',
  },
};

const MemberInfoM = () => {
  const [postParams, setPostParams] = useState(DEFALUT_HOOKS.postParams);
  const handlePassword = (e) => {
    setPostParams((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    userStore.handleNaviTrigger();
  });

  const beforSubmit = (e) => {
    e.preventDefault();
    CM.cfnConfirm('변경하시겠습니까?', () => updatePassword());
  };

  const updatePassword = () => {
    if (checkValidation(postParams)) {
      let url = 'web/companies/users/passwords?user_id=' + userStore.getUserId();
      axiosUtil.cfnAxiosApi(
        url,
        'PUT',
        postParams,
        null,
        (objStatus, response) => {
          CM.cfnAlert('비밀번호가 변경되었습니다.', () => window.location.reload());
        },
        null,
      );
    }
  };

  const checkValidation = (param) => {
    const pattern =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_])(?!.*[^a-zA-Z0-9!@#$%^&*()_])[a-zA-Z0-9!@#$%^&*()_]{9,20}$/;

    if (param.current_pwd === '') {
      CM.cfnAlert('기존 비밀번호를 입력해주세요.');
      return false;
    }
    if (param.new_pwd === '') {
      CM.cfnAlert('새 비밀번호를 입력해주세요.');
      return false;
    }
    if (param.new_pwd_re === '') {
      CM.cfnAlert('새 비밀번호 확인을 입력해주세요.');
      return false;
    }
    if (param.new_pwd !== param.new_pwd_re) {
      CM.cfnAlert('비밀번호가 일치하지 않습니다.');
      return false;
    }
    if (!pattern.test(param.new_pwd)) {
      CM.cfnAlert('영문 + 숫자 + 특수기호 9자리 이상으로 구성해야 합니다.');
      return false;
    }
    if (param.new_pwd.indexOf(userStore.getUserId()) > -1) {
      CM.cfnAlert('계정ID를 포함할 수 없습니다.');
      return false;
    }
    if (checkPattern(param.new_pwd, 4)) {
      CM.cfnAlert('연속된 문자를 포함할 수 없습니다.');
      return false;
    }
    return true;
  };

  const checkPattern = (str, max) => {
    if (!max) max = 4;
    let i, j, k, x, y;
    var buff = ['0123456789', 'abcdefghijklmnopqrstuvwxyz', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
    var src,
      src2,
      ptn = '';
    for (i = 0; i < buff.length; i++) {
      src = buff[i];
      src2 = buff[i] + buff[i];
      for (j = 0; j < src.length; j++) {
        x = src.substr(j, 1);
        y = src2.substr(j, max);
        ptn += '[' + x + ']{' + max + ',}|';
        ptn += y + '|';
      }
    }
    ptn = new RegExp(ptn.replace(/.$/, ''));
    if (ptn.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="subContent">
      <div className="subInner">
        <div className="section">
          <h4>회원정보 변경</h4>
          <div className="searchArea mb0">
            <div className="tblSearch">
              <form>
                <table>
                  <colgroup>
                    <col width="30%" />
                    <col width="40%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">기존 비밀번호</th>
                      <td colSpan="2" className="pt16">
                        <div className="searchList w51_5p">
                          <div className="inputTxt" noValidate autoComplete="off">
                            <TextField
                              id="basicinput"
                              name="current_pwd"
                              data-testid="current_pwd"
                              type="password"
                              variant="outlined"
                              onChange={handlePassword}
                              value={postParams.current_pwd}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">새 비밀번호</th>
                      <td colSpan="2">
                        <div className="searchList w51_5p">
                          <div className="inputTxt" noValidate autoComplete="off">
                            <TextField
                              id="basicinput"
                              name="new_pwd"
                              data-testid="new_pwd"
                              type="password"
                              variant="outlined"
                              onChange={handlePassword}
                              value={postParams.new_pwd}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">새 비밀번호 확인</th>
                      <td>
                        <div className="searchList w90p">
                          <div className="inputTxt" noValidate autoComplete="off">
                            <TextField
                              id="basicinput"
                              name="new_pwd_re"
                              data-testid="new_pwd_re"
                              type="password"
                              variant="outlined"
                              onChange={handlePassword}
                              value={postParams.new_pwd_re}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <Button variant="contained" data-testid="submitButton" onClick={beforSubmit}>
                          비밀번호 변경
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span className="hide">참고사항</span>
                      </th>
                      <td colSpan="2">
                        <p className="info gold">
                          ※ 숫자·영문자·허용된 특수문자(!@#$%^&*()-_) 1자리 이상 혼합해 길이 9~20자리
                        </p>
                        <p className="info gold">
                          ※ 연속숫자, 연속문자 4자리 이상 포함불가 (abcd, dcba, zyxw, 1234, 4321, 9876 등)
                        </p>
                        <p className="info gold">※ 동일숫자, 동일문자 4자리 이상 포함불가 (aaaa, 1111 등)</p>
                        <p className="info gold">※ 기존 비밀번호 사용불가</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="recordArea">
                    <MemberInfoUpdateHistoryS ></MemberInfoUpdateHistoryS>
                </div> */}
      </div>
    </div>
  );
};

export default MemberInfoM;
